import React from "react";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { BrowserTracing } from "@sentry/tracing";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import UserProvider from "state/userContext/userContext";
import App from "pages/App/App";
import ErrorPage from "components/Blocks/ErrorPage";
import "index.css";
import "flexboxgrid.css";
import "normalize.css";
import { name, version } from "../package.json";

console.log("You are using Correlation One " + name + " version: " + version);

Sentry.init({
  dsn:
    "https://a2c90500389a4d1dac22e6b0f7dcdf2d@o1174160.ingest.sentry.io/6331765",
  integrations: [
    new BrowserTracing(),
    new CaptureConsole({
      levels: ["error"]
    }),
    new Replay({
      sessionSampleRate: 0,
      errorSampleRate: 1.0
    })
  ],
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.NODE_ENV === "production",
  release: version,
  tracesSampleRate: 0.05
});

const favicon = document.getElementById("favicon");
const isTalentstat = origin.includes(process.env.REACT_APP_TALENTSTAT_DOMAIN);
const theme = {
  fonts: {
    avenirNextMedium: "AvenirNextLTPro-Medium",
    avenirProHeavy: "AvenirLTPro-Heavy",
    avenirProRoman: "AvenirLTPro-Roman",
    avenirProMedium: "AvenirLTPro-Medium",
    avenirProBook: "AvenirLTPro-Book",
    avenirProBlack: "AvenirLTPro-Black",
    condensedFontFamily: "AvenirNextLTPro-BoldCn",
    roboto: "Roboto"
  },
  breakpoints: {
    small: "48em", // 768px
    medium: "62em", // 992px
    large: "75em" // 1200px
  },
  colors: {
    black: "#000000",
    brickOrange: "#df7c48",
    correctGreen: "#e6f1de",
    darkBlue: "#2b2b3b",
    disableGrey: "#ececec",
    grey: "#f0f2f4",
    gunmetal: "#212629",
    iron: "#dadcdf",
    errorRed: "#da1e28",
    invalidRed: "#f70e0ee0",
    incorrectRed: "#faddd6",
    lightGrey: "#f8f8f8",
    lightSilver: "#d7d7d7",
    mantisGreen: "#7ebf5a",
    mercury: "#e4e4e4",
    mediumGrey: "#c9c9c9",
    skyBlue: "#34b7ea10",
    slateGrey: "#6a7f87",
    smoky: "#575766",
    steel: "#939393",
    transparent: "transparent",
    veryLightGrey: "#cbcbcb",
    white: "#ffffff",
    whiteSmoke: "#f5f5f5"
  },
  tenantAccent: isTalentstat ? "#6fbc96" : "#59b0a1",
  domainTheme: isTalentstat
    ? {
        logo:
          "https://storage.googleapis.com/assessments-server-assets-prod/prod/logos/proctoring/tenant-logo-talentstat.png",
        background:
          "linear-gradient(-180deg, rgb(44, 55, 76) 0%, rgb(57, 72, 101) 100%)"
      }
    : {
        logo:
          "https://storage.googleapis.com/assessments-server-assets-prod/prod/logos/proctoring/tenant-logo-c1.png",
        background:
          "linear-gradient(-180deg, rgb(27, 27, 50) 0%, rgb(46, 46, 76) 100%)"
      }
};

if (isTalentstat)
  favicon.href =
    "https://storage.googleapis.com/c1-assessments-assets/ts-favicon.ico";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID
  });

  ReactDOM.render(
  <LDProvider>
  <ThemeProvider theme={theme}>
    <UserProvider>
      <Sentry.ErrorBoundary fallback={ErrorPage}>
        <App />
      </Sentry.ErrorBoundary>
    </UserProvider>
  </ThemeProvider>
  </LDProvider>,
  document.getElementById("root")
  );
}
)();
