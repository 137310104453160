import React from "react";
import styled from "styled-components";
import { useUserContext } from "state/userContext/useUserContext";
import AssessmentShareLink from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/AssessmentShareLink";
import { FlexContainer } from "components/Atoms/Containers";
import { Body1, Subtitle2 } from "components/Atoms/Text";
import {
  Row,
  Cell,
  BorderTable,
  BorderCell
} from "components/Blocks/Styled/Tables";

const TakerAtAGlance = ({ takerData, candidateName }) => {
  const { isAnonymous } = useUserContext();
  const formattedDate = new Date(takerData.submitted_at)
    .toISOString()
    .split("T")[0];

  const formattedTime = new Date(takerData.time_taken_seconds * 1000)
    .toISOString()
    .substr(11, 8);

  const candidatePercentile = takerData?.assessment_percentile
    ? `${parseFloat(takerData.assessment_percentile * 100).toFixed(2)}%`
    : "-";

  const renderScores = () => {
    const totalScore = { obtained: 0, maxScore: 0 };

    const sectionScores = takerData.section_attempts.map(section => {
      const sectionScore = Object.values(section.category_scores).reduce(
        (sum, { weighted_score, weighted_max_score }) => {
          totalScore.obtained += weighted_score;
          totalScore.maxScore += weighted_max_score;

          return {
            obtained: sum.obtained + weighted_score,
            maxScore: sum.maxScore + weighted_max_score
          };
        },
        { obtained: 0, maxScore: 0 }
      );

      return (
        <FlexContainer
          key={section.id}
          padding="0 24px 9px"
          justify="space-between"
        >
          <Body1 textTransform="capitalize" color="#6a7f87">
            {section.name}
          </Body1>
          <Subtitle2>
            {sectionScore.obtained.toFixed(2)} /{" "}
            {sectionScore.maxScore.toFixed(2)}
          </Subtitle2>
        </FlexContainer>
      );
    });

    return (
      <>
        {sectionScores}
        {renderTotalScores(totalScore)}
      </>
    );
  };

  const renderTotalScores = totalScore => {
    return (
      <ScoreContainer justify="space-between" align="baseline">
        <Body1 className="bold" textTransform="capitalize" color="#6a7f87">
          Total Score
        </Body1>
        <Subtitle2 className="bold">
          {totalScore.obtained.toFixed(2)} / {totalScore.maxScore.toFixed(2)}
        </Subtitle2>
      </ScoreContainer>
    );
  };

  return (
    <BorderTable borderCollapse="collapse">
      <thead>
        <Row>
          <Cell padding="16px 24px" colSpan="3">
            {candidateName.length > 0 && (
              <CandidateNameSubtitle2>
                {candidateName[0].result}
              </CandidateNameSubtitle2>
            )}
            <Body1>{takerData.taker.user_id ?? takerData.taker.email}</Body1>
          </Cell>
          <Cell padding="16px 24px" textAlign="right">
            {!isAnonymous && (
              <FlexContainer justify="flex-end">
                <AssessmentShareLink />
              </FlexContainer>
            )}
          </Cell>
        </Row>
      </thead>
      <tbody>
        <Row>
          <BorderCell textAlign="center">
            <Subtitle2>{formattedDate}</Subtitle2>
            <Body1 textTransform="capitalize" padding="6px 0 0" color="#6a7f87">
              date
            </Body1>
          </BorderCell>
          <BorderCell textAlign="center">
            <Subtitle2>{formattedTime}</Subtitle2>
            <Body1 textTransform="capitalize" padding="6px 0 0" color="#6a7f87">
              time spent
            </Body1>
          </BorderCell>
          <BorderCell textAlign="center">
            <Subtitle2 className="bold">{candidatePercentile}</Subtitle2>
            <Body1 textTransform="capitalize" padding="6px 0 0" color="#6a7f87">
              candidate percentile
            </Body1>
          </BorderCell>
          <BorderCell padding="16px 0" textAlign="center">
            {renderScores()}
          </BorderCell>
        </Row>
      </tbody>
    </BorderTable>
  );
};

export default TakerAtAGlance;

const ScoreContainer = styled(FlexContainer)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  padding: 16px 24px 0;
`;

const CandidateNameSubtitle2 = styled(Subtitle2)`
  color: rgb(0, 0, 0);
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 16px;
  height: 28px;
  letter-spacing: 0.44px;
  line-height: 28px;
  text-transform: capitalize;
  width: auto;
`;
