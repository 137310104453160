import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";
import downArrowVIcon from "assets/downArrowVIcon";

const ITEMS_LIMIT = 8;

const QuestionNavigator = ({ questionIds, active, setActive }) => {
  const [activeViewMore, setActiveViewMore] = useState(false);
  const copyIds = [...questionIds];
  const firstIds = copyIds.splice(0, ITEMS_LIMIT);
  const restIds = [...copyIds];

  const moreItemsRef = useRef();

  useClickedOutsideEvent(moreItemsRef, () => setActiveViewMore(false));

  return (
    <FlexContainer>
      {firstIds.map(id => (
        <QuestionPill
          key={id}
          data-testid={`question-${id}`}
          align="center"
          justify="center"
          isActive={active === id}
          onClick={() => setActive(id)}
        >
          <QuestionId>{id}</QuestionId>
        </QuestionPill>
      ))}
      {!!restIds.length && (
        <QuestionPill
          key="see-view-more"
          align="center"
          justify="center"
          isActive={restIds.includes(active)}
          onClick={() => setActiveViewMore(true)}
          ref={moreItemsRef}
          isViewMore
        >
          <QuestionId isActive={restIds.includes(active)}>
            <FlexContainer align="center">
              {restIds.includes(active) && active}
              {downArrowVIcon}
            </FlexContainer>
          </QuestionId>
          {activeViewMore && (
            <MoreIdsDropdown align="center" direction="column">
              {restIds.map(id => (
                <DropdownItem key={id} onClick={() => setActive(id)}>
                  {id}
                </DropdownItem>
              ))}
            </MoreIdsDropdown>
          )}
        </QuestionPill>
      )}
    </FlexContainer>
  );
};

export default QuestionNavigator;

QuestionNavigator.propTypes = {
  questionIds: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired
};

const QuestionPill = styled(FlexContainer)`
  background: ${({ theme }) => theme.tenantAccent}1a;
  border: 2px solid transparent;
  border-radius: 20px;
  margin-right: 6px;
  max-width: ${({ isViewMore }) => !isViewMore && "40px"};
  padding: 6px 10px;
  position: relative;
  ${({ isActive, theme }) =>
    !isActive &&
    `
  background: ${theme.colors.white};
  border: 2px solid #e3e7eb;
  cursor: pointer;
  span {
    color: #c5cad1;
  }
  `}
`;

const QuestionId = styled.span`
  color: ${({ theme }) => theme.tenantAccent};
  display: contents;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  letter-spacing: 0.1px;
  svg {
    fill: ${({ theme }) => theme.tenantAccent};
  }
  ${({ isActive }) =>
    isActive &&
    `
  svg {
    fill: 34b7ea;
    margin-left:4px;
  }`}
`;

const MoreIdsDropdown = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.mercury};
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  max-height: 600%;
  min-width: 65px;
  overflow-y: scroll;
  padding: 4px;
  position: absolute;
  top: calc(100% + 4px);
`;
const DropdownItem = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-bottom: 2px;
  padding: 5px 8px;
  text-align: left;
  width: 100%;
  &:hover {
    background: #f2f2f2;
  }
`;
