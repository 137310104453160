import React from "react";

const leftArrowCompleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-48 -12)">
        <g transform="translate(48 12)">
          <g fill="#575766" transform="translate(6 6)">
            <path
              fillRule="nonzero"
              d="M13.818 3.637a1.83 1.83 0 011.866 1.554l.019.211.262 9.091a1.824 1.824 0 01-1.677 1.864l-.208.006-9.166-.26a1.825 1.825 0 01-1.78-1.87 1.825 1.825 0 011.67-1.76l.215-.006 7.225.206-.206-7.166a1.823 1.823 0 011.566-1.852l.214-.018z"
              transform="rotate(135 9.55 10)"
            ></path>
            <rect width="15" height="4" x="4" y="8" rx="2"></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default leftArrowCompleteIcon;
