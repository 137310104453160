import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Underline = ({ type }) => {
  switch (type) {
    case "nav-link":
      return <UnderlineNavLink />;
    case "filter-list":
      return <UnderlineFilterList />;
    default:
      return <UnderlineLine />;
  }
};

export default Underline;

const UnderlineLine = styled.div`
  background-color: ${({ theme }) => theme.colors.steel};
  height: 1px;
  margin-top: 5px;
  width: 100%;
`;

const UnderlineNavLink = styled.div`
  background: ${({ theme }) => theme.tenantAccent};
  height: 2px;
  width: 100%;
`;

const UnderlineFilterList = styled.div`
  background: ${({ theme }) => theme.colors.darkBlue};
  min-height: 1.5px;
  width: 110%;
`;

Underline.propTypes = {
  type: PropTypes.string
};
