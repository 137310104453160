import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import Modal from "components/Systems/Modal";
import freeResponseQuestionIcon from "assets/freeResponseQuestionIcon";
import multipleChoiceQuestionIcon from "assets/multipleChoiceQuestionIcon";
import uploadIcon from "assets/uploadIcon";
import videoUploadIcon from "assets/videoRecordIcon";

const SelectQuestionType = ({
  fileUploadClickHandler,
  freeResponseClickHandler,
  multipleChoiceQuestionHandler,
  videoUploadClickHandler,
  onClose
}) => {
  return (
    <Modal
      data-testid="select-question"
      onClose={onClose}
      title="Select a question type you want to start"
    >
      <ContentWrapper align="center" direction="row" justify="space-evenly">
        <QuestionContainer
          align="center"
          data-testid="multiple-choice"
          direction="column"
          onClick={multipleChoiceQuestionHandler}
        >
          {multipleChoiceQuestionIcon}
          <QuestionTypeLabel>Multiple choice</QuestionTypeLabel>
        </QuestionContainer>
        <QuestionContainer
          align="center"
          data-testid="free-response"
          direction="column"
          onClick={freeResponseClickHandler}
        >
          {freeResponseQuestionIcon}
          <QuestionTypeLabel>Free response</QuestionTypeLabel>
        </QuestionContainer>
        <QuestionContainer
          align="center"
          data-testid="file-upload"
          direction="column"
          onClick={fileUploadClickHandler}
        >
          {uploadIcon}
          <QuestionTypeLabel>File Upload</QuestionTypeLabel>
        </QuestionContainer>
        <QuestionContainer
          align="center"
          data-testid="video-upload"
          direction="column"
          onClick={videoUploadClickHandler}
        >
          {videoUploadIcon}
          <QuestionTypeLabel>Video Record</QuestionTypeLabel>
        </QuestionContainer>
      </ContentWrapper>
    </Modal>
  );
};

export default SelectQuestionType;

SelectQuestionType.propTypes = {
  freeResponseClickHandler: PropTypes.func.isRequired,
  multipleChoiceQuestionHandler: PropTypes.func.isRequired,
  videoUploadClickHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const ContentWrapper = styled(FlexContainer)`
  padding: 40px 20px;
`;

const QuestionContainer = styled(FlexContainer)`
  border: 1px solid rgb(229, 225, 225);
  border-radius: 4px;
  cursor: pointer;
  max-height: 130px;
  padding: 30px 35px 22px;
  &:hover {
    border-color: ${({ theme }) => theme.tenantAccent};
  }
  @media (max-width: 992px) {
    padding: 30px 8% 22px;
  }
`;

const QuestionTypeLabel = styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin-top: 18px;
  text-transform: capitalize;
`;
