import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import { H4, Label } from "components/Atoms/Text";
import calculateDuration from "utils/helpers/calculateDuration";
import secondsToTimeStamp from "utils/helpers/secondsToTimeStamp";

const PreviewSectionHead = ({ section }) => {
  const getDuration = section =>
    section.time_allowed_seconds
      ? secondsToTimeStamp(section.time_allowed_seconds)
      : secondsToTimeStamp(
          calculateDuration({ section: section.slots, mode: "preview-page" })
        );

  const duration = getDuration(section);

  return (
    <PreviewSectionHeadWrapper>
      <SectionInfoBorder>
        <SectionInfo>
          <ItemGroup>
            <ItemLabel>Section</ItemLabel>
            <Item>{section.title}</Item>
          </ItemGroup>
          <ItemGroup>
            <ItemLabel>Duration</ItemLabel>
            <Item>{duration}</Item>
          </ItemGroup>
          <ItemGroup>
            <ItemLabel>Number of Questions:</ItemLabel>
            <Item>{section.slots.length}</Item>
          </ItemGroup>
        </SectionInfo>
      </SectionInfoBorder>
      <div>
        <ItemGroup>
          <ItemLabel>Description:</ItemLabel>
          <div>{parseHtml(section.description)}</div>
        </ItemGroup>
      </div>
    </PreviewSectionHeadWrapper>
  );
};

export default PreviewSectionHead;

PreviewSectionHead.propTypes = {
  section: PropTypes.object.isRequired
};

const SectionInfo = styled.div`
  display: inline-block;
`;

const SectionInfoBorder = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  margin-bottom: 24px;
  width: 100%;
`;

const PreviewSectionHeadWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px 48px;
`;

const ItemGroup = styled.div`
  display: inline-block;
  margin: 12px 18px;
  &:first-of-type {
    margin-left: 0;
  }
`;

const ItemLabel = styled(Label)`
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Item = styled(H4)`
  color: ${({ theme }) => theme.tenantAccent};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
`;
