import React, { useEffect, useState } from "react";
import equal from "fast-deep-equal";
import { saveAs } from "file-saver";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAssessmentContext } from "state/assessmentContext/useAssessmentContext";
import { useUserContext } from "state/userContext/useUserContext";
import { listTypes } from "pages/App/helpers/listTypes";
import { paths } from "pages/App/helpers/paths";
import { permissionTypes } from "pages/App/helpers/permissionTypes";
import AddAssessment from "pages/ManagementPage/AddAssessment";
import { assessmentStates, tenantAccount } from "pages/PanelPage/Constants";
import AssessmentCard from "pages/PanelPage/components/AssessmentCard";
import Navbar from "pages/PanelPage/components/Navbar";
import usePrevious from "pages/PanelPage/hooks/usePrevious";
import {
  getAssessmentsMW,
  getTenantSummaryCsvMW
} from "pages/PanelPage/middleware";
import {
  Container,
  MaxWidthContainer,
  FlexContainer,
  PageContentContainer
} from "components/Atoms/Containers";
import FilterList from "components/Blocks/FilterList";
import Button from "components/Blocks/Forms/Button";
import NoResults from "components/Blocks/NoResults";
import PopupNotification from "components/Blocks/PopupNotification";
import SortList from "components/Blocks/SortList";
import { PageWrapper } from "components/Blocks/Styled/Wrappers";
import AccessControl from "components/Systems/AccessControl";
import Loader from "components/Systems/Loader";
import {
  VALID_STATES as VS,
  INACTIVE_ASSESSMENTS
} from "utils/constants/stateTypes";

const AssessmentList = () => {
  const history = useHistory();
  const {
    pathname,
    createdAssessment = null,
    wasEdited = false
  } = useLocation();

  const {
    assessmentState: { assessmentList },
    assessmentDispatch
  } = useAssessmentContext();

  const {
    userState: { userData }
  } = useUserContext();

  const [sortByRecent, setSortByRecent] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [stateList, setStateList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [tenantData, setTenantData] = useState(null);
  const [isTenantSummaryExporting, setIsTenantSummaryExporting] = useState(
    false
  );

  useEffect(() => {
    const handleFilters = () => {
      switch (pathname) {
        case `${paths.ADMIN}/${VS.ALL}`:
          setStateList(Object.values(assessmentStates));
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.ALL}/${VS.ACTIVE}`:
          setStateList([VS.ACTIVE]);
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.ALL}/${VS.REVIEW}`:
          setStateList([VS.REVIEW]);
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.ALL}/${VS.ARCHIVED}`:
          setStateList([VS.ARCHIVED]);
          setSelectedFilter(VS.ALL);
          break;
        case `${paths.ADMIN}/${VS.INACTIVE}`:
          setStateList(INACTIVE_ASSESSMENTS);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${paths.ADMIN}/${VS.INACTIVE}/${VS.REVIEW}`:
          setStateList([VS.REVIEW]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${paths.ADMIN}/${VS.INACTIVE}/${VS.ARCHIVED}`:
          setStateList([VS.ARCHIVED]);
          setSelectedFilter(VS.INACTIVE);
          break;
        case `${paths.ADMIN}/${VS.ACTIVE}`:
          setStateList([VS.ACTIVE]);
          setSelectedFilter(VS.ACTIVE);
          break;
        default:
          break;
      }
    };
    handleFilters();
  }, [pathname]);

  const prevStateList = usePrevious(stateList);
  const prevSortByRecent = usePrevious(sortByRecent);
  const prevTenantData = usePrevious(tenantData);

  useEffect(() => {
    assessmentDispatch.setSelectedAssessment(null);
    const params = new URLSearchParams(location.search);
    const hasSortOrder = params.has("sort_order");
    const sortOrder = sortByRecent ? "desc" : "asc";

    if (hasSortOrder) {
      params.set("sort_order", sortOrder);
    } else {
      params.append("sort_order", sortOrder);
    }

    const hasAssessmentStates = params.has("assessment_states");

    if (!hasAssessmentStates) {
      stateList.forEach(state => params.append("assessment_states", state));
    }

    const hasNoSearchParams = !location.search;

    if (hasNoSearchParams) {
      const tenantId = tenantData?.id ?? userData?.tenant_id;

      if (tenantId && tenantId !== tenantAccount.ALL_ACCOUNTS) {
        params.append("tenant_id", tenantId);
      } else {
        return;
      }
    }

    const searchParams = params.toString();

    history.push({
      search: new URLSearchParams(searchParams).toString()
    });

    const fetchAssessments = async () => {
      let results = await getAssessmentsMW({ queryParams: params });
      assessmentDispatch.setAssessments(results?.data);
      setIsLoading(false);
    };
    stateList.length &&
      (!equal(prevStateList, stateList) ||
        sortByRecent !== prevSortByRecent ||
        !equal(prevTenantData, tenantData)) &&
      fetchAssessments();
  }, [stateList, sortByRecent, tenantData]);

  const renderAssessmentList = () => {
    return assessmentList?.length ? (
      assessmentList.map(assessment => (
        <AssessmentCard
          key={assessment.id}
          assessment={assessment}
          tenantId={tenantData?.id}
        />
      ))
    ) : (
      <NoResults />
    );
  };

  const renderPopup = () => {
    const action = wasEdited ? "edited" : "created";
    return (
      (createdAssessment || wasEdited) && (
        <PopupContainer>
          <PopupNotification
            content={`You have successfully ${action} assessment ${createdAssessment}`}
            title={`New assessment has been ${action}`}
          />
        </PopupContainer>
      )
    );
  };

  const exportTenantSummary = async () => {
    setIsTenantSummaryExporting(true);
    const id = tenantData?.id ?? userData.tenant_id;
    const csv = await getTenantSummaryCsvMW({
      urlParams: [id]
    });
    const contentType = csv.headers["content-type"];
    const blob = new Blob([csv.data], { type: contentType });
    saveAs(blob, "tenant_summary.csv");
    setIsTenantSummaryExporting(false);
  };
  const shouldExportSummaryButtonRender =
    !window.location.href.includes("inactive") &&
    (tenantData?.id > 0 || (!tenantData && userData.tenant_id > 0)) &&
    (tenantData?.id || userData.tenant_id) &&
    assessmentList?.length;

  return (
    <PageWrapper background="#f0f2f4" id="admin-page">
      <Navbar
        setStateList={setStateList}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        setTenantId={setTenantData}
        tenantId={tenantData?.id}
      />
      <PageContentContainer>
        <MaxWidthContainer>
          <Container width="100%">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <ActionsContainer align="center" justify="space-between">
                  <FilterList
                    type={listTypes.ASSESSMENTS}
                    onFilterClick={setStateList}
                    selectedFilter={selectedFilter}
                  />
                  <AccessControl
                    allowedPermissions={[
                      permissionTypes.CAN_CREATE_ASSESSMENTS,
                      permissionTypes.CAN_CREATE_ALL_ASSESSMENTS
                    ]}
                    renderNoAccess={() => null}
                  >
                    <AddNewAssessment>
                      <AddAssessment />
                    </AddNewAssessment>
                  </AccessControl>
                  {shouldExportSummaryButtonRender && (
                    <ExportButton
                      onClick={exportTenantSummary}
                      text={"Get Summary"}
                      disabled={isTenantSummaryExporting}
                      loading={isTenantSummaryExporting}
                    />
                  )}
                  <SortList
                    handleSort={() => setSortByRecent(!sortByRecent)}
                    isCaretUp={!sortByRecent}
                    text="created at"
                  />
                </ActionsContainer>
                <Container width="100%">
                  {renderPopup()}
                  {renderAssessmentList()}
                </Container>
              </>
            )}
          </Container>
        </MaxWidthContainer>
      </PageContentContainer>
    </PageWrapper>
  );
};

export default AssessmentList;

const ActionsContainer = styled(FlexContainer)`
  margin-bottom: 36px;
  width: 100%;
`;

const AddNewAssessment = styled.section`
  > button {
    position: absolute;
    right: 0;
    top: -52px;
  }
`;

const PopupContainer = styled.section`
  position: absolute;
  right: 0;
  top: -80px;
  z-index: 10;
`;

const ExportButton = styled(Button)`
  align-items: center;
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 12px;
  font-weight: normal;
  height: 32px;
  justify-content: center;
  margin-right: auto;
  padding: 8px;
  width: 140px;
`;
