import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import Modal from "components/Systems/Modal";

const QuestionUpdateWarning = ({
  setShowWarningModal,
  affectedAssessments,
  updateQuestion,
  isDelete,
  setQuestionUpdateData
}) => {
  const modalTitle = isDelete ? "Delete Question" : "";

  return (
    <Modal
      onClose={() => {
        setShowWarningModal(false);
        setQuestionUpdateData({});
      }}
      title={modalTitle}
    >
      <FlexContainer direction="column" justify="center">
        {isDelete && !affectedAssessments.length && (
          <ModalDescription>
            Are you sure you want to delete this question?
          </ModalDescription>
        )}
        {!!affectedAssessments.length && (
          <>
            <ModalDescription>
              This question is currently being used on the following
              assessments:
            </ModalDescription>
            <ListWrapper>
              {affectedAssessments.map(({ name, tenant }, index) => (
                <ModalDescription
                  data-testid="affected-assessment"
                  key={index}
                  className="bold"
                >
                  {name} by {tenant.name}
                </ModalDescription>
              ))}
            </ListWrapper>
            <ModalDescription>
              Please make sure this action will not disrupt the listed
              assessments before continuing.
            </ModalDescription>
          </>
        )}
        <FlexContainer>
          <ModalButton
            onClick={() => {
              setShowWarningModal(false);
              updateQuestion();
              setQuestionUpdateData({});
            }}
            data-testid="update-question"
          >
            CONTINUE WITH CHANGE
          </ModalButton>
          <ModalButton
            background="#d94a54"
            data-testid="cancel-update"
            onClick={() => {
              setShowWarningModal(false);
              setQuestionUpdateData({});
            }}
          >
            CANCEL
          </ModalButton>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

QuestionUpdateWarning.propTypes = {
  setQuestionUpdateData: PropTypes.func.isRequired,
  setShowWarningModal: PropTypes.func.isRequired,
  affectedAssessments: PropTypes.array.isRequired,
  updateQuestion: PropTypes.func.isRequired,
  isDelete: PropTypes.bool.isRequired
};

export default QuestionUpdateWarning;

const ListWrapper = styled.div`
  margin: 12px 0;
`;

const ModalButton = styled(StyledButton)`
  margin: 16px 32px 16px 0;
`;

const ModalDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 16px;
  letter-spacing: 0.4px;
  margin: 12px 0;
  &.bold {
    font-weight: 900;
  }
`;
