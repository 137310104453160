import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import Input from "components/Blocks/Forms/Input";
import calendarBlackIcon from "assets/calendarBlackIcon";

const CalendarPicker = ({
  className,
  dateFilter,
  endInputName,
  endInputvalidations,
  handleDatePicker,
  isFullWidth,
  isRangeSelection,
  shouldCloseCalendar,
  showLabels,
  startInputName,
  startInputValidations
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (shouldCloseCalendar) {
      setShowCalendar(false);
    }
  }, [shouldCloseCalendar]);

  const formatDay = date =>
    date
      .getDate()
      .toString()
      .padStart(2, 0);

  const formatMonth = date =>
    (date.getMonth() + 1)
      .toString()
      .toString()
      .padStart(2, 0);

  const formatDisplayedDate = date => {
    const month = formatMonth(dateFilter[date]);
    const day = formatDay(dateFilter[date]);
    const year = dateFilter[date].getFullYear();

    return `${month}/${day}/${year}`;
  };

  return (
    <>
      <PickerContainer
        className={className}
        justify="space-between"
        width={isFullWidth && "100%"}
      >
        {isRangeSelection && (
          <FlexContainer
            direction="column"
            align="start"
            width={isFullWidth && "100%"}
          >
            {showLabels && <DateOptions>Start date</DateOptions>}
            <DateInput
              onClick={() => setShowCalendar(!showCalendar)}
              isRanged={isRangeSelection}
              width={isFullWidth && "100%"}
            >
              <StyledDateInput
                type="text"
                name={startInputName || "startDate"}
                placeholder="mm/dd/yyyy"
                value={dateFilter.startDate && formatDisplayedDate("startDate")}
                validations={startInputValidations}
                isFullWidth={isFullWidth}
              />
              <CalendarIcon>{calendarBlackIcon}</CalendarIcon>
            </DateInput>
          </FlexContainer>
        )}
        <FlexContainer
          direction="column"
          align="start"
          width={isFullWidth && "100%"}
        >
          {showLabels && <DateOptions>End date</DateOptions>}
          <DateInput
            onClick={() => setShowCalendar(!showCalendar)}
            width={isFullWidth && "100%"}
          >
            <StyledDateInput
              type="text"
              name={endInputName || "endDate"}
              placeholder="mm/dd/yyyy"
              value={dateFilter.endDate && formatDisplayedDate("endDate")}
              validations={endInputvalidations}
              isFullWidth={isFullWidth}
            />
            <CalendarIcon>{calendarBlackIcon}</CalendarIcon>
          </DateInput>
        </FlexContainer>
      </PickerContainer>
      {showCalendar && (
        <StyledCalendar
          value={[dateFilter.startDate, dateFilter.endDate]}
          onChange={dateRange => {
            handleDatePicker(dateRange);
            setShowCalendar(false);
          }}
          selectRange={isRangeSelection}
        />
      )}
    </>
  );
};

export default CalendarPicker;

CalendarPicker.propTypes = {
  dateFilter: PropTypes.object,
  handleDatePicker: PropTypes.func,
  isRangeSelection: PropTypes.bool,
  className: PropTypes.string,
  showLabels: PropTypes.bool,
  shouldCloseCalendar: PropTypes.bool,
  startInputName: PropTypes.string,
  endInputName: PropTypes.string,
  startInputValidations: PropTypes.object,
  endInputvalidations: PropTypes.object
};

CalendarPicker.defaultProps = {
  dateFilter: {
    startDate: new Date(),
    endDate: new Date()
  },
  handleDatePicker: () => null,
  isRangeSelection: false,
  className: "",
  showLabels: true,
  shouldCloseCalendar: false,
  startInputName: "",
  endInputName: "",
  startInputValidations: {},
  endInputvalidations: {}
};

const StyledCalendar = styled(Calendar)`
  border: none;
  font-size: 12px;
  padding: 0 27px 20px;
  position: relative;
  z-index: 10;
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${({ theme }) => theme.tenantAccent};
  }
  .react-calendar__tile--now {
    background: inherit;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
    text-decoration: underline;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: ${({ theme }) => theme.tenantAccent};
    color: ${({ theme }) => theme.colors.white};
  }
  .react-calendar__month-view__weekdays {
    text-transform: capitalize;
    color: #656565;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #656565;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #cccccc;
  }
  abbr[title] {
    text-decoration: none;
    font-size: 12px;
  }
  &.react-calendar--isRangeSelection .react-calendar__tile--hover {
    background: #e6e6e6;
  }
`;

const DateOptions = styled.p`
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin: 0 0 6px;
`;

const DateInput = styled(FlexContainer)`
  cursor: pointer;
  height: 40px;
  margin-bottom: ${({ isRanged }) => !isRanged && "5px"};
`;

const StyledDateInput = styled(Input)`
  background: #f5f5f5;
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

const CalendarIcon = styled.div`
  position: absolute;
  right: 9px;
  top: 13px;
`;

const PickerContainer = styled(FlexContainer)`
  padding: 17px 25px;
`;
