/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import equal from "fast-deep-equal";
import PropTypes from "prop-types";
import styled from "styled-components";
import QuestionLibraryList from "pages/ManagementPage/questionSection/QuestionLibraryList";
import QuestionPoolEditor from "pages/ManagementPage/questionSection/QuestionPoolEditor";
import QuestionPoolNavigator from "pages/ManagementPage/questionSection/QuestionPoolNavigator";
import usePrevious from "pages/PanelPage/hooks/usePrevious";
import PopupNotification from "components/Blocks/PopupNotification";
import Modal from "components/Systems/Modal";

const TABS = {
  QUESTION_LIBRARY: "QUESTION_LIBRARY",
  QUESTION_LISTS: "QUESTION_LISTS"
};

const AddQuestionModal = ({
  onClose,
  setQuestions,
  selectedQuestions,
  selectedGroupIds,
  isQuestionPool,
  questionPools,
  handleAddQuestionPool,
  handleUpdateQuestionPool,
  handleRemoveQuestionPool
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    isQuestionPool ? TABS.QUESTION_LISTS : TABS.QUESTION_LIBRARY
  );
  const [usedVariants, setUsedVariants] = useState([]);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);
  const prevCategories = usePrevious(selectedQuestion);

  useEffect(() => {
    if (filteredCategories && equal(prevCategories, filteredCategories)) {
      setFilteredCategories(prevCategories);
    }
  }, [filteredCategories, prevCategories]);

  useEffect(() => {
    if (selectedQuestion) {
      const usedVariantsInAssessment = selectedGroupIds
        .filter(groupInfo => groupInfo[selectedQuestion.group_id])
        .map(groupInfo => groupInfo[selectedQuestion.group_id]);

      setUsedVariants(usedVariantsInAssessment);
    }
  }, [selectedQuestion, selectedGroupIds]);

  let questionSetter = setQuestions;

  if (!questionSetter) {
    questionSetter = () => {
      const newQuestions = [...selectedPool?.questions, selectedQuestion];
      handleUpdateQuestionPool(selectedPool, null, newQuestions);
      setSelectedQuestion(null);
      setShowConfirmationPopup(true);
    };
  }

  const onRemoveQuestionPool = () => {
    handleRemoveQuestionPool(selectedPool);
    setSelectedPool(null);
  };

  const renderBody = () =>
    selectedTab === TABS.QUESTION_LIBRARY ? (
      <QuestionLibraryList
        key={selectedQuestions}
        setSelectedQuestion={setSelectedQuestion}
        selectedQuestions={selectedQuestions}
        setQuestions={questionSetter}
        selectedQuestion={selectedQuestion}
        isQuestionPool={isQuestionPool}
        usedVariants={usedVariants}
      />
    ) : (
      <QuestionPoolEditor
        selectedPool={selectedPool}
        handleUpdateQuestionPool={handleUpdateQuestionPool}
        createdPools={questionPools}
        goToQuestionLibrary={() => setSelectedTab(TABS.QUESTION_LIBRARY)}
        handleRemoveQuestionPool={onRemoveQuestionPool}
        usedVariants={usedVariants}
      />
    );

  const cleanPopupHandler = () => {
    setShowConfirmationPopup(false);
  };

  const renderConfirmationPopup = () => (
    <PopupContainer>
      <PopupNotification
        cleanHandler={cleanPopupHandler}
        content={`1 question added to ${selectedPool?.title} list`}
        duration={1500}
        title="Question added"
      />
    </PopupContainer>
  );

  return (
    <StyledModal onClose={onClose}>
      {showConfirmationPopup && isQuestionPool && renderConfirmationPopup()}
      <QuestionTabs>
        <QuestionTabItem
          active={selectedTab === TABS.QUESTION_LIBRARY}
          onClick={() => setSelectedTab(TABS.QUESTION_LIBRARY)}
          isDisabled={isQuestionPool && !selectedPool}
        >
          Question Library
        </QuestionTabItem>
        {isQuestionPool && (
          <QuestionTabItem
            active={selectedTab === TABS.QUESTION_LISTS}
            onClick={() => setSelectedTab(TABS.QUESTION_LISTS)}
          >
            Question Banks
          </QuestionTabItem>
        )}
      </QuestionTabs>
      {isQuestionPool && (
        <QuestionPoolNavigator
          questionPools={questionPools}
          handleAddQuestionPool={handleAddQuestionPool}
          selectedPool={selectedPool}
          setSelectedPool={setSelectedPool}
        />
      )}
      {renderBody()}
    </StyledModal>
  );
};

export default AddQuestionModal;

AddQuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setQuestions: PropTypes.func,
  selectedQuestions: PropTypes.array,
  selectedGroupIds: PropTypes.array,
  isQuestionPool: PropTypes.bool,
  questionPools: PropTypes.array,
  handleAddQuestionPool: PropTypes.func,
  handleUpdateQuestionPool: PropTypes.func,
  handleRemoveQuestionPool: PropTypes.func
};

AddQuestionModal.defaultProps = {
  selectedQuestions: [],
  selectedGroupIds: [],
  isQuestionPool: false,
  questionPools: [],
  handleAddQuestionPool: () => null,
  handleRemoveQuestionPool: () => null,
  setQuestions: null
};

const StyledModal = styled(Modal)`
  > div {
    margin: 0 0 0 auto;
    max-width: 85%;
    min-height: 100vh;
    padding: 14px 16px 100px;
    position: relative;
    .close-btn {
      align-items: center;
      background: #e3e7eb;
      display: flex;
      height: 23px;
      justify-content: center;
      margin-right: auto;
      padding: 8px;
      position: relative;
      width: 25px;
      z-index: 10;
      > svg {
        fill: #212629;
        height: 11px;
        width: 11px;
      }
    }
  }
`;

const QuestionTabs = styled.nav`
  border-bottom: 1px solid rgb(241, 241, 241);
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 14px;
  transform: translateX(-50%);
  width: 100%;
  > div:not(:only-child) {
    margin: 0 30px;
  }
`;

const QuestionTabItem = styled.div`
  border-bottom: ${({ active, theme }) =>
    active && `4px solid ${theme.colors.gunmetal}`};
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.grey : theme.colors.gunmetal};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1px;
  line-height: 24px;
  padding-bottom: 10px;
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
  width: fit-content;
`;

const PopupContainer = styled.div`
  bottom: 36px;
  position: absolute;
  z-index: 300;
`;
