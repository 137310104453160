import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { useAssessmentContext } from "state/assessmentContext/useAssessmentContext";
import { paths } from "pages/App/helpers/paths";
import {
  c1AdminPermissions,
  permissionTypes
} from "pages/App/helpers/permissionTypes";
import ConfirmDeleteModal from "pages/ManagementPage/questionSection/ConfirmDeleteModal";
import { tenantAccount } from "pages/PanelPage/Constants";
import PreviewAssessmentButton from "pages/PanelPage/components/PreviewAssessmentButton";
import AssessmentCategoriesPie from "pages/PanelPage/components/charts/AssessmentCategoriesPie";
import assessmentStateHelpers from "pages/PanelPage/helpers/assessmentStates";
import { getAssessmentCategoryStatisticsMW } from "pages/PanelPage/middleware";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import { H6, Caption } from "components/Atoms/Text";
import MoreOptionsMenu from "components/Blocks/MoreOptionsMenu";
import AccessControl from "components/Systems/AccessControl";
import { VALID_STATES as VS } from "utils/constants/stateTypes";

const AssessmentCard = ({ assessment, tenantId }) => {
  const history = useHistory();
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const {
    assessmentDispatch: { setSelectedAssessment }
  } = useAssessmentContext();
  const {
    created_at,
    attempt_stats,
    name,
    question_count,
    duration_seconds,
    slug,
    assessment_state,
    id: assessment_id,
    tenant: assessmentTenant
  } = assessment;

  const [showCategories, setShowCategories] = useState(false);
  const [assessmentDetail, setAssessmentDetail] = useState([]);
  const [isChartLoading, setisChartLoading] = useState(false);

  const dateCreated = new Date(created_at);
  const formattedDate = `Created: ${dateCreated.getMonth() +
    1}-${dateCreated.getDate()}-${dateCreated.getFullYear()}`;

  const fetchAssessmentDetail = async () => {
    setisChartLoading(true);
    const assessmentCategoryStatistics = await getAssessmentCategoryStatisticsMW(
      { urlParams: [assessment_id] }
    );
    if (assessmentCategoryStatistics) {
      setAssessmentDetail(
        transformDataForPie(assessmentCategoryStatistics.data)
      );
      setShowCategories(true);
    }
    setisChartLoading(false);
  };

  const toggleCategoriesDetail = showCategories => {
    if (!assessmentDetail.length && showCategories) {
      fetchAssessmentDetail();
    } else {
      setShowCategories(showCategories);
    }
  };

  const transformDataForPie = obj => {
    const arr = [];
    for (let key in obj) {
      let name = key;
      if (name === "null") {
        name = "No Category";
      }
      arr.push({
        id: name,
        value: obj[key]
      });
    }
    arr.sort((a, b) => (a.id > b.id ? 1 : -1));

    return arr;
  };

  // Set the selected assessment in context
  const handleSetAssessment = () => {
    setSelectedAssessment(assessment);
  };

  // Set the selected assessment and redirect to invite page
  const handleInvite = assessment => {
    setSelectedAssessment(assessment);

    // Redirect to invite path
    history.push(`${paths.ADMIN}/${slug}/invite`);
  };

  const renderMoreOptionsMenu = () => {
    const assessmentProps = {
      assessmentState: assessment_state,
      onOptionClick: {
        [VS.ARCHIVED]: () => {
          assessmentStateHelpers.setArchived(assessment_id);
        },
        [VS.DELETE]: () => {
          setShowDeleteWarningModal(true);
        },
        [VS.EDIT]: () =>
          history.push(
            `${paths.ADMIN}/assessment-management/edit-assessment/${slug}`
          ),
        [VS.LIVE]: () => {
          assessmentStateHelpers.setLive(assessment_id);
        },
        [VS.REVIEW]: () => {
          assessmentStateHelpers.setReview(assessment_id);
        }
      }
    };
    return (
      <AccessControl
        allowedPermissions={c1AdminPermissions}
        renderNoAccess={() => null}
        shouldCompareWithAnd
      >
        <MoreOptionsContainer>
          <MoreOptionsMenu assessmentProps={{ ...assessmentProps }} />
        </MoreOptionsContainer>
      </AccessControl>
    );
  };

  const renderWarningModal = () =>
    showDeleteWarningModal && (
      <ConfirmDeleteModal
        onClose={() => setShowDeleteWarningModal(false)}
        onConfirm={() => {
          assessmentStateHelpers.delete(assessment_id);
          setShowDeleteWarningModal(false);
        }}
      />
    );

  return (
    <AssessmentCardWrapper align="center" direction="column">
      {renderWarningModal()}
      <AssessmentContainer align="center" justify="space-between">
        {assessment_state !== VS.ACTIVE && (
          <StateTag state={assessment_state}>{assessment_state}</StateTag>
        )}
        <EndContainer direction="column">
          <AssessmentTitle>
            <Link onClick={handleSetAssessment} to={`${paths.ADMIN}/${slug}`}>
              {name}
            </Link>
          </AssessmentTitle>
          <InfoContainer align="center">
            {tenantId && tenantId === tenantAccount.ALL_ACCOUNTS && (
              <>
                <AssessmentInfo
                  type="isTenantName"
                  data-testid="assessment-name-field"
                >
                  {assessmentTenant.name}
                </AssessmentInfo>
                <Divider />
              </>
            )}
            <AssessmentInfo>{question_count} questions</AssessmentInfo>
            <Divider />
            <AssessmentInfo>
              {Math.round(duration_seconds / 60)}(min)
            </AssessmentInfo>
          </InfoContainer>
          <AssessmentInfo type="isDateCreated">
            {dateCreated && formattedDate}
          </AssessmentInfo>
        </EndContainer>
        <StatsContainer align="center">
          <Stat>
            <StatNumber>{attempt_stats.INITIALIZED}</StatNumber>
            <StatTitle>Invited</StatTitle>
          </Stat>
          <Stat middle>
            <StatNumber>
              {attempt_stats.PENDING +
                attempt_stats.IN_PROGRESS +
                attempt_stats.SUBMITTED}
            </StatNumber>
            <StatTitle>Started</StatTitle>
          </Stat>
          <Stat>
            <StatNumber>{attempt_stats.COMPLETE}</StatNumber>
            <StatTitle>Completed</StatTitle>
          </Stat>
        </StatsContainer>
        <EndContainer align="center" justify="flex-end">
          <AccessControl
            allowedPermissions={[permissionTypes.CAN_CREATE_ALL_ASSESSMENTS]}
            renderNoAccess={() => null}
          >
            <PreviewAssessmentButton assessmentId={assessment_id} />
          </AccessControl>
          <AccessControl
            allowedPermissions={[permissionTypes.CAN_CREATE_TAKER_INVITE]}
            renderNoAccess={() => null}
          >
            <InviteButton
              disabled={assessment_state === VS.ARCHIVED}
              onClick={() => handleInvite(assessment)}
            >
              Invite
            </InviteButton>
          </AccessControl>
        </EndContainer>
      </AssessmentContainer>
      {renderMoreOptionsMenu()}
      <ExpandedCategoryContainer showCategories={showCategories}>
        <AssessmentCategoriesPie
          chartData={assessmentDetail}
          toggleCategoriesDetail={toggleCategoriesDetail}
          isChartLoading={isChartLoading}
        />
      </ExpandedCategoryContainer>
    </AssessmentCardWrapper>
  );
};

AssessmentCard.propTypes = {
  tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assessment: PropTypes.object
};

export default AssessmentCard;

const AssessmentCardWrapper = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #e5e1e1;
  border-radius: 4px;
  margin: 0 0 8px;
  width: 100%;
`;

const AssessmentContainer = styled(FlexContainer)`
  padding: 32px 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
  }
`;

const InfoContainer = styled(FlexContainer)`
  margin: 0 0 8px;
`;

const ExpandedCategoryContainer = styled.div`
  color: ${({ theme }) => theme.tenantAccent};
  text-align: center;
  width: 100%;
`;

const EndContainer = styled(FlexContainer)`
  @media (min-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 200px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 300px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    width: 360px;
  }
`;

const Stat = styled(FlexContainer)`
  align-items: center;
  border-left: ${({ middle }) => middle && "1px solid #eeecec;"};
  border-right: ${({ middle }) => middle && "1px solid #eeecec;"};
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 4px 0 0 0;
  width: 100px;
`;

const StatNumber = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0px;
`;

const StatsContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.lightGrey};
  border: 1px solid #eeecec;
  border-radius: 23px;
  height: 48px;
  width: 300px;
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    margin: 24px 0;
  }
`;

const AssessmentTitle = styled(H6)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  a {
    color: ${({ theme }) => theme.tenantAccent};
    text-decoration: none;
  }
`;

const AssessmentInfo = styled(Caption)`
  color: ${({ type, theme }) =>
    type === "isTenantName" ? theme.colors.black : theme.colors.slateGrey};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  font-weight: ${({ type }) => (type === "isDateCreated" ? "normal" : "bold")};
  letter-spacing: 0.49px;
  opacity: 0.7;
  padding: 3px 0 0;
`;

const StatTitle = styled(Caption)`
  color: #818181;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.48px;
`;

const Divider = styled.div`
  background: #6a7f87;
  height: 13px;
  margin: 0 8px;
  width: 1px;
`;

const StateTag = styled.div`
  background: ${({ state, theme }) =>
    state === VS.REVIEW ? "#df7c48" : theme.colors.steel};
  border-radius: 0px 0px 2px 2px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  height: 20px;
  margin-bottom: 8px;
  max-width: 100px;
  padding: 2px 16px 0;
  position: absolute;
  text-align: center;
  text-transform: capitalize;
  top: 0px;
`;

const MoreOptionsContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
`;

const InviteButton = styled(StyledButton)`
  &:disabled {
    background: ${({ theme }) => theme.colors.disableGrey};
    color: ${({ theme }) => theme.colors.lightGrey};
    cursor: not-allowed;
  }
`;
