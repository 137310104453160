export const assessmentStates = Object.freeze({
  ACTIVE: "active",
  ARCHIVED: "archived",
  REVIEW: "review"
});

export const takerFieldTypes = Object.freeze({
  SELECT: "taker_field_select",
  TEXT: "taker_field_text"
});

export const tenantAccount = Object.freeze({
  ALL_ACCOUNTS: "allAccounts"
});

export const fullNameField = "Full Name";
