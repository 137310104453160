import { questionTypes } from "pages/App/helpers/questionTypes";

export const getQuestions = (slots, questions) =>
  slots
    .map((slot, idx) => {
      const fetchedQuestionData = questions.find(
        ({ id }) => slot.question_id === id
      );

      const isSlotRandom = slot.slot_type === questionTypes.SLOT_RANDOM;
      const categoriesKey = isSlotRandom ? "category" : "categories";

      const defaultCategories =
        slot?.[categoriesKey]?.length === 1
          ? slot[categoriesKey][0]
          : slot[categoriesKey];

      let questionData = {};

      if (isSlotRandom) {
        questionData = {
          id: `question-pool-${idx}`,
          isQuestionPool: true,
          weight: slot.weight * 100,
          duration: slot.time_allowed_seconds,
          slotId: slot.id,
          selectedPool: defaultCategories.name,
          slot_type: slot.slot_type,
          category_functions: slot.category_functions
        };
      } else {
        questionData = {
          id: slot.question_id,
          weight: slot.weight * 100,
          duration: slot.time_allowed_seconds,
          slotId: slot.id,
          default_category: defaultCategories,
          question_type: fetchedQuestionData?.question_type || slot.slot_type,
          slot_type: slot.slot_type,
          category_functions: slot.category_functions
        };
      }

      if (fetchedQuestionData) {
        questionData = {
          ...fetchedQuestionData,
          ...questionData
        };
      }

      return questionData;
    })
    .filter(data => data);
