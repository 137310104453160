import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  MAX_ANSWERS_OPTIONS,
  MIN_ANSWERS_OPTIONS
} from "pages/QuestionPage/Constants";
import convertNumberToLetter from "pages/QuestionPage/helpers/convertNumberToLetter/convertNumberToLetter";
import ScoreInput from "pages/QuestionPage/questionCreation/ScoreInput";
import { Container, FlexContainer } from "components/Atoms/Containers";
import { Caption } from "components/Atoms/Text";
import Input from "components/Blocks/Forms/Input";
import { removeNumericInputSpinners } from "utils/mixins/Mixins";
import cancelIcon from "assets/cancelIcon";

const FreeResponseInputs = ({ answerOptions, setAnswerOptions }) => {
  const handleAddNew = () => {
    setAnswerOptions([
      ...answerOptions,
      { min: null, max: null, inclusive: true, score: null }
    ]);
  };

  const handleRemove = index => {
    const newOptions = [...answerOptions];
    newOptions.splice(index, 1);
    setAnswerOptions(newOptions);
  };

  const renderHeader = () => (
    <Header>
      <FreeResponseLabel>Value input</FreeResponseLabel>
      <FreeResponseLabel>Score</FreeResponseLabel>
    </Header>
  );

  const renderInput = (type, value, optionId) => (
    <OptionInput
      testId="numeric-input"
      name={`${optionId}-${type}`}
      type="number"
      step="any"
      placeholder={`${type.charAt(0).toUpperCase() + type.slice(1)} Value`}
      showErrors={false}
      value={value}
    />
  );

  const renderInputs = () =>
    answerOptions.map(({ min, max, score }, index) => {
      const answerOption = convertNumberToLetter(index);
      const optionId = `free-response-answer-option-${answerOption}`;
      return (
        <OptionContainer data-testid="answer-option" key={optionId}>
          {answerOptions.length > MIN_ANSWERS_OPTIONS && (
            <DeleteIcon onClick={() => handleRemove(index)}>
              {cancelIcon}
            </DeleteIcon>
          )}
          <InputsContainer>
            {renderInput("min", min, optionId)}
            <InputSpacer>-</InputSpacer>
            {renderInput("max", max, optionId)}
          </InputsContainer>
          <ScoreInputContainer>
            <ScoreInput inputName={`${optionId}-score`} score={score} />
          </ScoreInputContainer>
        </OptionContainer>
      );
    });

  const renderFooter = () => {
    return (
      <Footer>
        {answerOptions.length < MAX_ANSWERS_OPTIONS && (
          <AddNewCaption onClick={handleAddNew}>+ Add</AddNewCaption>
        )}
        <Disclaimer>
          Min and Max values are inclusive. 0 points will be given to any answer
          outside the bands
        </Disclaimer>
      </Footer>
    );
  };

  return (
    <FreeResponseContainer>
      {renderHeader()}
      {renderInputs()}
      {renderFooter()}
    </FreeResponseContainer>
  );
};

export default FreeResponseInputs;

FreeResponseInputs.propTypes = {
  answerOptions: PropTypes.array.isRequired,
  setAnswerOptions: PropTypes.func.isRequired
};

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin: 0 40px 16px 0;
`;

const FreeResponseContainer = styled(Container)`
  margin: 12px 0 0;
  text-align: left;
`;

const FreeResponseLabel = styled.span`
  color: #6a7f87;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const OptionContainer = styled(FlexContainer)`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  color: #6a7f87;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  justify-content: space-between;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

const DeleteIcon = styled.div`
  bottom: 2px;
  cursor: pointer;
  position: relative;
  right: 11px;
`;

const OptionInput = styled(Input)`
  max-height: 24px;
  padding: 4px 8px;
  ${removeNumericInputSpinners}
`;

const InputsContainer = styled(FlexContainer)`
  align-items: center;
  flex: 1;
  margin-right: 40px;
  & > div {
    flex: 1;
  }
`;

const InputSpacer = styled.span`
  height: 24px;
  margin: 0 14px;
`;

const ScoreInputContainer = styled.div`
  bottom: 4px;
  position: relative;
`;

const AddNewCaption = styled(Caption)`
  color: ${({ theme }) => theme.tenantAccent};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  margin: 5px 8px 0 0;
  text-align: center;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const Footer = styled(FlexContainer)`
  justify-content: space-between;
`;

const Disclaimer = styled.div`
  background: #dbdde2;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  padding: 5px 8px;
`;
