import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InstructionsListContainer } from "pages/ManagementPage/ProctorComponents";
import { FlexContainer } from "components/Atoms/Containers";
import { FormInput, FormTitle } from "components/Blocks/ManagementForm";
import QuillEditor from "components/Systems/QuillEditor";

const CompletionPageForm = ({
  setData,
  errors,
  completionHeading,
  completionBody,
  completionButtonText,
  completionButtonUrl
}) => {
  return (
    <FormContainer direction="column">
      <FormTitle>Completion Page Heading</FormTitle>
      <FormInput
        name="assessment-title"
        onChange={ev => setData({ completionHeading: ev.target.value })}
        placeholder="Name your Completion heading"
        type="text"
        defaultValue={completionHeading}
        isValid={!errors?.HEADING}
      />
      <FormSection />
      <FormTitle>Completion Body</FormTitle>
      <InstructionsListContainer>
        <QuillEditor
          initialBody={completionBody}
          grayTheme
          inputName="assessment-landing-page-instructions"
          placeholder="Body for your Completion page"
          isLatexRestricted
          onUpdatedBody={body => setData({ completionBody: body })}
          validateOnSubmit
          isValidField
        />
      </InstructionsListContainer>
      <FormSection />
      <FormTitle>Completion Button Text</FormTitle>
      <FormInput
        name="assessment-title"
        onChange={ev => setData({ completionButtonText: ev.target.value })}
        placeholder="Name your Completion button"
        type="text"
        defaultValue={completionButtonText}
        isValid={!errors?.BUTTON_TEXT}
      />
      <FormSection />
      <FormTitle>Completion Button URL</FormTitle>
      <FormInput
        name="assessment-title"
        onChange={ev => setData({ completionButtonUrl: ev.target.value })}
        placeholder="Add your Completion button's redirection URL"
        type="text"
        defaultValue={completionButtonUrl}
        isValid={!errors?.BUTTON_URL}
      />
    </FormContainer>
  );
};

export default CompletionPageForm;

CompletionPageForm.propTypes = {
  completionHeading: PropTypes.string.isRequired,
  completionBody: PropTypes.string.isRequired,
  completionButtonText: PropTypes.string.isRequired,
  completionButtonUrl: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
  errors: PropTypes.object
};

CompletionPageForm.defaultProps = {
  errors: {},
  logoFileName: "",
  defaultLogoUrl: ""
};

const FormContainer = styled(FlexContainer)`
  width: 100%;
`;

const FormSection = styled.div`
  padding-top: 12px;
`;
