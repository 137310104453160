import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  DEFAULT_ANSWER_OPTIONS,
  FREE_RESPONSE,
  MULTIPLE_CHOICE,
  FILE_UPLOAD,
  QUESTION_BACKEND_TYPES,
  MAP_BACKEND_QUESTION_TYPES,
  questionLibraryPath,
  INVALID_VARIANT_ID,
  INPUT_TYPES,
  VIDEO_RECORD
} from "pages/QuestionPage/Constants";
import convertNumberToLetter from "pages/QuestionPage/helpers/convertNumberToLetter/convertNumberToLetter";
import useFetchCategories from "pages/QuestionPage/hooks/useFetchCategories";
import {
  createQuestionMW,
  deleteFileMW,
  fetchSingleQuestionDataMW,
  fetchSingleQuestionGroupMW,
  saveFileMW,
  updateQuestionMW
} from "pages/QuestionPage/middleware";
import Loader from "pages/QuestionPage/questionCreation/Loader";
import QuestionCreationForm from "pages/QuestionPage/questionCreation/QuestionCreationForm";
import QuestionCreationNav from "pages/QuestionPage/questionCreation/QuestionCreationNav";
import QuestionPreview from "pages/QuestionPage/questionPreview/QuestionPreview";
import { Container, FlexContainer } from "components/Atoms/Containers";
import { PageWrapper } from "components/Blocks/Styled/Wrappers";
import { VALID_STATES as VS } from "utils/constants/stateTypes";

const QuestionCreation = () => {
  const history = useHistory();
  const initialQuestionType = useLocation()?.questionType || MULTIPLE_CHOICE;
  const { questionId } = useParams();
  const [questionType, setQuestionType] = useState(initialQuestionType);
  const [questionTypeOptions, setQuestionTypeOptions] = useState(
    Object.keys(QUESTION_BACKEND_TYPES)
  );

  const [questionState, setQuestionState] = useState(VS.DRAFT);
  const [questionBody, setQuestionBody] = useState("");
  const [categories, setCategories] = useState([]);
  const [helperText, setHelperText] = useState("");
  const [tenantsList, setTenantsList] = useState([]);
  const [selectedRestrictedTenant, setSelectedRestrictedTenant] = useState(
    null
  );
  const [title, setTitle] = useState("");
  const [legacyQuestionId, setLegacyQuestionId] = useState(null);
  const [multipleAnswerOptions, setMultipleAnswerOptions] = useState(
    Array(DEFAULT_ANSWER_OPTIONS).fill({ body: "", score: null, id: null })
  );
  const [freeResponseAnswerOptions, setFreeResponseAnswerOptions] = useState(
    Array(DEFAULT_ANSWER_OPTIONS).fill({
      id: null,
      min: null,
      max: null,
      inclusive: true,
      score: null
    })
  );
  const [fileUploadAnswerOptions, setFileUploadAnswerOptions] = useState(
    Array(1).fill({
      id: null,
      score: null
    })
  );

  const [fileUploadPayload, setFileUploadPayload] = useState({});
  const [questionFiles, setQuestionFiles] = useState([]);
  const [fileUploadErrors, setFileUploadErrors] = useState([]);
  const [pendingUploadedFiles, setPendingUploadedFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [variantQuestionId, setVariantQuestionId] = useState(null);

  useFetchCategories(setCategories);

  useEffect(() => {
    // Reset variables on if you press back from edit-question to create-question
    // Related to issue: #4545
    if (!questionId) {
      setQuestionFiles([]);
      setPendingUploadedFiles([]);
      setFileUploadErrors([]);
    }
  }, [questionId]);

  const fetchGroupbyId = useCallback(
    async groupId => {
      const group = await fetchSingleQuestionGroupMW({
        urlParams: [groupId]
      });
      const questions = group.data?.questions;
      let variantId = null;
      if (questions?.length) {
        const variantQuestion = questions.find(({ id }) => +id !== +questionId);
        variantId = +variantQuestion?.id;
      }
      return variantId;
    },
    [questionId]
  );

  const fetchQuestion = useCallback(async () => {
    const questionData = await fetchSingleQuestionDataMW({
      urlParams: [questionId]
    });
    const {
      accept_types: acceptTypes,
      question_type: questionType,
      question_state: questionState,
      content,
      default_category: defaultCategory,
      options,
      bands,
      legacy_question_id: legacyQuestionId,
      files,
      tenant,
      group_id: groupId
    } = questionData.data;

    const questionContent = content[0];
    if (acceptTypes?.includes("video")) {
      setQuestionType(VIDEO_RECORD);
      setQuestionTypeOptions([VIDEO_RECORD]);
    }else{
      setQuestionType(MAP_BACKEND_QUESTION_TYPES[questionType]);
      setQuestionTypeOptions([MAP_BACKEND_QUESTION_TYPES[questionType]]);
    }
    setQuestionState(questionState);
    setQuestionBody(questionContent.text_display);
    setTitle(questionContent.title);
    setHelperText(questionContent.placeholder);
    setSelectedCategory(defaultCategory?.name);
    setLegacyQuestionId(legacyQuestionId);
    setQuestionFiles(files);
    setSelectedRestrictedTenant({
      ...tenant,
      value: tenant?.name
    });
    if (questionType === QUESTION_BACKEND_TYPES[MULTIPLE_CHOICE]) {
      const newOptions = options.map(({ id, value, content }) => ({
        score: value,
        body: content[0].text_display,
        id: id
      }));
      setMultipleAnswerOptions(newOptions);
    } else if (questionType === QUESTION_BACKEND_TYPES[FREE_RESPONSE]) {
      const newBands = bands.map(({ id, max_value, min_value, score }) => ({
        id,
        min: min_value,
        max: max_value,
        score
      }));
      setFreeResponseAnswerOptions(newBands);
    } else if (questionType === QUESTION_BACKEND_TYPES[FILE_UPLOAD]) {
      setFileUploadAnswerOptions([{ score: questionData.data.score }]);
    }
    if (groupId && +groupId !== +questionId) {
      const variantId = await fetchGroupbyId(groupId);
      setVariantQuestionId(variantId);
    }
    setIsLoading(false);
  }, [questionId, fetchGroupbyId]);

  const fetchGroupIdByQuestionId = async questionId => {
    const question = await fetchSingleQuestionDataMW({
      urlParams: [questionId]
    });
    const groupId = question?.data?.group_id;
    return groupId;
  };

  useEffect(() => {
    questionId && fetchQuestion();
  }, [fetchQuestion, questionId]);

  useEffect(() => {
    questionId === undefined && setIsLoading(false);
  }, [questionId]);

  const getQuestionCreationSchema = (data, isCreation) => {
    // Note: We do not currently support language_id, default english is chosen by backend
    const questionSchema = {
      content: [
        {
          title: data["question-title"],
          text_display: questionBody
        }
      ],
      default_category_id: data["question-category"]
        ? categories.find(cat => cat.name === data["question-category"]).id
        : null,
      question_type: QUESTION_BACKEND_TYPES[data["question-type"]],
      question_state: questionState,
      legacy_question_id: data["legacy-question"] || null,
      tenant_id: selectedRestrictedTenant?.id || null,
      group_id: data["group_id"]
    };
    const { score, ...payload } = fileUploadPayload;

    const getInput = (prefix, option, suffix) =>
      data[
        `${prefix}-answer-option-${convertNumberToLetter(option)}-${suffix}`
      ];

    switch (data["question-type"]) {
      case FREE_RESPONSE:
        questionSchema.content[0].placeholder =
          data["free-response-helper-text"];
        questionSchema.bands = freeResponseAnswerOptions.map(({ id }, idx) => {
          const bandSchema = {
            ...(!isCreation && { id }),
            min_value: getInput(INPUT_TYPES[FREE_RESPONSE], idx, "min"),
            max_value: getInput(INPUT_TYPES[FREE_RESPONSE], idx, "max"),
            score: getInput(INPUT_TYPES[FREE_RESPONSE], idx, "score")
          };

          return bandSchema;
        });
        break;
      case MULTIPLE_CHOICE:
        questionSchema.options = multipleAnswerOptions.map(
          ({ id, body }, idx) => {
            const optionSchema = {
              ...(!isCreation && { id }),
              content: [{ text_display: body }],
              value: getInput(INPUT_TYPES[MULTIPLE_CHOICE], idx, "score"),
              sort_index: idx
            };
            return optionSchema;
          }
        );
        break;
      case FILE_UPLOAD:
        return {
          content: [
            {
              title: payload["question-title"],
              text_display: payload.body,
              placeholder: ""
            }
          ],
          score: parseInt(score),
          question_type: QUESTION_BACKEND_TYPES[FILE_UPLOAD],
          question_state: questionState,
          default_category_id: categories.find(
            cat => cat.name === payload["question-category"]
          ).id,
          legacy_question_id: data["legacy-question"] || null,
          tenant_id: selectedRestrictedTenant?.id || null,
          group_id: data["group_id"]
        };
      case VIDEO_RECORD:
        return {
          content: [
            {
              title: payload["question-title"],
              text_display: payload.body,
              placeholder: ""
            }
          ],
          score: parseInt(score),
          question_type: QUESTION_BACKEND_TYPES[VIDEO_RECORD],
          question_state: questionState,
          default_category_id: categories.find(
            cat => cat.name === payload["question-category"]
          ).id,
          legacy_question_id: data["legacy-question"] || null,
          tenant_id: selectedRestrictedTenant?.id || null,
          group_id: data["group_id"],
          accept_types: ["video"]
        };
      default:
        console.error("getQuestionCreationSchema: Invalid question type");
        return;
    }

    return questionSchema;
  };

  const createQuestion = async data => {
    const response = await createQuestionMW({
      data: getQuestionCreationSchema(data, true)
    });

    if (response.status === 200) {
      saveFiles(response.data.id);
    }
  };

  const updateQuestion = async data => {
    const response = await updateQuestionMW({
      urlParams: [questionId],
      data: getQuestionCreationSchema(data, false)
    });
    if (response.status === 200) {
      saveFiles(response.data.id);
    }
  };

  const handleSubmitData = async data => {
    let groupId = null;
    if (data["question-variant"]) {
      groupId = await fetchGroupIdByQuestionId(data["question-variant"]);
      if (!groupId) {
        setVariantQuestionId(INVALID_VARIANT_ID);
        return;
      }
    }

    let questionData = { ...data, group_id: groupId };

    if (questionType === FILE_UPLOAD || questionType === VIDEO_RECORD) {
      questionData = { ...questionData, ...fileUploadPayload };
    }

    questionId ? updateQuestion(questionData) : createQuestion(questionData);
  };

  const goBack = () => history.push(`${questionLibraryPath}/${VS.ALL}`);

  const getCurrentAnswerOptionsData = () => {
    switch (questionType) {
      case MULTIPLE_CHOICE:
        return {
          answerOptions: multipleAnswerOptions
        };
      case FREE_RESPONSE:
        return {
          answerOptions: freeResponseAnswerOptions,
          helperText: helperText
        };
      case FILE_UPLOAD:
        return {
          answerOptions: fileUploadAnswerOptions,
          helperText: helperText
        };
      case VIDEO_RECORD:
        return {
          answerOptions: fileUploadAnswerOptions,
          helperText: helperText
        };
      default:
        break;
    }
  };

  const deleteFile = useCallback(
    async questionFileId => {
      setIsLoading(true);
      const fileResponse = await deleteFileMW({
        urlParams: [questionId, questionFileId]
      });

      if (fileResponse.status === 200) {
        setQuestionFiles(oldQuestionFiles =>
          oldQuestionFiles.filter(file => file.id !== questionFileId)
        );
      } else {
        setFileUploadErrors(oldErrors => [
          `Failed to delete ${questionFileId}`,
          ...oldErrors
        ]);
      }
      setIsLoading(false);
    },
    [questionId, setFileUploadErrors, setQuestionFiles, setIsLoading]
  );

  const saveAllFiles = useCallback(
    async questionId => {
      const requestFile = async f => {
        const formData = new FormData();
        formData.append("file", f);
        const file = await saveFileMW({
          urlParams: [questionId],
          data: formData
        });

        if (file) {
          return file.data;
        } else {
          return { error: `Failed to save ${f.name}` };
        }
      };

      let requestArray = [];

      for (let file of pendingUploadedFiles) {
        requestArray.push(await requestFile(file));
      }

      let successes = [];
      let errors = [];
      requestArray.forEach(file => {
        if (!file.error) {
          successes.push(file);
        } else {
          errors.push(file.error);
        }
      });
      setFileUploadErrors(oldErrors => [...errors, ...oldErrors]);
      setQuestionFiles(oldQuestionFiles => [...successes, ...oldQuestionFiles]);
      setIsLoading(false);
      setPendingUploadedFiles([]);

      if (errors.length) {
        history.push(`${questionLibraryPath}/edit-question/${questionId}`);
      } else {
        history.push(`${questionLibraryPath}/${VS.ALL}`);
      }
    },
    [
      history,
      setQuestionFiles,
      setIsLoading,
      pendingUploadedFiles,
      setPendingUploadedFiles,
      setFileUploadErrors
    ]
  );

  const saveFiles = useCallback(
    async _questionId => {
      setIsLoading(true);
      setFileUploadErrors([]);
      saveAllFiles(_questionId);
    },
    [saveAllFiles, setIsLoading, setFileUploadErrors]
  );

  return (
    <PageWrapper background="#f0f2f4">
      <QuestionCreationNav
        handleGoBack={goBack}
        existingQuestion={Boolean(questionId)}
      />
      <QuestionCreationContainer>
        <QuestionCreationSection background="transparent">
          {isLoading ? (
            <Loader />
          ) : (
            <QuestionCreationForm
              freeResponseAnswerOptions={freeResponseAnswerOptions}
              helperText={helperText}
              legacyQuestionId={legacyQuestionId}
              multipleAnswerOptions={multipleAnswerOptions}
              onSubmit={handleSubmitData}
              questionBody={questionBody}
              questionType={questionType}
              questionTypeOptions={questionTypeOptions}
              selectedCategory={selectedCategory}
              setFreeResponseAnswerOptions={setFreeResponseAnswerOptions}
              setHelperText={setHelperText}
              setMultipleAnswerOptions={setMultipleAnswerOptions}
              setQuestionBody={setQuestionBody}
              setQuestionType={setQuestionType}
              setSelectedCategory={setSelectedCategory}
              setTenantsList={setTenantsList}
              selectedRestrictedTenant={selectedRestrictedTenant}
              setSelectedRestrictedTenant={setSelectedRestrictedTenant}
              tenantsList={tenantsList}
              title={title}
              questionFiles={questionFiles}
              fileUploadErrors={fileUploadErrors}
              saveFiles={saveFiles}
              deleteFile={deleteFile}
              pendingUploadedFiles={pendingUploadedFiles}
              setPendingUploadedFiles={setPendingUploadedFiles}
              selectedVariantQuestion={variantQuestionId}
              questionId={+questionId}
              fileUploadAnswerOptions={fileUploadAnswerOptions}
              setFileUploadAnswerOptions={setFileUploadAnswerOptions}
              setFileUploadPayload={setFileUploadPayload}
            />
          )}
        </QuestionCreationSection>
        <QuestionCreationSection background="white">
          {isLoading ? (
            <Loader isPreview />
          ) : (
            <QuestionPreview
              answersOptionsData={getCurrentAnswerOptionsData()}
              body={questionBody}
              type={questionType}
            />
          )}
        </QuestionCreationSection>
      </QuestionCreationContainer>
    </PageWrapper>
  );
};

export default QuestionCreation;

const QuestionCreationContainer = styled(FlexContainer)`
  margin-top: 2px;
`;

const QuestionCreationSection = styled(Container)`
  flex: 1;
  max-width: 50%;
  min-height: 100vh;
  padding: 38px 104px;
`;
