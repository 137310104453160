import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "pages/App/helpers/paths";
import AddButton from "pages/ManagementPage/AddButton";
import SelectAssessment from "pages/ManagementPage/SelectAssessment";

const AddAssessment = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <AddButton
        testId="add-assessment-btn"
        onClick={() => setShowModal(true)}
      />
      {showModal && (
        <SelectAssessment
          onCreateScratch={() =>
            history.push(`${paths.ADMIN}/assessment-management/create`)
          }
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default AddAssessment;
