import React from "react";

const pencilIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-1062 -294)">
        <g transform="translate(208 152)">
          <g transform="translate(0 86)">
            <g transform="translate(24 23)">
              <g transform="translate(821 1)">
                <g transform="translate(0 28)">
                  <g transform="translate(1)">
                    <g transform="translate(8 4)">
                      <g>
                        <rect
                          width="24"
                          height="24"
                          x="0"
                          y="0"
                          opacity="0.048"
                          rx="2"
                        ></rect>
                        <g
                          fill="#575766"
                          fillRule="nonzero"
                          stroke="#575766"
                          strokeWidth="0.2"
                          transform="translate(7 7)"
                        >
                          <path d="M9.689 1.217L8.783.31a1.063 1.063 0 00-1.502 0L.539 7.053a.298.298 0 00-.081.154L.005 9.654a.293.293 0 00.341.341l2.447-.453a.298.298 0 00.154-.08l6.742-6.743a1.061 1.061 0 000-1.502zM.659 9.34l.274-1.48 1.206 1.206-1.48.274zm2.08-.501L1.16 7.26l5.766-5.766 1.58 1.58L2.74 8.84zm6.535-6.536l-.354.355-1.58-1.58.355-.354a.476.476 0 01.673 0l.906.906a.476.476 0 010 .673z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default pencilIcon;
