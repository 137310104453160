import React from "react";

const searchIcon = (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-216.000000, -330.000000)">
        <g transform="translate(200.000000, 314.000000)">
          <g transform="translate(8.000000, 8.000000)">
            <rect x="0" y="0" width="32" height="32" rx="2"></rect>
            <g
              transform="translate(16.000000, 16.707107) rotate(-315.000000) 
              translate(-16.000000, -16.707107) translate(9.500000, 6.207107)"
              fill="#525252"
              fillRule="nonzero"
            >
              <path
                d="M6.61549019,0.745055617 C9.92919869,0.745055617 
                12.6154902,3.43134712 12.6154902,6.74505562 C12.6154902,9.85600223 
                10.247883,12.4139646 7.21625688,12.7153543 L7.21549019,19.8859248 
                C7.21549019,20.2172956 6.94686104,20.4859248 6.61549019,20.4859248 
                C6.28411934,20.4859248 6.01549019,20.2172956 6.01549019,19.8859248 
                L6.01549019,19.8859248 L6.01571978,12.7154533 C2.98361612,12.414525 
                0.615490187,9.85634301 0.615490187,6.74505562 C0.615490187,3.43134712 
                3.30178169,0.745055617 6.61549019,0.745055617 Z M6.61549019,1.94505562 
                C3.96452339,1.94505562 1.81549019,4.09408882 1.81549019,6.74505562 
                C1.81549019,9.39602242 3.96452339,11.5450556 6.61549019,11.5450556 
                C9.26645699,11.5450556 11.4154902,9.39602242 11.4154902,6.74505562 
                C11.4154902,4.09408882 9.26645699,1.94505562 6.61549019,1.94505562 Z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default searchIcon;
