import endpointNames from "utils/constants/endpointNames";
import makeRequest from "utils/requests/makeRequest";

export const getPoolsByAssessmentMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getPoolsByAssessment,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getQuestionsListMW = async ({ queryParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getQuestionList,
      queryParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};
export const createAssessmentMW = async ({ data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.createAssessment,
      data
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getTenantWebhooksMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getTenantWebhooks,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const uploadLogoMW = async ({ data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.uploadLogo,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateAssessmentConfigurationMW = async ({ data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.updateAssessmentConfig,
      data
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateAssessmentMW = async ({ data, urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.updateAssessment,
      urlParams,
      data
    });

    if (result?.status === 200) {
      return result;
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateAssessmentMetadataMW = async ({ data, urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.updateAssessmentMetadata,
      urlParams,
      data
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const validateSlugUniquenessMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.checkAssessmentBySlug,
      urlParams
    });
    return !result;
  } catch (err) {
    console.error(err);
  }
};
