import { css } from "styled-components";

const removeNumericInputSpinners = () => css`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  &[type="number"] {
    appearance: textfield;
  }
`;

const dialogBoxArrow = () => css`
  &::before {
    border: 5px solid transparent;
    border-bottom: none;
    border-top-color: ${({ theme }) => theme.colors.black};
    content: "";
    height: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 100%;
    width: 0;
  }
`;

const ellipsis = () => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export { removeNumericInputSpinners, dialogBoxArrow, ellipsis };
