import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useInputFocus } from "pages/PanelPage/hooks/useInputFocus";
import { Container } from "components/Atoms/Containers";
import InputUnderLine from "components/Blocks/Forms/InputUnderLine";
import { InputLabel, ErrorMessage } from "components/Blocks/Styled/Forms";

const Input = ({
  autoFocus,
  className,
  disabled,
  isValid,
  isVisible,
  label,
  name,
  placeholder,
  required,
  showErrors,
  testId,
  type,
  validations,
  value,
  showUnderLine,
  isDarkTheme,
  isFullWidth,
  ...inputProps
}) => {
  const { register = () => null, errors } = useFormContext() || {};
  const { focus, onFocus, onBlur } = useInputFocus();
  const hasErrors = !isValid || errors?.[name];
  let inputRef = useRef(null);

  useEffect(() => {
    autoFocus && inputRef.focus();
  }, [autoFocus]);

  return (
    <InputContainer
      onFocus={onFocus}
      onBlur={onBlur}
      hasErrors={hasErrors}
      isDarkTheme={isDarkTheme}
      isVisible={isVisible}
      isFullWidth={isFullWidth}
    >
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <InputStyled
        key={value}
        className={className}
        data-testid={testId}
        type={type}
        name={name}
        placeholder={placeholder}
        ref={element => {
          register(element, { required, ...validations });
          inputRef = element;
        }}
        defaultValue={value}
        disabled={disabled}
        isFocus={focus || (!hasErrors && hasErrors)}
        id={name}
        {...inputProps}
      />
      {showUnderLine && (!hasErrors || isDarkTheme) && (
        <InputUnderLine className="underline" focused={focus} />
      )}
      {errors?.[name]?.message && showErrors && (
        <ErrorMessage data-testid="errors-section">
          {errors?.[name]?.message}
        </ErrorMessage>
      )}
    </InputContainer>
  );
};

Input.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  isValid: PropTypes.bool,
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showErrors: PropTypes.bool,
  testId: PropTypes.string,
  type: PropTypes.string,
  validations: PropTypes.object,
  value: PropTypes.any,
  showUnderLine: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
  isFullWidth: PropTypes.bool
};

Input.defaultProps = {
  autoFocus: false,
  className: "",
  disabled: false,
  isValid: true,
  isVisible: true,
  label: "",
  placeholder: "",
  required: false,
  showErrors: true,
  testId: "",
  type: "text",
  validations: {},
  value: "",
  showUnderLine: true,
  isDarkTheme: false,
  isFullWidth: false
};

export default Input;

const InputContainer = styled(Container)`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  text-align: left;
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "")};
  > input {
    border: ${({ hasErrors, theme }) =>
      hasErrors && `2px solid ${theme.colors.invalidRed}`};
    transition: all 150ms ease-out;

    ${({ theme, isDarkTheme }) =>
      isDarkTheme &&
      `{
        background: rgba(255, 255, 255, 0.03);
        border:0;
        color: ${theme.colors.white};
        + .underline {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    `}
  }
`;

const InputStyled = styled.input`
  appearance: none;
  background: ${({ theme }) => theme.colors.white};
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 17px;
  outline: none;
  padding: 12px 8px;
  width: 100%;
  &::placeholder {
    opacity: ${({ placeholder }) => (placeholder ? 1 : 0)};
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;
