import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { MaxWidthContainer } from "components/Atoms/Containers";

const AssessmentsLoader = ({ withNav }) => {
  return (
    <LoadingContainer>
      {withNav && <LoadingNav height={120} />}
      <MaxWidthContainer>
        <LoadingSortContainer>
          <Skeleton width={116} height={18} />
        </LoadingSortContainer>
        {Array(5)
          .fill()
          .map((el, idx) => (
            <LoadingAssessment key={idx} height={130} />
          ))}
      </MaxWidthContainer>
    </LoadingContainer>
  );
};

export default AssessmentsLoader;

const LoadingContainer = styled.div`
  background: white;
`;

const LoadingNav = styled(Skeleton)`
  margin-bottom: 32px;
`;

const LoadingSortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 36px;
`;

const LoadingAssessment = styled(Skeleton)`
  margin-bottom: 8px;
`;
