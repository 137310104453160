export const QuestionInformationItemTypes = Object.freeze({
  CATEGORY: "CATEGORY",
  QUESTION_TYPE: "QUESTION_TYPE",
  DATE: "DATE",
  TENANT: "TENANT"
});

export const TENANTS_IDS = {
  MARSHALL_WACE: 19,
  CORRELATION_ONE: 1
};
