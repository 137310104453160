import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import convertNumberToLetter from "pages/QuestionPage/helpers/convertNumberToLetter/convertNumberToLetter";
import ScoreInput from "pages/QuestionPage/questionCreation/ScoreInput";
import { Container, FlexContainer } from "components/Atoms/Containers";

const FileUploadInputs = ({ answerOptions, disclaimer }) => {
  const renderHeader = () => (
    <Header>
      <FreeResponseLabel>Score</FreeResponseLabel>
    </Header>
  );

  const renderInputs = () =>
    answerOptions.map(({ score }, index) => {
      const answerOption = convertNumberToLetter(index);
      const optionId = `file-upload-answer-option-${answerOption}`;
      return (
        <OptionContainer data-testid="answer-option" key={optionId}>
          <ScoreInputContainer>
            <ScoreInput
              margin="0px"
              data-testid="score-input"
              inputName={`${optionId}-score`}
              score={score}
              min={0}
            />
          </ScoreInputContainer>
        </OptionContainer>
      );
    });

  const renderFooter = () => {
    return (
      <Footer>
        <Disclaimer>{disclaimer}</Disclaimer>
      </Footer>
    );
  };

  return (
    <FreeResponseContainer>
      {renderHeader()}
      {renderInputs()}
      {renderFooter()}
    </FreeResponseContainer>
  );
};

export default FileUploadInputs;

FileUploadInputs.propTypes = {
  answerOptions: PropTypes.array.isRequired,
  setAnswerOptions: PropTypes.func.isRequired,
  disclaimer: PropTypes.string.isRequired
};

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin: 0 40px 16px 0;
`;

const FreeResponseContainer = styled(Container)`
  margin: 12px 0 0;
  text-align: left;
`;

const FreeResponseLabel = styled.span`
  color: #6a7f87;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const OptionContainer = styled(FlexContainer)`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  color: #6a7f87;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  justify-content: space-between;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-bottom: 16px;
  padding-bottom: 16px;
`;

const ScoreInputContainer = styled.div`
  bottom: 4px;
  position: relative;
`;

const Footer = styled(FlexContainer)`
  justify-content: space-between;
`;

const Disclaimer = styled.div`
  background: #dbdde2;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  padding: 5px 8px;
`;
