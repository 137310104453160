import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAssessmentContext } from "state/assessmentContext/useAssessmentContext";
import { sortTypes } from "pages/App/helpers/sortTypes";
import { fullNameField } from "pages/PanelPage/Constants";
import AssessmentTaker from "pages/PanelPage/components/selectedAssessment/assessmentResults/AssessmentTaker";
import buildTakerObject from "pages/PanelPage/helpers/buildTakerObject";
import getFullName, {
  getFullNameShort
} from "pages/PanelPage/helpers/getFullName";
import { FlexContainer } from "components/Atoms/Containers";
import LoadingIndicator from "components/Atoms/LoadingIndicator";
import { Body1 } from "components/Atoms/Text";
import { Table, Row, Cell } from "components/Blocks/Styled/Tables";
import downArrowV from "assets/downArrowVIcon";

/**
 * TakersContainer component renders the takers of the selected assessment.
 *
 * @param {Object} props - The component props.
 * @param {string} props.sortBy - The current sort option.
 * @param {function} props.setSortBy - The function to update the sort option.
 * @param {boolean} props.isLoading - Flag indicating if the component is in a loading state.
 * @returns {JSX.Element} The rendered TakersContainer component.
 */
const TakersContainer = ({ sortBy, setSortBy, isLoading }) => {
  const {
    assessmentState: {
      selectedAssessmentAttempts,
      selectedAssessment: { taker_fields }
    }
  } = useAssessmentContext();
  const [selected, setSelected] = useState("date");

  /**
   * Renders the takers of the selected assessment.
   * @returns {JSX.Element[]} The rendered takers.
   */
  const renderTakers = () => {
    return selectedAssessmentAttempts?.map(
      ({
        id,
        taker: { user_id: userId, email },
        time_taken_seconds,
        submitted_at,
        uuid,
        weighted_score,
        weighted_max_score,
        assessment_percentile,
        taker_field_responses
      }) => {
        const formattedDate = new Date(submitted_at)
          .toISOString()
          .split("T")[0];

        const fullName = getFullName(taker_field_responses);

        // This variable enables us to display taker's user id instead of email if we have one.
        const takerEmailFieldText = userId ?? email;

        const takerData = [
          buildTakerObject(
            "email",
            takerEmailFieldText,
            takerEmailFieldText,
            "90%"
          ),
          buildTakerObject("date", formattedDate),
          buildTakerObject(
            "time",
            new Date(time_taken_seconds * 1000).toISOString().substr(11, 8)
          ),
          buildTakerObject(
            "totalScore",
            `${weighted_score.toFixed(2)} / ${weighted_max_score}`
          )
        ];

        if (fullName) {
          takerData.unshift(
            buildTakerObject(
              "fullName",
              getFullNameShort(fullName),
              fullName,
              "90%",
              "300px"
            )
          );
        } else if (hasFullName()) {
          takerData.unshift(buildTakerObject("fullName", ""));
        }

        if (assessment_percentile) {
          takerData.push({
            type: "candidatePercent",
            value: `${parseFloat(assessment_percentile * 100).toFixed(2)}%`
          });
        }

        // Time and sectionScore need to be implemented, but both need to be discussed further
        return <AssessmentTaker key={id} takerData={takerData} uuid={uuid} />;
      }
    );
  };

  const handleSort = useCallback(header => {
    if (header.sortBy) {
      if (selected === header.name) {
        setSortBy({
          ...sortBy,
          order: sortBy.order === "asc" ? "desc" : "asc"
        });
      } else {
        setSelected(header.name);
        setSortBy({
          sortKey: header.sortBy,
          order: "desc"
        });
      }
    }
  });

  /**
   * Checks if the taker fields have a full name.
   * @returns {boolean} Flag indicating if the taker fields have a full name.
   */
  const hasFullName = () => {
    return taker_fields?.some(
      ({ content }) => content[0]?.title === fullNameField
    );
  };

  /**
   * Renders the table headers for the TakersContainer component.
   * @returns {JSX.Element} The rendered table headers.
   */
  const renderTableHeaders = () => {
    const headers = [
      { name: "email / user ID", sortBy: sortTypes.EMAIL },
      { name: "date", sortBy: sortTypes.DATE },
      { name: "time taken", sortBy: sortTypes.TIME_TAKEN },
      { name: "total score", sortBy: sortTypes.SCORE },
      { name: "details" }
    ];

    if (hasFullName()) {
      headers.splice(0, 0, {
        name: "name",
        sortBy: sortTypes.FULL_NAME,
        minWidth: "150px"
      });
    }

    if (selectedAssessmentAttempts[0]?.assessment_percentile) {
      headers.splice(4, 0, { name: "candidate %", sortBy: sortTypes.SCORE });
    }

    // Memoize the onClick handlers to prevent unnecessary re-renders.
    const memoizedOnClickHandlers = useMemo(() => {
      return headers.map(header => ({
        ...header,
        onClick: () => handleSort(header)
      }));
    }, [headers, handleSort]);

    return (
      <Row height="41px">
        {memoizedOnClickHandlers.map((header, index) => (
          <HeaderCell
            key={index}
            onClick={header.onClick}
            selected={header.name === selected && true}
            minWidth={header.minWidth}
          >
            <TableHeader
              color="#6a7f87"
              fontSize="12px"
              textTransform="capitalize"
              sortable={header.sortBy}
            >
              {header.name} {header.sortBy && downArrowV}
            </TableHeader>
          </HeaderCell>
        ))}
      </Row>
    );
  };

  return (
    <FlexContainer direction="column">
      <CandidatesBody className="bold">
        Candidates
        {isLoading && (
          <LoadingContainer>
            <LoadingIndicator variant="md" />
          </LoadingContainer>
        )}
      </CandidatesBody>
      <Table borderCollapse="collapse">
        <thead>{renderTableHeaders()}</thead>
        <tbody>{renderTakers()}</tbody>
      </Table>
    </FlexContainer>
  );
};

TakersContainer.propTypes = {
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func,
  isLoading: PropTypes.bool
};

TakersContainer.defaultProps = {
  sortBy: "",
  setSortBy: () => {},
  isLoading: false
};

export default TakersContainer;

const TableHeader = styled(Body1)`
  cursor: ${({ sortable }) => sortable && "pointer"};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
`;

const CandidatesBody = styled(Body1)`
  align-items: center;
  color: ${({ theme }) => theme.colors.smoky};
  display: flex;
  height: 20px;
  margin: 0 0 16px;
`;

const HeaderCell = styled(Cell)`
  min-width: ${props => (props?.minWidth ? props?.minWidth : "")};
  svg {
    fill: ${({ selected, theme }) =>
      selected ? theme.tenantAccent : "#e0e0e0"};
  }
  &:active {
    svg {
      fill: #656565;
    }
  }
  &:hover {
    svg {
      fill: ${({ selected }) => !selected && "#656565"};
    }
  }
`;

const LoadingContainer = styled.div`
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
`;
