import React, { useEffect, useState, useCallback } from "react";
import equal from "fast-deep-equal";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useQueryParam, StringParam, NumberParam } from "use-query-params";
import usePrevious from "pages/PanelPage/hooks/usePrevious";
import QuestionCard from "pages/QuestionPage/QuestionCard";
import QuestionNav from "pages/QuestionPage/QuestionNav";
import QuestionNavigator from "pages/QuestionPage/QuestionNavigator";
import QuestionUpdateWarning from "pages/QuestionPage/QuestionUpdateWarning";
import {
  fetchAssessmentUsingQuestionMW,
  fetchQuestionGroupListMW,
  updateQuestionMW
} from "pages/QuestionPage/middleware";
import { Container, MaxWidthContainer } from "components/Atoms/Containers";
import NoResults from "components/Blocks/NoResults";
import Paginator from "components/Blocks/Paginator";
import Loader from "components/Systems/Loader";
import {
  VALID_STATES as VS,
  QUESTION_STATES
} from "utils/constants/stateTypes";

const QuestionList = ({
  showSelectQuestionType,
  setEditingQuestionId,
  stateList,
  setStateList,
  selectedFilter,
  isAssessmentCreation,
  onCardClick,
  activeCard,
  preserveHistory,
  shouldFetchAssessmentsByQuestion,
  excludedQuestionIds
}) => {
  const [questionGroups, setQuestionsGroups] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [log, setLog] = useState({ rangeStart: 1, rangeEnd: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortByRecent, setSortByRecent] = useState(true);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [questionAssessmentData, setQuestionAssessmentData] = useState([]);
  const [questionUpdateData, setQuestionUpdateData] = useState({});
  const [wasQuestionUpdated, setWasQuestionUpdated] = useState(false);
  const [questionVariants, setQuestionVariants] = useState({});
  const [questionsAdditionalInfo, setQuestionsAdditionalInfo] = useState([]);
  const [filteredCategorySearch, setFilteredCategoryParam] = useQueryParam(
    "category",
    NumberParam
  );
  const [searchInputValue, setSearchInputValue] = useQueryParam(
    "search",
    StringParam
  );
  const previousQuestionGroups = usePrevious(questionGroups);
  const previousStateList = usePrevious(stateList);
  const previousSearch = usePrevious(searchInputValue);
  const previousCategorySearch = usePrevious(filteredCategorySearch);
  const previousPage = usePrevious(pageNum);
  const previousSort = usePrevious(sortByRecent);

  const urlUpdateType = preserveHistory ? "pushIn" : "replaceIn";

  const onOptionClick = updateData => {
    const questionAsssessmentData = questionsAdditionalInfo.find(
      ({ question_id }) => question_id === updateData.questionId
    ).assessments;
    setQuestionUpdateData(updateData);
    setQuestionAssessmentData(questionAsssessmentData);
  };

  const checkShouldFetch = useCallback(() => {
    if (!stateList.length) {
      return false;
    }
    if (wasQuestionUpdated) {
      return true;
    }
    if (!equal(previousStateList, stateList)) {
      return true;
    }
    if (previousSearch !== searchInputValue && pageNum === 1) {
      return true;
    }
    if (previousCategorySearch !== filteredCategorySearch && pageNum === 1) {
      return true;
    }
    if (previousPage !== pageNum) {
      return true;
    }
    if (previousSort !== sortByRecent) {
      return true;
    }
    return false;
  }, [
    filteredCategorySearch,
    pageNum,
    previousCategorySearch,
    previousPage,
    previousSearch,
    previousSort,
    previousStateList,
    searchInputValue,
    sortByRecent,
    stateList,
    wasQuestionUpdated
  ]);

  const handleFetchQuestions = useCallback(async () => {
    if (!checkShouldFetch()) {
      return;
    }

    const initializeQuestionVariants = () => {
      return questionsGroups
        .filter(({ questions }) => questions.length > 1)
        .reduce((prevValue, { id, questions }) => {
          const sortedQuestions = questions.sort((a, b) => a.id - b.id);
          const activeQuestionId = questionVariants[id];
          const activeQuestion =
            sortedQuestions.find(({ id }) => id === activeQuestionId) ||
            sortedQuestions[0];
          return {
            ...prevValue,
            [id]: activeQuestion.id
          };
        }, {});
    };

    setIsLoading(true);
    const params = new URLSearchParams();
    params.append("page", pageNum);
    params.append("limit", itemsPerPage);
    params.append("sort_order", sortByRecent ? "desc" : "asc");
    searchInputValue && params.append("search", searchInputValue);
    stateList.forEach(state => params.append("question_states", state));
    filteredCategorySearch &&
      params.append("category_ids", filteredCategorySearch);
    excludedQuestionIds.forEach(id => params.append("excluded_ids", id));
    const {
      data: { paginated_data: questionsGroups, count: totalCount }
    } = await fetchQuestionGroupListMW({ queryParams: params });
    setQuestionsGroups(questionsGroups);
    setQuestionVariants(initializeQuestionVariants());
    setTotalCount(totalCount);
    setTotalPages(Math.ceil(totalCount / itemsPerPage));
    setIsLoading(false);
    setWasQuestionUpdated(false);
  }, [
    pageNum,
    itemsPerPage,
    sortByRecent,
    searchInputValue,
    stateList,
    filteredCategorySearch,
    checkShouldFetch,
    questionVariants,
    excludedQuestionIds
  ]);

  const handleFetchAsessmentsUsingQuestion = useCallback(async () => {
    const params = new URLSearchParams();
    const questionIds = questionGroups
      .map(({ questions }) => questions.map(({ id }) => id))
      .flat();
    questionIds.forEach(id => params.append("question_ids", id));
    const assessments = await fetchAssessmentUsingQuestionMW({
      queryParams: params
    });
    setQuestionsAdditionalInfo(assessments.data);
  }, [questionGroups]);

  const handleUpdateQuestion = useCallback(async () => {
    const { questionId, data: state, groupID } = questionUpdateData;
    if (state === VS.EDIT) {
      setEditingQuestionId(questionId);
    } else {
      const currentGroup = questionGroups.find(({ id }) => id === groupID);
      const question = currentGroup.questions.find(
        ({ id }) => id === questionId
      );

      const updatedQuestion = { ...question };
      updatedQuestion.question_state = state;
      delete updatedQuestion.created_at;
      delete updatedQuestion.updated_at;

      await updateQuestionMW({
        urlParams: [questionId],
        data: updatedQuestion
      });

      setWasQuestionUpdated(true);
      setQuestionUpdateData({});
    }
  }, [questionUpdateData, questionGroups, setEditingQuestionId]);

  useEffect(() => {
    handleFetchQuestions();
  }, [handleFetchQuestions]);

  useEffect(() => {
    if (
      questionGroups.length &&
      shouldFetchAssessmentsByQuestion &&
      previousQuestionGroups !== questionGroups
    ) {
      handleFetchAsessmentsUsingQuestion();
    }
  }, [
    handleFetchAsessmentsUsingQuestion,
    questionGroups,
    shouldFetchAssessmentsByQuestion,
    previousQuestionGroups
  ]);

  useEffect(() => {
    setPageNum(1);
  }, [stateList, filteredCategorySearch, searchInputValue]);

  useEffect(() => {
    if (questionUpdateData.questionId) {
      const isDangerousEditing =
        questionAssessmentData.length || questionUpdateData.data === VS.DELETE;
      if (isDangerousEditing) {
        setShowWarningModal(true);
      } else {
        handleUpdateQuestion();
      }
    }
  }, [questionAssessmentData, questionUpdateData, handleUpdateQuestion]);

  const renderQuestionVariantNavigator = (
    questions,
    activeQuestion,
    groupId
  ) => {
    const questionIds = questions.map(({ id }) => id);
    const handleClick = newActive => {
      setQuestionVariants(prevVariants => ({
        ...prevVariants,
        [groupId]: newActive
      }));
      const selectedQuestion = questions.find(({ id }) => id === newActive);
      onCardClick(selectedQuestion);
    };

    return (
      <NavigatorContainer>
        <QuestionNavigator
          questionIds={questionIds}
          active={activeQuestion}
          setActive={handleClick}
        />
      </NavigatorContainer>
    );
  };

  return (
    <Container>
      {showWarningModal && (
        <QuestionUpdateWarning
          setShowWarningModal={setShowWarningModal}
          affectedAssessments={questionAssessmentData}
          updateQuestion={handleUpdateQuestion}
          setQuestionUpdateData={setQuestionUpdateData}
          isDelete={questionUpdateData.data === VS.DELETE}
        />
      )}
      <MaxWidthContainer>
        <Container width="100%">
          <QuestionNav
            setSortByRecent={setSortByRecent}
            sortByRecent={sortByRecent}
            showSelectQuestionType={showSelectQuestionType}
            totalQuestions={totalCount}
            setStateList={setStateList}
            stateList={stateList}
            selectedFilter={selectedFilter}
            isAssessmentCreation={isAssessmentCreation}
            searchInputValue={searchInputValue}
            setSearchInputValue={value =>
              setSearchInputValue(value, urlUpdateType)
            }
            setFilteredCategory={category =>
              setFilteredCategoryParam(category, urlUpdateType)
            }
            filteredCategory={filteredCategorySearch}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <Container width="100%">
              {questionGroups.length ? (
                questionGroups.map(({ questions, id: groupID }) => {
                  const hasQuestionVariants = questions.length > 1;
                  let question = questions?.[0];
                  let activeQuestion = null;
                  if (hasQuestionVariants) {
                    activeQuestion = questionVariants[groupID];
                    question = questions.find(
                      ({ id }) => id === activeQuestion
                    );
                  }

                  const shouldShowCardToggle = Boolean(
                    questionsAdditionalInfo.find(
                      ({ question_id }) => question_id === question?.id
                    )?.assessments?.length
                  );
                  return (
                    question && (
                      <QuestionCardContainer key={question.id}>
                        <QuestionCard
                          questionProps={question}
                          onOptionClick={updateData =>
                            onOptionClick({ ...updateData, groupID })
                          }
                          isAssessmentCreation={isAssessmentCreation}
                          onClick={() => onCardClick(question)}
                          isActiveCard={
                            isAssessmentCreation && question.id === activeCard
                          }
                          shouldShowToggle={shouldShowCardToggle}
                          shouldShowId={
                            !isAssessmentCreation && !hasQuestionVariants
                          }
                        />
                        {hasQuestionVariants &&
                          renderQuestionVariantNavigator(
                            questions,
                            activeQuestion,
                            groupID
                          )}
                      </QuestionCardContainer>
                    )
                  );
                })
              ) : (
                <NoResults />
              )}
            </Container>
          )}
        </Container>
        <Paginator
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          log={log}
          setLog={setLog}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalCount={totalCount}
          totalPages={totalPages}
        />
      </MaxWidthContainer>
    </Container>
  );
};

export default QuestionList;

QuestionList.propTypes = {
  showSelectQuestionType: PropTypes.func,
  setEditingQuestionId: PropTypes.func,
  stateList: PropTypes.array,
  setStateList: PropTypes.func,
  selectedFilter: PropTypes.string,
  isAssessmentCreation: PropTypes.bool,
  onCardClick: PropTypes.func,
  activeCard: PropTypes.number,
  excludedQuestionIds: PropTypes.array,
  preserveHistory: PropTypes.bool,
  shouldFetchAssessmentsByQuestion: PropTypes.bool
};

QuestionList.defaultProps = {
  showSelectQuestionType: () => null,
  setEditingQuestionId: () => null,
  stateList: QUESTION_STATES,
  setStateList: () => null,
  selectedFilter: "",
  isAssessmentCreation: false,
  onCardClick: () => null,
  activeCard: null,
  excludedQuestionIds: [],
  preserveHistory: true,
  shouldFetchAssessmentsByQuestion: false
};

const QuestionCardContainer = styled.div`
  position: relative;
`;

const NavigatorContainer = styled.div`
  left: 22px;
  position: absolute;
  top: 16px;
  z-index: 10;
`;
