import styled from "styled-components";

const Table = styled.table`
  border: ${({ border }) => border};
  border-collapse: ${({ borderCollapse }) => borderCollapse};
  margin: ${({ margin }) => margin};
  width: 100%;
`;

const BorderTable = styled(Table)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
`;

const Row = styled.tr`
  border: ${({ border }) => border};
  border-bottom: ${({ borderBottom }) => borderBottom};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
`;

const BorderRow = styled(Row)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
`;

const Cell = styled.td`
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
  text-align: ${({ textAlign }) => textAlign};
`;

const BorderCell = styled(Cell)`
  border: ${({ theme }) => `1px solid ${theme.colors.iron}`};
`;

// Export Table Components
export { Table, BorderTable, Row, BorderRow, Cell, BorderCell };
