import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { OPTION_TYPES } from "pages/ManagementPage/Constants";
import OptionIcon from "pages/ManagementPage/OptionIcon";
import { FlexContainer } from "components/Atoms/Containers";

const OptionsNav = ({
  handleAddSection,
  handleNavItemClick,
  handleShowCustomCompletionPage,
  isShowingText,
  optionsData
}) => (
  <NavContainer justify="center">
    {optionsData.map(props => {
      const isQuestionSection = props.id.includes(
        OPTION_TYPES.QUESTION_SECTION
      );
      const clickHandler = () =>
        isQuestionSection
          ? handleAddSection()
          : !props?.isCardCreated &&
            (handleNavItemClick(props.id), handleShowCustomCompletionPage());
      return (
        <EditableOptionItem key={props.id} onClick={clickHandler}>
          <OptionIcon
            {...props}
            disabled={!isQuestionSection && props?.isCardCreated}
            text={isShowingText ? props.text : ""}
            isNavIcon={true}
          />
        </EditableOptionItem>
      );
    })}
  </NavContainer>
);

export default OptionsNav;

OptionsNav.propTypes = {
  handleAddSection: PropTypes.func.isRequired,
  handleNavItemClick: PropTypes.func.isRequired,
  isShowingText: PropTypes.bool.isRequired,
  optionsData: PropTypes.array.isRequired
};

const NavContainer = styled(FlexContainer)`
  margin-top: 45px;
`;

const EditableOptionItem = styled.div`
  margin: 0 8px;
`;
