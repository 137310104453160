import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { questionTypes } from "pages/App/helpers/questionTypes";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import BandedResponse from "pages/PreviewPage/BandedResponse";
import QuestionSelectOptions from "pages/PreviewPage/QuestionSelectOptions";
import { FlexContainer } from "components/Atoms/Containers";
import { Body3 } from "components/Atoms/Text";

const PreviewQuestion = ({
  slot,
  index,
  sectionIndex,
  questionRef,
  poolQuestions,
  handleGoToPool
}) => {
  const { question, categories, category } = slot;
  const slotTarget = `S${sectionIndex}-Q${index}`;

  const renderCategories = categories =>
    categories?.map((category, i) => (
      <CategoryBubble key={i}>{category?.name}</CategoryBubble>
    ));

  const renderContent = question =>
    parseHtml(question?.content[0].text_display, true);

  const renderAnswerOptions = question => {
    const { options = [], question_type } = question || {};
    if (question_type === questionTypes.STOCK) {
      return (
        <IncorrectContainer align="center" justify="space-between">
          No answer data found
        </IncorrectContainer>
      );
    } else if (question_type === questionTypes.BANDED) {
      const { bands } = question;
      return <BandedResponse bands={bands} />;
    } else if (question_type === questionTypes.SELECT) {
      const notDeleted = options?.filter(({ is_deleted }) => !is_deleted);
      return <QuestionSelectOptions options={notDeleted} />;
    }
  };

  const renderQuestionContent = () => (
    <>
      {renderCategories(categories)}
      <ContentWrapper data-testid="preview-content-wrapper">
        {renderContent(question)}
      </ContentWrapper>
      {renderAnswerOptions(question)}
    </>
  );

  const renderPoolContent = () => (
    <FlexContainer align="center" direction="column" justify="center">
      <QuestionPoolCopy>
        Randomly chosen from the question bank <b>{category?.name}</b>
      </QuestionPoolCopy>
      <QuestionPoolBtn onClick={handleGoToPool}>
        See Questions ({poolQuestions.length})
      </QuestionPoolBtn>
    </FlexContainer>
  );

  return (
    <PreviewQuestionWrapper
      slotTarget={slotTarget}
      ref={questionRef}
      data-testid="preview-question-wrapper"
    >
      <QuestionNumber>Q{index + 1}</QuestionNumber>
      {poolQuestions.length ? renderPoolContent() : renderQuestionContent()}
    </PreviewQuestionWrapper>
  );
};

export default PreviewQuestion;

PreviewQuestion.propTypes = {
  slot: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  questionRef: PropTypes.object,
  poolQuestions: PropTypes.array,
  handleGoToPool: PropTypes.func
};

PreviewQuestion.defaultProps = {
  poolQuestions: [],
  handleGoToPool: () => null
};

const PreviewQuestionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 8px auto;
  max-width: 800px;
  padding: 32px 48px;
  img {
    max-width: 100%;
  }
`;

const QuestionNumber = styled(Body3)`
  color: ${({ theme }) => theme.tenantAccent};
  font-family: ${({ theme }) => theme.fonts.condensedFontFamily};
  font-size: 26px;
  letter-spacing: 2px;
  margin: 0 0 12px 0;
`;

const ContentWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: normal;
  margin: 12px 0;
`;

const CategoryBubble = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.iron};
  border-radius: 18px;
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 12px;
  font-style: italic;
  margin: 8px 8px 8px 0;
  padding: 5px 16px 2px 16px;
  user-select: none;
`;

const IncorrectContainer = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.colors.incorrectRed};
  border-radius: 4px;
  height: 40px;
  margin: 4px 0;
  padding: 8px 16px;
`;

const QuestionPoolCopy = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 16px;
`;

const QuestionPoolBtn = styled.button`
  background: ${({ theme }) => theme.colors.transparent};
  border: 1px solid #d9d9de;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.slateGrey};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 9px 14px;
  text-transform: uppercase;
`;
