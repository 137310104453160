import React from "react";

const emptyContentIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80"
    height="80"
    viewBox="0 0 80 80"
  >
    <defs>
      <circle id="path-1" cx="40" cy="40" r="40"></circle>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeWidth="1"
      opacity="0.4"
    >
      <g transform="translate(-1040 -378)">
        <g transform="translate(1009 378)">
          <g transform="translate(31)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use fill="#DCDFE1" xlinkHref="#path-1"></use>
            <path
              fill="silver"
              fillRule="nonzero"
              d="M76.617 66.464h-5.39c0-.085.086-.169.086-.253a9.893 9.893 0 00-10.094-9.767c-4.02 0-7.613 2.442-9.153 6.062-2.651.337-5.132 1.768-6.672 3.958h-8.383c-1.368-2.442-3.935-3.873-6.672-3.873h-.085c-1.54-4.379-6.416-6.736-10.864-5.136a9.19 9.19 0 00-3.85 2.61l-1.625-.169c-4.02 0-7.613 2.695-8.64 6.568H3.395c-1.198 0-2.139.926-2.139 2.104 0 1.18.941 15.375 2.139 15.375h73.223c1.197 0 2.138-14.196 2.138-15.375 0-1.178-.94-2.104-2.138-2.104z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="silver"
              fillRule="nonzero"
              d="M55.1 33.362a10.677 10.677 0 00-4.038-2.515 30.354 30.354 0 00-4.502-14.83c-.199-.464-3.642-5.099-5.363-6.82a1.812 1.812 0 00-2.384 0c-6.223 6.29-9.6 13.639-9.864 21.65a10.677 10.677 0 00-4.039 2.515c-4.171 3.84-4.502 10.329-.662 14.566.265.331.662.53 1.125.53h.067c.397 0 .86-.133 1.125-.464l4.502-4.105c.927 2.384 2.053 4.701 3.51 6.82 1.323 1.986 4.7 2.184 5.693 2.184h.066c1.39 0 3.774-.264 4.9-1.853a31.865 31.865 0 003.707-7.15l4.502 4.104c.265.331.728.464 1.126.464h.066c.463 0 .86-.199 1.125-.53 3.84-4.237 3.51-10.726-.662-14.566zm-15.095.994c-3.112-.067-5.627-2.583-5.627-5.694a5.623 5.623 0 015.627-5.628 5.623 5.623 0 015.628 5.628c0 3.111-2.516 5.627-5.628 5.694z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="silver"
              fillRule="nonzero"
              d="M38.35 55.277H41.660000000000004V63.023H38.35z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="silver"
              fillRule="nonzero"
              d="M43.779 54.019H47.089000000000006V61.102999999999994H43.779z"
              mask="url(#mask-2)"
            ></path>
            <path
              fill="silver"
              fillRule="nonzero"
              d="M32.921 54.019H36.231V60.507H32.921z"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default emptyContentIcon;
