import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useUserContext } from "state/userContext/useUserContext";
import { paths } from "pages/App/helpers/paths";

const AccessControl = ({
  allowedPermissions,
  children,
  renderNoAccess,
  shouldCompareWithAnd
}) => {
  const {
    userState: { permissions: userPermissions }
  } = useUserContext();

  let permitted = false;
  if (shouldCompareWithAnd) {
    permitted = allowedPermissions.every(allowedPermission =>
      userPermissions.includes(allowedPermission)
    );
  } else {
    permitted = userPermissions.some(allowedPermission =>
      allowedPermissions.includes(allowedPermission)
    );
  }
  return permitted ? children : renderNoAccess();
};

AccessControl.propTypes = {
  allowedPermissions: PropTypes.array,
  renderNoAccess: PropTypes.func,
  shouldCompareWithAnd: PropTypes.bool
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  renderNoAccess: () => <Redirect to={paths.UNAUTHORIZED} />,
  shouldCompareWithAnd: false
};

export default AccessControl;
