import { actionTypes } from "state/assessmentContext/actionTypes";

const {
  SET_ASSESSMENTS,
  SET_SELECTED_ASSESSMENT,
  SET_SELECTED_ASSESSMENT_ATTEMPTS,
  CLEAR_ASSESSMENTS
} = actionTypes;

export const dispatchActions = dispatch => {
  return {
    setAssessments: payload =>
      dispatch({
        type: SET_ASSESSMENTS,
        payload
      }),
    setSelectedAssessment: payload =>
      dispatch({
        type: SET_SELECTED_ASSESSMENT,
        payload
      }),
    setAssessmentAttempts: payload =>
      dispatch({
        type: SET_SELECTED_ASSESSMENT_ATTEMPTS,
        payload
      }),
    clearAssessments: () =>
      dispatch({
        type: CLEAR_ASSESSMENTS
      })
  };
};
