//Builds each takerData object
export const buildTakerObject = (
  type,
  value,
  tooltipValue,
  width,
  maxWidth
) => {
  let takerObject = {};
  if (type) {
    takerObject.type = type;
  }
  if (value) {
    takerObject.value = value;
  }
  if (tooltipValue) {
    takerObject.tooltipValue = tooltipValue;
  }
  if (width) {
    takerObject.width = width;
  }
  if (maxWidth) {
    takerObject.maxWidth = maxWidth;
  }
  return takerObject;
};

export default buildTakerObject;
