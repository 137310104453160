import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, FormContext } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import * as yup from "yup";
import { paths } from "pages/App/helpers/paths";
import { resetPasswordMW } from "pages/AuthPage/middleware";
import {
  AuthFormTitle,
  SubmitBtnContainer
} from "pages/AuthPage/styled/StyledComponents";
import { FormContainer } from "components/Atoms/Containers";
import Button from "components/Blocks/Forms/Button";
import Input from "components/Blocks/Forms/Input";
import { ErrorMessage } from "components/Blocks/Styled/Forms";

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .strict(false)
    .trim()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be 20 characters or less")
    .matches(/^.*[0-9]+.*$/, "Password needs at least one number")
    .matches(/^.*[A-Z]+.*$/, "Password needs at least one uppercase letter")
    .matches(/^.*[a-z]+.*$/, "Password needs at least one lowercase letter")
    .matches(/^\S*$/, "Password cannot contain spaces"),
  confirmPassword: yup
    .string()
    .strict(false)
    .trim()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match")
});

const ResetPassword = ({ isSignup }) => {
  const methods = useForm({
    validationSchema
  });
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const email = params.get("email");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const actionText = isSignup ? "Create Account" : "Reset Password";

  useEffect(() => {
    success && history.push(paths.LOGIN);
  }, [success, history]);

  // On form submit
  const onSubmit = async data => {
    setLoading(true);
    const resetPassword = await resetPasswordMW({
      data: { password: data.password, token }
    });
    setLoading(false);

    if (resetPassword?.error) {
      setError(resetPassword.error.message);
    } else {
      setError(null);
      setSuccess(true);
    }
  };

  return (
    <FormContext {...methods}>
      <FormContainer
        onSubmit={methods.handleSubmit(onSubmit)}
        onFocus={() => setError(null)}
      >
        <AuthFormTitle>{actionText}</AuthFormTitle>
        {isSignup && (
          <Input disabled label="Email" name="email" value={email} />
        )}
        <Input
          disabled={!token}
          label="Password"
          name="password"
          type="password"
        />
        <Input
          disabled={!token}
          label="Confirm Password"
          name="confirmPassword"
          type="password"
        />
        <ErrorMessage isHidden={!error}>{error}</ErrorMessage>
        <SubmitBtnContainer>
          <Button
            loading={loading}
            isFullWidth
            text={actionText}
            textTransform="uppercase"
            type="submit"
          />
        </SubmitBtnContainer>
      </FormContainer>
    </FormContext>
  );
};

ResetPassword.propTypes = {
  isSignup: PropTypes.bool
};

ResetPassword.defaultProps = {
  isSignup: false
};

export default ResetPassword;
