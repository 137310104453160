import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import { FlexContainer } from "components/Atoms/Containers";

const QuestionSelectOption = ({ letter, content }) => {
  const parsedContent = parseHtml(content, true);
  return (
    <QuestionSelectWrapper align="center" data-testid="question-select-option">
      <FakeRadioButton />
      <Letter>{letter}.</Letter>
      <QuestionSelectContent>{parsedContent}</QuestionSelectContent>
    </QuestionSelectWrapper>
  );
};

export default QuestionSelectOption;

QuestionSelectOption.propTypes = {
  letter: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

const FakeRadioButton = styled.span`
  border: 1px solid ${({ theme }) => theme.colors.steel};
  border-radius: 15px;
  display: inline-block;
  min-height: 15px;
  min-width: 15px;
`;

const Letter = styled.span`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  margin: 4px 12px;
  text-transform: uppercase;
`;

const QuestionSelectContent = styled.div`
  display: inline-block;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: normal;
`;

const QuestionSelectWrapper = styled(FlexContainer)`
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
`;
