import React from "react";
import { Redirect, useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { paths } from "pages/App/helpers/paths";
import { permissionTypes } from "pages/App/helpers/permissionTypes";
import { FlexContainer } from "components/Atoms/Containers";
import Underline from "components/Atoms/Underline";
import AccessControl from "components/Systems/AccessControl";

const NavbarSublinks = ({ selectedAssessment }) => {
  const history = useHistory();

  // Format assessment url
  const assessmentPath = `${paths.ADMIN}/${selectedAssessment.slug}`;

  // If route on just assessment, redirect to results
  if (history.location.pathname === assessmentPath)
    return <Redirect to={`${assessmentPath}/results`} />;

  // Set toggle options
  const options = [
    {
      url: `${assessmentPath}/results`,
      text: "results",
      permissions: [
        permissionTypes.CAN_READ_ASSESSMENT_ATTEMPTS,
        permissionTypes.CAN_READ_ALL_ASSESSMENT_ATTEMPTS
      ]
    },
    {
      url: `${assessmentPath}/invite`,
      text: "invite",
      permissions: [permissionTypes.CAN_CREATE_TAKER_INVITE]
    }
  ];

  return (
    <FlexContainer>
      {options.map(option => {
        const isHighlighted = history.location.pathname.includes(option.url);

        return (
          <AccessControl
            allowedPermissions={option.permissions}
            renderNoAccess={() => null}
            key={option.text}
          >
            <LinkContainer
              isHighlighted={isHighlighted}
              direction="column"
              justify="space-between"
            >
              <Link to={option.url} data-testid="nav-sublink">
                {option.text}
              </Link>
              {isHighlighted && <Underline type="nav-link" />}
            </LinkContainer>
          </AccessControl>
        );
      })}
    </FlexContainer>
  );
};

export default NavbarSublinks;

const LinkContainer = styled(FlexContainer)`
  height: 24px;
  margin-right: 32px;
  a {
    color: rgba(256, 256, 256, 1);
    font-family: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.fonts.avenirProHeavy : theme.fonts.avenirProBook};
    font-size: 16px;
    letter-spacing: 0.1px;
    text-decoration: none;
    text-transform: capitalize;
  }
`;
