import React from "react";
import styled from "styled-components";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import Modal from "components/Systems/Modal";

const ConfirmDeleteModal = ({ onClose, onConfirm }) => (
  <StyledModal title="Delete" onClose={onClose}>
    <ModalDescription>Are you sure you want to delete? </ModalDescription>
    <FlexContainer>
      <ModalButton onClick={onConfirm}>CONFIRM</ModalButton>
      <ModalButton background="#d94a54" onClick={onClose}>
        CANCEL
      </ModalButton>
    </FlexContainer>
  </StyledModal>
);

export default ConfirmDeleteModal;

const StyledModal = styled(Modal)`
  > div {
    max-width: 400px;
    min-height: 200px;
  }
`;

const ModalDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 16px;
  letter-spacing: 0.4px;
  margin: 12px 0;
  text-align: left;
`;

const ModalButton = styled(StyledButton)`
  margin: 16px 32px 16px 0;
`;
