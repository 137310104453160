import React, { useState, useRef } from "react";
import styled from "styled-components";
import { FlexContainer, PositionContainer } from "components/Atoms/Containers";
import { Body2, Subtitle } from "components/Atoms/Text";
import Underline from "components/Atoms/Underline";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";
import getLinkIcon from "assets/getLinkIcon";

const AssessmentLink = ({ assessment: { slug } }) => {
  const [linkModalShowing, setLinkModalShowing] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const modalRef = useRef(null);
  const linkRef = useRef(null);

  useClickedOutsideEvent(modalRef, () => setLinkModalShowing(false));

  const {
    REACT_APP_PROCTORING_URI,
    REACT_APP_TALENTSTAT_DOMAIN,
    REACT_APP_CORRELATIONONE_OLD_DOMAIN,
    NODE_ENV
  } = process.env;

  const getProctoringURL = () => {
    const proctorURI = REACT_APP_PROCTORING_URI;
    const tStatDomain = REACT_APP_TALENTSTAT_DOMAIN;
    const c1OldDomain = REACT_APP_CORRELATIONONE_OLD_DOMAIN;

    if (NODE_ENV === "development") {
      return `${proctorURI}`;
    } else if (window.location.href.includes(tStatDomain)) {
      return `${proctorURI}.${tStatDomain}`;
    } else {
      return `${proctorURI}.${c1OldDomain}`;
    }
  };

  const proctoringDomain = getProctoringURL();

  const copyLink = () => {
    linkRef.current.select();
    document.execCommand("copy");
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <FlexContainer
      ref={modalRef}
      align="center"
      className="pointer"
      onClick={() => setLinkModalShowing(true)}
    >
      {getLinkIcon}
      <StyledSubtitle>Get Link</StyledSubtitle>
      {linkModalShowing && (
        <ModalContainer top="24px" right="0px">
          <FlexContainer justify="space-between">
            <GetSharableLinkBody>
              Get shareable link of this test
            </GetSharableLinkBody>
            <div>
              <CopyText onClick={copyLink} className="pointer">
                Copy Link
              </CopyText>
              {copySuccess && <SuccessContainer>Copied!</SuccessContainer>}
            </div>
          </FlexContainer>
          <LinkText
            ref={linkRef}
            value={`${proctoringDomain}/${slug}`}
            readOnly
          ></LinkText>
          <Underline />
        </ModalContainer>
      )}
    </FlexContainer>
  );
};

export default AssessmentLink;

const StyledSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.smoky};
  margin: 0 0 0 8px;
  padding: 4px 0 0;
`;

const GetSharableLinkBody = styled(Body2)`
  color: ${({ theme }) => theme.colors.smoky};
`;

const CopyText = styled(Body2)`
  color: ${({ theme }) => theme.tenantAccent};
  font-size: 12px;
`;

const ModalContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  cursor: default;
  min-width: 480px;
  padding: 16px;
  z-index: 101;
`;

const SuccessContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 12px;
  padding: 7px 4px 4px;
  right: 0;
  z-index: 102;
`;

const LinkText = styled.input`
  border: none;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-top: 16px;
  outline: none;
  width: 100%;
`;
