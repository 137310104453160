import { updateAssessmentStateMW } from "pages/PanelPage/middleware";
import { VALID_STATES as VS } from "utils/constants/stateTypes";

const assessmentStateHelpers = {
  setArchived: async assessmentId => {
    const response = await updateAssessmentStateMW({
      urlParams: [assessmentId],
      data: { assessment_state: VS.ARCHIVED }
    });

    if (response.status === 200) {
      window.location.reload();
    }
  },
  setReview: async assessmentId => {
    const response = await updateAssessmentStateMW({
      urlParams: [assessmentId],
      data: { assessment_state: VS.REVIEW }
    });

    if (response.status === 200) {
      window.location.reload();
    }
  },
  setLive: async assessmentId => {
    const response = await updateAssessmentStateMW({
      urlParams: [assessmentId],
      data: { assessment_state: VS.ACTIVE }
    });

    if (response.status === 200) {
      window.location.reload();
    }
  },
  delete: async assessmentId => {
    const response = await updateAssessmentStateMW({
      urlParams: [assessmentId],
      data: { assessment_state: VS.RETIRED }
    });

    if (response.status === 200) {
      window.location.reload();
    }
  }
};

export default assessmentStateHelpers;
