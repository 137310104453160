import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { EACH_QUESTION } from "pages/ManagementPage/Constants";
import QuestionItemCard from "pages/ManagementPage/questionSection/questionItemCard/QuestionItemCard";
import dragAndDropIcon from "assets/dragAndDropIcon";

const AddedQuestionsList = ({
  addedQuestions,
  handleRemoveQuestion,
  selectedDuration,
  handleUpdateQuestions,
  errors,
  isSectionStock,
  handleOpenQuestionPoolEditor,
  questionPools
}) =>
  addedQuestions.map(
    (
      {
        id,
        content,
        question_type,
        accept_types,
        default_category,
        weight,
        duration,
        isQuestionPool = false,
        selectedPool = null
      },
      idx
    ) => {
      const isSlotDisabled = isSectionStock;
      const isDragDisabled = addedQuestions.length <= 1;
      return (
        <Draggable
          draggableId={`${id}`}
          index={idx}
          key={id}
          isDragDisabled={isDragDisabled}
        >
          {provided => (
            <ListItem {...provided.draggableProps} ref={provided.innerRef}>
              {addedQuestions.length > 1 && (
                <DragIcon {...provided.dragHandleProps}>
                  {dragAndDropIcon}
                </DragIcon>
              )}

              <QuestionItemCard
                category={default_category?.name}
                content={content?.[0]?.text_display}
                questionNumber={idx + 1}
                questionType={question_type}
                isVideoRecordQuestionType={accept_types?.includes("video")}
                handleRemoveQuestion={() => handleRemoveQuestion(id)}
                showDuration={selectedDuration === EACH_QUESTION}
                handleUpdateQuestions={(attr, value) =>
                  handleUpdateQuestions(attr, value, id)
                }
                weight={weight}
                duration={duration}
                isSlotDisabled={isSlotDisabled}
                errors={errors?.[id]}
                isQuestionPool={isQuestionPool}
                handleOpenQuestionPoolEditor={handleOpenQuestionPoolEditor}
                questionPools={questionPools}
                selectedPool={selectedPool}
              />
            </ListItem>
          )}
        </Draggable>
      );
    }
  );

export default AddedQuestionsList;

AddedQuestionsList.propTypes = {
  addedQuestions: PropTypes.array,
  handleRemoveQuestion: PropTypes.func.isRequired,
  selectedDuration: PropTypes.string.isRequired,
  handleUpdateQuestions: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isSectionStock: PropTypes.bool.isRequired,
  handleOpenQuestionPoolEditor: PropTypes.func.isRequired,
  questionPools: PropTypes.array
};

AddedQuestionsList.defaultProps = {
  addedQuestions: [],
  errors: {},
  questionPools: []
};

const ListItem = styled.li`
  list-style-type: none;
  margin-bottom: 8px;
  position: relative;
  & + li[data-rbd-placeholder-context-id] {
    color: transparent;
  }
`;

const DragIcon = styled.span`
  position: absolute;
  top: -5px;
  transform: rotate(90deg);
  z-index: 10;
  &[data-rbd-drag-handle-context-id] {
    cursor: move;
  }
`;
