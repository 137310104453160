import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { dialogBoxArrow } from "utils/mixins/Mixins";
import tooltipIcon from "assets/tooltipIcon";

const OptionTooltip = ({ children, className, content, align }) => {
  return (
    <TooltipContainer className={className} data-testid="tooltip-container">
      {children}
      <Info align={align} data-testid="tooltip-info">
        <TooltipText align={align}>{content}</TooltipText>
      </Info>
    </TooltipContainer>
  );
};

export default OptionTooltip;

OptionTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
  align: PropTypes.string
};

OptionTooltip.defaultProps = {
  children: tooltipIcon,
  className: "",
  content: "",
  align: ""
};

const TooltipText = styled.span`
  margin: ${({ align }) => (align === "center" ? "auto" : "")};
`;

const TooltipContainer = styled.div`
  cursor: pointer;
  position: relative;
  &:hover {
    & > div {
      display: flex;
    }
  }
`;

const Info = styled.div`
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  bottom: 25px;
  color: ${({ theme }) => theme.colors.white};
  display: none;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  left: ${({ align }) => (align === "center" ? "" : "-146px")};
  letter-spacing: 0.4px;
  line-height: 18px;
  max-width: 305px;
  padding: 18px 8px;
  position: absolute;
  width: 100vw;
  z-index: 10;
  ${dialogBoxArrow}
`;
