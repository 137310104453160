import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useForm, FormContext } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import CategoriesDropdown from "pages/ManagementPage/CategoriesDropdown";
import usePrevious from "pages/PanelPage/hooks/usePrevious";
import {
  FORM_ID,
  FREE_RESPONSE,
  FILE_UPLOAD,
  INVALID_VARIANT_ID,
  MULTIPLE_CHOICE,
  INPUT_TYPES,
  VIDEO_RECORD
} from "pages/QuestionPage/Constants";
import convertNumberToLetter from "pages/QuestionPage/helpers/convertNumberToLetter/convertNumberToLetter";
import useFetchTenants from "pages/QuestionPage/hooks/useFetchTenants";
import FileUploadInputs from "pages/QuestionPage/questionCreation/FileUploadInputs";
import FreeResponseInputs from "pages/QuestionPage/questionCreation/FreeResponseInputs";
import MultipleChoiceInputs from "pages/QuestionPage/questionCreation/MultipleChoiceInputs";
import QuestionTypeRadioGroup from "pages/QuestionPage/questionCreation/QuestionTypeRadioGroup";
import { FormContainer, FlexContainer } from "components/Atoms/Containers";
import Input from "components/Blocks/Forms/Input";
import { FormTitle } from "components/Blocks/ManagementForm";
import PopupNotificationContainer from "components/Blocks/PopupNotification";
import PopupNotification from "components/Blocks/PopupNotification";
import QuillEditor from "components/Systems/QuillEditor";
import { removeNumericInputSpinners } from "utils/mixins/Mixins";
import trashCanRedIcon from "assets/trashCanRedIcon";

const QuestionCreationForm = ({
  freeResponseAnswerOptions,
  helperText,
  legacyQuestionId,
  multipleAnswerOptions,
  onSubmit,
  questionBody,
  questionType,
  questionTypeOptions,
  selectedCategory,
  setFreeResponseAnswerOptions,
  setHelperText,
  setMultipleAnswerOptions,
  setQuestionBody,
  setQuestionType,
  title,
  setSelectedCategory,
  questionFiles,
  fileUploadErrors,
  deleteFile,
  pendingUploadedFiles,
  setPendingUploadedFiles,
  setTenantsList,
  tenantsList,
  setSelectedRestrictedTenant,
  selectedRestrictedTenant,
  selectedVariantQuestion,
  questionId,
  fileUploadAnswerOptions,
  setFileUploadAnswerOptions,
  setFileUploadPayload
}) => {
  const getInputNames = useCallback(
    (options, type, sufix) =>
      options.map(({ dummy = "" }, index) => {
        const answerOption = convertNumberToLetter(index);
        const optionId = `${type}-answer-option-${answerOption}-${sufix}${dummy}`;
        return optionId;
      }),
    []
  );

  const getAnswers = useCallback(() => {
    switch (questionType) {
      case FREE_RESPONSE:
        return freeResponseAnswerOptions;
      case MULTIPLE_CHOICE:
        return multipleAnswerOptions;
      case FILE_UPLOAD:
        return fileUploadAnswerOptions;
      case VIDEO_RECORD:
        return fileUploadAnswerOptions;
      default:
        console.error("Invalid question type");
        break;
    }
  }, [
    multipleAnswerOptions,
    freeResponseAnswerOptions,
    fileUploadAnswerOptions,
    questionType
  ]);

  const getInputType = useCallback(() => INPUT_TYPES[questionType], [
    questionType
  ]);

  const checkHTML = body => {
    const strippedTags = body
      ?.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, "$1")
      .trim();
    return strippedTags === "";
  };

  const validateHtml = function(body) {
    if (checkHTML(body)) {
      return this.createError({
        path: this.path,
        message: "Empty answer body"
      });
    }
    return true;
  };

  const validateVariantId = function(variantId) {
    if (variantId === questionId) {
      return this.createError({
        path: this.path,
        message: "Question can't be a variant of itself"
      });
    }
    return true;
  };

  const validationSchema = useMemo(() => {
    const getCommonSchema = () =>
      yup.object().shape({
        "question-title": yup.string(),
        "question-body": yup
          .string()
          .required("")
          .test(validateHtml),
        "question-category": yup.string().required(""),
        "question-variant": yup
          .number()
          .transform((currentValue, originalValue) =>
            !originalValue ? null : currentValue
          )
          .positive("This question does not exist")
          .notRequired()
          .nullable()
          .test(validateVariantId)
      });

    const getScoresSchema = (answers, inputType) => {
      const scoreFields = {};
      const scoreInputNames = getInputNames(answers, inputType, "score");

      for (const input of scoreInputNames) {
        scoreFields[input] = yup
          .number()
          .required("")
          .min(0)
          .integer();
      }

      yup.addMethod(yup.object, "atLeastOnePositive", function() {
        return this.test("atLeastOnePositive", function(list) {
          for (const scoreInput of scoreInputNames) {
            if (scoreInput.includes("file-upload")) return list[scoreInput] < 0;
            if (list[scoreInput] > 0) {
              return true;
            }
          }
          return this.createError({
            path: [scoreInputNames[0]],
            message: ""
          });
        });
      });

      return yup
        .object()
        .shape({ ...scoreFields })
        .atLeastOnePositive();
    };

    const getBodySchema = (answers, type, inputType) => {
      const getFreeResponseBodySchema = () => {
        const bodyMinInputs = getInputNames(answers, inputType, "min");
        const bodyMaxInputs = getInputNames(answers, inputType, "max");
        const bodyFields = {};
        const limit = Math.pow(10, 8);

        for (const input of bodyMinInputs) {
          const compareName = input.split("-").slice(0, -1);
          const compareInput = compareName.join("-") + "-max";
          bodyFields[input] = yup
            .number()
            .max(yup.ref(compareInput))
            .test(value => value < limit);
        }
        for (const input of bodyMaxInputs) {
          const compareName = input.split("-").slice(0, -1);
          const compareInput = compareName.join("-") + "-min";
          bodyFields[input] = yup
            .number()
            .min(yup.ref(compareInput))
            .test(value => value < limit);
        }

        return yup.object().shape({ ...bodyFields });
      };
      const getFileUploadSchema = () => {
        const bodyInputs = getInputNames(answers, inputType, "body");
        const bodyFields = {};

        for (const input of bodyInputs) {
          bodyFields[input] = yup.string().test(validateHtml);
        }
        return yup.object().shape({
          "file-upload-answer-option-A-score": yup.number().required(),
          "question-category": yup.string().required()
        });
      };
      const getMultipleChoiceBodySchema = () => {
        const bodyInputs = getInputNames(answers, inputType, "body");
        const bodyFields = {};
        for (const input of bodyInputs) {
          bodyFields[input] = yup.string().test(validateHtml);
        }

        return yup.object().shape({
          ...bodyFields
        });
      };

      switch (type) {
        case FREE_RESPONSE:
          return getFreeResponseBodySchema();
        case MULTIPLE_CHOICE:
          return getMultipleChoiceBodySchema();
        case FILE_UPLOAD:
          return getFileUploadSchema();
        case VIDEO_RECORD:
          return getFileUploadSchema();
        default:
          return;
      }
    };

    const answers = getAnswers();
    const inputType = getInputType();

    const commonSchema = getCommonSchema();
    const scoresSchema = getScoresSchema(answers, inputType);
    const bodySchemas = getBodySchema(answers, questionType, inputType);
    return commonSchema.concat(scoresSchema).concat(bodySchemas);
  }, [multipleAnswerOptions, freeResponseAnswerOptions, questionType]);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    validationSchema
  });

  const {
    handleSubmit,
    errors,
    setError,
    getValues,
    clearError,
    watch,
    setValue,
    formState: { isSubmitting, submitCount }
  } = methods;

  const prevCount = usePrevious(submitCount);

  const checkKeyDown = e => {
    e.keyCode === 13 && e.preventDefault();
  };

  const scoreFileUploadWatcher = watch("file-upload-answer-option-A-score");
  const fileUploadBodyWatcher = watch("question-body");
  const questionCategoryWatcher = watch("question-category");
  const questionTitleWatcher = watch("question-title");
  const questionTypeWatcher = watch("question-type");

  useEffect(() => {
    if (
      questionTypeWatcher === FILE_UPLOAD ||
      questionTypeWatcher === VIDEO_RECORD
    ) {
      setFileUploadPayload({
        score: scoreFileUploadWatcher,
        body: fileUploadBodyWatcher,
        "question-category": questionCategoryWatcher,
        "question-title": questionTitleWatcher,
        "question-type": questionTypeWatcher
      });
    }
  }, [
    scoreFileUploadWatcher,
    fileUploadBodyWatcher,
    questionCategoryWatcher,
    questionTitleWatcher,
    questionTypeWatcher,
    setFileUploadPayload
  ]);

  const onDrop = useCallback(
    acceptedFiles => {
      setPendingUploadedFiles(oldPendingUploadedFiles => [
        ...oldPendingUploadedFiles,
        ...acceptedFiles
      ]);
    },
    [setPendingUploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noDrag: false,
    multiple: true
  });

  useEffect(() => {
    if (isSubmitting) {
      const category = getValues("question-category");
      if (!category) return;
      else if (selectedCategory?.id) setSelectedCategory(selectedCategory);

      return;
    }
    const scores = getInputNames(getAnswers(), getInputType(), "score");
    const inputErrorNames = Object.keys(errors);
    const noPositiveScore =
      inputErrorNames &&
      inputErrorNames.some(
        input =>
          input.split("-").reverse()[0] === "score" &&
          errors[input]?.type === "atLeastOnePositive"
      );

    if (noPositiveScore) {
      scores.forEach(score => setError(score));
    }

    const modifiedIndex = multipleAnswerOptions.find(
      ({ modifiedIndex }) => modifiedIndex
    );

    if (modifiedIndex) {
      scores.forEach(score => clearError(score));
      setMultipleAnswerOptions(
        multipleAnswerOptions.map(({ ...answerAttr }) => ({
          ...answerAttr
        }))
      );
    }
  }, [isSubmitting, errors, setError, getInputType, getAnswers, getInputNames]);

  useEffect(() => {
    if (selectedVariantQuestion === INVALID_VARIANT_ID) {
      setError("question-variant", "error", "This question does not exists");
    }
  }, [selectedVariantQuestion, setError]);
  useFetchTenants(tenants => {
    setTenantsList([{ id: "None", value: "None" }, ...tenants]);
  });

  const handleSelectedOption = selected => {
    const tenantsData = tenantsList.find(({ id }) => selected === id);
    setSelectedRestrictedTenant(tenantsData);
    if (tenantsData.id === "None") {
      setSelectedRestrictedTenant(null);
    }
  };

  const renderRestrictedPopup = () => {
    if (selectedRestrictedTenant?.value) {
      return (
        <RestrictedPopupNotification
          content="Restricted from being used on any other accounts."
          popupType="ALERT"
          title={`Limits this question for only ${selectedRestrictedTenant.value} assessments.`}
        ></RestrictedPopupNotification>
      );
    }
  };

  const getMultipleAnswerErrors = () =>
    Object.keys(errors)
      .map(key => {
        if (errors[key]?.message === "Empty answer body") {
          const errorAnswerIndex =
            key
              .split("-")
              .reverse()[1]
              .charCodeAt() - 65;
          return errorAnswerIndex;
        }
        return null;
      })
      .filter(errElement => errElement !== null);

  const renderCommonFields = () => (
    <>
      <FormSection>
        <FormTitle>Type of question</FormTitle>
        <QuestionTypeRadioGroup
          questionType={questionType}
          questionTypeOptions={questionTypeOptions}
          setQuestionType={setQuestionType}
        />
      </FormSection>
      <FormSection>
        <FormTitle>Question title</FormTitle>
        <Input
          name="question-title"
          placeholder="Question Title"
          defaultValue={title}
        />
      </FormSection>
      <FormSection>
        <FormTitle>Question body</FormTitle>
        <QuillEditor
          key={isSubmitting}
          initialBody={questionBody}
          onUpdatedBody={setQuestionBody}
          inputName="question-body"
          isValidField={submitCount === 0 ? true : Boolean(questionBody)}
          validateOnSubmit
        />
      </FormSection>
    </>
  );

  const renderCategoryField = () => (
    <CategorySection>
      <FormTitle>Category</FormTitle>
      <StyledDropdown
        isValid={Boolean(selectedCategory || !submitCount)}
        defaultCategory={selectedCategory}
        placeholder="Select Default Category"
        onChange={e => {
          setSelectedCategory(e);
          setValue("question-category", e);
        }}
      />
    </CategorySection>
  );

  const renderRestrictedTenantDropdown = () => (
    <RestrictedCategorySection>
      <FormTitle>Restricted to</FormTitle>
      <RestrictedDropdown
        name="restrictedTenantDropdown"
        setSelectedOption={selected => handleSelectedOption(selected)}
        selectedOption={selectedRestrictedTenant?.value}
        options={tenantsList}
        placeholder={selectedRestrictedTenant?.value || "None"}
      />
    </RestrictedCategorySection>
  );

  const renderExtraFields = () => {
    const renderMultipleChoiceFields = () => (
      <MultipleChoiceInputs
        answerOptions={multipleAnswerOptions}
        setAnswerOptions={setMultipleAnswerOptions}
        answerErrors={
          submitCount !== prevCount ? getMultipleAnswerErrors() : null
        }
      />
    );

    const renderFreeResponseInputs = () => (
      <FreeResponseInputs
        answerOptions={freeResponseAnswerOptions}
        setAnswerOptions={setFreeResponseAnswerOptions}
      />
    );
    const renderFileUploadInputs = () => (
      <FileUploadInputs
        answerOptions={fileUploadAnswerOptions}
        setAnswerOptions={setFileUploadAnswerOptions}
        disclaimer="Set a score to the file upload answer. A score of 0 is allowed."
      />
    );
    const renderVideoUploadInputs = () => (
      <FileUploadInputs
        answerOptions={fileUploadAnswerOptions}
        setAnswerOptions={setFileUploadAnswerOptions}
        disclaimer="Set a score to the video record answer. A score of 0 is allowed."
      />
    );
    const renderHelperText = () => (
      <FormSection>
        <FormTitle>Helper text</FormTitle>
        <HelperText>
          <Input
            defaultValue={helperText}
            name="free-response-helper-text"
            onChange={ev => setHelperText(ev.target.value)}
          />
        </HelperText>
      </FormSection>
    );
    let fields = null;
    switch (questionType) {
      case MULTIPLE_CHOICE:
        fields = renderMultipleChoiceFields();
        break;
      case FREE_RESPONSE:
        fields = renderFreeResponseInputs();
        break;
      case FILE_UPLOAD:
        fields = renderFileUploadInputs();
        break;
      case VIDEO_RECORD:
        fields = renderVideoUploadInputs();
        break;
      default:
        return null;
    }
    return (
      <>
        <FormSection>
          <FormTitle>Answer & Score</FormTitle>
          {fields}
        </FormSection>
        {questionType === FREE_RESPONSE && renderHelperText()}
      </>
    );
  };

  const renderLegacyQuestionId = () => (
    <FormSection>
      <FormTitle>Legacy Question ID</FormTitle>
      <HelperText>
        <NumericInputWithPrefix
          type="number"
          name="legacy-question"
          defaultValue={legacyQuestionId}
          min={1}
        />
      </HelperText>
    </FormSection>
  );

  const renderQuestionVariantField = () => (
    <QuestionVariantSection>
      <FormTitle>Variation Of Question</FormTitle>
      <HelperText>
        <NumericInputWithPrefix
          type="number"
          name="question-variant"
          defaultValue={selectedVariantQuestion}
          min={1}
        />
      </HelperText>
    </QuestionVariantSection>
  );

  const getParsedFileName = rawFileName => {
    const nameList = rawFileName.split("/");
    return nameList[nameList.length - 1];
  };

  const renderQuestionFileListDisplay = () =>
    questionFiles.length > 0 && (
      <DropzoneFilesList>
        {questionFiles.map(questionFile => (
          <DropzoneFilesGroup key={questionFile.id}>
            <span>{getParsedFileName(questionFile.file_name)}</span>
            <div onClick={() => deleteFile(questionFile.id)}>
              {trashCanRedIcon}
            </div>
          </DropzoneFilesGroup>
        ))}
      </DropzoneFilesList>
    );

  const renderPendingUploadedFiles = () =>
    pendingUploadedFiles.length > 0 && (
      <DropzoneFilesList>
        {pendingUploadedFiles.map((file, i) => (
          <DropzoneFilesGroup key={i}>
            <span>{file.name}</span>
            <div
              onClick={() => {
                setPendingUploadedFiles(oldSetPendingUploadedFiles => [
                  ...oldSetPendingUploadedFiles.filter(
                    (_, index) => index !== i
                  )
                ]);
              }}
            >
              {trashCanRedIcon}
            </div>
          </DropzoneFilesGroup>
        ))}
      </DropzoneFilesList>
    );

  const createDropZone = () => (
    <FlexContainer justify="space-between" as="section">
      <DropzoneButton justify="center" align="center" {...getRootProps()}>
        <input {...getInputProps()} />
        <DropzoneButtonText>Upload Files</DropzoneButtonText>
      </DropzoneButton>
      <FlexContainer direction="column">
        {renderQuestionFileListDisplay()}
        {renderPendingUploadedFiles()}
      </FlexContainer>
    </FlexContainer>
  );

  const renderQuestionFileErrors = () =>
    fileUploadErrors.map((err, i) => (
      <PopupNotification
        content={err}
        duration={500000}
        popupType="ERROR"
        title="There was an error uploading file"
        key={i}
      ></PopupNotification>
    ));

  const renderQuestionFile = () => (
    <FormSection>
      <FormTitle>Attachments</FormTitle>
      {createDropZone()}
    </FormSection>
  );

  return (
    <FormContainer
      id={FORM_ID}
      onKeyDown={e => checkKeyDown(e)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormContext {...methods}>
        {renderQuestionFileErrors()}
        {renderCommonFields()}
        {renderQuestionFile()}
        {renderExtraFields()}
        {renderCategoryField()}
        <FlexContainer align="flex-start">
          {renderQuestionVariantField()}
          {renderLegacyQuestionId()}
        </FlexContainer>
        {renderRestrictedTenantDropdown()}
        {renderRestrictedPopup()}
      </FormContext>
    </FormContainer>
  );
};

export default QuestionCreationForm;

QuestionCreationForm.propTypes = {
  freeResponseAnswerOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  helperText: PropTypes.string.isRequired,
  legacyQuestionId: PropTypes.number,
  multipleAnswerOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
  questionBody: PropTypes.string.isRequired,
  questionType: PropTypes.string,
  questionTypeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCategory: PropTypes.string,
  selectedRestrictedTenant: PropTypes.object,
  setFreeResponseAnswerOptions: PropTypes.func.isRequired,
  setHelperText: PropTypes.func.isRequired,
  setMultipleAnswerOptions: PropTypes.func.isRequired,
  setQuestionBody: PropTypes.func.isRequired,
  setQuestionType: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  title: PropTypes.string,
  questionFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
  fileUploadErrors: PropTypes.arrayOf(PropTypes.any).isRequired,
  deleteFile: PropTypes.func.isRequired,
  pendingUploadedFiles: PropTypes.arrayOf(PropTypes.any).isRequired,
  setPendingUploadedFiles: PropTypes.func.isRequired,
  selectedVariantQuestion: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  questionId: PropTypes.number
};

QuestionCreationForm.defaultProps = {
  questionType: MULTIPLE_CHOICE,
  title: "",
  legacyQuestionId: null,
  selectedCategory: null,
  selectedVariantQuestion: null,
  questionId: null
};

const FormSection = styled.div`
  margin-bottom: 18px;
`;

const CategorySection = styled(FormSection)`
  width: fit-content;
`;

const QuestionVariantSection = styled(FormSection)`
  margin-right: 40px;
`;

const DropzoneButton = styled(FlexContainer)`
  background-color: ${({ theme }) => theme.tenantAccent};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 12px;
  height: 24px;
  line-height: 12px;
  text-transform: uppercase;
  width: 110px;
`;

const DropzoneButtonText = styled.span`
  position: relative;
  top: 1.5px;
`;

const DropzoneFilesGroup = styled.li`
  align-content: center;
  display: flex;
  justify-content: center;
  margin: 0;

  span {
    align-items: center;
    border: ${({ theme }) => theme.colors.veryLightGrey};
    display: flex;
    height: 24px;
    padding: 1px;
    top: 50%;
    user-select: none;
  }
  > div {
    cursor: pointer;
  }
  svg {
    height: 24px;
    width: 24px;
  }
`;
const DropzoneFilesList = styled.ul`
  align-items: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const HelperText = styled.div`
  max-width: 180px;
`;

const RestrictedCategorySection = styled(FormSection)`
  width: 100%;
`;

const RestrictedDropdown = styled(CategoriesDropdown)`
  height: 36px;
  width: 100%;
  > div:first-child {
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fonts.avenirProRoman};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
  & ~ div:last-child {
    max-height: 300px;
    max-width: auto;
    overflow-y: auto;
    position: relative;
    width: 100%;
  }
`;

const RestrictedPopupNotification = styled(PopupNotificationContainer)`
  background: rgba(231, 126, 70, 0.19);
  border: 1px solid #df7c48;
  border-radius: 2px;
  max-width: 100%;
  padding-left: 25px;
  svg {
    margin-right: 18px;
  }
  > div:first-child {
    color: #c35a22;
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.15px;
    line-height: 24px;
    width: auto;
  }
  p {
    color: #bf7046;
    font-family: ${({ theme }) => theme.fonts.avenirProRoman};
    font-size: 12px;
    font-weight: normal;
    height: 16px;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-left: 42.5px;
    width: auto;
  }
`;

const StyledDropdown = styled(CategoriesDropdown)`
  height: 36px;
  max-width: 206px;
  width: 100%;
  > div:first-child {
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fonts.avenirProRoman};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
  & ~ div:last-child {
    max-height: 300px;
    max-width: 206px;
    overflow-y: auto;
    position: relative;
    width: 100%;
  }
`;

const NumericInputWithPrefix = styled(Input)`
  padding-left: 16px;
  ${removeNumericInputSpinners}
  + div:first-of-type {
    &::before {
      align-items: center;
      bottom: 100%;
      color: ${({ theme }) => theme.colors.steel};
      content: "#";
      display: flex;
      font-family: ${({ theme }) => theme.fonts.avenirProRoman};
      font-size: 12px;
      height: 40px;
      left: 8px;
      position: absolute;
      top: 2px;
    }
    &.underline {
      &::before {
        top: unset;
      }
    }
  }
`;
