import { createBrowserHistory } from "history";

const EPHEMERAL_HOSTNAME = "eph.panel.assessments.nprd.correlation-one.com";
const baseName =
  EPHEMERAL_HOSTNAME === window.location.hostname
    ? `/ephemeral-${process.env.REACT_APP_PR_NUMBER}`
    : "";

export default createBrowserHistory({
  basename: baseName
});
