import styled from "styled-components";

const PageWrapper = styled.div`
  background: ${({ background }) => background};
  min-height: 100vh;
  width: 100%;
`;

// Export Wrappers
export { PageWrapper };
