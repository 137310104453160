import styled from "styled-components";

/**
 * Generates the CSS styles for indenting text in a question body.
 * We are using Quill for the question creation, and we need to apply the same
 * indent styles in the question body.
 * The indent style goes from ql-indent-1 to ql-indent-9.
 * @returns {string} The CSS styles for indenting text.
 */
export const generateIndentStyles = () => {
  let indentStyles = "";
  for (let i = 1; i <= 9; i++) {
    indentStyles += `
      .ql-indent-${i} {
        margin-left: ${i * 40}px;
      }
    `;
  }
  return indentStyles;
};

export const QuestionBody = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 28px;
  margin-bottom: 16px;
  min-height: 28px;
  ${generateIndentStyles()}
`;

export const AnswerDescription = styled.div`
  color: ${({ theme }) => theme.tenantAccent};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.67px;
  line-height: 16px;
  margin-bottom: 8px;
`;

export const RadioRowWrapper = styled.div`
  align-items: flex-start;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  margin: 6px 6px 6px 0;
  min-height: 50px;
  position: relative;
`;

export const RadioInput = styled.input`
  appearance: none;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  height: 16px;
  left: 16px;
  min-height: 16px;
  min-width: 16px;
  outline: none;
  position: absolute;
  top: 17px;
  width: 16px;
`;

export const RadioLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.89px;
  padding: 18px 16px 16px 50px;
  width: 100%;
`;

export const FreeResponseInput = styled.div`
  background: #f5f5f5;
  border-bottom: solid 2px #909090;
  color: #909090;
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 17px;
  min-height: 45px;
  min-width: 180px;
  padding: 12px 8px;
`;
