import React from "react";
import { Prompt } from "react-router";
import { paths } from "pages/App/helpers/paths";

const isValidPath = (pathname, createdAssessment) => {
  const VALID_PATHS = [
    `${paths.ADMIN}/assessment-management/edit-assessment`,
    `${paths.ADMIN}/assessment-management/create`
  ];
  if (
    (pathname === `${paths.ADMIN}/active` && createdAssessment) ||
    VALID_PATHS.some(path => pathname.includes(path))
  ) {
    return true;
  }
  return false;
};

const LeaveRoutePrompt = () => (
  <Prompt
    message={({ pathname, createdAssessment }) => {
      if (isValidPath(pathname, createdAssessment)) {
        return true;
      }
      return "Changes you made may not be saved.";
    }}
  />
);
export default LeaveRoutePrompt;
