import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import usePrevious from "pages/PanelPage/hooks/usePrevious";
import useFetchCategories from "pages/QuestionPage/hooks/useFetchCategories";
import Dropdown from "components/Blocks/Dropdown";

const CategoriesDropdown = ({
  className,
  defaultCategory,
  updateCategory,
  placeholder,
  resetDropdown,
  excludedCategories,
  onChange,
  ...dropdownProps
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const prevCategories = usePrevious(categories);

  const handleFetchCategories = categories => {
    const fetchedCategories = categories.map(({ id, name }) => ({
      id: `${id}`,
      value: name
    }));

    setCategories(fetchedCategories);

    const getDefaultCategory = () =>
      fetchedCategories.find(({ value }) => value === defaultCategory) ||
      fetchedCategories.find(({ id }) => id === `${defaultCategory}`);

    setSelectedCategory(getDefaultCategory());
  };

  useFetchCategories(handleFetchCategories);

  const handleSelectedCategory = selectedId => {
    onChange && onChange(categories.find(({ id }) => selectedId === id));
    setSelectedCategory(categories.find(({ id }) => selectedId === id));
  };

  useEffect(() => {
    if (
      excludedCategories &&
      categories &&
      prevCategories &&
      prevCategories.length !== categories.length
    ) {
      setCategories(
        categories.filter(({ value }) => !excludedCategories.includes(value))
      );
    }
  }, [excludedCategories, categories, prevCategories]);

  useEffect(() => {
    selectedCategory && updateCategory(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    resetDropdown && setSelectedCategory(null);
  }, [resetDropdown]);

  return (
    <StyledDropdown
      className={className}
      options={categories}
      placeholder={placeholder || "Categories"}
      setSelectedOption={selectedId => handleSelectedCategory(selectedId)}
      selectedOption={selectedCategory?.value}
      name="question-category"
      {...dropdownProps}
    />
  );
};

export default CategoriesDropdown;

CategoriesDropdown.propTypes = {
  className: PropTypes.string,
  defaultCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateCategory: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resetDropdown: PropTypes.bool,
  excludedCategories: PropTypes.array
};

CategoriesDropdown.defaultProps = {
  className: "",
  defaultCategory: "",
  placeholder: "",
  updateCategory: () => null,
  excludedCategories: []
};

const StyledDropdown = styled(Dropdown)`
  height: 30px;
  + div {
    background: ${({ theme }) => theme.colors.transparent};
  }
`;
