import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TakerSummaryLine from "pages/PanelPage/components/charts/TakerSummaryLine";
import { getAssessmentAttemptStockStatisticsMW } from "pages/PanelPage/middleware";
import { Body1, Subtitle2 } from "components/Atoms/Text";
import { Row, BorderTable, BorderCell } from "components/Blocks/Styled/Tables";

const OverallPortfolioTimeline = ({ currentUuid }) => {
  let [stockStatisticsData, setStockStatisticsData] = useState();

  const fetchAssessmentAttemptStockStatistics = async () => {
    const statisticsData = await getAssessmentAttemptStockStatisticsMW({
      urlParams: [currentUuid]
    });
    setStockStatisticsData(statisticsData.data);
  };

  useEffect(() => {
    fetchAssessmentAttemptStockStatistics();
  }, []);

  const renderRow = () => {
    const {
      bankruptcies,
      max_drawdown,
      return_average,
      sharpe_ratio,
      volatility
    } = stockStatisticsData;

    return (
      <Row>
        <BorderCell padding="24px 12px" textAlign="center">
          <Subtitle2 className="bold">
            {(return_average * 100).toFixed(1)}%
          </Subtitle2>
          <Body1 color="#6a7f87">Return Avg.</Body1>
        </BorderCell>
        <BorderCell padding="24px 12px" textAlign="center">
          <Subtitle2 className="bold">
            {(volatility * 100).toFixed(1)}%
          </Subtitle2>
          <Body1 color="#6a7f87">Volatility Avg.</Body1>
        </BorderCell>
        <BorderCell padding="24px 12px" textAlign="center">
          <Subtitle2 className="bold">{sharpe_ratio}</Subtitle2>
          <Body1 color="#6a7f87">Annualized Sharpe Ratio</Body1>
        </BorderCell>
        <BorderCell padding="24px 12px" textAlign="center">
          <Subtitle2 className="bold">
            {parseInt(max_drawdown * 100)}%
          </Subtitle2>
          <Body1 color="#6a7f87">Max drawdown</Body1>
        </BorderCell>
        <BorderCell padding="24px 12px" textAlign="center">
          <Subtitle2 className="bold">{bankruptcies}</Subtitle2>
          <Body1 color="#6a7f87">Bankruptcy Count</Body1>
        </BorderCell>
      </Row>
    );
  };

  return (
    <>
      {stockStatisticsData?.all_ticks?.length > 0 && (
        <BorderTable margin="20px 0 0" borderCollapse="collapse">
          <thead>
            <Row>
              <BorderCell colSpan="5" padding="12px 27px 8px">
                <Body1
                  className="bold"
                  color="#212629"
                  textTransform="capitalize"
                >
                  portfolio timeline
                </Body1>
              </BorderCell>
            </Row>
          </thead>
          <tbody>
            <Row>
              <BorderCell colSpan="6">
                <TakerSummaryLine ticks={stockStatisticsData.all_ticks} />
              </BorderCell>
            </Row>
            {renderRow()}
          </tbody>
        </BorderTable>
      )}
    </>
  );
};

export default OverallPortfolioTimeline;

OverallPortfolioTimeline.propTypes = {
  currentUuid: PropTypes.string.isRequired
};
