import endpointNames from "utils/constants/endpointNames";
import makeRequest from "utils/requests/makeRequest";

export const fetchQuestionGroupListMW = async ({ queryParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getQuestionGroupList,
      queryParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const fetchSingleQuestionDataMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getSingleQuestionData,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const fetchAssessmentUsingQuestionMW = async ({ queryParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getAssessmentsByQuestion,
      queryParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const updateQuestionMW = async ({ urlParams, data }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.updateQuestion,
      urlParams,
      data
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const fetchSingleQuestionGroupMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getSingleQuestionGroupData,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const getQuestionInformationMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getQuestionInformation,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const createQuestionMW = async ({ data }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.createQuestion,
      data
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const saveFileMW = async ({ urlParams, data }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.saveFile,
      urlParams,
      data,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return request;
  } catch (err) {
    console.error(err);
  }
};

export const deleteFileMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.deleteFile,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};
