import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { useHistory, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useAssessmentContext } from "state/assessmentContext/useAssessmentContext";
import { useThemeContext } from "state/themeContext/useThemeContext";
import { useUserContext } from "state/userContext/useUserContext";
import { listTypes } from "pages/App/helpers/listTypes";
import { paths } from "pages/App/helpers/paths";
import { permissionTypes } from "pages/App/helpers/permissionTypes";
import { tenantAccount } from "pages/PanelPage/Constants";
import NavbarSublinks from "pages/PanelPage/components/NavbarSublinks";
import ProfileOptionsMenu from "pages/PanelPage/components/ProfileOptionsMenu";
import { getAssessmentsCountMW } from "pages/PanelPage/middleware";
import { questionLibraryPath } from "pages/QuestionPage/Constants";
import useFetchTenants from "pages/QuestionPage/hooks/useFetchTenants";
import {
  FlexContainer,
  Container,
  MaxWidthContainer
} from "components/Atoms/Containers";
import { H4, Body1 } from "components/Atoms/Text";
import Dropdown from "components/Blocks/Dropdown";
import { Img } from "components/Blocks/Styled/Media";
import TypeToggle from "components/Blocks/TypeToggle";
import AccessControl from "components/Systems/AccessControl";
import { VALID_STATES as VS } from "utils/constants/stateTypes";
import { handleButtonHover } from "utils/helpers/handleButtonHover";

const Navbar = ({
  isQuestionLibraryPage,
  isUnauthorizedPage,
  setStateList,
  setSelectedFilter,
  selectedFilter,
  setTenantId,
  tenantId
}) => {
  const params = new URLSearchParams(location.search);
  const [assessmentCount, setAssessmentCount] = useState(null);
  const [tenantsList, setTenantsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const history = useHistory();
  const theme = useThemeContext();
  const {
    assessmentState: { selectedAssessment },
    assessmentDispatch: { setSelectedAssessment, clearAssessments }
  } = useAssessmentContext();
  const {
    userState: { userData },
    userDispatch: { setAuthenticated, setUserData, setTenantUser },
    isAnonymous
  } = useUserContext();

  useEffect(() => {
    setTenantUser(selectedOption);
  }, [selectedOption]);

  const goToAssessmentsView = () => {
    setSelectedAssessment(null);
  };

  const handleLogout = () => {
    // TODO: request to blacklist token
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    clearAssessments();
    setAuthenticated(false);
    setUserData(null);
    Sentry.setUser(null);
    history.push(paths.LOGIN);
  };

  useEffect(() => {
    const getAssessmentsCount = async () => {
      if (tenantId && tenantId !== tenantAccount.ALL_ACCOUNTS) {
        params.append("tenant_id", tenantId);
      } else if (tenantId === null && userData?.tenant_id) {
        params.append("tenant_id", userData.tenant_id);
      }

      let response = await getAssessmentsCountMW({ queryParams: params });
      setAssessmentCount(response.data);
    };
    getAssessmentsCount();
  }, [tenantId, userData]);

  useFetchTenants(tenants => {
    setTenantsList([
      { id: tenantAccount.ALL_ACCOUNTS, value: "All Accounts" },
      ...tenants
    ]);
  });

  useEffect(() => {
    const paramTenantId = Number(params.get("tenant_id"));
    const currentTenant = tenantsList.find(({ id }) => paramTenantId === id);
    setSelectedOption(currentTenant);
  }, [tenantsList, selectedOption]);

  const updateSearchParams = parameters => {
    history.push({
      search: parameters.toString()
    });
  };
  const handleSelectedOption = selected => {
    const currentTenant = tenantsList.find(({ id }) => selected === id);
    setSelectedOption(currentTenant);
    setTenantUser(currentTenant);
    setTenantId(currentTenant);

    const newParams = new URLSearchParams(params);
    if (currentTenant.id === "allAccounts") {
      newParams.delete("tenant_id");
    } else {
      newParams.set("tenant_id", currentTenant.id);
    }

    updateSearchParams(newParams);
  };

  const renderNavBottom = () => {
    if (selectedAssessment) {
      return (
        <>
          <NameContainer>
            <H4>{selectedAssessment.name}</H4>
          </NameContainer>
          <NavbarSublinks selectedAssessment={selectedAssessment} />
        </>
      );
    } else {
      return (
        <FlexContainer>
          <TypeToggle
            type={
              isQuestionLibraryPage
                ? listTypes.QUESTIONS
                : listTypes.ASSESSMENTS
            }
            setStateList={setStateList}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            countObj={assessmentCount}
          />
          {userData?.tenant_id === 1 && !isQuestionLibraryPage && (
            <TenantDropdown
              name="tenantDropdown"
              setSelectedOption={selected => handleSelectedOption(selected)}
              options={tenantsList}
              placeholder={selectedOption?.value || "All Accounts"}
            />
          )}
        </FlexContainer>
      );
    }
  };

  const renderLogo = () => {
    const logo = (
      <Img
        width="125px"
        src={theme.domainTheme.logo}
        alt="tenant logo"
        className="pointer"
      />
    );
    return isAnonymous ? (
      logo
    ) : (
      <NavBarLink
        to={`${paths.ADMIN}/${VS.ACTIVE}`}
        onClick={goToAssessmentsView}
      >
        {logo}
      </NavBarLink>
    );
  };

  return (
    <NavContainer
      showAssessmentName={!!selectedAssessment}
      isCollapsed={isUnauthorizedPage}
    >
      <MaxWidthContainer>
        <FlexContainer direction="column" justify="space-between">
          <TopRowContainer justify="space-between">
            <FlexContainer align="flex-start">
              {renderLogo()}
              {!isUnauthorizedPage && !isAnonymous && (
                <>
                  <Divider />
                  <NavBarLink
                    className={!isQuestionLibraryPage && VS.ACTIVE}
                    to={`${paths.ADMIN}/${VS.ACTIVE}`}
                    onClick={goToAssessmentsView}
                  >
                    <NavBarLinkText className="pointer">
                      All Assessments
                    </NavBarLinkText>
                  </NavBarLink>
                  <AccessControl
                    allowedPermissions={
                      permissionTypes.CAN_VIEW_QUESTION_LIBRARY
                    }
                    renderNoAccess={() => null}
                  >
                    <NavBarLink
                      to={`${questionLibraryPath}/${VS.ALL}`}
                      className={isQuestionLibraryPage && VS.ACTIVE}
                    >
                      <NavBarLinkText className="pointer">
                        Question Library
                      </NavBarLinkText>
                    </NavBarLink>
                  </AccessControl>
                </>
              )}
            </FlexContainer>
            {!isAnonymous && (
              <ProfileOptionsMenu
                label={userData ? userData.email : "Menu"}
                options={[{ text: "Log out", onClick: handleLogout }]}
              />
            )}
          </TopRowContainer>
          {!isUnauthorizedPage && renderNavBottom()}
        </FlexContainer>
      </MaxWidthContainer>
    </NavContainer>
  );
};

Navbar.propTypes = {
  isQuestionLibraryPage: PropTypes.bool,
  isUnauthorizedPage: PropTypes.bool,
  setStateList: PropTypes.func,
  setSelectedFilter: PropTypes.func,
  selectedFilter: PropTypes.string,
  setTenantId: PropTypes.func,
  tenantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Navbar.defaultProps = {
  isQuestionLibraryPage: false,
  isUnauthorizedPage: false,
  setStateList: () => null,
  setSelectedFilter: () => null,
  selectedFilter: "",
  setTenantId: () => null,
  tenantId: null
};

export default Navbar;

const NavContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
  height: ${({ showAssessmentName, isCollapsed }) =>
    isCollapsed ? "46px" : showAssessmentName ? "158px" : "120px"};
  padding: ${({ showAssessmentName }) =>
    showAssessmentName ? "12px 0 0" : "12px 0 24px"};
`;

const NavBarLinkText = styled(Body1)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0 16px 0 0;
  padding: 4px 0 0;
`;

const Divider = styled(Container)`
  background: rgba(255, 255, 255, 0.2);
  height: 20px;
  margin: 0 20px;
  width: 1px;
`;

const NavBarLink = styled(NavLink)`
  text-decoration: none;
  p {
    font-family: ${({ theme }) => theme.fonts.avenirProRoman};
    opacity: 0.7;
  }
  &.active p {
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-weight: 900;
    opacity: 1;
  }
`;

const NameContainer = styled.div`
  margin-bottom: 32px;
`;

const TopRowContainer = styled(FlexContainer)`
  margin-bottom: 32px;
  width: 100%;
`;

const TenantDropdown = styled(Dropdown)`
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 2px;
  height: 32px;
  margin-left: 32px;
  padding-left: 8px;
  padding-right: 11px;
  width: auto;
  &.isOpen,
  &:hover {
    background: ${({ theme }) => handleButtonHover(theme.tenantAccent)};
  }
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
  > div {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
  + div {
    display: none;
    margin-left: 32px;
    width: 250px;
    ~ div:last-child {
      margin-left: 32px;
      max-height: 250px;
      overflow-y: auto;
      width: 250px;
    }
  }
`;
