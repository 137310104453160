import React from "react";
import PropTypes from "prop-types";
import QuestionFields from "pages/ManagementPage/candidateInfoForm/QuestionFields";
import { FormContainer, FormSection } from "components/Blocks/ManagementForm";

const CandidateInfoForm = ({ createdFields, setData }) => (
  <FormContainer direction="column">
    <FormSection data-testid="question-fields-candidate-info">
      <QuestionFields
        createdFields={createdFields}
        setFieldsData={fields => setData({ fields })}
      />
    </FormSection>
  </FormContainer>
);

export default CandidateInfoForm;

CandidateInfoForm.propTypes = {
  createdFields: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired
};
