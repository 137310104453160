import React from "react";

const addQuestionPoolIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-83 -76)">
        <g transform="translate(83 76)">
          <rect width="24" height="24" x="0" y="0" fill="#FFC043" rx="2"></rect>
          <g fill="#FFF" fillRule="nonzero" transform="translate(6.988 5.548)">
            <path d="M4.294 8.626h-.342v4.826H0V0h4.997c.773 0 1.447.101 2.024.304.576.203 1.048.494 1.415.874s.643.84.826 1.377c.184.539.276 1.144.276 1.815a4.644 4.644 0 01-.532 2.213 2.985 2.985 0 01-.513.713c-.19.19-.38.33-.57.418l2.147 5.738H5.719L4.294 8.626zm-.342-5.187v2.356h.342c.114 0 .24-.016.38-.048a.958.958 0 00.665-.541c.076-.152.114-.348.114-.589 0-.253-.035-.456-.105-.608a.994.994 0 00-.266-.361.793.793 0 00-.341-.171 1.623 1.623 0 00-.333-.038h-.456z"></path>
          </g>
          <path
            fill="#FFF"
            fillRule="nonzero"
            stroke="#FFC043"
            d="M18.068 4.5c.328.003.625.091.855.228.323.19.517.475.565.774h0l-.01.998h.873c.331 0 .654.174.878.52.163.251.271.596.271.98 0 .335-.082.64-.21.878-.187.344-.47.55-.764.605h0l-1.106.012-.019.87c-.007.33-.182.646-.51.865a1.709 1.709 0 01-.96.27 1.723 1.723 0 01-.854-.228c-.323-.19-.517-.475-.565-.774h0l.01-.998h-.873c-.331 0-.654-.174-.878-.52A1.819 1.819 0 0114.5 8c0-.335.082-.64.21-.878.187-.344.47-.55.764-.605h0l1.106-.012.019-.87c.007-.33.182-.646.51-.865.247-.166.585-.274.96-.27z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default addQuestionPoolIcon;
