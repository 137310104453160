import React from "react";
import PropTypes from "prop-types";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import styled from "styled-components";

const Loader = ({ isPreview }) => (
  <SkeletonTheme color={!isPreview && "white"}>
    <SkeletonTypeSelector />
    <SkeletonSpacer />
    <SkeletonInput />
    <SkeletonSpacer />
    <SkeletonQuill />
    <SkeletonSpacer />
    <SkeletonSpacer />
    <SkeletonQuill />
  </SkeletonTheme>
);

export default Loader;

Loader.propTypes = {
  isPreview: PropTypes.bool
};

Loader.defaultProps = {
  isPreview: false
};

const SkeletonInput = styled(Skeleton)`
  height: 32px;
`;

const SkeletonSpacer = styled.div`
  height: 32px;
`;

const SkeletonTypeSelector = styled(SkeletonInput)`
  max-width: 30%;
`;

const SkeletonQuill = styled(Skeleton)`
  height: 200px;
`;
