import React from "react";
import pluralize from "pluralize";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import OptionTooltip from "components/Blocks/OptionTooltip";

const QuestionVariantsWarning = ({ questionIds }) => {
  const renderIds = () => {
    const initialString = "Ques";
    const ids = questionIds.map(id => `#${id}`);
    return `${initialString} ${ids.join(", ")}`;
  };

  return (
    <WarningContainer align="center" justify="center">
      <WarningInfoContainer>
        <VariantTooltip content={renderIds()}>
          {questionIds.length} {pluralize("variant", questionIds.length)}
        </VariantTooltip>
        <WarningCopy>
          {` of this question ${pluralize(
            "are",
            questionIds.length
          )} already on this assessment`}
        </WarningCopy>
      </WarningInfoContainer>
    </WarningContainer>
  );
};

export default QuestionVariantsWarning;

const WarningContainer = styled(FlexContainer)`
  background: #fbeeed;
  border: 1px solid ${({ theme }) => theme.colors.errorRed};
  border-radius: 3px;
  padding: 15px 0;
`;

const WarningInfoContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 12px;
`;

const WarningCopy = styled.span`
  color: ${({ theme }) => theme.colors.black};
`;

const VariantTooltip = styled(OptionTooltip)`
  color: #367ebe;
  display: inline;
  margin-left: 25px;
  text-decoration: underline;
  div {
    justify-content: center;
    margin-left: 25px;
  }
`;
