import React from "react";

const upArrowIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="#ffffff"
  >
    <path d="M12 3l12 18h-24z" />
  </svg>
);

export default upArrowIcon;
