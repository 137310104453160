import React from "react";

const errorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-221 -204)">
        <g transform="rotate(45 -130.578 383.599)">
          <circle cx="8" cy="8" r="7.5" stroke="#DA1E28"></circle>
          <path
            fill="#DA1E28"
            fillRule="nonzero"
            d="M8 4a.8.8 0 01.795.707L8.8 4.8l-.001 2.4H11.2a.8.8 0 01.093 1.595L11.2 8.8l-2.401-.001L8.8 11.2a.8.8 0 01-1.595.093L7.2 11.2V8.799L4.8 8.8a.8.8 0 01-.093-1.595L4.8 7.2h2.4V4.8A.8.8 0 018 4z"
            transform="rotate(-90 8 8)"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default errorIcon;
