import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import blackXIcon from "assets/blackXIcon";

const RemoveFilter = ({ filterLabel, onRemove }) => (
  <FlexContainer align="center">
    <FilterText>{filterLabel}</FilterText>
    <Icon data-testid="remove-icon" onClick={onRemove} align="center">
      {blackXIcon}
    </Icon>
  </FlexContainer>
);

export default RemoveFilter;

const FilterText = styled.div`
  color: ${({ theme }) => theme.colors.smoky};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  line-height: normal;
  margin: 6px 0 0;
`;

const Icon = styled(FlexContainer)`
  cursor: pointer;
  padding-left: 8px;
`;

RemoveFilter.propTypes = {
  filterLabel: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};
