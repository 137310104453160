import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  MAP_BACKEND_QUESTION_TYPES,
  VIDEO_RECORD,
  questionLibraryPath
} from "pages/QuestionPage/Constants";
import QuestionAdditionalInformation from "pages/QuestionPage/QuestionAdditionalInformation";
import QuestionAdditionalInformationLoader from "pages/QuestionPage/QuestionAdditionalInformationLoader";
import createPreview from "pages/QuestionPage/helpers/createPreview";
import { getQuestionInformationMW } from "pages/QuestionPage/middleware";
import { Container, FlexContainer } from "components/Atoms/Containers";
import { H6, Caption, TruncateTextByLines } from "components/Atoms/Text";
import MoreOptionsMenu from "components/Blocks/MoreOptionsMenu";
import OptionTooltip from "components/Blocks/OptionTooltip";
import QuestionInformationItem from "components/Blocks/QuestionInformationItem";
import ToggleWrapper from "components/Blocks/ToggleWrapper";
import { QuestionInformationItemTypes } from "utils/constants/constants";
import { VALID_STATES as VS } from "utils/constants/stateTypes";
import blackXIcon from "assets/blackXIcon";
import eyeLineIcon from "assets/eyeLineIcon";

const QuestionCard = ({
  questionProps,
  onOptionClick,
  isAssessmentCreation,
  onClick,
  isActiveCard,
  showStateTag,
  handleSelfRemove,
  shouldShowToggle,
  shouldShowId
}) => {
  const {
    id,
    accept_types,
    question_state,
    content,
    question_type,
    default_category,
    created_at,
    tenant
  } = questionProps;
  const { title, text_display } = content[0];

  const [
    questionAdditionalInformation,
    setQuestionAdditionalInformation
  ] = useState({});
  const [isLoadingAdditionalInfo, setIsLoadingAdditionalInfo] = useState(false);
  const isVideoRecordQuestionType = accept_types?.includes("video");

  const renderDate = () => {
    const date = new Date(created_at).toLocaleDateString("en-US");
    if (date && date !== "Invalid Date") {
      return (
        <QuestionInformationItem
          content={<span data-testid="card-date">{date}</span>}
          type={QuestionInformationItemTypes.DATE}
        />
      );
    } else return null;
  };

  const fetchDetailInformation = async () => {
    setIsLoadingAdditionalInfo(true);
    const additionalInformation = await getQuestionInformationMW({
      urlParams: [id]
    });
    setQuestionAdditionalInformation(additionalInformation.data);
    setIsLoadingAdditionalInfo(false);
  };

  return (
    <QuestionContainer
      data-testid="question-card"
      isAssessmentCreation={isAssessmentCreation}
      onClick={onClick}
      isActiveCard={isActiveCard}
    >
      {question_state !== VS.ACTIVE && showStateTag && (
        <StateTag
          state={question_state}
          isAssessmentCreation={isAssessmentCreation}
        >
          {question_state}
        </StateTag>
      )}
      <QuestionId isHidden={!shouldShowId}>{id}</QuestionId>
      <QuestionInformation isAssessmentCreation={isAssessmentCreation}>
        {handleSelfRemove && (
          <RemoveContainer onClick={handleSelfRemove}>
            <RemoveIconContainer
              align="center"
              justify="center"
              isVisible={isActiveCard}
            >
              {blackXIcon}
            </RemoveIconContainer>
            <StyledTooltip content="Remove from this bank" />
          </RemoveContainer>
        )}
        <QuestionHeader isAssessmentCreation={isAssessmentCreation}>
          <QuestionTitle>
            <TruncateTextByLines lines={2}>{title}</TruncateTextByLines>
          </QuestionTitle>
          {!isAssessmentCreation && (
            <QuestionNav>
              <QuestionNavLink
                to={`${questionLibraryPath}/question-preview/${id}`}
                target="_blank"
              >
                {eyeLineIcon}
              </QuestionNavLink>
              <MoreOptionsMenu
                questionProps={{
                  id,
                  onOptionClick,
                  questionState: question_state,
                  questionType: question_type
                }}
              />
            </QuestionNav>
          )}
        </QuestionHeader>
        <QuestionColumn
          align="flex-start"
          direction="column"
          flex="2"
          noTitle={!title}
          isAssessmentCreation={isAssessmentCreation}
        >
          <QuestionContent>{createPreview(text_display, 4)}</QuestionContent>
        </QuestionColumn>
        <QuestionColumn
          align="center"
          direction="column"
          flex="1"
          noTitle={!title}
          isAssessmentCreation={isAssessmentCreation}
        >
          <QuestionAdditionalInfo isAssessmentCreation={isAssessmentCreation}>
            {tenant && (
              <QuestionInformationItem
                content={`Only for ${tenant.name}`}
                type={QuestionInformationItemTypes.TENANT}
              />
            )}
            <QuestionInformationItem
              content={isVideoRecordQuestionType ? VIDEO_RECORD : MAP_BACKEND_QUESTION_TYPES[question_type]}
              type={QuestionInformationItemTypes.QUESTION_TYPE}
            />
            {default_category && (
              <QuestionInformationItem
                content={default_category.name}
                type={QuestionInformationItemTypes.CATEGORY}
              />
            )}

            {!isAssessmentCreation && renderDate()}
          </QuestionAdditionalInfo>
        </QuestionColumn>
      </QuestionInformation>

      {shouldShowToggle && (
        <ToggleWrapper
          showLabel="Show all"
          showCallback={fetchDetailInformation}
        >
          {isLoadingAdditionalInfo ? (
            <QuestionAdditionalInformationLoader />
          ) : (
            <QuestionAdditionalInformation
              information={questionAdditionalInformation}
            />
          )}
        </ToggleWrapper>
      )}
    </QuestionContainer>
  );
};

export default QuestionCard;

QuestionCard.propTypes = {
  questionProps: PropTypes.object.isRequired,
  onOptionClick: PropTypes.func,
  isAssessmentCreation: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isActiveCard: PropTypes.bool.isRequired,
  showStateTag: PropTypes.bool,
  handleSelfRemove: PropTypes.func,
  shouldShowToggle: PropTypes.bool,
  shouldShowId: PropTypes.bool
};

QuestionCard.defaultProps = {
  onOptionClick: () => null,
  showStateTag: true,
  handleSelfRemove: null,
  shouldShowToggle: true,
  shouldShowId: false
};

const QuestionAdditionalInfo = styled.ul`
  color: ${({ theme }) => theme.colors.steel};
  display: ${({ isAssessmentCreation }) =>
    isAssessmentCreation ? "flex" : "block"};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 20px;
  list-style-type: none;
  margin: ${({ isAssessmentCreation }) =>
    isAssessmentCreation ? "0" : "0 10px 0 0"};
  padding: ${({ isAssessmentCreation }) =>
    isAssessmentCreation ? "0" : "0 0 0 32px"};
  width: ${({ isAssessmentCreation }) => !isAssessmentCreation && "225px"};
  & > li:first-of-type {
    margin-right: ${({ isAssessmentCreation }) =>
      isAssessmentCreation && "16px"};
  }
`;

const QuestionColumn = styled(FlexContainer)`
  flex: ${({ flex }) => flex};
  margin-top: ${({ isAssessmentCreation, noTitle }) =>
    noTitle && !isAssessmentCreation ? "-24px" : "16px"};
  @media (max-width: 992px) {
    align-items: flex-start;
    margin-top: 16px;
  }
`;

const QuestionContainer = styled(Container)`
  background: ${({ theme, isActiveCard }) =>
    isActiveCard ? "#f5f5f5" : theme.colors.white};
  border: 1px solid #e5e1e1;
  border-radius: 2px;
  cursor: ${({ isAssessmentCreation }) => isAssessmentCreation && "pointer"};
  margin: 0 0 8px;
  padding: 24px 24px 16px;
`;

const QuestionContent = styled(Caption)`
  color: ${({ theme }) => theme.colors.smoky};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 18px;
  text-align: left;
`;

const QuestionHeader = styled(FlexContainer)`
  display: flex;
  flex: 0 1 100%;
  justify-content: space-between;
  width: ${({ isAssessmentCreation }) => isAssessmentCreation && "100%"};
`;

const QuestionId = styled.span`
  color: #c5cad1;
  flex: 0 0 16px;
  font-size: 14px;
  margin-right: 24px;
  visibility: ${({ isHidden }) => (isHidden ? "hidden" : "visible")};
`;

const QuestionInformation = styled(FlexContainer)`
  align-items: ${({ isAssessmentCreation }) =>
    isAssessmentCreation ? "flex-start" : "center"};
  flex: 1;
  flex-direction: ${({ isAssessmentCreation }) =>
    isAssessmentCreation ? "column" : "row"};
  flex-wrap: wrap;
  margin-bottom: 11px;
  margin-top: 10px;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const QuestionNav = styled.nav`
  display: flex;
  margin-left: auto;
  position: relative;
  & svg {
    cursor: pointer;
    z-index: 10;
  }
  & > svg {
    margin-right: 8px;
  }
`;

const QuestionNavLink = styled(Link)`
  position: relative;
  z-index: 10;
`;

const QuestionTitle = styled(H6)`
  align-items: center;
  display: block;
  flex-basis: 60%;
  font-family: ${({ theme }) => theme.fonts.avenirNextMedium};
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.11px;
  line-height: 24px;
  margin-right: auto;
  text-align: left;
`;

const RemoveContainer = styled.div`
  position: absolute;
  right: -16px;
  top: -16px;
`;

const RemoveIconContainer = styled(FlexContainer)`
  border: 1px solid #d0d0d0;
  border-radius: 2px;
  cursor: pointer;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  height: 24px;
  justify-content: center;
  width: 24px;
  svg g {
    stroke: #6a7f87;
  }
`;

const StateTag = styled.div`
  background: ${({ state, theme }) =>
    state === VS.DRAFT ? "#df7c48" : theme.colors.steel};
  border-radius: 0px 0px 2px 2px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  height: 20px;
  max-width: 80px;
  padding: 2px 16px 0;
  position: absolute;
  right: ${({ isAssessmentCreation }) => (isAssessmentCreation ? "0" : "19%")};
  text-align: center;
  text-transform: capitalize;
  top: 0;
  width: 100%;
`;

const StyledTooltip = styled(OptionTooltip)`
  height: 24px;
  position: absolute;
  top: 0;
  width: 24px;
  svg {
    color: ${({ theme }) => theme.colors.transparent};
  }
  :hover {
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-left: 6px;
      pointer-events: none;
    }
  }
`;
