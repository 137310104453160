import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import downArrowV from "assets/downArrowVIcon";
import upArrowVIcon from "assets/upArrowVIcon";

const ToggleWrapper = ({
  children,
  hideCallback,
  hideLabel,
  showCallback,
  showLabel
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const onShow = () => {
    showCallback();
    setShowChildren(true);
  };

  const onHide = () => {
    hideCallback();
    setShowChildren(false);
  };

  const renderShowButton = () => (
    <ToggleButtonContainer align="center" justify="center" onClick={onShow}>
      <ToggleButton>{showLabel}</ToggleButton>
      {downArrowV}
    </ToggleButtonContainer>
  );

  const renderHideComponents = () => (
    <>
      {children}
      <ToggleButtonContainer align="center" justify="center" onClick={onHide}>
        <ToggleButton>{hideLabel}</ToggleButton>
        {upArrowVIcon}
      </ToggleButtonContainer>
    </>
  );
  return showChildren ? renderHideComponents() : renderShowButton();
};

export default ToggleWrapper;

ToggleWrapper.propTypes = {
  children: PropTypes.node,
  hideCallback: PropTypes.func,
  hideLabel: PropTypes.string,
  showCallback: PropTypes.func,
  showLabel: PropTypes.string
};

ToggleWrapper.defaultProps = {
  children: null,
  hideCallback: () => null,
  hideLabel: "Hide",
  showCallback: () => null,
  showLabel: "Show"
};

const ToggleButtonContainer = styled(FlexContainer)`
  cursor: pointer;
  svg {
    bottom: 1px;
    fill: ${({ theme }) => theme.tenantAccent};
    position: relative;
  }
`;

const ToggleButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.tenantAccent};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.9px;
  padding: 0 4px 0 0;
  text-align: center;
  text-transform: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.transparent};
    color: ${({ theme }) => theme.tenantAccent};
  }
`;
