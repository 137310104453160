import React from "react";

const warningIcon = (
  <svg
    width="24px"
    height="21px"
    viewBox="0 0 24 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-404.000000, -95.000000)"
        fill="#DF7C48"
        fillRule="nonzero"
      >
        <g transform="translate(381.000000, 80.000000)">
          <g transform="translate(23.000000, 15.000000)">
            <path d="M23.6907656,17.4090938 L13.8463594,1.08290625 C13.4585156,0.439734375 12.7510312,0.040171875 11.9999531,0.040171875 C11.248875,0.040171875 10.5413906,0.439734375 10.1535,1.08295313 L0.309140625,17.4090938 C-0.0916875,18.073875 -0.103546875,18.90675 0.27815625,19.5826875 C0.659953125,20.258625 1.37929687,20.6785313 2.15554688,20.6785313 L21.8443594,20.6785313 C22.6206094,20.6785313 23.34,20.258625 23.7217969,19.5826406 C24.1035,18.9066563 24.0916406,18.0737813 23.6907656,17.4090938 Z M22.3612031,18.8143125 C22.2561094,19.0003594 22.0580625,19.116 21.8443594,19.116 L2.15554687,19.116 C1.94184375,19.116 1.74379687,19.0004063 1.63875,18.8143594 C1.53365625,18.6282656 1.5369375,18.3989531 1.64723437,18.216 L11.4916875,1.8898125 C11.5984687,1.71276563 11.7932344,1.60275 12,1.60275 C12.2067187,1.60275 12.4014844,1.71276563 12.5082656,1.8898125 L22.352625,18.216 C22.4630156,18.399 22.4662969,18.6282656 22.3612031,18.8143125 Z"></path>
            <path d="M12.0072188,6.46898437 C11.4128438,6.46898437 10.9489688,6.78792187 10.9489688,7.35332812 C10.9489688,9.07842187 11.1518906,11.5573594 11.1518906,13.2825 C11.1519375,13.7318906 11.5433906,13.9203281 12.0072656,13.9203281 C12.3551719,13.9203281 12.8480625,13.7318906 12.8480625,13.2825 C12.8480625,11.5574062 13.0509844,9.07846875 13.0509844,7.35332812 C13.0509844,6.78796875 12.572625,6.46898437 12.0072188,6.46898437 Z"></path>
            <path d="M12.0217969,14.9205938 C11.3839687,14.9205938 10.9055156,15.4279688 10.9055156,16.036875 C10.9055156,16.63125 11.3839219,17.1531563 12.0217969,17.1531563 C12.6161719,17.1531563 13.1235938,16.63125 13.1235938,16.036875 C13.1235938,15.4279688 12.616125,14.9205938 12.0217969,14.9205938 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default warningIcon;
