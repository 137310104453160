import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import AddedQuestionsList from "pages/ManagementPage/questionSection/AddedQuestionsList";
import { Container, FlexContainer } from "components/Atoms/Containers";
import addQuestionIcon from "assets/addQuestionIcon";
import addQuestionPoolIcon from "assets/addQuestionPoolIcon";

const ExtendedSectionCard = ({
  onAddQuestion,
  onAddPool,
  addedQuestions,
  handleRemoveQuestion,
  selectedDuration,
  handleUpdateQuestions,
  setIsDragging,
  handleSetNewQuestions,
  errors,
  isSectionStock,
  isQuestionPoolEnabled,
  handleOpenQuestionPoolEditor,
  questionPools
}) => {
  const handleAddQuestion = event => {
    event.stopPropagation();
    onAddQuestion();
  };

  const handleAddPool = event => {
    event.stopPropagation();
    onAddPool();
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      setIsDragging(false);
      return;
    }
    const newQuestions = [...addedQuestions];
    const [reorderedItem] = newQuestions.splice(result.source.index, 1);
    newQuestions.splice(result.destination.index, 0, { ...reorderedItem });
    handleSetNewQuestions(newQuestions);
    setIsDragging(false);
  };

  return (
    <Container>
      <DragDropContext
        onDragStart={() => setIsDragging(true)}
        onDragEnd={handleDragEnd}
      >
        <QuestionListContainer onClick={ev => ev.stopPropagation()}>
          <Droppable droppableId="question-list">
            {provided => (
              <QuestionList
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <AddedQuestionsList
                  addedQuestions={addedQuestions}
                  handleRemoveQuestion={handleRemoveQuestion}
                  handleUpdateQuestions={handleUpdateQuestions}
                  selectedDuration={selectedDuration}
                  errors={errors}
                  isSectionStock={isSectionStock}
                  handleOpenQuestionPoolEditor={handleOpenQuestionPoolEditor}
                  questionPools={questionPools}
                />
                {provided.placeholder}
              </QuestionList>
            )}
          </Droppable>
        </QuestionListContainer>
      </DragDropContext>
      <FlexContainer
        data-testid="action-buttons"
        align="center"
        justify="center"
      >
        {!isSectionStock && (
          <ActionButton
            data-testid="add-question-btn"
            align="center"
            justify="center"
            className="question"
            onClick={handleAddQuestion}
          >
            {addQuestionIcon}
          </ActionButton>
        )}
        {isQuestionPoolEnabled && (
          <ActionButton
            data-testid="add-pool-btn"
            align="center"
            justify="center"
            onClick={handleAddPool}
          >
            {addQuestionPoolIcon}
          </ActionButton>
        )}
      </FlexContainer>
    </Container>
  );
};

export default ExtendedSectionCard;

ExtendedSectionCard.propTypes = {
  onAddQuestion: PropTypes.func.isRequired,
  onAddPool: PropTypes.func.isRequired,
  addedQuestions: PropTypes.array,
  handleRemoveQuestion: PropTypes.func.isRequired,
  selectedDuration: PropTypes.string.isRequired,
  handleUpdateQuestions: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  handleSetNewQuestions: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isSectionStock: PropTypes.bool.isRequired,
  isQuestionPoolEnabled: PropTypes.bool.isRequired,
  handleOpenQuestionPoolEditor: PropTypes.func.isRequired,
  questionPools: PropTypes.array
};

ExtendedSectionCard.defaultProps = {
  addedQuestions: [],
  errors: {},
  questionPools: []
};

const ActionButton = styled(FlexContainer)`
  cursor: pointer;
  margin: 0 4px;
  &.question {
    background: #257aff;
  }
  &.instructions {
    background: #e0ac1c;
  }
`;

const QuestionListContainer = styled.div`
  margin: 16px 0 8px;
`;

const QuestionList = styled.ul`
  margin: 0;
  padding: 0;
`;
