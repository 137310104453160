import React, { useEffect, useState } from "react";
import { deepCopy } from "fast-deep-copy";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  DEFAULT_EMAIL_TAKER_FIELD,
  DEFAULT_FULL_NAME_TAKER_FIELD
} from "pages/ManagementPage/Constants";
import QuestionFieldCreationForm from "pages/ManagementPage/candidateInfoForm/QuestionFieldCreationForm";
import QuestionFieldItem from "pages/ManagementPage/candidateInfoForm/QuestionFieldItem";
import { fullNameField } from "pages/PanelPage/Constants";
import { FlexContainer } from "components/Atoms/Containers";
import { FormTitle } from "components/Blocks/ManagementForm";
import addBlueIcon from "assets/addBlueIcon";

const DEFAULT_FIELDS = [
  DEFAULT_EMAIL_TAKER_FIELD,
  DEFAULT_FULL_NAME_TAKER_FIELD()
];

const QuestionFields = ({ createdFields, setFieldsData }) => {
  const getCreatedFields = () => {
    const createdFieldsCopy = deepCopy(createdFields);
    if (!createdFieldsCopy.find(field => field.itemName === fullNameField)) {
      createdFieldsCopy.push(
        DEFAULT_FULL_NAME_TAKER_FIELD(false, createdFields.length)
      );
    }
    return createdFieldsCopy;
  };

  const [fields, setFields] = useState(
    createdFields.length ? getCreatedFields() : DEFAULT_FIELDS
  );

  const addItem = () => {
    setFields(prevFields => [
      ...prevFields,
      {
        itemId: prevFields[prevFields.length - 1].itemId + 1,
        showForm: true,
        isEditing: false,
        isMandatory: true
      }
    ]);
  };

  const removeItem = id =>
    setFields(fields.filter(({ itemId }) => itemId !== id));

  const modifyItem = (id, newData) => {
    const modifiedItemIndex = fields.findIndex(({ itemId }) => itemId === id);
    const newItems = [...fields];
    newItems[modifiedItemIndex] = {
      ...newItems[modifiedItemIndex],
      ...newData
    };
    setFields(newItems);
  };

  const toggleItem = modifiedId => {
    const modifiedFields = [...fields];
    const item = modifiedFields.find(({ itemId }) => itemId === modifiedId);
    item.isChecked = !item.isChecked;
    setFields(modifiedFields);
  };

  const editItem = id => {
    const editFields = [...fields];
    const item = editFields.find(({ itemId }) => itemId === id);
    item.showForm = true;
    item.isEditing = true;
    setFields(editFields);
  };

  const handleCancel = id => {
    const editFields = [...fields];
    const item = editFields.find(({ itemId }) => itemId === id);
    if (item?.isEditing) {
      item.showForm = false;
      item.isEditing = false;
      setFields(editFields);
    } else {
      removeItem(id);
    }
  };

  useEffect(() => {
    setFieldsData(fields.filter(({ showForm }) => !showForm));
  }, [fields]);

  return (
    <>
      <TitleContainer align="center" justify="space-between">
        <FormTitle>Question field</FormTitle>
        <AddContainer
          align="center"
          justify="center"
          onClick={addItem}
          data-testid="add-question-field"
        >
          {addBlueIcon}
          <AddCopy>Add a custom field </AddCopy>
        </AddContainer>
      </TitleContainer>
      <FlexContainer direction="column">
        {fields.map(
          ({
            itemId,
            itemName,
            isChecked,
            isDisabled,
            isEditing,
            isMandatory,
            showForm,
            configuration,
            isDisableEdit = false
          }) => {
            const item = {
              itemId: itemId,
              itemName: itemName,
              isChecked: isChecked,
              isDisabled: isDisabled,
              isEditing: isEditing,
              isMandatory: isMandatory,
              configuration: { ...configuration },
              isDisableEdit: isDisableEdit
            };
            return !showForm ? (
              <QuestionFieldItemContainer
                data-testid="question-field-item"
                key={itemId}
              >
                <QuestionFieldItem
                  item={item}
                  handleChange={() => !isDisabled && toggleItem(itemId)}
                  handleRemove={() => removeItem(itemId)}
                  onClick={() => !isDisableEdit && editItem(itemId)}
                />
              </QuestionFieldItemContainer>
            ) : (
              <QuestionFieldItemContainer
                key={`form-${itemId}`}
                data-testid="question-field-form"
                isForm={true}
              >
                <QuestionFieldCreationForm
                  item={item}
                  handleAdd={modifyItem}
                  handleCancel={() => handleCancel(itemId)}
                />
              </QuestionFieldItemContainer>
            );
          }
        )}
      </FlexContainer>
    </>
  );
};

export default QuestionFields;

QuestionFields.propTypes = {
  createdFields: PropTypes.array.isRequired,
  setFieldsData: PropTypes.func.isRequired
};

const QuestionFieldItemContainer = styled.div`
  margin-bottom: 8px;
  margin-top: ${({ isForm }) => isForm && "6px"};
`;

const AddContainer = styled(FlexContainer)`
  cursor: pointer;
`;

const AddCopy = styled.span`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-left: 5px;
  position: relative;
  top: 1px;
`;

const TitleContainer = styled(FlexContainer)`
  margin-bottom: 12px;
`;
