import React from "react";
import PropTypes from "prop-types";
import TakerSummaryPie from "pages/PanelPage/components/charts/TakerSummaryPie";
import { FlexContainer } from "components/Atoms/Containers";
import { H6, Body1, Subtitle2 } from "components/Atoms/Text";
import { Row, BorderCell, BorderTable } from "components/Blocks/Styled/Tables";

const SectionSummaryTable = ({ section }) => {
  const { weightMaxScore, unanswered, obtained } = Object.values(
    section.category_scores
  ).reduce(
    (sum, { weighted_max_score, blank, weighted_score }) => {
      return {
        weightMaxScore: sum.weightMaxScore + weighted_max_score,
        unanswered: sum.unanswered + blank,
        obtained: sum.obtained + weighted_score
      };
    },
    { weightMaxScore: 0, unanswered: 0, obtained: 0 }
  );

  const renderRow = () => {
    const timeTakenSeconds = section.time_taken_seconds;

    return (
      <Row height="166px">
        <BorderCell padding="24px 12px" textAlign="center" width="260px">
          <Subtitle2 className="bold">
            {obtained.toFixed(2)} / {weightMaxScore.toFixed(2)}
          </Subtitle2>
          <Body1 color="#6a7f87">Total Score</Body1>
        </BorderCell>
        <BorderCell padding="24px 12px" textAlign="center" width="260px">
          <Subtitle2 className="bold">-</Subtitle2>
          <Body1 color="#6a7f87">Candidate Percentile</Body1>
        </BorderCell>
        <BorderCell padding="24px 12px" textAlign="center" width="260px">
          <Subtitle2 className="bold">
            {timeTakenSeconds
              ? new Date(timeTakenSeconds * 1000).toISOString().substr(11, 8)
              : "-"}
          </Subtitle2>
          <Body1 color="#6a7f87">Time Spent</Body1>
        </BorderCell>
      </Row>
    );
  };

  return (
    <>
      <H6 padding="64px 0 32px">{section.name}</H6>
      <BorderTable borderCollapse="collapse">
        <thead>
          <Row>
            <BorderCell colSpan="6" padding="12px 27px 8px">
              <Body1
                className="bold"
                color="#212629"
                textTransform="capitalize"
              >
                summary
              </Body1>
            </BorderCell>
          </Row>
        </thead>
        <tbody>
          <Row>
            <BorderCell rowSpan="2">
              <FlexContainer height="130px" width="350px">
                <TakerSummaryPie
                  weightMaxScore={weightMaxScore}
                  unanswered={unanswered}
                  obtained={obtained}
                />
              </FlexContainer>
            </BorderCell>
          </Row>
          {renderRow()}
        </tbody>
      </BorderTable>
    </>
  );
};

SectionSummaryTable.propTypes = {
  section: PropTypes.object.isRequired
};

export default SectionSummaryTable;
