import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  HAS_EMPTY_CATEGORIES,
  INVALID_POOL_SCORES,
  SETTINGS_ERRORS
} from "pages/ManagementPage/Constants";
import { FlexContainer } from "components/Atoms/Containers";
import { FormInput } from "components/Blocks/ManagementForm";
import { removeNumericInputSpinners } from "utils/mixins/Mixins";

const CategoryWeightsForm = ({
  reconciledWeights,
  setWasWeightsCustomized,
  handleResetValues,
  readOnly,
  formName
}) => {
  const renderEditWeightForm = () => (
    <Field data-testid={`edit-weight-form${formName}`}>
      <CategoryRow>
        <FieldLabel>Category</FieldLabel>
        <FieldLabel>Weight</FieldLabel>
      </CategoryRow>
      {reconciledWeights?.[HAS_EMPTY_CATEGORIES] && (
        <AlertMessage>{SETTINGS_ERRORS.HAS_EMPTY_CATEGORIES} </AlertMessage>
      )}
      {reconciledWeights?.[INVALID_POOL_SCORES] && (
        <AlertMessage>{SETTINGS_ERRORS.INVALID_POOL_SCORES}</AlertMessage>
      )}
      <CategoriesData>
        {Object.keys(reconciledWeights).map(
          id =>
            id !== HAS_EMPTY_CATEGORIES &&
            id !== INVALID_POOL_SCORES && (
              <CategoryRow key={id}>
                <CategoryLabel>{`${reconciledWeights[id].name}`}</CategoryLabel>
                <FieldInput
                  defaultValue={reconciledWeights[id].weight}
                  name={`${reconciledWeights[id].id}${formName}`}
                  type="number"
                  validations={{ min: 1 }}
                  required
                  readOnly={readOnly}
                  onChange={ev => {
                    if (readOnly) return;
                    if (
                      parseInt(ev.target.value) &&
                      parseInt(reconciledWeights[id].weight) &&
                      parseInt(ev.target.value) !==
                        parseInt(reconciledWeights[id].weight)
                    ) {
                      setWasWeightsCustomized(true);
                    }
                  }}
                />
              </CategoryRow>
            )
        )}
      </CategoriesData>
      {!readOnly && (
        <ActionBtn onClick={handleResetValues}>Reset default weights</ActionBtn>
      )}
    </Field>
  );

  return renderEditWeightForm();
};

export default CategoryWeightsForm;

CategoryWeightsForm.propTypes = {
  reconciledWeights: PropTypes.object.isRequired,
  setWasWeightsCustomized: PropTypes.func,
  handleResetValues: PropTypes.func,
  readOnly: PropTypes.bool,
  formName: PropTypes.string
};

CategoryWeightsForm.defaultProps = {
  handleResetValues: () => null,
  setWasWeightsCustomized: () => null,
  readOnly: false,
  formName: ""
};

const Field = styled.div`
  margin-top: 25px;
  text-align: left;
`;

const CategoryRow = styled(FlexContainer)`
  height: 32px;
  margin-bottom: 8px;
  > * {
    &:first-child {
      flex: 4;
      margin-right: 12px;
    }
    &:last-child {
      flex: 1;
      max-width: 15%;
      > input {
        height: 30px;
      }
    }
  }
`;

const FieldLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const AlertMessage = styled.div`
  color: ${({ theme }) => theme.colors.invalidRed};
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const CategoriesData = styled.div`
  margin-bottom: 16px;
  max-height: 128px;
  overflow-y: auto;
`;

const CategoryLabel = styled.div`
  background: #f5f5f5;
  color: #292945;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  padding: 9px 16px;
`;

const FieldInput = styled(FormInput)`
  color: ${({ isValidHex }) => isValidHex === false && "#6a7f87"};
  height: 32px;
  ${removeNumericInputSpinners}
`;

const ActionBtn = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.tenantAccent};
  cursor: pointer;
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  outline: 0;
  padding: 0;
  text-decoration: underline;
`;
