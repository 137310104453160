import React from "react";
import styled from "styled-components";
import { questionTypes } from "pages/App/helpers/questionTypes";
import BandedResponse from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/BandedResponse";
import SelectOptions from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/SelectOptions";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import { FlexContainer, Container } from "components/Atoms/Containers";
import { Body1, Body2, Body3, Subtitle2 } from "components/Atoms/Text";
import { Row, Cell } from "components/Blocks/Styled/Tables";
import downloadIcon from "assets/downloadIcon";

const Question = ({ attempt, formattedTimeTaken, index }) => {
  const { answer, question } = attempt;
  const isVideoQuestion = question?.accept_types?.includes("video");

  return (
    <tbody key={attempt.id}>
      <Row padding="32px 0 0 16px">
        <Cell colSpan="2">
          <TitleContainer className="bold">
            {attempt.question.content[0].title}
          </TitleContainer>
        </Cell>
      </Row>
      <Row>
        <Cell colSpan="2">
          <FlexContainer>
            <QuestionNumber textTransform="uppercase">
              Q{index + 1}
            </QuestionNumber>
            <FlexContainer direction="column" width="100%">
              <FlexContainer direction="column">
                <QuestionText margin="0 0 6px">
                  {parseHtml(attempt.question.content[0].text_display, true)}
                </QuestionText>
              </FlexContainer>
              {attempt.question_type === questionTypes.BANDED && (
                <BandedResponse
                  attempt={attempt}
                  formattedTimeTaken={formattedTimeTaken}
                />
              )}
              {attempt.question_type === questionTypes.SELECT && (
                <SelectOptions
                  attempt={attempt}
                  formattedTimeTaken={formattedTimeTaken}
                />
              )}
              {attempt.question_type === questionTypes.UPLOAD &&
                !isVideoQuestion && (
                  <FlexContainer align="center" margin="0px 0px 16px 0px">
                    <StyledLink
                      href={answer?.files?.[0]?.gcs_link || ""}
                      download="Question_Submission"
                    >
                      <Container margin="-2px 0 0">{downloadIcon}</Container>
                      <DownloadText>Download Submission</DownloadText>
                    </StyledLink>
                  </FlexContainer>
                )}
              {isVideoQuestion &&
                (answer?.files && answer?.files[0]?.gcs_link ? (
                  <video controls width="350">
                    <source src={answer.files[0].gcs_link} type="video/mp4" />
                    There is an issue with the recorded video.
                  </video>
                ) : (
                  <p>Invalid video link or video not available.</p>
                ))}
            </FlexContainer>
          </FlexContainer>
        </Cell>
      </Row>
    </tbody>
  );
};

export default Question;

const TitleContainer = styled(Body1)`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.iron}`};
  color: ${({ theme }) => theme.colors.smoky};
  margin: 30px 0 24px 24px;
  padding: 0 0 5px;
  text-transform: capitalize;
`;

const QuestionNumber = styled(Body3)`
  color: ${({ theme }) => theme.tenantAccent};
  font-size: 26px;
  margin: 0 24px;
`;

const QuestionText = styled(Subtitle2)`
  color: #212629;
  font-weight: normal;
  line-height: 28px;
`;

const DownloadText = styled(Body2)`
  color: ${({ theme }) => theme.tenantAccent};
  margin-left: 8px;
`;

const StyledLink = styled.a`
  display: flex;
  text-decoration: none;
`;
