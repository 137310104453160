import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FieldDropdown } from "pages/ManagementPage/ProctorComponents";
import {
  Input,
  InputLabel
} from "pages/PanelPage/styles/StyledComponents/StyledComponents";

const FieldItem = ({
  assessmentColorTheme,
  fieldOptions,
  isDropdown,
  isOptional,
  itemName
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const mappedValues =
    fieldOptions &&
    fieldOptions.map(({ value }, index) => ({
      id: `option-${index}`,
      value
    }));

  isOptional &&
    mappedValues &&
    mappedValues.unshift({ id: "EMPTY_OPTION", value: "" });

  const setOption = selectedId =>
    setSelectedOption(mappedValues.find(({ id }) => id === selectedId).value);

  return (
    <FieldItemContainer isDropdown={isDropdown}>
      <FieldLabel data-testid="field-label">
        <FieldName>{itemName} </FieldName>
        {isOptional && (
          <OptionalField data-testid="field-optional">(Optional)</OptionalField>
        )}
      </FieldLabel>
      <div data-testid="field-input">
        {isDropdown ? (
          <FieldDropdown
            name="dummy"
            placeholder="Choose Option Here"
            selectedOption={selectedOption}
            setSelectedOption={selectedId => setOption(selectedId)}
            options={mappedValues}
            isRequired={false}
            color={assessmentColorTheme}
          />
        ) : (
          <FieldInput data-testid="field-item-input" disabled />
        )}
      </div>
    </FieldItemContainer>
  );
};

export default FieldItem;

FieldItem.propTypes = {
  assessmentColorTheme: PropTypes.string.isRequired,
  fieldOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isDropdown: PropTypes.bool,
  isOptional: PropTypes.bool,
  itemName: PropTypes.string.isRequired
};

FieldItem.defaultProps = {
  fieldOptions: "",
  isDropdown: false,
  isOptional: false
};

const FieldItemContainer = styled.div`
  flex: ${({ isDropdown }) => (isDropdown ? "0 100%" : "0 50%")};
  margin-bottom: 20px;
  max-width: ${({ isDropdown }) => (isDropdown ? "100%" : "calc(50% - 20px)")};
  width: 100%;
`;

const FieldLabel = styled(InputLabel)`
  display: flex;
  font-weight: normal;
  text-align: left;
`;

const FieldInput = styled(Input)`
  border-bottom-color: #d3d3d3;
`;

const FieldName = styled.span`
  display: block;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OptionalField = styled.span`
  color: #6a7f87;
  margin-left: 8px;
`;
