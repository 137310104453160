import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import {
  MAX_ANSWERS_OPTIONS,
  MIN_ANSWERS_OPTIONS
} from "pages/QuestionPage/Constants";
import convertNumberToLetter from "pages/QuestionPage/helpers/convertNumberToLetter/convertNumberToLetter";
import createPreview from "pages/QuestionPage/helpers/createPreview";
import ScoreInput from "pages/QuestionPage/questionCreation/ScoreInput";
import { Container, FlexContainer } from "components/Atoms/Containers";
import { Caption, TruncateTextByLines } from "components/Atoms/Text";
import QuillEditor from "components/Systems/QuillEditor";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";
import cancelIcon from "assets/cancelIcon";
import dragAndDropIcon from "assets/dragAndDropIcon";

const MultipleChoiceInputs = ({
  answerOptions,
  setAnswerOptions,
  answerErrors
}) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [focusScore, setFocusScore] = useState(false);
  const [validCards, setValidCards] = useState(
    Array(answerOptions.length).fill(true)
  );

  useEffect(() => {
    if (answerOptions.length && answerErrors) {
      const validCards = [];
      for (let i = 0; i < answerOptions.length; ++i) {
        validCards.push(!answerErrors.includes(i));
      }
      setValidCards(validCards);
    }
  }, [answerErrors]);

  const containerRef = useRef(null);
  useClickedOutsideEvent(
    containerRef,
    () => editingIndex !== null && editingIndex >= 0 && setEditingIndex(null)
  );

  const handleAddNewOption = () => {
    setAnswerOptions([...answerOptions, { body: "" }]);
    setValidCards([...validCards, true]);
  };

  const handleRemoveOption = (event, index) => {
    event.stopPropagation();
    const newOptions = [...answerOptions];
    newOptions.splice(index, 1);
    setEditingIndex(null);
    setAnswerOptions(newOptions);
  };

  const handleBodyUpdate = (body, index) => {
    const newOptions = [...answerOptions];
    const modifiedOption = { ...newOptions[index] };
    modifiedOption.body = body;
    newOptions[index] = modifiedOption;
    setAnswerOptions(newOptions);
  };

  const handleDragAnswers = result => {
    if (!result.destination) return;
    const newAnswers = [...answerOptions];
    const newValidCards = [...validCards];
    const [reorderedAnswerItem] = newAnswers.splice(result.source.index, 1);
    const [reorderedValidCard] = newValidCards.splice(result.source.index, 1);
    newAnswers.splice(result.destination.index, 0, {
      ...reorderedAnswerItem
    });
    newValidCards.splice(result.destination.index, 0, reorderedValidCard);
    setAnswerOptions(
      newAnswers.map(answer => ({ ...answer, modifiedIndex: true }))
    );
    setValidCards(newValidCards);
  };

  const renderEditActions = (editing, index, provided) => {
    return (
      answerOptions.length > MIN_ANSWERS_OPTIONS && (
        <EditActions className="edit-actions">
          <EditableIcon
            className="drag"
            hidden={editing}
            {...provided.dragHandleProps}
          >
            {dragAndDropIcon}
          </EditableIcon>
          <EditableIcon
            className="delete"
            data-testid="remove-option"
            onClick={ev => handleRemoveOption(ev, index)}
          >
            {cancelIcon}
          </EditableIcon>
        </EditActions>
      )
    );
  };

  const createAnswerPreview = string =>
    string && <AnswerPreview>{createPreview(string, 3)}</AnswerPreview>;

  const handleCardClick = (event, index) => {
    setEditingIndex(index);
    const clickedScore = event.target.closest("[data-scoreinput]");
    setFocusScore(!!clickedScore);
  };

  const updateScore = (index, score) => {
    const newAnswers = [...answerOptions];
    newAnswers[index].score = parseInt(score);
    setAnswerOptions(newAnswers);
  };

  const renderInputs = () =>
    answerOptions.map(({ body, score }, index) => {
      const answerOption = convertNumberToLetter(index);
      const optionId = `multiple-answer-option-${answerOption}`;
      const editing = editingIndex === index;
      return (
        <Draggable
          draggableId={optionId}
          index={index}
          key={optionId}
          isDragDisabled={answerOptions.length <= MIN_ANSWERS_OPTIONS}
        >
          {provided => (
            <OptionContainer
              align={editing ? "flex-start" : "center"}
              {...provided.draggableProps}
              onClick={ev => handleCardClick(ev, index)}
              ref={provided.innerRef}
              data-testid="answer-option"
              data-testindex={answerOption}
              isValid={editing || validCards?.[index] === true}
            >
              {renderEditActions(editing, index, provided)}
              <OptionPlaceHolder isVisible={!editing}>
                {createAnswerPreview(body) ||
                  `Click here to edit option ${answerOption}`}
              </OptionPlaceHolder>
              <EditableContainer
                data-testid="editable-info"
                isVisible={editing}
              >
                <QuillEditor
                  key={validCards[index] && !editing}
                  validateOnSubmit
                  autoFocus={!focusScore && editing}
                  initialBody={body}
                  inputName={`${optionId}-body`}
                  isSmall
                  onUpdatedBody={body => handleBodyUpdate(body, index)}
                  isValidField={validCards[index]}
                />
              </EditableContainer>
              <MultipleAnswerScore data-scoreinput cardIsOpen={editing}>
                <ScoreInput
                  key={score}
                  autoFocus={focusScore && editing}
                  disabled={!editing}
                  inputName={`${optionId}-score`}
                  score={score}
                  onChange={ev => updateScore(index, ev.target.value)}
                  showErrors={false}
                />
              </MultipleAnswerScore>
            </OptionContainer>
          )}
        </Draggable>
      );
    });

  return (
    <Container ref={containerRef}>
      <DragDropContext
        onDragStart={() => setEditingIndex(null)}
        onDragEnd={handleDragAnswers}
      >
        <Droppable droppableId="multiple-answers-list">
          {provided => (
            <AnswerList {...provided.droppableProps} ref={provided.innerRef}>
              {renderInputs()}
              {provided.placeholder}
            </AnswerList>
          )}
        </Droppable>
      </DragDropContext>

      {answerOptions.length < MAX_ANSWERS_OPTIONS && (
        <AddNewCaption
          data-testid="add-new-option"
          onClick={handleAddNewOption}
        >
          + Add an option
        </AddNewCaption>
      )}
    </Container>
  );
};

export default MultipleChoiceInputs;

MultipleChoiceInputs.propTypes = {
  answerOptions: PropTypes.array.isRequired,
  setAnswerOptions: PropTypes.func.isRequired,
  answerErrors: PropTypes.array
};

MultipleChoiceInputs.defaultProps = {
  answerErrors: null
};

const OptionContainer = styled(FlexContainer)`
  align-items: ${({ align }) => `${align}`};
  background: ${({ theme }) => theme.colors.white};
  border: ${({ isValid, theme }) =>
    !isValid
      ? `2px solid ${theme.colors.invalidRed}`
      : "1px solid rgb(227, 227, 227)"};
  border-radius: 2px;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 4px;
  min-height: 75px;
  padding: 12px 12px 12px 16px;
  position: relative;
  transition: border 50ms ease-out;

  &:hover {
    .edit-actions {
      > span {
        display: flex;
      }
    }
  }
`;

const OptionPlaceHolder = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  display: ${({ isVisible }) => !isVisible && "none"};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const EditableContainer = styled(Container)`
  display: ${({ isVisible }) => !isVisible && "none"};
  margin-right: 4px;
  position: static;
  width: 100%;
`;

const EditActions = styled(Container)`
  bottom: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: "flex-start";
  margin: auto;
  padding: 0 8px;
  position: absolute;
  right: 100%;
  top: 0;
`;

const EditableIcon = styled.span`
  cursor: pointer;
  display: none;
  order: ${({ hidden }) => (hidden ? "1" : "0")};
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
  &.drag {
    cursor: move;
    + &:active {
      display: flex;
    }
  }
  &.delete {
    margin-top: 8px;
  }
`;

const AddNewCaption = styled(Caption)`
  color: ${({ theme }) => theme.tenantAccent};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  margin-top: 5px;
  text-align: left;
`;

const AnswerList = styled.ul`
  margin: 0;
  padding: 0;
`;

const AnswerPreview = styled(TruncateTextByLines)`
  color: ${({ theme }) => theme.colors.black};
  text-align: left;
`;

const MultipleAnswerScore = styled.div`
  position: ${({ cardIsOpen }) => (cardIsOpen ? "absolute" : "static")};
  right: 20px;
  top: 19px;
`;
