import React from "react";

const addQuestionIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-331 -565)">
        <g transform="translate(84 318)">
          <g transform="translate(247 247)">
            <g>
              <rect width="24" height="24" x="0" y="0" rx="2"></rect>
              <g fill="#FFF" fillRule="nonzero" transform="translate(7 5)">
                <path d="M11 13.941v-3.324l-1.653.124v-.07c.104-.12.211-.294.322-.525.11-.231.205-.495.287-.791.08-.296.148-.613.2-.951.052-.338.078-.667.078-.987 0-.96-.116-1.837-.348-2.63-.232-.795-.566-1.47-1-2.027a4.519 4.519 0 00-1.602-1.298C6.652 1.154 5.941 1 5.152 1c-.79 0-1.503.154-2.14.462a4.54 4.54 0 00-1.628 1.325C.937 3.36.594 4.055.357 4.867.119 5.677 0 6.586 0 7.594c0 2.038.43 3.606 1.288 4.702.859 1.096 2.1 1.644 3.725 1.644H11zm-5.848-3.484a.9.9 0 01-.6-.213 1.512 1.512 0 01-.41-.596 3.932 3.932 0 01-.235-.906 7.785 7.785 0 01-.078-1.147c0-.403.026-.776.078-1.12.053-.344.131-.64.235-.889.105-.249.241-.444.41-.586a.9.9 0 01.6-.214c.452 0 .786.264 1 .791.215.528.323 1.2.323 2.018 0 .83-.105 1.514-.314 2.053-.208.54-.545.809-1.01.809z"></path>
                <path
                  stroke="#037AFF"
                  d="M11.068-.5c.328.003.625.091.855.228.323.19.517.475.565.774h0l-.01.998h.873c.331 0 .654.174.878.52.163.251.271.596.271.98 0 .335-.082.64-.21.878-.187.344-.47.55-.764.605h0l-1.106.012-.019.87c-.007.33-.182.646-.51.865a1.709 1.709 0 01-.96.27 1.723 1.723 0 01-.854-.228c-.323-.19-.517-.475-.565-.774h0l.01-.998H8.65c-.331 0-.654-.174-.878-.52A1.819 1.819 0 017.5 3c0-.335.082-.64.21-.878.187-.344.47-.55.764-.605h0l1.106-.012.019-.87c.007-.33.182-.646.51-.865.247-.166.585-.274.96-.27z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default addQuestionIcon;
