import React from "react";

const rightArrowIcon = (
  <svg
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1187.000000, -780.000000)">
        <g transform="translate(1.000000, 159.000000)">
          <g transform="translate(206.000000, 546.000000)">
            <g transform="translate(0.000000, 40.000000)">
              <g transform="translate(0.000000, 35.000000)">
                <g transform="translate(0.000000, 0.365243)">
                  <g transform="translate(0.870709, 0.000000)">
                    <g transform="translate(996.129291, 16.634757) rotate(-90.000000) translate(-996.129291, -16.634757) translate(980.129291, 0.634757)">
                      <rect x="0" y="0" width="32" height="32" rx="4"></rect>
                      <path
                        d="M12.0485281,12.3653052 C11.579899,11.8782316 10.820101,11.8782316 10.3514719,12.3653052 C9.88284271,12.8523789 9.88284271,13.6420813 10.3514719,14.1291549 L16,20 L21.6485281,14.1291549 C22.1171573,13.6420813 22.1171573,12.8523789 21.6485281,12.3653052 C21.179899,11.8782316 20.420101,11.8782316 19.9514719,12.3653052 L16,16.4723007 L12.0485281,12.3653052 Z"
                        fill="#575766"
                        fillRule="nonzero"
                        transform="translate(16.000000, 16.000000) rotate(-360.000000) translate(-16.000000, -16.000000) "
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default rightArrowIcon;
