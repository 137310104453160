import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { FlexContainer, MaxWidthContainer } from "components/Atoms/Containers";

const QuestionAdditionalInformationLoader = () => (
  <MaxWidthContainer>
    <FlexContainer>
      <SkeletonPiece>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonPiece>
      <SkeletonPiece>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonPiece>
      <SkeletonPiece>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </SkeletonPiece>
    </FlexContainer>
  </MaxWidthContainer>
);

export default QuestionAdditionalInformationLoader;

const SkeletonPiece = styled.div`
  height: 60px;
  margin: 15px;
  width: 33.33%;
`;
