import React from "react";

const roundedWarningIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clipPath="url(#clip0_510_40)">
      <circle cx="7" cy="7" r="6.5" stroke="#34B7EA" />
      <path
        d="M6.49408 3.504V9.624H7.50208V3.504H6.49408ZM7.71808 11.352C7.71808 10.968 7.39408 10.632 6.99808 10.632C6.60208 10.632 6.27808 10.956 6.27808 11.352C6.27808 11.736 6.62608 12.072 6.99808 12.072C7.38208 12.072 7.71808 11.736 7.71808 11.352Z"
        fill="#34B7EA"
      />
    </g>
    <defs>
      <clipPath id="clip0_510_40">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default roundedWarningIcon;
