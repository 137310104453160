import React, { useEffect, useState, useRef } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import RecipientInput from "pages/PanelPage/components/selectedAssessment/assessmentInvite/RecipientInput";
import { FlexContainer } from "components/Atoms/Containers";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";

const Recipients = ({ fieldProps, sendSuccess }) => {
  const { fields, append, remove } = fieldProps;
  const { watch } = useFormContext();
  const [focus, setFocus] = useState(false);
  const emailContainerRef = useRef(null);

  useEffect(() => {
    if (sendSuccess) {
      remove();
    }
  }, [sendSuccess]);

  // Handle resetting emails on blur
  const handleReset = () => {
    setFocus(false);
    if (!fields.length) {
      remove();
    } else {
      const lastRecipient = watch(`recipients[${fields.length - 1}].value`);
      if (lastRecipient) {
        append("");
      } else {
        fields.length === 1 && remove();
      }
    }
  };

  // Appends input if field length is 0
  const handleClick = () => {
    setFocus(true);
    if (!fields.length) {
      append("");
    }
  };

  useClickedOutsideEvent(emailContainerRef, handleReset);

  // Render logic
  const renderRecipients = () => {
    const hiddenCount = fields.length - 4;
    return (
      <>
        {fields.map((field, index) => {
          return (
            <RecipientInput
              key={field.id}
              index={index}
              append={append}
              remove={remove}
              fields={fields}
              field={field}
              focus={focus}
              hide={!focus && index > 2}
            />
          );
        })}
        {!focus && hiddenCount > 0 && `${hiddenCount} more`}
      </>
    );
  };

  return (
    <RecipientContainer
      align="center"
      onClick={handleClick}
      ref={emailContainerRef}
      showPlaceholder={!fields.length}
      wrap="wrap"
    >
      {fields.length ? renderRecipients() : "Recipients"}
    </RecipientContainer>
  );
};

export default Recipients;

const RecipientContainer = styled(FlexContainer)`
  border: none;
  color: ${({ showPlaceholder }) => (showPlaceholder ? "#969696" : "#242437")};
  cursor: text;
  font-family: ${({ theme, showPlaceholder }) =>
    showPlaceholder ? theme.fonts.avenirProMedium : theme.fonts.avenirProBook};
  font-size: ${({ showPlaceholder }) => (showPlaceholder ? "14px" : "12px")};
  font-weight: ${({ showPlaceholder }) => (showPlaceholder ? 500 : 300)};
  letter-spacing: ${({ showPlaceholder }) =>
    showPlaceholder ? "0.4px" : "0.34px"};
  min-height: 30px;
  width: 85%;
`;
