import styled from "styled-components";

const InputLabel = styled.label`
  color: ${({ theme }) => theme.colors.white};
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirNextMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.78px;
  line-height: normal;
  margin-bottom: 8px;
  opacity: 70%;
`;

const ErrorMessage = styled.div`
  color: #d93025;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: 8px;
  text-align: left;
  width: 100%;
`;

export { InputLabel, ErrorMessage };
