import React from "react";
import { Svg } from "components/Blocks/Styled/Media";

const addBlueIcon = (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-459 -430)">
        <g transform="translate(129 174)">
          <g transform="translate(16 252)">
            <g transform="translate(0 4)">
              <g transform="translate(314)">
                <g transform="translate(.926)">
                  <circle cx="8" cy="8" r="8" fill="currentColor"></circle>
                  <path
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4.482 8L11.21 8"
                  ></path>
                  <path
                    stroke="#FFF"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3.852 8L11.852 8"
                    transform="rotate(90 7.852 8)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default addBlueIcon;
