import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { QuestionInformationItemTypes } from "utils/constants/constants";
import calendarGreyIcon from "assets/calendarGreyIcon";
import checkListIcon from "assets/checkListIcon";
import gridIcon from "assets/gridIcon";
import warningIcon from "assets/warningIcon";

const QuestionInformationItem = ({ testId, className, content, type }) => {
  const getIcon = () => {
    switch (type) {
      case QuestionInformationItemTypes.CATEGORY:
        return gridIcon;
      case QuestionInformationItemTypes.QUESTION_TYPE:
        return checkListIcon;
      case QuestionInformationItemTypes.DATE:
        return calendarGreyIcon;
      case QuestionInformationItemTypes.TENANT:
        return <WarningGreyIcon>{warningIcon}</WarningGreyIcon>;
      default:
        return;
    }
  };

  return (
    <Container data-testid={testId} className={className}>
      {getIcon()}
      <span>{content}</span>
    </Container>
  );
};

export default QuestionInformationItem;

QuestionInformationItem.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(Object.values(QuestionInformationItemTypes)).isRequired
};

QuestionInformationItem.defaultProps = {
  content: "",
  className: "",
  testId: ""
};

const Container = styled.div`
  color: ${({ theme }) => theme.colors.steel};
  display: flex;
  margin-bottom: 5px;
  & svg {
    height: 16px;
    width: 16px;
  }
  & span {
    margin-left: 5px;
    text-align: left;
  }
  &:first-of-type {
    margin-right: 16px;
  }
  @media (max-width: 992px) {
    margin-bottom: 0;
  }
`;

const WarningGreyIcon = styled.div`
  svg g {
    fill: ${({ theme }) => theme.colors.steel};
  }
`;
