import React, { useState } from "react";
import { useForm, FormContext } from "react-hook-form";
import * as yup from "yup";
import { requestResetPasswordMW } from "pages/AuthPage/middleware";
import {
  AuthFormTitle,
  SubmitBtnContainer,
  SuccessMessage
} from "pages/AuthPage/styled/StyledComponents";
import { validateEmail } from "pages/PanelPage/helpers/validateEmail";
import { FormContainer } from "components/Atoms/Containers";
import Button from "components/Blocks/Forms/Button";
import Input from "components/Blocks/Forms/Input";
import { ErrorMessage } from "components/Blocks/Styled/Forms";

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .strict(false)
    .trim()
    .required("Email is required")
    .test(
      "Valid Email",
      "Please enter a valid email",
      value => value && validateEmail(value)
    )
});

const RequestResetPassword = () => {
  const methods = useForm({
    validationSchema
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  // On form submit
  const onSubmit = async data => {
    const newData = { email: data.email.toLowerCase() };
    setLoading(true);
    const requestResetPassword = await requestResetPasswordMW({
      data: newData
    });
    setLoading(false);

    if (requestResetPassword?.error) {
      setResponse(null);
      setError(requestResetPassword?.error.message);
    } else {
      setError(null);
      setResponse(requestResetPassword.data);
    }
  };

  return (
    <FormContext {...methods}>
      <FormContainer
        onSubmit={methods.handleSubmit(onSubmit)}
        onFocus={() => setError(null)}
      >
        <AuthFormTitle>Confirm Your Email</AuthFormTitle>
        <Input label="Email" name="email" />
        <ErrorMessage isHidden={!error}>{error}</ErrorMessage>
        <SubmitBtnContainer>
          <Button loading={loading} isFullWidth text="CONFIRM" type="submit" />
        </SubmitBtnContainer>
        <SuccessMessage isHidden={!response}>{response}</SuccessMessage>
      </FormContainer>
    </FormContext>
  );
};

export default RequestResetPassword;
