import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useAssessmentContext } from "state/assessmentContext/useAssessmentContext";
import {
  ATTEMPT_STATES,
  ALLOWED_CSV_DOWNLOAD_TENANT_IDS
} from "pages/ManagementPage/Constants";
import Search from "pages/PanelPage/components/Search";
import AtAGlance from "pages/PanelPage/components/selectedAssessment/assessmentResults/AtAGlance";
import DistributionTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/DistributionTable";
import TakersContainer from "pages/PanelPage/components/selectedAssessment/assessmentResults/TakersContainer";
import usePrevious from "pages/PanelPage/hooks/usePrevious";
import {
  fetchAssessmentAttemptsMW,
  fetchHistogramDataMW,
  getAssessmentAttemptCsvMW,
  getDetailedAssessmentAttemptCsvMW,
  getDetailedWarningMessageTrackingCsvMW
} from "pages/PanelPage/middleware";
import { Separator } from "pages/PanelPage/styles/StyledComponents/StyledComponents";
import { FlexContainer } from "components/Atoms/Containers";
import Button from "components/Blocks/Forms/Button";
import NoResults from "components/Blocks/NoResults";
import Paginator from "components/Blocks/Paginator";
import Loader from "components/Systems/Loader";
import { TENANTS_IDS } from "utils/constants/constants";

const AllResults = ({ chartData, setChartData }) => {
  const {
    assessmentState: { selectedAssessment },
    assessmentDispatch: { setAssessmentAttempts }
  } = useAssessmentContext();
  const [pageNum, setPageNum] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [log, setLog] = useState({ rangeStart: 1, rangeEnd: 10 });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingDetailed, setIsExportingDetailed] = useState(false);
  const [generalAttemptData, setGeneralAttemptData] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [sortBy, setSortBy] = useState({ sortKey: "date", order: "desc" });
  const prevPageNum = usePrevious(pageNum);

  const fetchAssessmentAttempts = async () => {
    const attempts = await fetchAssessmentAttemptsMW({
      queryParams: {
        page: pageNum,
        limit: itemsPerPage,
        start_date: startDate,
        end_date: endDate,
        search: searchValue,
        assessment_id: selectedAssessment.id,
        attempt_state: ATTEMPT_STATES.COMPLETE,
        sort_by: sortBy.sortKey,
        sort_order: sortBy.order
      }
    });

    const { paginated_data, ...generalData } = attempts.data;
    generalData.pages = Math.ceil(generalData.count / itemsPerPage);
    generalData.weighted_max_score = paginated_data?.[0]?.weighted_max_score;
    setAssessmentAttempts(paginated_data);
    setGeneralAttemptData(generalData);
    const histogramData = await fetchHistogramDataMW({
      queryParams: {
        start_date: startDate,
        end_date: endDate,
        search: searchValue,
        assessment_id: selectedAssessment.id,
        attempt_state: ATTEMPT_STATES.COMPLETE
      }
    });
    const { chart_data } = histogramData.data;
    setChartData(chart_data);
    setIsLoading(false);
    setIsLoadingGraph(false);
    setIsLoadingTable(false);
  };

  const fetchAssessmentAttemptsCsv = async useParams => {
    const requestParams = useParams
      ? {
          start_date: startDate,
          end_date: endDate,
          search: searchValue,
          assessment_id: selectedAssessment.id,
          attempt_state: ATTEMPT_STATES.COMPLETE,
          sort_by: sortBy.sortKey,
          sort_order: sortBy.order
        }
      : {
          assessment_id: selectedAssessment.id,
          attempt_state: ATTEMPT_STATES.COMPLETE,
          sort_by: sortBy.sortKey,
          sort_order: sortBy.order
        };

    const csv = await getAssessmentAttemptCsvMW({
      queryParams: { ...requestParams }
    });

    const contentType = csv.headers["content-type"];
    const blob = new Blob([csv.data], { type: contentType });
    saveAs(blob, "assessment_results.csv");
  };
  const fetchDetailedAssessmentAttemptsCsv = async () => {
    try {
      const slug = selectedAssessment.slug;
      const csv = await getDetailedAssessmentAttemptCsvMW({
        urlParams: [slug]
      });
      const contentType = csv.headers["content-type"];
      const blob = new Blob([csv.data], { type: contentType });
      const date = new Date().toISOString().split("T")[0];
      saveAs(blob, `detailed_${slug}_${date}.csv`);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDetailedAssessmentWithWarningTrackingCsv = async () => {
    try {
      const slug = selectedAssessment.slug;
      const csv = await getDetailedWarningMessageTrackingCsvMW({
        urlParams: [slug]
      });
      const contentType = csv.headers["content-type"];
      const blob = new Blob([csv.data], { type: contentType });
      const date = new Date().toISOString().split("T")[0];
      saveAs(blob, `detailed_${slug}_warning-message-tracking_${date}.csv`);
    } catch (e) {
      console.error(e);
    }
  };
  const handleExportClick = async () => {
    setIsExporting(true);
    await fetchAssessmentAttemptsCsv(true);
    setIsExporting(false);
  };
  const handleExportDetailedClick = async tenantId => {
    try {
      setIsExportingDetailed(true);

      if (tenantId === TENANTS_IDS.MARSHALL_WACE || tenantId === TENANTS_IDS.CORRELATION_ONE) {
        await fetchDetailedAssessmentWithWarningTrackingCsv();
      } else {
        await fetchDetailedAssessmentAttemptsCsv();
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsExportingDetailed(false);
    }
  };
  useEffect(() => {
    setAssessmentData(selectedAssessment);
  }, [selectedAssessment]);

  useEffect(() => {
    setIsLoadingGraph(true);
    fetchAssessmentAttempts();
  }, [searchValue, endDate]);

  // When sorting, we are not changing the graph
  // so we split the useEffects to have different loading indicators
  useEffect(() => {
    setIsLoadingTable(true);
    fetchAssessmentAttempts();
  }, [pageNum, itemsPerPage, sortBy.sortKey, sortBy.order, searchValue]);

  // NOTE: This use effect sets the page number back to 1 when a date filter is applied
  useEffect(() => {
    pageNum > 1 && pageNum === prevPageNum && setPageNum(1);
  }, [endDate, searchValue, pageNum]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <FlexContainer justify="space-between" align="flex-end">
        <SearchWrapper>
          <Search name="search" setSearchInputValue={setSearchValue} />
        </SearchWrapper>
        <AtAGlance assessmentData={assessmentData} />
      </FlexContainer>
      <FlexContainer gap="10">
        {generalAttemptData.count && (
          <ExportButton
            text="Export Summary Results"
            disabled={isExporting}
            textTransform="uppercase"
            loading={isExporting}
            onClick={handleExportClick}
          />
        )}
        {generalAttemptData.count &&
          ALLOWED_CSV_DOWNLOAD_TENANT_IDS.includes(
            selectedAssessment.tenant.id
          ) && (
            <>
              <ExportButton
                text="Export Detailed Results"
                disabled={isExportingDetailed}
                textTransform="uppercase"
                loading={isExportingDetailed}
                onClick={() =>
                  handleExportDetailedClick(selectedAssessment.tenant.id)
                }
              />
            </>
          )}
      </FlexContainer>
      <Separator />
      <DistributionTable
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        chartData={chartData}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoading={isLoadingGraph}
      />
      <TakersContainer
        sortBy={sortBy}
        setSortBy={setSortBy}
        isLoading={isLoadingTable}
      />
      {!generalAttemptData.count ? <NoResults /> : null}
      {generalAttemptData.count ? (
        <Paginator
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          log={log}
          setLog={setLog}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalCount={generalAttemptData.count}
          totalPages={generalAttemptData.pages}
        />
      ) : null}
    </>
  );
};

AllResults.propTypes = {
  chartData: PropTypes.object.isRequired,
  setChartData: PropTypes.func.isRequired
};

AllResults.defaultProps = {
  selectedSection: {},
  setSelectedSection: () => {}
};

export default AllResults;

const SearchWrapper = styled.div`
  margin-bottom: 16px;
  width: auto;
`;

const ExportButton = styled(Button)`
  align-items: center;
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: 10px;
  height: auto;
  justify-content: center;
  min-height: 40px;
  width: 175px;
`;
