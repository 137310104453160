import React from "react";

const trashCanGrayIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-558 -112)">
        <g transform="translate(129 90)">
          <g transform="translate(320 22)">
            <g transform="translate(109)">
              <rect
                width="23"
                height="23"
                x="0.5"
                y="0.5"
                stroke="#D0D0D0"
                rx="2"
              ></rect>
              <g fill="#6A7F87" fillRule="nonzero" transform="translate(7 6)">
                <path d="M8.813 1.5H6.75v-.375C6.75.504 6.246 0 5.625 0h-1.5C3.504 0 3 .504 3 1.125V1.5H.937A.938.938 0 000 2.438v.75c0 .207.168.374.375.374h9a.375.375 0 00.375-.374v-.75a.938.938 0 00-.938-.938zM3.75 1.125c0-.207.168-.375.375-.375h1.5c.207 0 .375.168.375.375V1.5H3.75v-.375zM.712 4.313a.117.117 0 00-.117.122l.309 6.493C.933 11.53 1.426 12 2.028 12h5.694c.602 0 1.095-.47 1.124-1.072l.31-6.493a.117.117 0 00-.118-.122H.712zm5.663.937a.375.375 0 11.75 0v4.875a.375.375 0 11-.75 0V5.25zm-1.875 0a.375.375 0 11.75 0v4.875a.375.375 0 11-.75 0V5.25zm-1.875 0a.375.375 0 11.75 0v4.875a.375.375 0 11-.75 0V5.25z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default trashCanGrayIcon;
