export const getSlotSchema = (
  questions = [],
  isQuestionTimed,
  isEditing,
  categories
) =>
  questions.map(
    (
      {
        slotId = null,
        id,
        default_category,
        duration,
        weight,
        slot_type = "slot_question",
        category_functions = null,
        isQuestionPool = false,
        selectedPool = ""
      },
      idx
    ) => {
      const selectedCategories = Array.isArray(default_category)
        ? default_category
        : [default_category];

      const commonFields = {
        ...(isEditing && slotId && { id: slotId }),
        compile_index: idx,
        sort_index: idx,
        weight: weight && !isNaN(weight) ? weight / 100 : 1
      };

      if (isQuestionPool) {
        const poolCategory = categories?.find(
          ({ name }) => name === selectedPool
        );

        return {
          slot_type: "slot_random",
          category_id: poolCategory?.id,
          category: poolCategory,
          ...commonFields
        };
      } else {
        return {
          slot_type,
          question_id: id,
          categories:
            selectedCategories?.length > 0 ? selectedCategories : undefined,
          ...commonFields,
          // TODO: actually support category_functions #4819
          ...(category_functions && { category_functions }),
          time_allowed_seconds: isQuestionTimed ? duration : null
        };
      }
    }
  );
