import React from "react";

const xIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="39"
    viewBox="0 0 40 39"
  >
    <defs>
      <filter
        id="filter-1"
        width="107.1%"
        height="112.4%"
        x="-3.5%"
        y="-6.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="3"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.100879589 0"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g stroke="#656565" strokeWidth="2" transform="translate(-546 -611)">
        <g filter="url(#filter-1)" transform="translate(145 556)">
          <g transform="translate(15.143 16)">
            <g transform="translate(1 42)">
              <g transform="rotate(45 189.777 493.526)">
                <path d="M0.378 4.125L8.702 4.125"></path>
                <path d="M4.54 0L4.54 8.25"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default xIcon;
