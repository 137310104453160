import {
  EXPIRATION_TYPES,
  EXPIRATION_UNITS,
  ONE_DAY_IN_SECONDS
} from "pages/ManagementPage/Constants";
import getHourStringFromNumber from "utils/helpers/getHourStringFromNumber";

const resetTime = date => date.setHours(0, 0, 0, 0);

export const getExpirationData = (
  expType,
  availabilityOpenDate,
  availabilityCloseDate,
  timeToExp,
  expiresOn
) => {
  const expirationData = {};
  switch (expType) {
    case EXPIRATION_TYPES.AVAILABILITY: {
      const openDate = new Date(`${availabilityOpenDate}+00:00`);
      const closeDate = new Date(`${availabilityCloseDate}+00:00`);
      expirationData.expirationType = EXPIRATION_TYPES.AVAILABILITY;
      expirationData.openDate = openDate;
      expirationData.openHour = getHourStringFromNumber(openDate.getHours());
      expirationData.closeDate = closeDate;
      expirationData.closeHour = getHourStringFromNumber(closeDate.getHours());
      expirationData.timeToExp = timeToExp;
      break;
    }
    case EXPIRATION_TYPES.DATE: {
      const expirationDate = new Date(expiresOn);
      expirationData.expirationType = EXPIRATION_TYPES.DATE;
      expirationData.expirationDate = resetTime(expirationDate);
      expirationData.expirationHour = getHourStringFromNumber(
        expirationDate.getHours()
      );
      break;
    }
    case EXPIRATION_TYPES.NO_EXPIRATION:
      expirationData.timeToExp = null;
      expirationData.expirationType = EXPIRATION_TYPES.NO_EXPIRATION;
      expirationData.expirationNumber = null;
      expirationData.expirationUnit = null;
      break;
    default: {
      const possibleDays = timeToExp / ONE_DAY_IN_SECONDS;
      expirationData.expirationType = EXPIRATION_TYPES.DURATION;
      expirationData.expirationNumber = possibleDays;
      expirationData.expirationUnit = EXPIRATION_UNITS.DAYS;
      break;
    }
  }
  return expirationData;
};
