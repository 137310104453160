import React from "react";
import styled from "styled-components";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import Dropdown from "components/Blocks/Dropdown";

export const TenantLogoContainer = styled.div`
  background: rgb(0, 0, 0, 0.09);
  border-radius: 200px;
  height: 126px;
  position: relative;
  width: 126px;
`;

export const TenantLogo = styled.img`
  background: #bdbdbd;
  border-radius: 200px;
  height: 100px;
  left: 50%;
  object-fit: contain;
  padding: 4px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
`;

export const AssessmentTitle = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 34px;
  letter-spacing: 0.25px;
  line-height: 41px;
`;

export const AssessmentInfoItem = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  line-height: 14px;
  margin: 0 4px;
  text-transform: capitalize;
`;

export const InstructionContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey};
  margin: 28px 0;
  padding: 24px;
  text-align: left;
  width: 100%;
`;

export const TermsButton = styled.div`
  button {
    text-transform: uppercase;
    width: 300px;
  }
`;

const TermsAndConditionsContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 22px;
  margin-top: 28px;
`;

export const termsAndConditions = (
  <TermsAndConditionsContainer>
    {"By starting this assessment, you agree to Correlation One's"}
    <a
      href="https://www.correlation-one.com/privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      privacy policy
    </a>{" "}
    and{" "}
    <a
      href="https://www.correlation-one.com/terms"
      target="_blank"
      rel="noopener noreferrer"
    >
      terms of use
    </a>
    .
  </TermsAndConditionsContainer>
);

const FooterContainer = styled(FlexContainer)`
  background: ${({ background }) => background};
  height: 65px;
  width: 100%;
`;

const FooterButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ color }) => color};
  margin-right: 110px;
  pointer-events: none;
`;

export const ButtonFooter = ({ text, color }) => (
  <FooterContainer align="center" justify="flex-end" background={color}>
    <FooterButton color={color}>{text}</FooterButton>
  </FooterContainer>
);

export const CandidateDetailTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 20px;
  line-height: 24px;
  margin: 16px 0;
`;

export const FieldDropdown = styled(Dropdown)`
  background: #f5f5f5;
  border-bottom-width: 2px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  & ~ div:last-child {
    border-top: ${({ color }) => `solid 2px ${color}`};
    position: relative;
    top: -2px;
    > div {
      padding-left: 20px;
      padding-right: 20px;
      &[data-active="true"] {
        background: ${({ color }) => color};
      }
    }
  }
`;

export const OuterBar = styled.div`
  background: #f1f1f1;
  border-radius: 2px;
  height: 12px;
  margin-bottom: 64px;
`;

export const SectionLandingBodyContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  padding: 32px 0 100px;
  @media (max-width: 667px) {
    padding: 40px 0 100px;
  }
`;

export const TableContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const TableCell = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 0.1;
  margin-right: 23px;
  min-height: 55px;
  min-width: 85px;
`;

export const TableHeader = styled.div`
  color: #5a5a5a;
  font-size: 12px;
  font-weight: normal;
  height: 16px;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

export const TableData = styled.div`
  color: ${({ color }) => color};
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 5px;
  text-align: left;
`;

const Underline = styled.div`
  background: #e5e5e5;
  height: 1px;
  margin-top: 10px;
  width: 100%;
`;

export const SectionLandingDivider = styled(Underline)`
  margin: 25px 0 34px;
  @media (max-width: 667px) {
    margin: 10px 0 20px;
  }
`;

export const Description = styled.span`
  text-align: left;
  word-wrap: break-word;
`;

export const BlankSlot = styled.div`
  background: #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  flex-grow: 1;
  height: 12px;
  margin: 0 2px 0;
  min-width: 10px;
`;

export const InstructionsListContainer = styled.section`
  color: #5a5a5a;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-weight: 600;
  letter-spacing: 0.44px;
  line-height: 28px;
  padding: 0;
  ol,
  ul {
    counter-reset: custom-counter;
    font-family: ${({ theme }) => theme.fonts.avenirProRoman};
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      counter-increment: custom-counter;
      margin-bottom: 4px;
      padding: 0 0 4px 0px !important;
      &::before {
        background-color: #d1e1e7;
        border-radius: 100px;
        content: counter(custom-counter);
        display: inline;
        font-weight: bold;
        margin: 0 10px 0 0 !important;
        padding: 6px 8px 3px;
      }
    }
  }
`;
