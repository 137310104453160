import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { FormContext, useForm } from "react-hook-form";
import styled from "styled-components";
import { EMAIL_REGEX } from "pages/App/constants/constants";
import CategoryWeightsForm from "pages/ManagementPage/CategoryWeightsForm";
import {
  ASSESSMENT_TYPES,
  EXPIRATION_TYPES
} from "pages/ManagementPage/Constants";
import ToggleSwitch from "pages/ManagementPage/questionSection/ToggleSwitch";
import validateSlugUniqueness from "pages/ManagementPage/utils/validateSlugUniqueness";
import { FlexContainer } from "components/Atoms/Containers";
import CalendarPicker from "components/Blocks/CalendarPicker";
import Dropdown from "components/Blocks/Dropdown";
import HoursDropdown from "components/Blocks/HoursDropdown";
import { FormInput } from "components/Blocks/ManagementForm";
import OptionTooltip from "components/Blocks/OptionTooltip";
import Modal from "components/Systems/Modal";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";
import { handleButtonHover } from "utils/helpers/handleButtonHover";
import { removeNumericInputSpinners } from "utils/mixins/Mixins";
import roundedWarningIcon from "assets/roundedWarningIcon";

const EMAIL_SENDER_DOMAIN = "@correlation1.com";

const SettingsModal = ({
  defaultSettings,
  onCancel,
  onSave,
  categoriesWeightData,
  isEditing,
  isValidSlug,
  setWasWeightsCustomized,
  availableWebhooksForTenant
}) => {
  const {
    assessmentColorTheme,
    assessmentName,
    assessmentSlug,
    attemptLimit,
    closeDate,
    closeHour,
    emailSender,
    emailSenderName,
    expirationDate,
    expirationHour: expHour,
    expirationNumber,
    expirationType,
    expirationUnit,
    isQuestionPoolEnabled,
    notificationSender,
    openDate,
    openHour,
    selectedType: assessmentType,
    webhooks
  } = defaultSettings;
  const { defaultCategoriesWeight, reconciledWeights } = categoriesWeightData;
  const [selectedType, setSelectedType] = useState(assessmentType);
  const [editWeighting, setEditWeighting] = useState(false);
  const [selectedExpirationDate] = useState(expirationDate);
  const [selectedExpirationHour] = useState(expHour);
  const [selectedOpenDate, setSelectedOpenDate] = useState(openDate);
  const [selectedOpenHour, setSelectedOpenHour] = useState(openHour);
  const [selectedCloseDate, setSelectedCloseDate] = useState(closeDate);
  const [selectedCloseHour, setSelectedCloseHour] = useState(closeHour);
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);
  const [isValidExpirationType, setIsValidExpirationType] = useState(true);
  const [selectedExpirationType, setSelectedExpirationType] = useState(
    expirationType
  );
  const [selectedWebhook, setSelectedWebhook] = useState(webhooks?.[0]?.id);
  const [showEmailToNotify, setShowshowEmailToNotify] = useState(
    Boolean(notificationSender)
  );
  const [questionPoolIsEnabled, setquestionPoolIsEnabled] = useState(
    Boolean(isQuestionPoolEnabled)
  );
  const [isAttemptLimited, setIsAttemptLimited] = useState(
    Boolean(attemptLimit)
  );
  const [colorTheme, setColorTheme] = useState(assessmentColorTheme);

  const pickerRef = useRef(null);

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });

  const { handleSubmit, setValue } = methods;

  useClickedOutsideEvent(pickerRef, () => setShouldCloseCalendar(true));
  const handleResetValues = event => {
    event.preventDefault();
    const defaultValues = Object.keys(defaultCategoriesWeight).map(id => ({
      [defaultCategoriesWeight[id].id]: defaultCategoriesWeight[id].weight
    }));
    setValue(defaultValues);
  };

  const handleTypeChange = (type, event) => {
    event.preventDefault();
    setSelectedType(type);
  };

  const formID = "settings-form";

  const customEmailValidation = email => {
    const re = EMAIL_REGEX;
    return re.test(email.toLowerCase());
  };

  const customExpirationDateValidation = () => {
    const openDate = new Date(selectedOpenDate);
    const closeDate = new Date(selectedCloseDate);
    const date = new Date(selectedExpirationDate);
    const today = new Date();
    const hours = parseInt(selectedExpirationHour.substring(0, 2));
    date.setHours(hours, 0, 0);
    openDate.setHours(selectedOpenHour.split(":")[0], 0, 0);
    closeDate.setHours(selectedCloseHour.split(":")[0], 0, 0);
    const isAvailabilityDatesValid = openDate > today && closeDate > today;
    const isValidExpirationDate =
      selectedExpirationType &&
      selectedExpirationType === EXPIRATION_TYPES.AVAILABILITY
        ? isAvailabilityDatesValid
        : date > today;
    return isValidExpirationDate;
  };

  const hexColorValidation = color =>
    !(color?.[0] !== "#" || color.length !== 7);

  const positiveValidation = value => parseInt(value) > 0;

  const getSelectedExpirationTypeOption = value => {
    const isExpirationTypeDeprecated =
      selectedExpirationType === EXPIRATION_TYPES.DATE &&
      selectedExpirationDate === EXPIRATION_TYPES.DURATION;

    if (isExpirationTypeDeprecated) {
      return selectedExpirationType;
    }
    return value;
  };

  const renderAssessmentNameField = () => (
    <Field data-testid="assessment-name">
      <FieldLabel>Assessment name</FieldLabel>
      <FieldInput
        name="assessment-settings-name"
        defaultValue={assessmentName}
        required
      />
    </Field>
  );

  const renderAssessmentSlugField = () => (
    <SlugField data-testid="assessment-slug">
      <FieldLabel>Slug name</FieldLabel>
      <FieldInput
        name="assessment-settings-slug"
        defaultValue={assessmentSlug}
        required
        validations={{
          // Just allow letters, numbers and hyphens
          pattern: /^[a-zA-Z0-9-]*$/,
          validate: isEditing ? true : validateSlugUniqueness
        }}
        isValid={isValidSlug}
      />
    </SlugField>
  );

  const renderAssessmentTypeField = () => (
    <Field data-testid="assessment-type">
      <FieldLabel>Assessment type</FieldLabel>
      <FieldInput
        name="assessment-settings-type"
        defaultValue={selectedType}
        value={selectedType}
        isVisible={false}
      />

      <FlexContainer>
        <AssessmentTypeBtn
          onClick={ev => {
            handleTypeChange(ASSESSMENT_TYPES.PRIVATE, ev);
          }}
          active={selectedType === ASSESSMENT_TYPES.PRIVATE}
        >
          Private
        </AssessmentTypeBtn>
        <AssessmentTypeBtn
          onClick={ev => handleTypeChange(ASSESSMENT_TYPES.PUBLIC, ev)}
          active={selectedType === ASSESSMENT_TYPES.PUBLIC}
        >
          Public
        </AssessmentTypeBtn>
      </FlexContainer>
    </Field>
  );

  const renderAssessmentExpirationField = () => (
    <ExpirationField>
      <AssessmentAccessContainer align="center" justify="flex-start">
        <FlexContainer align="center" justify="flex-start" flex="3">
          <StyledFieldLabel>Assessment Access</StyledFieldLabel>
          <WarnIconContainer>{roundedWarningIcon}</WarnIconContainer>
        </FlexContainer>
        <FlexContainer flex="5">
          <AssessmentAccessDropdown
            name="assessment-settings-expiration-type"
            selectedOption={selectedExpirationType || null}
            setSelectedOption={value => {
              setSelectedExpirationType(getSelectedExpirationTypeOption(value));
              setShouldCloseCalendar(false);
            }}
            placeholder="Set an expiration date for assessment accessibility"
            options={[
              { id: EXPIRATION_TYPES.NO_EXPIRATION },
              { id: EXPIRATION_TYPES.AVAILABILITY }
            ]}
            showUnderline
            width="100%"
            isValid={isValidExpirationType}
          />
        </FlexContainer>
      </AssessmentAccessContainer>
      {selectedExpirationType === EXPIRATION_TYPES.DURATION && (
        <FlexContainer justify="flex-start">
          <FieldInput
            name="assessment-settings-expiration-number"
            testId="assessment-settings-expiration-number"
            type="number"
            defaultValue={expirationNumber}
            required
            width={"120px"}
            disabled
          />
          <FieldInput
            name="assessment-settings-expiration-unit"
            testId="assessment-settings-expiration-unit"
            type="text"
            placeholder={expirationUnit}
            defaultValue={expirationUnit}
            width={"60px"}
            disabled
          />
        </FlexContainer>
      )}
      {selectedExpirationType === EXPIRATION_TYPES.DATE && (
        <FlexContainer justify="flex-start" width={"35%"}>
          <FieldInput
            name="assessment-settings-expiration-date"
            testId="assessment-settings-expiration-date"
            type="text"
            placeholder={String(selectedExpirationDate)}
            defaultValue={selectedExpirationDate}
            width={"140px"}
            disabled
          />
          <FieldInput
            name="assessment-settings-expiration-hour"
            testId="assessment-settings-expiration-hour"
            type="text"
            placeholder={selectedExpirationHour}
            defaultValue={selectedExpirationHour}
            width={"60px"}
            disabled
          />
        </FlexContainer>
      )}
      {selectedExpirationType === EXPIRATION_TYPES.AVAILABILITY && (
        <FlexContainer direction="column" gap="9">
          <FlexContainer>
            <FlexContainer flex="3">
              <BackgroundedText>Start Date</BackgroundedText>
            </FlexContainer>
            <FlexContainer flex="5" ref={pickerRef}>
              <FlexContainer flex="5">
                <StyledCalendarPicker
                  shouldCloseCalendar={shouldCloseCalendar}
                  dateFilter={{
                    startDate: selectedOpenDate,
                    endDate: selectedOpenDate
                  }}
                  handleDatePicker={date => {
                    setSelectedOpenDate(date);
                  }}
                  showLabels={false}
                  endInputName="assessment-settings-availability-start-date"
                  endInputvalidations={{
                    validate: () => customExpirationDateValidation()
                  }}
                  isFullWidth
                />
              </FlexContainer>
              <FlexContainer flex="3">
                <HoursDropdownContainer
                  onClick={() => setShouldCloseCalendar(true)}
                  isFullWidth
                >
                  <StyledHoursDropdown
                    selectedHour={selectedOpenHour}
                    onSelect={setSelectedOpenHour}
                    inputName="assessment-settings-availability-start-hour"
                    isValid={true}
                    isFullWidth
                  />
                </HoursDropdownContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="3">
              <BackgroundedText>Close Date</BackgroundedText>
            </FlexContainer>
            <FlexContainer flex="5" ref={pickerRef}>
              <FlexContainer flex="5">
                <StyledCalendarPicker
                  shouldCloseCalendar={shouldCloseCalendar}
                  dateFilter={{
                    startDate: selectedCloseDate,
                    endDate: selectedCloseDate
                  }}
                  handleDatePicker={date => {
                    setSelectedCloseDate(date);
                  }}
                  showLabels={false}
                  endInputName="assessment-settings-availability-close-date"
                  endInputvalidations={{
                    validate: () => customExpirationDateValidation()
                  }}
                  isFullWidth
                />
              </FlexContainer>
              <FlexContainer flex="3">
                <HoursDropdownContainer
                  onClick={() => setShouldCloseCalendar(true)}
                  isFullWidth
                >
                  <StyledHoursDropdown
                    selectedHour={selectedCloseHour}
                    onSelect={setSelectedCloseHour}
                    inputName="assessment-settings-availability-close-hour"
                    isValid={true}
                    isFullWidth
                  />
                </HoursDropdownContainer>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer />
          </FlexContainer>
        </FlexContainer>
      )}
    </ExpirationField>
  );

  const renderEmailSenderField = () => (
    <EmailField data-testid="assessment-email-sender">
      <FieldLabel>Email sender</FieldLabel>
      <FlexContainer>
        <FieldInput
          name="assessment-settings-email-sender"
          defaultValue={emailSender}
          required
          validations={{
            validate: email =>
              customEmailValidation(`${email}${EMAIL_SENDER_DOMAIN}`)
          }}
        />
        <DisabledEmail>@correlation1.com</DisabledEmail>
      </FlexContainer>
    </EmailField>
  );

  const renderAssessmentEmailSenderNameField = () => (
    <EmailSenderNameField data-testid="assessment-email-sender">
      <FieldLabel>Email sender name</FieldLabel>
      <FlexContainer>
        <FieldInput
          name="assessment-settings-email-sender-name"
          defaultValue={emailSenderName}
          width={"100%"}
          required
        />
      </FlexContainer>
    </EmailSenderNameField>
  );

  const renderColorThemeField = () => (
    <Field data-testid="assessment-color-theme">
      <FlexContainer align="center">
        <ColorThemeLabel>
          Color theme
          <CustomTooltip content="Applies new color to text headings, links, and buttons in all assessments" />
        </ColorThemeLabel>
      </FlexContainer>
      <FlexContainer>
        <FieldInput
          name="assessment-settings-color-theme"
          defaultValue={assessmentColorTheme}
          onChange={ev => setColorTheme(ev.target.value)}
          placeholder="######"
          required
          isValidHex={hexColorValidation(colorTheme)}
          validations={{
            validate: hexColorValidation
          }}
        />
        <ColorPickerDisplay
          color={hexColorValidation(colorTheme) ? colorTheme : ""}
        />
      </FlexContainer>
    </Field>
  );

  const renderNotificationField = () => (
    <Field data-testid="assessment-complete-notification">
      <NotificationFieldContainer align="center">
        <SwitchContainer>
          <ToggleSwitch
            inputName="assessment-settings-complete-notification-toggle"
            isOn={showEmailToNotify}
            handleSwitch={() => setShowshowEmailToNotify(!showEmailToNotify)}
          />
        </SwitchContainer>
        <ToggleFieldLabel>Notify me of completed assessment </ToggleFieldLabel>
        {showEmailToNotify && (
          <EmailInputContainer>
            <FieldInput
              name="assessment-settings-complete-notification-sender"
              required
              placeholder="Notification email"
              defaultValue={notificationSender}
              validations={{
                validate: customEmailValidation
              }}
            />
          </EmailInputContainer>
        )}
      </NotificationFieldContainer>
    </Field>
  );

  const renderQuestionPoolToggleField = () => (
    <Field data-testid="assessment-question-pool-toggle">
      <FlexContainer align="center">
        <SwitchContainer>
          <ToggleSwitch
            inputName="assessment-settings-question-pool-toggle"
            isOn={questionPoolIsEnabled}
            handleSwitch={() =>
              setquestionPoolIsEnabled(!questionPoolIsEnabled)
            }
          />
        </SwitchContainer>
        <ToggleFieldLabel>Enable question pools</ToggleFieldLabel>
      </FlexContainer>
    </Field>
  );

  const renderAttemptLimit = () => (
    <Field data-testid="assessment-attempt-limit">
      <FlexContainer align="center">
        <SwitchContainer>
          <ToggleSwitch
            inputName="assessment-settings-attempt-limit-toggle"
            isOn={isAttemptLimited}
            handleSwitch={() => setIsAttemptLimited(!isAttemptLimited)}
          />
        </SwitchContainer>
        <ToggleLimitFieldLabel>
          Limit how many attempts allowed
        </ToggleLimitFieldLabel>
        <LimitToolTip content="Attempt limit will only apply to public links" />
        {isAttemptLimited && (
          <AttemptLimitContainer>
            <FieldInput
              name="assessment-settings-attempt-limit"
              required
              defaultValue={defaultSettings.attemptLimit || 1}
              validations={{ validate: positiveValidation }}
              type="number"
            />
          </AttemptLimitContainer>
        )}
      </FlexContainer>
    </Field>
  );

  const renderEditWeightBtn = () =>
    Object.keys(reconciledWeights)?.length > 0 && (
      <ActionBtnContainer>
        <ActionBtn
          data-testid="edit-weight-btn"
          onClick={() => setEditWeighting(true)}
        >
          Edit category weighting
        </ActionBtn>
      </ActionBtnContainer>
    );

  const renderActions = () => (
    <NavActions data-testid="action-buttons">
      <ActionButton data-testid="save-btn" className="save" form={formID}>
        Save
      </ActionButton>
      <ActionButton
        data-testid="cancel-btn"
        className="cancel"
        onClick={ev => {
          ev.preventDefault();
          onCancel();
        }}
      >
        Cancel
      </ActionButton>
    </NavActions>
  );

  const renderWebhooksField = () => {
    const webhookOptions = [{ id: "NONE" }];
    availableWebhooksForTenant?.map(webhook => webhookOptions.push(webhook));

    return (
      <WebhookContainer justify="flex-start">
        <FlexContainer justify="flex-start" direction="column">
          <FlexContainer align="center" justify="flex-start" flex="3">
            <StyledFieldLabel>Webhooks</StyledFieldLabel>
          </FlexContainer>
          <FlexContainer flex="5">
            <WebhookDropdown
              name="assessment-settings-selected-webhook"
              selectedOption={
                selectedWebhook ? String(selectedWebhook) : webhookOptions[0].id
              }
              setSelectedOption={value => {
                setSelectedWebhook(String(value));
              }}
              options={webhookOptions}
              showUnderline
              width="100%"
            />
          </FlexContainer>
        </FlexContainer>
        <LinkContainer>
          <a
            href="https://www.notion.so/Which-Webhook-should-I-use-fae712d0f370452292991ed805b767a1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Which webhook should I use?
          </a>
        </LinkContainer>
      </WebhookContainer>
    );
  };

  return (
    <StyledModal title="Settings" showCloseIcon={false} onClose={onCancel}>
      <form
        id={formID}
        onSubmit={e => {
          e.preventDefault();
          setIsValidExpirationType(Boolean(selectedExpirationType));

          handleSubmit(onSave)();
        }}
      >
        <FormContext {...methods}>
          <Settings>
            {renderAssessmentNameField()}
            <FlexContainer align="center" justify="space-between">
              {renderAssessmentSlugField()}
              {renderColorThemeField()}
            </FlexContainer>
            {renderAssessmentTypeField()}
            <FlexContainer direction="column">
              {renderAssessmentExpirationField()}
              <FlexContainer>
                {renderEmailSenderField()}
                {renderAssessmentEmailSenderNameField()}
              </FlexContainer>
              <FlexContainer>{renderWebhooksField()}</FlexContainer>
            </FlexContainer>

            <ToggleConfigurations>
              {renderAttemptLimit()}
              {renderNotificationField()}
              {renderQuestionPoolToggleField()}
            </ToggleConfigurations>
            {!editWeighting ? (
              renderEditWeightBtn()
            ) : (
              <CategoryWeightsForm
                reconciledWeights={reconciledWeights}
                handleResetValues={handleResetValues}
                setWasWeightsCustomized={setWasWeightsCustomized}
              />
            )}
            {renderActions()}
          </Settings>
        </FormContext>
      </form>
    </StyledModal>
  );
};

export default SettingsModal;

SettingsModal.propTypes = {
  defaultSettings: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  categoriesWeightData: PropTypes.shape({
    defaultCategoriesWeight: PropTypes.object,
    reconciledWeights: PropTypes.object
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  isValidSlug: PropTypes.bool.isRequired,
  setWasWeightsCustomized: PropTypes.func
};

SettingsModal.defaultProps = {
  setWasWeightsCustomized: () => null
};

const StyledModal = styled(Modal)`
  > div {
    min-height: 85%;
    padding: 34px 31px 32px;
  }
`;

const Settings = styled.div`
  margin-top: 24px;
`;

const Field = styled.div`
  margin-top: 25px;
  text-align: left;
`;

const FieldLabel = styled.label`
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-bottom: 8px;
`;

const BackgroundedText = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  font-size: 14px;
  height: 43px;
  justify-content: flex-start;
  margin-right: 15px;
  padding: 8px;
  user-select: none;
  width: 100%;
`;

const ColorPickerDisplay = styled.div`
  background: ${({ color, theme }) => color || theme.colors.iron};
  height: 34px;
  width: 34px;
`;

const ColorThemeLabel = styled(FieldLabel)`
  align-items: center;
  display: flex;
`;

const CustomTooltip = styled(OptionTooltip)`
  line-height: 12px;
  margin-left: 6px;
`;

const LimitToolTip = styled(CustomTooltip)`
  bottom: 2px;
  margin-right: 11px;
  position: relative;
`;

const DisabledEmail = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  color: #858585;
  height: 34px;
  padding: 8px;
  user-select: none;
`;

const FieldInput = styled(FormInput)`
  color: ${({ isValidHex }) => isValidHex === false && "#6a7f87"};
  height: 32px;
  width: ${({ width }) => width};
  ${removeNumericInputSpinners}
`;

const AssessmentTypeBtn = styled.button`
  background: ${({ active, theme }) =>
    active ? `${theme.tenantAccent}17` : "#f5f5f5"};
  border: ${({ active, theme }) =>
    `1px solid ${active ? theme.tenantAccent : "rgb(208, 208, 208)"}`};
  color: ${({ active, theme }) => (active ? theme.tenantAccent : "#858585")};
  cursor: pointer;
  flex: 1;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  font-weight: ${({ active }) => (active ? "900" : "500")};
  letter-spacing: 0.4px;
  line-height: 20px;
  outline: 0;
  padding: 8px 0;
  text-align: center;
  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }
  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }
`;

const NavActions = styled.div`
  display: flex;
`;

const ActionBtnContainer = styled.div`
  margin-top: 25px;
`;

const ActionButton = styled.button`
  border: 1px solid rgb(41, 41, 69);
  border-radius: 2px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  letter-spacing: 1.25px;
  margin-top: 32px;
  min-width: 94px;
  outline: 0;
  padding: 7px 14px;
  text-align: center;
  text-transform: uppercase;
  transition: 200ms all ease-out;
  &.cancel {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid rgb(208, 208, 208);
    color: ${({ theme }) => theme.colors.darkBlue};
    margin-left: 8px;
    &:hover {
      background: ${({ theme }) => handleButtonHover(theme.colors.white)};
      color: ${({ theme }) => theme.colors.white};
    }
  }
  &.save {
    background: ${({ theme }) => theme.colors.darkBlue};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background: ${({ theme }) => handleButtonHover(theme.colors.darkBlue)};
    }
    &:disabled {
      background: #9c9c9c;
      cursor: auto;
    }
  }
`;

const AssessmentAccessDropdown = styled(Dropdown)`
  flex: 5;
  width: 100%;
`;

const WebhookDropdown = styled(Dropdown)`
  flex: 5;
  width: 100%;
`;

const StyledFieldLabel = styled(FieldLabel)`
  margin-bottom: 0;
`;

const ActionBtn = styled.button`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.tenantAccent};
  cursor: pointer;
  display: block;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  outline: 0;
  padding: 0;
  text-decoration: underline;
`;

const NotificationFieldContainer = styled(FlexContainer)`
  min-height: 38px;
`;

const SwitchContainer = styled.div`
  min-width: 65px;
`;

const EmailInputContainer = styled.div`
  width: 40%;
`;

const AttemptLimitContainer = styled.div`
  max-width: 100px;
`;

const StyledCalendarPicker = styled(CalendarPicker)`
  margin-right: 12px;
  padding: 0;
  + .react-calendar {
    position: absolute;
  }
`;

const StyledHoursDropdown = styled(HoursDropdown)`
  height: 100%;
  min-height: ${({ isValid }) => !isValid && "45px"};
  width: ${({ isFullWidth }) => isFullWidth && "100%"};
`;

const HoursDropdownContainer = styled.div`
  height: 100%;
  width: ${({ isFullWidth }) => isFullWidth && "100%"};
  > div {
    height: 41px;
    width: ${({ isFullWidth }) => isFullWidth && "100%"};
  }
`;

const SlugField = styled(Field)`
  flex: 0.9;
`;

const EmailField = styled(Field)`
  flex: 1;
`;

const ExpirationField = styled(Field)`
  flex: 1;
`;

const ToggleFieldLabel = styled(FieldLabel)`
  margin: 0 16px 0 20px;
`;

const ToggleLimitFieldLabel = styled(ToggleFieldLabel)`
  margin-right: 0;
`;

const ToggleConfigurations = styled.div`
  margin-top: 40px;
`;

const WarnIconContainer = styled.div`
  margin-left: 4px;
`;

const AssessmentAccessContainer = styled(FlexContainer)`
  margin-bottom: 12px;
`;

const WebhookContainer = styled(FlexContainer)`
  margin-top: 25px;
  width: 50%;
`;

const EmailSenderNameField = styled(Field)`
  flex: 1;
`;

const LinkContainer = styled(FlexContainer)`
  margin-left: 14px;
  a {
    color: ${({ theme }) => theme.colors.blue};
    display: block;
    font-family: ${({ theme }) => theme.fonts.avenirProMedium};
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    text-decoration: none;
    text-transform: capitalize;
  }
`;
