import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from "pages/ManagementPage/candidateInfoForm/Checkbox";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer, Container } from "components/Atoms/Containers";
import Dropdown from "components/Blocks/Dropdown";
import { FormInput } from "components/Blocks/ManagementForm";
import xIcon from "assets/xIcon";

const FIELDS_TYPES = {
  DROPDOWN: "Dropdown",
  FREE_RESPONSE: "Free Response"
};

const QuestionFieldCreationForm = ({ item, handleAdd, handleCancel }) => {
  const {
    itemId,
    itemName,
    isEditing,
    isMandatory,
    configuration: { fieldOptions: defaultFieldOptions }
  } = item;

  const getFieldType = () =>
    Array.isArray(defaultFieldOptions)
      ? FIELDS_TYPES.DROPDOWN
      : FIELDS_TYPES.FREE_RESPONSE;

  const [selectedFieldType, setSelectedFieldType] = useState(
    isEditing ? getFieldType() : FIELDS_TYPES.DROPDOWN
  );

  const [fieldName, setFieldName] = useState(itemName || "");
  const [fieldOptions, setFieldOptions] = useState(null);
  const [isFieldMandatory, setIsFieldMandatory] = useState(
    isEditing && isMandatory
  );
  const validateInputs = () => {
    if (Array.isArray(fieldOptions)) {
      return fieldOptions.length && fieldOptions.every(({ value }) => value);
    }
    return true;
  };
  const isFormValid = !!fieldName && validateInputs();

  const dropdownProps = {
    name: `assessment-field-candidate-info-${itemId}-type`,
    selectedOption: selectedFieldType,
    setSelectedOption: type => setSelectedFieldType(FIELDS_TYPES[type]),
    options: Object.keys(FIELDS_TYPES).map(key => ({
      id: key,
      value: FIELDS_TYPES[key]
    }))
  };

  const addOptionFieldHandler = () => {
    setFieldOptions(prevFieldOptions => [
      ...prevFieldOptions,
      { optionId: `option-${prevFieldOptions.length + 1}` }
    ]);
  };

  const removeOptionFieldHandler = id => {
    setFieldOptions(fieldOptions.filter(({ optionId }) => id !== optionId));
  };

  const addFieldHandler = event => {
    event.preventDefault();
    handleAdd(itemId, {
      itemName: fieldName,
      isChecked: true,
      isMandatory: isFieldMandatory,
      showForm: false,
      configuration: {
        fieldOptions
      }
    });
  };

  const cancelFieldHandler = event => {
    event.preventDefault();
    event.stopPropagation();
    handleCancel();
  };

  const handleFieldOptionChange = (id, value) => {
    const modifiedOptionIndex = fieldOptions.findIndex(
      ({ optionId }) => optionId === id
    );
    const newOptions = [...fieldOptions];
    newOptions[modifiedOptionIndex] = {
      ...newOptions[modifiedOptionIndex],
      value
    };
    setFieldOptions(newOptions);
  };

  const renderOptionInputs = () =>
    Array.isArray(fieldOptions) && (
      <SectionContainer direction="column">
        {fieldOptions.map(({ optionId, value }, index) => {
          return (
            <Fragment key={optionId}>
              <FieldOptionContainer align="center" justify="space-between">
                <QuestionFieldInputContainer isList>
                  <FieldOption
                    defaultValue={value}
                    testId={"field-option"}
                    placeholder={`Option ${index + 1}`}
                    name={`assessment-field-candidate-info-option-${itemId}-${index +
                      1}-dropdown`}
                    required
                    onChange={ev =>
                      handleFieldOptionChange(optionId, ev.target.value)
                    }
                  />
                </QuestionFieldInputContainer>
                {fieldOptions.length > 1 && (
                  <RemoveIcon
                    data-testid="remove-field-btn"
                    onClick={() => removeOptionFieldHandler(optionId)}
                  >
                    {xIcon}
                  </RemoveIcon>
                )}
              </FieldOptionContainer>
            </Fragment>
          );
        })}
        <AddField data-testid="add-field-btn" onClick={addOptionFieldHandler}>
          + Add Option
        </AddField>
      </SectionContainer>
    );

  useEffect(() => {
    const getFieldOptions = () =>
      Array.isArray(defaultFieldOptions)
        ? [...defaultFieldOptions]
        : [
            { optionId: "option-1", value: "" },
            { optionId: "option-2", value: "" }
          ];

    switch (selectedFieldType) {
      case FIELDS_TYPES.DROPDOWN:
        setFieldOptions(
          isEditing
            ? getFieldOptions()
            : [
                { optionId: "option-1", value: "" },
                { optionId: "option-2", value: "" }
              ]
        );
        break;
      case FIELDS_TYPES.FREE_RESPONSE:
        setFieldOptions("");
        break;
      default:
        return;
    }
  }, [selectedFieldType, defaultFieldOptions, isEditing]);

  return (
    <FieldFormContainer>
      <Container width="100%">
        <FlexContainer align="center" justify="space-between">
          <NameFieldContainer>
            <QuestionFieldInput
              defaultValue={itemName}
              testId="field-name"
              name={`assessment-candidate-info-field-${itemId}-name`}
              placeholder="Name of field"
              required
              type="text"
              onChange={ev => setFieldName(ev.target.value)}
            />
          </NameFieldContainer>
          <DropdownContainer>
            <StyledDropdown {...dropdownProps} />
          </DropdownContainer>
        </FlexContainer>
        {renderOptionInputs()}
        <SectionContainer align="center" data-testid="field-required-checkbox">
          <Checkbox
            isChecked={isFieldMandatory}
            onClick={() => setIsFieldMandatory(!isFieldMandatory)}
          />
          <CheckboxLabel>is mandatory </CheckboxLabel>
        </SectionContainer>
        <SectionContainer data-testid="field-buttons">
          <AddButton
            background="#fff"
            color="#000"
            disabled={!isFormValid}
            onClick={ev => addFieldHandler(ev)}
          >
            {isEditing ? "Save" : "Add"}
          </AddButton>
          <StyledButton
            background="#fff"
            color="#a9aab5"
            onClick={cancelFieldHandler}
          >
            Cancel
          </StyledButton>
        </SectionContainer>
      </Container>
    </FieldFormContainer>
  );
};

export default QuestionFieldCreationForm;

QuestionFieldCreationForm.propTypes = {
  item: PropTypes.object.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};

const FieldFormContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid rgb(219, 219, 219);
  border-radius: 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 100%;
`;

const NameFieldContainer = styled.div`
  flex: 0 0 calc(70% - 4px);
`;

const DropdownContainer = styled.div`
  flex: 0 0 calc(30% - 4px);
`;

const StyledDropdown = styled(Dropdown)`
  border: 1px solid #d7d7d7;
  border-radius: 2px;
  height: 30px;
`;

const QuestionFieldInput = styled(FormInput)`
  height: 30px;
  + div {
    background: #d2d2d2;
  }
`;

const FieldOption = styled(QuestionFieldInput)`
  background: ${({ theme }) => theme.colors.white};
`;

const FieldOptionContainer = styled(FlexContainer)`
  margin-bottom: 8px;
`;

const RemoveIcon = styled.div`
  cursor: pointer;
`;

const QuestionFieldInputContainer = styled.div`
  flex: 1;
  max-width: ${({ isList }) => isList && "90%"};
`;

const AddField = styled.div`
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: left;
`;

const CheckboxLabel = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-left: 8px;
`;

const SectionContainer = styled(FlexContainer)`
  margin-top: 20px;
`;

const AddButton = styled(StyledButton)`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #d7d7dd;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  height: 32px;
  margin-right: 8px;
  padding: 9px 10px;
  &:disabled {
    background: #9c9c9c;
    border: 0;
    color: ${({ theme }) => theme.colors.white};
    cursor: default;
  }
`;
