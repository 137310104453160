import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CardItem from "pages/ManagementPage/CardItem";
import { OPTION_STATES, OPTION_TYPES } from "pages/ManagementPage/Constants";
import OptionIcon from "pages/ManagementPage/OptionIcon";

const EditableCards = ({
  formData,
  handleClickOption,
  handleSaveSection,
  optionsData,
  handleAddQuestion,
  handleUpdateSection,
  sectionsData,
  setDeleteConfirmationId,
  handleRemoveQuestion,
  setIsDragging,
  formErrors,
  isQuestionPoolEnabled,
  handleAddPool,
  handleOpenQuestionPoolEditor,
  questionPools
}) => (
  <>
    {optionsData.map(
      ({
        background,
        id,
        cardState,
        svg,
        text,
        isSectionCreated,
        isDeletable
      }) =>
        cardState !== OPTION_STATES.HIDDEN && (
          <CardContainer
            key={id}
            data-testid="option-card"
            onClick={() => handleClickOption(id)}
          >
            <CardItem
              isQuestionPoolEnabled={isQuestionPoolEnabled}
              setDeleteConfirmationId={() => setDeleteConfirmationId(id)}
              isDeletable={isDeletable}
              handleUpdateSection={handleUpdateSection}
              handleEdit={() => handleClickOption(id)}
              handleSaveSection={handleSaveSection}
              itemId={id}
              formData={formData}
              icon={
                <OptionIcon
                  background={background}
                  svg={svg}
                  text={text}
                  isNavIcon={false}
                />
              }
              isCardOpen={OPTION_STATES.EXPANDED === cardState}
              showSectionCard={
                id.includes(OPTION_TYPES.QUESTION_SECTION) &&
                isSectionCreated &&
                OPTION_STATES.EXPANDED !== cardState
              }
              handleAddQuestion={() => handleAddQuestion(id)}
              handleAddPool={() => handleAddPool(id)}
              sectionsData={sectionsData}
              handleRemoveQuestion={handleRemoveQuestion}
              setIsDragging={setIsDragging}
              formErrors={formErrors}
              handleOpenQuestionPoolEditor={handleOpenQuestionPoolEditor}
              questionPools={questionPools}
            />
          </CardContainer>
        )
    )}
  </>
);

export default EditableCards;

EditableCards.propTypes = {
  formData: PropTypes.object.isRequired,
  handleClickOption: PropTypes.func.isRequired,
  handleSaveSection: PropTypes.func.isRequired,
  optionsData: PropTypes.array.isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleAddPool: PropTypes.func.isRequired,
  handleUpdateSection: PropTypes.func.isRequired,
  sectionsData: PropTypes.array.isRequired,
  setDeleteConfirmationId: PropTypes.func.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  isQuestionPoolEnabled: PropTypes.bool.isRequired,
  handleOpenQuestionPoolEditor: PropTypes.func.isRequired,
  questionPools: PropTypes.array
};

EditableCards.defaultProps = {
  errors: {},
  questionPools: []
};

const CardContainer = styled.div`
  margin-bottom: 16px;
`;
