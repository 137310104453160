import React, { useState } from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import styled from "styled-components";
import { listTypes } from "pages/App/helpers/listTypes";
import CategoriesDropdown from "pages/ManagementPage/CategoriesDropdown";
import Search from "pages/PanelPage/components/Search";
import { FlexContainer } from "components/Atoms/Containers";
import { Label } from "components/Atoms/Text";
import FilterList from "components/Blocks/FilterList";
import Button from "components/Blocks/Forms/Button";
import RemoveFilter from "components/Blocks/RemoveFilter";
import SortList from "components/Blocks/SortList";

const QuestionNav = ({
  setSortByRecent,
  sortByRecent,
  showSelectQuestionType,
  setStateList,
  totalQuestions,
  selectedFilter,
  isAssessmentCreation,
  setSearchInputValue,
  searchInputValue,
  setFilteredCategory,
  filteredCategory
}) => {
  const [filteredCategoryName, setFilteredCategoryName] = useState("");
  return (
    <Nav>
      <NavSection margin="0 0 8px" justify="space-between">
        <FlexContainer align="center">
          <SearchWrapper>
            <Search
              name="search"
              placeholder="Search questions"
              defaultValue={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
          </SearchWrapper>
          <FilterList
            type={listTypes.QUESTIONS}
            onFilterClick={setStateList}
            selectedFilter={selectedFilter}
          />
        </FlexContainer>
        {!isAssessmentCreation && (
          <Button
            textTransform="uppercase"
            text="Add New Question"
            onClick={showSelectQuestionType}
          />
        )}
      </NavSection>
      <FilterContainer
        direction="column"
        align="flex-start"
        isAssessmentCreation={isAssessmentCreation}
      >
        {searchInputValue && (
          <RemoveFilter
            filterLabel={`Filter: "${searchInputValue}"`}
            onRemove={() => setSearchInputValue("")}
          />
        )}
        {filteredCategory && (
          <RemoveFilter
            filterLabel={`Filter by category: ${filteredCategoryName}`}
            onRemove={() => setFilteredCategory(null)}
          />
        )}
      </FilterContainer>

      <NavSection justify="space-between">
        <QuestionsCount textTransform="uppercase">
          {pluralize("question", totalQuestions, true)}
        </QuestionsCount>
        <FlexContainer>
          <CategoriesContainer isAssessmentCreation={isAssessmentCreation}>
            <StyledDropdown
              isAssessmentCreation={isAssessmentCreation}
              updateCategory={selectedCat => {
                setFilteredCategoryName(selectedCat.value);
                setFilteredCategory(selectedCat.id);
              }}
              defaultCategory={filteredCategory}
              resetDropdown={!filteredCategory}
            />
          </CategoriesContainer>
          {!isAssessmentCreation && (
            <SortList
              handleSort={() => setSortByRecent(!sortByRecent)}
              isCaretUp={!sortByRecent}
              text="created at"
            />
          )}
        </FlexContainer>
      </NavSection>
    </Nav>
  );
};

export default QuestionNav;

QuestionNav.propTypes = {
  setSortByRecent: PropTypes.func.isRequired,
  sortByRecent: PropTypes.bool.isRequired,
  showSelectQuestionType: PropTypes.func.isRequired,
  setStateList: PropTypes.func.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  selectedFilter: PropTypes.string.isRequired,
  isAssessmentCreation: PropTypes.bool.isRequired,
  setSearchInputValue: PropTypes.func.isRequired,
  searchInputValue: PropTypes.string,
  setFilteredCategory: PropTypes.func,
  filteredCategory: PropTypes.number
};

const FilterContainer = styled(FlexContainer)`
  margin-bottom: 12px;
`;

const Nav = styled.nav`
  margin-bottom: 20px;
`;

const QuestionsCount = styled(Label)`
  align-items: center;
  display: flex;
`;

const NavSection = styled(FlexContainer)`
  margin: ${({ margin }) => margin};
`;

const SearchWrapper = styled.div`
  margin-right: 52px;
  width: auto;
`;

const CategoriesContainer = styled.div`
  bottom: ${({ isAssessmentCreation }) => isAssessmentCreation && "34px"};
  left: ${({ isAssessmentCreation }) => isAssessmentCreation && "36vw"};
  margin-right: 16px;
  min-width: 150px;
  position: ${({ isAssessmentCreation }) => isAssessmentCreation && "absolute"};
  z-index: 10;
`;

const StyledDropdown = styled(CategoriesDropdown)`
  background: ${({ theme }) => theme.colors.transparent};
  border: 0;
  justify-content: flex-end;
  min-width: ${({ isAssessmentCreation }) => isAssessmentCreation && "10vw"};
  text-transform: ${({ isAssessmentCreation }) =>
    !isAssessmentCreation && "uppercase"};
  > div:first-child {
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.89px;
  }
  ~ div:last-child {
    max-height: 400px;
    overflow-y: auto;
  }
  svg {
    height: 12px;
    width: 12px;
  }
`;
