import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CategoryWeightsForm from "pages/ManagementPage/CategoryWeightsForm";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import Modal from "components/Systems/Modal";

const CategoryWeightsWarningModal = ({
  handleResetValues,
  onClose,
  onConfirm,
  reconciledWeights
}) => (
  <StyledModal title="Confirm custom category weights" onClose={onClose}>
    <ModalDescription>
      The edits you made to this assessment may affect its custom category
      weights. Before saving, are you sure these values below are correct?{" "}
    </ModalDescription>
    <CategoryWeightsForm
      readOnly
      reconciledWeights={reconciledWeights}
      handleResetValues={handleResetValues}
      formName="modal-confirmation"
    />
    <FlexContainer>
      <ModalButton onClick={onConfirm}>SAVE</ModalButton>
      <ModalButton background="#d94a54" onClick={onClose}>
        CANCEL
      </ModalButton>
    </FlexContainer>
  </StyledModal>
);

export default CategoryWeightsWarningModal;

CategoryWeightsWarningModal.propTypes = {
  handleResetValues: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  reconciledWeights: PropTypes.object.isRequired
};

CategoryWeightsWarningModal.defaultProps = {
  handleResetValues: () => null
};

const StyledModal = styled(Modal)`
  > div {
    max-width: 600px;
    min-height: 500px;
  }
`;

const ModalDescription = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 16px;
  letter-spacing: 0.4px;
  margin: 32px 0 48px;
  text-align: left;
`;

const ModalButton = styled(StyledButton)`
  margin: 16px 32px 16px 0;
`;
