import { useCallback, useEffect } from "react";
import { getTenantsMW } from "pages/PanelPage/middleware";

const cache = {};

const useFetchTenants = setTenants => {
  const fetchTenants = useCallback(async () => {
    if (cache?.data) {
      setTenants(cache.data);
      return;
    }

    const tenants = await getTenantsMW();
    setTenants(tenants);
    cache.data = tenants;
  }, []);

  useEffect(() => {
    fetchTenants();
  }, [fetchTenants]);
};

export default useFetchTenants;
