import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { questionTypes } from "pages/App/helpers/questionTypes";
import { FlexContainer } from "components/Atoms/Containers";
import { removeNumericInputSpinners } from "utils/mixins/Mixins";
import questionItemIcon from "assets/questionItemIcon";
import questionPoolItemIcon from "assets/questionPoolItemIcon";
import trashCanGrayIcon from "assets/trashCanGrayIcon";

const QuestionItemHeader = ({
  questionNumber,
  questionType,
  handleRemoveQuestion,
  showDuration,
  handleUpdateQuestions,
  duration,
  weight,
  isSlotDisabled,
  errors,
  isQuestionPool
}) => (
  <Header align="center" direction="row" justify="space-between" role="header">
    <QuestionNumber align="center">
      <QuestionNumberItem className="icon">
        {isQuestionPool ? questionPoolItemIcon : questionItemIcon}
      </QuestionNumberItem>
      <QuestionNumberItem className="text">
        {isQuestionPool && "Random "} Question {questionNumber}
      </QuestionNumberItem>
    </QuestionNumber>
    <ActionsContainer align="center" justify="flex-end" data-testid="actions">
      <TrashCan onClick={handleRemoveQuestion}>{trashCanGrayIcon}</TrashCan>
      {showDuration && questionType !== questionTypes.STOCK && (
        <InformationBox
          allowEvents={!isSlotDisabled}
          align="center"
          justify="space-between"
          isValid={!errors?.TIMER}
        >
          <BoxInput
            placeholder="Duration"
            type="number"
            onChange={ev =>
              handleUpdateQuestions("duration", parseFloat(ev.target.value))
            }
            value={isNaN(duration) || !duration ? "" : duration}
          />
          <BoxLabel className="value">Sec</BoxLabel>
        </InformationBox>
      )}
      <InformationBox
        align="center"
        justify="space-between"
        isValid={!errors?.WEIGHT}
      >
        <BoxInput
          type="number"
          onChange={ev =>
            handleUpdateQuestions("weight", parseFloat(ev.target.value))
          }
          value={isNaN(weight) ? "" : weight}
        />
        <BoxLabel className="value">%</BoxLabel>
      </InformationBox>
    </ActionsContainer>
  </Header>
);

export default QuestionItemHeader;

QuestionItemHeader.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionType: PropTypes.string.isRequired,
  handleRemoveQuestion: PropTypes.func.isRequired,
  showDuration: PropTypes.bool.isRequired,
  handleUpdateQuestions: PropTypes.func.isRequired,
  duration: PropTypes.number,
  weight: PropTypes.number,
  isQuestionPool: PropTypes.bool.isRequired
};

QuestionItemHeader.defaultProps = {
  duration: null,
  weight: 100
};

const Header = styled(FlexContainer)`
  margin-bottom: 15px;
`;

const QuestionNumber = styled(FlexContainer)`
  margin-right: 8px;
`;

const QuestionNumberItem = styled.span`
  &.icon {
    margin-right: 5px;
  }
  &.text {
    color: ${({ theme }) => theme.colors.steel};
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 24px;
  }
`;

const InformationBox = styled(FlexContainer)`
  background: rgb(248, 249, 250);
  border: 1px solid rgb(215, 215, 215);
  border-radius: 2px;
  flex: 1;
  max-height: 24px;
  max-width: 150px;
  padding: 4px 6px;
  width: 100%;
  @media (max-width: 1200px) {
    max-width: 100px;
  }
  &:first-of-type {
    margin: 0 8px;
  }
`;

const BoxLabel = styled.label`
  color: ${({ theme }) => theme.colors.grey};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  opacity: 1;
  position: relative;
  top: 1px;
`;

const BoxInput = styled.input`
  background: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  opacity: 1;
  outline: 0;
  padding: 0;
  width: 100%;
  ${removeNumericInputSpinners}
  &::placeholder {
    color: ${({ theme }) => theme.colors.steel};
  }
  @media (max-width: 1200px) {
    max-width: 25px;
  }
`;

const ActionsContainer = styled(FlexContainer)`
  flex: 1;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    > span,
    > div {
      &:not(:only-child) {
        margin: 0 0 4px 0;
      }
    }
  }
`;

const TrashCan = styled.span`
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  height: 24px;
  margin-right: 8px;
  pointer-events: all;
`;
