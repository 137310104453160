import React, { useCallback, useEffect, useState } from "react";
import equal from "fast-deep-equal";
import PropTypes from "prop-types";
import { useForm, FormContext } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { getAssessmentBySlugMW } from "middleware";
import { useThemeContext } from "state/themeContext/useThemeContext";
import { useUserContext } from "state/userContext/useUserContext";
import { paths } from "pages/App/helpers/paths";
import { questionTypes } from "pages/App/helpers/questionTypes";
import {
  fillAssessmentData,
  getAssessmentCreationSchema
} from "pages/ManagementPage/AssessmentCreation/utils/assessmentDataUtils";
import { getExpirationData } from "pages/ManagementPage/AssessmentCreation/utils/expirationDateUtils";
import { processSettings } from "pages/ManagementPage/AssessmentCreation/utils/processSettings";
import { getSlotSchema } from "pages/ManagementPage/AssessmentCreation/utils/schemaUtils";
import { getSections } from "pages/ManagementPage/AssessmentCreation/utils/sectionUtils";
import { generateSlug } from "pages/ManagementPage/AssessmentCreation/utils/slugUtils";
import AssessmentCreationForm from "pages/ManagementPage/AssessmentCreationForm";
import AssessmentPreview from "pages/ManagementPage/AssessmentPreview";
import CategoryWeightsWarningModal from "pages/ManagementPage/CategoryWeightsWarningModal";
import {
  ASSESSMENT_TYPES,
  FORM_ID,
  EXPIRATION_UNITS,
  EACH_QUESTION,
  HAS_EMPTY_CATEGORIES,
  INVALID_POOL_SCORES,
  MAJOR_EDITING_MODE,
  SAVE_AND_REPLACE,
  EXPIRATION_TYPES,
  DEFAULT_ASSESSMENT_DATA,
  DEFAULT_SECTION_DATA,
  DEFAULT_SETTINGS,
  ONE_DAY_IN_SECONDS,
  ONE_WEEK_IN_SECONDS
} from "pages/ManagementPage/Constants";
import FormErrors from "pages/ManagementPage/FormErrors";
import Header from "pages/ManagementPage/Header";
import LeaveRoutePrompt from "pages/ManagementPage/LeaveRoutePrompt";
import SettingsModal from "pages/ManagementPage/SettingsModal";
import usePreventLeaveRoute from "pages/ManagementPage/hooks/usePreventLeaveRoute";
import { getTenantWebhooksMW } from "pages/ManagementPage/middleware";
import {
  getPoolsByAssessmentMW,
  createAssessmentMW,
  getQuestionsListMW,
  uploadLogoMW,
  updateAssessmentConfigurationMW,
  updateAssessmentMW,
  updateAssessmentMetadataMW
} from "pages/ManagementPage/middleware";
import getQuestionMaxScore from "pages/ManagementPage/utils/getQuestionMaxScore";
import validateQuestionPoolSameScore from "pages/ManagementPage/utils/validateQuestionPoolSameScore";
import validateForm from "pages/ManagementPage/validations/ValidateForm";
import AssessmentsLoader from "pages/PanelPage/AssessmentsLoader";
import useFetchCategories from "pages/QuestionPage/hooks/useFetchCategories";
import { StyledButton } from "components/Atoms/Buttons";
import {
  Container,
  FlexContainer,
  FormContainer
} from "components/Atoms/Containers";
import PopupNotification from "components/Blocks/PopupNotification";
import { PageWrapper } from "components/Blocks/Styled/Wrappers";
import Modal from "components/Systems/Modal";

const AssessmentCreation = ({ tenantInfo }) => {
  const { tenantAccent } = useThemeContext();
  const { userState } = useUserContext();
  const { tenantUser } = userState;
  const { name: tenantName, logo_url: logoUrl } = tenantInfo;
  const { assessmentSlug } = useParams();
  const history = useHistory();
  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit"
  });
  const emailSenderName = `${tenantUser?.value ??
    "Correlation One"} Hiring Team`;

  const [assessmentData, setAssessmentData] = useState({
    ...DEFAULT_ASSESSMENT_DATA,
    defaultLogo: logoUrl
  });

  const [sectionsData, setSectionsData] = useState([DEFAULT_SECTION_DATA]);
  const [assessmentSettings, setAssessmentSettings] = useState({
    ...DEFAULT_SETTINGS,
    emailSenderName,
    assessmentColorTheme: tenantAccent
  });
  const [availableWebhooksForTenant, setAvailableWebhooksForTenant] = useState(
    []
  );
  const [selectedOption, setSelectedOption] = useState("");
  const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [isLoading, setIsLoading] = useState(Boolean(assessmentSlug));
  const [categories, setCategories] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [createdCards, setCreatedCards] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMajorChange, setisMajorChange] = useState(false);
  const [majorEditingMode, setMajorEditingMode] = useState(null);
  const [showMajorEditingModal, setShowMajorEditingModal] = useState(false);
  const [questionPools, setQuestionPools] = useState([]);
  const [isRequestBeingSent, setIsRequestBeingSent] = useState(false);
  const [customWeightingValues, setCustomWeightingValues] = useState(null);
  const [confirmCustomWeighting, setConfirmCustomWeighting] = useState(false);
  const [fetchedWeighting, setFetchedWeighting] = useState(null);
  const [areWeightsAutoCalc, setAreWeightsAutoCalc] = useState(null);
  const { headerTitle, formTitle, title } = assessmentData;

  async function fetchWebhooks(tenantId) {
    const webhooks = await getTenantWebhooksMW({
      urlParams: [tenantId]
    });
    setAvailableWebhooksForTenant(webhooks?.data);
  }

  useEffect(() => {
    if (!assessmentSlug) {
      fetchWebhooks(tenantUser?.id);
    }
  }, [tenantUser]);

  usePreventLeaveRoute();
  useFetchCategories(setCategories);

  const modifyAssessmentData = newData => {
    setAssessmentData(prevData => ({
      ...prevData,
      ...newData
    }));
  };

  const handleAddQuestionPool = () => {
    const newPools = [...questionPools];
    newPools.push({
      title: `Untitled Bank ${newPools.length + 1}`,
      questions: []
    });
    setQuestionPools(newPools);
  };

  const handleUpdateQuestionPool = (
    editedPool,
    title = null,
    questions = null
  ) => {
    const newPools = [...questionPools];
    const newPool = newPools.find(({ title }) => editedPool.title === title);
    if (title) {
      newPool.title = title;
    }
    if (questions) {
      newPool.questions = questions;
    }
    setQuestionPools(newPools);
  };

  const handleRemoveQuestionPool = removedPool => {
    const newPools = questionPools.filter(
      ({ title }) => title !== removedPool.title
    );
    setQuestionPools(newPools);
  };

  const onSaveSettings = settings => {
    const { mapSettings, modifiedAssessmentsData } = processSettings(
      settings,
      assessmentSettings
    );

    setAssessmentSettings(mapSettings);
    modifyAssessmentData(modifiedAssessmentsData);

    setShowEditSettingsModal(false);
  };

  const handleKeyPress = event => {
    const code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      event.preventDefault();
    }
  };

  const formData = {
    data: {
      ...assessmentData,
      title: formTitle,
      defaultTitle: title
    },
    setData: modifyAssessmentData
  };

  const requestHandlers = {
    handleSuccess: (wasEdited = false) =>
      history.push({
        pathname: `${paths.ADMIN}/active`,
        search: `?sort_order=desc&tenant_id=${tenantUser.id}&assessment_states=active`,
        createdAssessment: headerTitle || title || formTitle || "Assessment",
        wasEdited
      }),
    handleError: () => {
      setRequestError(true);
      setConfirmCustomWeighting(false);
    }
  };

  const updateLogo = useCallback(async file => {
    const formData = new FormData();
    formData.append("file", file);
    const uploadLogoData = await uploadLogoMW({ data: formData });
    return uploadLogoData;
  }, []);

  const updateAssessmentConfigs = useCallback(
    async assessmentId => {
      const { file = null, url = "" } = assessmentData.logoFile;
      const shouldUpdateLogo = url && logoUrl !== url && file;
      let newLogoUrl = { data: logoUrl };
      if (shouldUpdateLogo) {
        newLogoUrl = await updateLogo(file);
      }

      return await updateAssessmentConfigurationMW({
        data: {
          default_theme_color: assessmentSettings.assessmentColorTheme,
          show_nav_logo: null,
          logo_url: newLogoUrl?.data || "",
          assessment_id: assessmentId
        }
      });
    },
    [
      assessmentData.logoFile,
      assessmentSettings.assessmentColorTheme,
      logoUrl,
      updateLogo
    ]
  );

  const getSlug = useCallback(
    assessmentName => {
      setAssessmentSettings(prevSettings => ({
        ...prevSettings,
        assessmentSlug: generateSlug(tenantName, assessmentName)
      }));
    },
    [tenantName]
  );

  const getSectionsSchema = useCallback(
    () =>
      sectionsData.map(
        (
          {
            sectionModelId = null,
            sectionTitle,
            sectionDescription,
            sectionIsLinear,
            sectionSeconds,
            sectionDuration,
            questions
          },
          idx
        ) => {
          const isSectionTimed = !(sectionDuration === EACH_QUESTION);
          return {
            ...(isEditing && sectionModelId && { id: sectionModelId }),
            title: sectionTitle,
            description: sectionDescription,
            // Question level timing sections must be linear
            is_linear: sectionIsLinear || !isSectionTimed,
            time_allowed_seconds: isSectionTimed ? sectionSeconds : null,
            sort_index: idx,
            is_timed: isSectionTimed,
            section_type: "section",
            slots: getSlotSchema(
              questions,
              !isSectionTimed,
              isEditing,
              categories
            )
          };
        }
      ),
    [sectionsData, getSlotSchema, isEditing]
  );

  const getAutoCalculateCategoryWeights = useCallback(() => {
    const defaultCategoriesWeight = {};
    for (const { questions = [] } of sectionsData) {
      for (const question of questions) {
        const {
          options,
          bands,
          default_category,
          question_type,
          isQuestionPool,
          selectedPool
        } = question;
        let questionScore = 0;
        // Handles talenstat edge cases with multiple categories per question
        let defaultCategories = Array.isArray(default_category)
          ? default_category
          : [default_category];

        if (isQuestionPool && selectedPool) {
          const currentPool = questionPools.find(
            ({ title }) => title === selectedPool
          );

          const possibleScore =
            currentPool && validateQuestionPoolSameScore(currentPool);

          if (possibleScore) {
            questionScore = possibleScore;
          } else {
            defaultCategoriesWeight[INVALID_POOL_SCORES] = true;
          }
          defaultCategories = [
            categories.find(({ name }) => name === selectedPool)
          ];
        } else if (
          question_type !== questionTypes.STOCK &&
          (options || bands)
        ) {
          questionScore = getQuestionMaxScore(question);
        }
        // If question doesn't have correct bands or options the questionScore is -Infinity
        if (questionScore < 0) {
          questionScore = 0;
        }

        for (const default_category of defaultCategories) {
          if (!default_category?.id) {
            defaultCategoriesWeight[HAS_EMPTY_CATEGORIES] = true;
            break;
          }

          if (!defaultCategoriesWeight?.[default_category.id]) {
            const weightId =
              assessmentSettings.categoriesWeight?.[default_category.id]
                ?.catWeightId || 0;
            defaultCategoriesWeight[default_category.id] = {
              id: `weight__${weightId}__${default_category.id}__${default_category?.name}`,
              name: default_category?.name,
              weight: questionScore
            };
          } else {
            defaultCategoriesWeight[
              default_category.id
            ].weight += questionScore;
          }
        }
      }
    }

    return defaultCategoriesWeight;
  }, [
    assessmentSettings.categoriesWeight,
    categories,
    questionPools,
    sectionsData
  ]);

  const getDefaultCategoriesWeight = useCallback(() => {
    const defaultCategoriesWeight = getAutoCalculateCategoryWeights();

    const reconciledWeights = {
      ...defaultCategoriesWeight,
      ...assessmentSettings.categoriesWeight,
      ...(areWeightsAutoCalc && defaultCategoriesWeight)
    };

    // Filter removed categories
    if (!Object.keys(defaultCategoriesWeight)?.length)
      return { reconciledWeights: {}, defaultCategoriesWeight };

    Object.keys(reconciledWeights).forEach(id => {
      // eslint-disable-next-line no-prototype-builtins
      if (!defaultCategoriesWeight.hasOwnProperty(id)) {
        delete reconciledWeights[id];
      }
    });
    return { defaultCategoriesWeight, reconciledWeights };
  }, [
    assessmentSettings.categoriesWeight,
    getAutoCalculateCategoryWeights,
    areWeightsAutoCalc
  ]);

  const getUsedCategoryWeights = useCallback(() => {
    const {
      reconciledWeights,
      defaultCategoriesWeight
    } = getDefaultCategoriesWeight();

    const categoryWeights = Object.keys(assessmentSettings?.categoriesWeight)
      .length
      ? reconciledWeights
      : defaultCategoriesWeight;

    return categoryWeights;
  }, [assessmentSettings.categoriesWeight, getDefaultCategoriesWeight]);

  const getCategoriesWeightSchema = useCallback(() => {
    const categoryWeights = getUsedCategoryWeights();
    return Object.keys(categoryWeights).map(key => {
      const catWeight = {
        category_id: parseInt(key),
        weight: categoryWeights[key].weight
      };
      // If adding a new category to the assessment, its weightId will be null
      // because anything new will have a null id cause its not created in db yet.
      // So we should check for that, and if its null don't define id in the object
      const weightId = parseInt(categoryWeights[key].catWeightId);
      if (isEditing && weightId) {
        catWeight.id = weightId;
      }
      return catWeight;
    });
  }, [isEditing, getUsedCategoryWeights]);

  const getDropdownFieldSchema = (isDropdown, configuration, isEditing) =>
    isDropdown &&
    configuration.fieldOptions.map(({ id = null, value }, idx) => ({
      ...(isEditing && id && { id }),
      sort_index: idx,
      content: [{ language_id: 1, text_display: value }]
    }));

  const getTakerFieldsSchema = useCallback(
    () =>
      assessmentData.fields
        .filter(({ isChecked, isDisabled }) => isChecked && !isDisabled)
        .map(({ id, configuration, itemName, isMandatory }) => {
          const isDropdown = !!configuration?.fieldOptions?.length;
          const takerFields = {
            ...(isEditing && { id }),
            field_type: isDropdown ? "taker_field_select" : "taker_field_text",
            // Language_id is currently supporting only 1 which is english
            content: [{ title: itemName, language_id: 1 }],
            is_required: isMandatory
          };
          if (isDropdown) {
            takerFields.options = getDropdownFieldSchema(
              isDropdown,
              configuration,
              isEditing
            );
          }
          return takerFields;
        }),
    [assessmentData.fields, isEditing]
  );

  const mapCategoriesWeight = useCallback(
    categoryWeights => {
      const getCategoryName = categoryId =>
        categories.find(({ id }) => id === categoryId)?.name;
      const weights = {};
      for (const categoryWeight of categoryWeights) {
        weights[categoryWeight.category_id] = {
          catWeightId: categoryWeight.id,
          id: `weight__${categoryWeight.id}__${
            categoryWeight.category_id
          }__${getCategoryName(categoryWeight.category_id)}`,
          name: getCategoryName(categoryWeight.category_id),
          weight: categoryWeight.weight
        };
      }
      return weights;
    },
    [categories]
  );

  const fillPoolsData = useCallback(
    poolsData => {
      const mappedPools = [];
      for (const poolDataKey of Object.keys(poolsData)) {
        const categoryId = parseInt(poolDataKey);
        const category = categories.find(({ id }) => id === categoryId);
        const questions = poolsData[poolDataKey];
        mappedPools.push({
          title: category?.name,
          questions
        });
      }
      setQuestionPools(mappedPools);
    },
    [categories]
  );

  const getAttachedPools = useCallback(() => {
    const attachedPools = [];

    for (const pool of questionPools) {
      if (
        !pool.title.startsWith("Untitled Bank") &&
        pool?.questions.length > 0
      ) {
        attachedPools.push({
          category_id: categories.find(({ name }) => name === pool.title).id,
          questions: pool.questions.map(({ id }) => id)
        });
      }
    }
    return attachedPools;
  }, [questionPools, categories]);

  const getTimeToExpirationInSeconds = useCallback(() => {
    return assessmentSettings.expirationUnit === EXPIRATION_UNITS.WEEKS
      ? parseInt(assessmentSettings.expirationNumber * ONE_WEEK_IN_SECONDS)
      : parseInt(assessmentSettings.expirationNumber * ONE_DAY_IN_SECONDS);
  }, [assessmentSettings]);

  const getTimeToExpiration = useCallback(() => {
    const expTimeData = {
      time_to_expiration: null,
      expires_on: null
    };
    if (assessmentSettings.expirationType === EXPIRATION_TYPES.DURATION) {
      expTimeData.time_to_expiration = getTimeToExpirationInSeconds();
    } else if (assessmentSettings.expirationType === EXPIRATION_TYPES.DATE) {
      const expDate = new Date(assessmentSettings.expirationDate);
      const hours = assessmentSettings.expirationHour.split(":")[0];
      expDate.setHours(hours);
      expDate.setMilliseconds(0, 0, 0);
      expTimeData.expires_on = expDate;
    } else if (
      assessmentSettings.expirationType === EXPIRATION_TYPES.AVAILABILITY
    ) {
      const openDate = new Date(assessmentSettings.openDate);
      const closeDate = new Date(assessmentSettings.closeDate);
      const openHour = assessmentSettings.openHour.split(":")[0];
      const closeHour = assessmentSettings.closeHour.split(":")[0];
      openDate.setHours(openHour);
      openDate.setMilliseconds(0, 0, 0);
      closeDate.setHours(closeHour);
      closeDate.setMilliseconds(0, 0, 0);
      expTimeData.expires_on = closeDate;
      assessmentSettings.openDate = openDate;
      assessmentSettings.closeDate = closeDate;
    }
    return expTimeData;
  }, [assessmentSettings, getTimeToExpirationInSeconds]);

  const handleGetAssessmentCreationSchema = (shouldRemoveAttempts = false) =>
    getAssessmentCreationSchema(
      assessmentData,
      assessmentSettings,
      getSectionsSchema,
      getTakerFieldsSchema,
      getCategoriesWeightSchema,
      getAttachedPools,
      getTimeToExpiration,
      tenantUser,
      isEditing,
      shouldRemoveAttempts
    );

  const createAssessmentRequest = useCallback(async () => {
    const createdAssessment = await createAssessmentMW({
      data: handleGetAssessmentCreationSchema()
    });
    return createdAssessment.data;
  }, [handleGetAssessmentCreationSchema]);

  const updateAssessmentRequest = useCallback(
    async (shouldRemoveAttempts = false, assessmentSchema = null) => {
      const assessmentSchemaWithoutTenantInfo =
        assessmentSchema ||
        handleGetAssessmentCreationSchema(shouldRemoveAttempts);

      delete assessmentSchemaWithoutTenantInfo.tenant_id;
      delete assessmentSchemaWithoutTenantInfo.tenant;
      const updateAssessment = await updateAssessmentMW({
        urlParams: [assessmentId],
        data: assessmentSchemaWithoutTenantInfo
      });

      if (updateAssessment) {
        requestHandlers.handleSuccess(true);
      } else {
        requestHandlers.handleError();
      }

      return updateAssessment;
    },
    [assessmentId, requestHandlers, getAssessmentCreationSchema]
  );

  const handleUpdateAssessment = useCallback(async () => {
    const assessmentSchema = handleGetAssessmentCreationSchema();
    const updateMetaData = await updateAssessmentMetadataMW({
      urlParams: [assessmentId],
      data: assessmentSchema
    });

    const { is_major_change: isMajorChange } = updateMetaData.data;
    const editingProps = {
      assessmentSchema,
      isMajorChange
    };

    setisMajorChange(isMajorChange);

    if (isMajorChange) {
      setIsSubmitting(false);
    } else {
      return updateAssessmentRequest(assessmentSchema);
    }

    return editingProps;
  }, [
    assessmentId,
    handleGetAssessmentCreationSchema,
    updateAssessmentRequest
  ]);

  const compareWeightingSchemas = (weighting1, weighting2) => {
    const schema1 = {};
    const schema2 = {};

    Object.keys(weighting1 || {}).map(
      catId => (schema1[catId] = parseInt(weighting1[catId].weight))
    );

    Object.keys(weighting2 || {}).map(
      catId => (schema2[catId] = parseInt(weighting2[catId].weight))
    );

    return equal(schema1, schema2);
  };

  const onSubmit = useCallback(() => {
    const createOrUpdateAssessment = async () => {
      setIsRequestBeingSent(true);
      try {
        let id = null;
        if (isEditing) {
          id = assessmentId;
          await handleUpdateAssessment();
        } else {
          ({ id } = await createAssessmentRequest());
        }
        await updateAssessmentConfigs(id);
        if (!isEditing) {
          requestHandlers.handleSuccess();
        }
      } catch (error) {
        console.error(error);
        requestHandlers.handleError();
      } finally {
        setIsRequestBeingSent(false);
      }
    };

    createOrUpdateAssessment();
  }, [
    updateAssessmentConfigs,
    createAssessmentRequest,
    handleUpdateAssessment,
    isEditing,
    assessmentId,
    requestHandlers
  ]);

  const validate = useCallback(async () => {
    const isValidform = await validateForm(
      assessmentData,
      assessmentSettings,
      sectionsData,
      setFormErrors,
      createdCards,
      isEditing,
      questionPools
    );
    if (isValidform) {
      onSubmit();
    } else {
      setConfirmCustomWeighting(false);
    }
  }, [
    assessmentData,
    assessmentSettings,
    createdCards,
    onSubmit,
    sectionsData,
    isEditing,
    questionPools
  ]);

  const checkCategoryWeights = useCallback(() => {
    const autoCalculatedWeights = getAutoCalculateCategoryWeights();
    const currentUsedWeights = getUsedCategoryWeights();
    const areWeightsDifferentFromAutoCalculation = compareWeightingSchemas(
      autoCalculatedWeights,
      currentUsedWeights
    );
    return [areWeightsDifferentFromAutoCalculation, currentUsedWeights];
  }, [getAutoCalculateCategoryWeights, getUsedCategoryWeights]);

  useEffect(() => {
    if (isEditing && fetchedWeighting && areWeightsAutoCalc === null) {
      let isAutoCalculation = compareWeightingSchemas(
        getAutoCalculateCategoryWeights(),
        fetchedWeighting
      );

      setAreWeightsAutoCalc(isAutoCalculation);
    }
  }, [
    fetchedWeighting,
    isEditing,
    getAutoCalculateCategoryWeights,
    areWeightsAutoCalc
  ]);

  useEffect(() => {
    if (assessmentSlug && categories && !assessmentData.id) {
      const fetchAssessmentData = async () => {
        setIsLoading(true);

        const assessment = await getAssessmentBySlugMW({
          urlParams: [assessmentSlug]
        });

        const pools = await getPoolsByAssessmentMW({
          urlParams: [assessmentSlug]
        });

        fillAssessmentData(
          assessment?.data,
          setAssessmentData,
          setAssessmentSettings,
          setFetchedWeighting,
          setSectionsData,
          setIsEditing,
          setAssessmentId,
          categories,
          mapCategoriesWeight,
          getSections,
          getExpirationData,
          ASSESSMENT_TYPES,
          getQuestionsListMW
        );

        fillPoolsData(pools.data || {});
        fetchWebhooks(assessment.data.tenant_id);

        setIsLoading(false);
      };

      fetchAssessmentData();
    }
  }, [
    assessmentSlug,
    categories,
    assessmentData.id,
    fillAssessmentData,
    fillPoolsData,
    getAssessmentBySlugMW,
    getPoolsByAssessmentMW,
    getSections,
    getExpirationData,
    mapCategoriesWeight,
    fetchWebhooks,
    setAssessmentData,
    setAssessmentSettings,
    setFetchedWeighting,
    setSectionsData,
    setIsEditing,
    setAssessmentId
  ]);

  useEffect(() => {
    if (isSubmitting) {
      if (isEditing) {
        const [
          areWeightsDifferentFromAutoCalculation,
          usedWeights
        ] = checkCategoryWeights();
        setConfirmCustomWeighting(areWeightsDifferentFromAutoCalculation);
        setCustomWeightingValues(usedWeights);
      } else {
        setConfirmCustomWeighting(true);
      }
    }
    setIsSubmitting(false);
  }, [isSubmitting, isEditing, checkCategoryWeights]);

  useEffect(() => {
    if (confirmCustomWeighting === true) {
      validate();
    }
  }, [confirmCustomWeighting]);

  useEffect(() => {
    assessmentData.title && !assessmentSlug && getSlug(assessmentData.title);
  }, [assessmentData.title, tenantName, assessmentSlug, getSlug]);

  useEffect(() => {
    if (isEditing) {
      setisMajorChange(false);
    }
  }, [assessmentData, sectionsData, assessmentSettings, isEditing]);

  const renderPopup = () =>
    requestError && (
      <PopupContainer>
        <PopupNotification
          content="Sorry, we were not able to create this assessment. Check your internet connection and try again"
          popupType="ERROR"
          title="Something went wrong"
          cleanHandler={() => setRequestError(false)}
        ></PopupNotification>
      </PopupContainer>
    );

  const renderMajorEditingModeModals = () =>
    showMajorEditingModal && (
      <Modal
        onClose={() => setShowMajorEditingModal(false)}
        title={MAJOR_EDITING_MODE[majorEditingMode].text}
      >
        <MajorEditingModal direction="column">
          {MAJOR_EDITING_MODE[majorEditingMode].message.map(piece => (
            <p key={piece}>{piece}</p>
          ))}
          <FlexContainer>
            <MajorEditingButton
              onClick={ev => {
                ev.preventDefault();
                updateAssessmentRequest(majorEditingMode === SAVE_AND_REPLACE);
              }}
            >
              Continue with change
            </MajorEditingButton>
            <MajorEditingButton
              className="cancel"
              onClick={() => setShowMajorEditingModal(false)}
            >
              Cancel
            </MajorEditingButton>
          </FlexContainer>
        </MajorEditingModal>
      </Modal>
    );

  const renderCategoriesWeightWarningModal = () => {
    const onClick = response => {
      setConfirmCustomWeighting(response);
      setCustomWeightingValues(null);
    };

    return (
      customWeightingValues &&
      !confirmCustomWeighting && (
        <CategoryWeightsWarningModal
          onClose={() => {
            onClick(false);
          }}
          onConfirm={() => {
            onClick(true);
          }}
          reconciledWeights={customWeightingValues}
        />
      )
    );
  };

  return isLoading ? (
    <AssessmentsLoader />
  ) : (
    <MainWrapper background="#f0f2f4">
      <LeaveRoutePrompt />
      <FormContext {...methods}>
        <FormContainer
          onKeyPress={ev => handleKeyPress(ev)}
          onSubmit={ev => {
            ev.preventDefault();
            setIsSubmitting(true);
          }}
          id={FORM_ID}
        >
          <Header
            onEditSettings={() => setShowEditSettingsModal(true)}
            onGoBack={() => history.push(`${paths.ADMIN}`)}
            setData={modifyAssessmentData}
            title={headerTitle}
            setIsSubmitting={() => setIsSubmitting(true)}
            isMajorChange={isMajorChange}
            setMajorEditingMode={setMajorEditingMode}
            setShowMajorEditingModal={setShowMajorEditingModal}
            isDisabled={isRequestBeingSent}
          />
          {renderPopup()}
          {renderMajorEditingModeModals()}
          <AssessmentCreationContainer>
            <AssessmentCreationSection background="transparent">
              {Object.keys(formErrors).length > 0 && (
                <FormErrors errors={formErrors} />
              )}
              <AssessmentCreationForm
                formData={formData}
                sectionsData={sectionsData}
                setSectionsData={setSectionsData}
                setSelectedOption={setSelectedOption}
                setCreatedCards={setCreatedCards}
                formErrors={formErrors}
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                isQuestionPoolEnabled={assessmentSettings.isQuestionPoolEnabled}
                questionPools={questionPools}
                handleAddQuestionPool={handleAddQuestionPool}
                handleUpdateQuestionPool={handleUpdateQuestionPool}
                handleRemoveQuestionPool={handleRemoveQuestionPool}
              />
            </AssessmentCreationSection>
            <AssessmentCreationSection background="white">
              <AssessmentPreview
                assessmentData={assessmentData}
                sectionsData={sectionsData}
                selectedOption={selectedOption}
                assessmentColorTheme={assessmentSettings.assessmentColorTheme}
              />
            </AssessmentCreationSection>
          </AssessmentCreationContainer>
        </FormContainer>
      </FormContext>
      {showEditSettingsModal && (
        <SettingsModal
          defaultSettings={{
            ...assessmentSettings,
            assessmentName: title
          }}
          onCancel={() => setShowEditSettingsModal(false)}
          onSave={onSaveSettings}
          categoriesWeightData={getDefaultCategoriesWeight()}
          isEditing={isEditing}
          isValidExpirationType={!formErrors?.EXPIRATION_TYPE}
          isValidSlug={!formErrors?.SETTINGS}
          availableWebhooksForTenant={availableWebhooksForTenant}
          setWasWeightsCustomized={wasWeightsCustomized =>
            setAreWeightsAutoCalc(!wasWeightsCustomized)
          }
        />
      )}
      {renderCategoriesWeightWarningModal()}
    </MainWrapper>
  );
};

export default AssessmentCreation;

AssessmentCreation.propTypes = {
  tenantInfo: PropTypes.object.isRequired
};

const MainWrapper = styled(PageWrapper)`
  display: flex;
`;

const AssessmentCreationContainer = styled(FlexContainer)`
  flex: 1;
  margin-top: 2px;
`;

const AssessmentCreationSection = styled(Container)`
  flex: 1;
  max-width: 50%;
  padding: 34px 104px 58px 112px;
`;

const PopupContainer = styled.section`
  left: 12px;
  position: absolute;
  top: 12px;
  z-index: 10;
`;

const MajorEditingModal = styled(FlexContainer)`
  text-align: left;
`;

const MajorEditingButton = styled(StyledButton)`
  margin-top: 12px;
  &:first-child {
    margin-right: 16px;
  }
  &.cancel {
    background: ${({ theme }) => theme.colors.invalidRed};
    color: ${({ theme }) => theme.colors.white};
  }
`;
