import styled from "styled-components";

const LatexWrapper = styled.span`
  display: block;
  /* stylelint-disable */
  .katex-html {
    .mord {
      position: relative;
      .mover,
      .munder {
        &.mover {
          .svg-align {
            height: 100%;
            position: absolute;
            width: 100%;
          }
          .vlist {
            position: static;
          }
          span.stretchy {
            left: -15%;
            top: 20px;
            transform: rotate(180deg);
          }
          + .msupsub {
            left: 0;
            position: absolute;
            text-align: center;
            top: -3em;
            width: 100%;
          }
        }
        span.stretchy {
          display: block;
          display: flex !important;
          height: fit-content !important;
          overflow: initial;
          padding: 0 5px;
          position: relative;
          right: -15%;
          top: -30px;
          [class^="brace"] {
            flex: 1;
            max-width: 100%;
            overflow: initial;
            position: relative;
            width: fit-content;
            &:before,
            &:after {
              content: "";
              display: block;
              height: 50px;
              position: absolute;
              width: 75%;
            }
            svg {
              display: none;
            }
            &[class$="left"] {
              left: -19%;
              &:before {
                border-bottom-left-radius: 10px;
                border-bottom: 5px solid;
                margin-top: -30px;
              }
              &:after {
                border-top-right-radius: 10px;
                border-top: 5px solid;
                margin-left: 30%;
                margin-top: 20px;
              }
            }
            &[class$="right"] {
              left: -16%;
              &:before {
                border-top-left-radius: 10px;
                border-top: 5px solid;
                margin-top: 20px;
              }
              &:after {
                border-bottom-right-radius: 10px;
                border-bottom: 5px solid;
                margin-left: 30%;
                margin-top: -30px;
              }
            }
            &[class$="center"] {
              display: none;
            }
          }
        }
      }
    }
  }
  /* stylelint-enable */
`;

export default LatexWrapper;
