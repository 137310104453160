import { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import jwt from "jsonwebtoken";
import { useUserContext } from "state/userContext/useUserContext";
import { permissionTypes } from "pages/App/helpers/permissionTypes";
import makeRequest from "utils/requests/makeRequest";

export const useAuth = () => {
  const {
    userState: { isAuthenticated, permissions, userData },
    userDispatch: { setAuthenticated, setRolesAndPermissions },
    isAnonymous
  } = useUserContext();
  const [authPending, setAuthPending] = useState(true);
  const uuid = window.location.pathname.split("/").pop();
  const publicUuid = new URLSearchParams(window.location.search).get(
    "public_uuid"
  );

  const onPubAuthSuccess = response => {
    const { access_token, refresh_token } = response.data;
    localStorage.setItem("access", access_token);
    localStorage.setItem("refresh", refresh_token);
  };

  const getPubAuth = async () => {
    let publicResponse = await makeRequest({
      endpoint: "publicAccess",
      data: { public_uuid: publicUuid, uuid }
    });
    onPubAuthSuccess(publicResponse);
  };

  useEffect(() => {
    // Need to make an async function so we can await getPubAuth properly
    (async () => {
      // Clear anonymous token incase accessing a different shareable report or
      // they navigate away from a shareable page
      let decodeAccess = jwt.decode(localStorage.getItem("access"));
      if (
        isAnonymous &&
        decodeAccess &&
        // eslint-disable-next-line no-prototype-builtins
        decodeAccess.hasOwnProperty("permissions")
      ) {
        // need to check token manually to avoid race conditions with setting state
        const isAnonToken = !decodeAccess.permissions
          .map(p => p.name)
          .includes(permissionTypes.CAN_READ_TENANT_USER);
        if (isAnonToken) {
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          setAuthenticated(false);
          Sentry.setUser(null);
        }
      }
      if (localStorage.getItem("access") === null && publicUuid !== null) {
        await getPubAuth();
      }

      decodeAccess = jwt.decode(localStorage.getItem("access"));
      const decodeRefresh = jwt.decode(localStorage.getItem("refresh"));
      const currentTime = new Date().getTime() / 1000;

      if (!decodeRefresh || !decodeAccess || decodeRefresh.exp < currentTime) {
        setAuthPending(false);
        localStorage.clear();
      } else {
        const { permissions } = decodeAccess;
        const rolesAndPermissions = {
          permissions: permissions?.map(p => p.name) || []
        };
        setRolesAndPermissions(rolesAndPermissions);
        setAuthenticated(true);
        setAuthPending(false);
      }
    })();
  }, []);
  return {
    isAuthenticated,
    authPending,
    permissions,
    tenantInfo: {
      name: userData?.tenant?.name || "",
      id: userData?.tenant_id || null,
      logo_url: userData?.tenant?.configuration?.logo_url || ""
    }
  };
};
