import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getAssessmentPreviewUuidMW } from "pages/PanelPage/middleware";
import { FlexContainer, PositionContainer } from "components/Atoms/Containers";
import { Body2 } from "components/Atoms/Text";
import Underline from "components/Atoms/Underline";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";
import eyeLineIcon from "assets/eyeLineIcon";

const PreviewAssessmentButton = ({ assessmentId }) => {
  const [linkModalShowing, setLinkModalShowing] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [previewLinkText, setPreviewLinkText] = useState("");
  const linkRef = useRef(null);
  const modalRef = useRef(null);
  const { REACT_APP_PR_NUMBER } = process.env;

  useClickedOutsideEvent(modalRef, () => setLinkModalShowing(false));

  const EPHEMERAL_HOSTNAME = "eph.panel.assessments.nprd.correlation-one.com";
  const ephemeralSlug =
    EPHEMERAL_HOSTNAME === window.location.hostname
      ? `/ephemeral-${REACT_APP_PR_NUMBER}/#`
      : "";

  const buildUrl = preview_uuid =>
    `${window.location.origin}${ephemeralSlug}/preview/${preview_uuid}`;

  const generatePreviewUrl = async () => {
    !linkModalShowing && setLinkModalShowing(true);

    if (!previewLinkText) {
      const assessmentPreviewUuid = await getAssessmentPreviewUuidMW({
        urlParams: [assessmentId]
      });

      setPreviewLinkText(buildUrl(assessmentPreviewUuid.data.preview_uuid));
    }
  };

  const copyLink = () => {
    linkRef.current.select();
    document.execCommand("copy");
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <PreviewIconContainer ref={modalRef} onClick={generatePreviewUrl}>
      {linkModalShowing && (
        <ModalContainer>
          <FlexContainer justify="space-between">
            <GetSharableLinkBody>
              Get shareable link of this test
            </GetSharableLinkBody>
            <div>
              <CopyText onClick={copyLink}>Copy Link</CopyText>
              {copySuccess && <SuccessContainer>Copied!</SuccessContainer>}
            </div>
          </FlexContainer>
          <LinkTextInput
            ref={linkRef}
            value={previewLinkText}
            readOnly
          ></LinkTextInput>
          <Underline />
        </ModalContainer>
      )}
      {eyeLineIcon}
    </PreviewIconContainer>
  );
};

export default PreviewAssessmentButton;

PreviewAssessmentButton.propTypes = {
  assessmentId: PropTypes.number.isRequired
};

const PreviewIconContainer = styled.div`
  cursor: pointer;
  margin-right: 8px;
`;

const ModalContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.mercury}`};
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);
  cursor: default;
  min-width: 480px;
  padding: 16px;
  right: 0;
  top: 0;
  z-index: 100;
`;

const GetSharableLinkBody = styled(Body2)`
  color: ${({ theme }) => theme.colors.smoky};
`;

const CopyText = styled(Body2)`
  color: ${({ theme }) => theme.tenantAccent};
  cursor: pointer;
  font-size: 12px;
`;

const SuccessContainer = styled(PositionContainer)`
  background: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 12px;
  padding: 7px 4px 4px;
  right: 0;
  z-index: 102;
`;

const LinkTextInput = styled.input`
  border: none;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 14px;
  letter-spacing: 0.4px;
  margin-top: 16px;
  outline: none;
  width: 100%;
`;
