import endpointNames from "utils/constants/endpointNames";
import makeRequest from "utils/requests/makeRequest";

export const loginMW = async loginData => {
  try {
    const loginRequest = await makeRequest({
      endpoint: endpointNames.login,
      data: loginData
    });
    return await loginRequest;
  } catch (err) {
    console.error(err);
  }
};
