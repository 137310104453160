import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import downArrowVIcon from "assets/downArrowVIcon";
import questionPoolItemIcon from "assets/questionPoolItemIcon";
import upArrowVIcon from "assets/upArrowVIcon";

const PreviewNavPoolsItem = ({
  categoryName,
  questions,
  isExpanded,
  onQuestionClick
}) => {
  const renderHeader = () => (
    <PoolItem align="center" justify="space-between" isExpanded={isExpanded}>
      <CategoryName>
        {categoryName} <QuestionCount>({questions.length})</QuestionCount>
      </CategoryName>
      {isExpanded ? upArrowVIcon : downArrowVIcon}
    </PoolItem>
  );

  const renderQuestions = () =>
    questions.map((_, index) => (
      <PoolItem
        key={index}
        align="center"
        onClick={e => onQuestionClick(e, index)}
      >
        {questionPoolItemIcon}
        <QuestionLabel>Question {index + 1}</QuestionLabel>
      </PoolItem>
    ));

  return (
    <>
      {renderHeader()}
      {isExpanded && renderQuestions()}
    </>
  );
};

export default PreviewNavPoolsItem;

PreviewNavPoolsItem.propTypes = {
  categoryName: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onQuestionClick: PropTypes.func.isRequired
};

const PoolItem = styled(FlexContainer)`
  background: ${({ isExpanded, theme }) =>
    isExpanded && theme.colors.whiteSmoke};
  cursor: pointer;
  padding: 8px 16px;
  &:hover {
    background: ${({ theme }) => theme.colors.whiteSmoke};
  }
`;

const CategoryName = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const QuestionCount = styled.span`
  color: #a09fa4;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
`;

const QuestionLabel = styled.span`
  color: ${({ theme }) => theme.colors.steel};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin-left: 10px;
`;
