import React from "react";
import { decode } from "html-entities";
import { renderToString } from "react-dom/server";
import styled from "styled-components";

const wrapWithWhiteSpace = html =>
  decode(renderToString(<WhiteSpaceWrapper>{html}</WhiteSpaceWrapper>));

export default wrapWithWhiteSpace;

const WhiteSpaceWrapper = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;
