import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import styled from "styled-components";
import { StyledButton } from "components/Atoms/Buttons";
import {
  PDF_DEFAULT_HEIGHT,
  PDF_DEFAULT_LEFT_MARGIN,
  PDF_DEFAULT_WIDTH
} from "./constants";

const PrintSectionPDF = ({ printId, pdfTopMargin, fileName }) => {
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const getImgData = async elementId => {
    const input = document.getElementById(elementId);
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    return imgData;
  };

  const printSpecificId = async () => {
    setIsLoadingPDF(true);
    try {
      const doc = new jsPDF("landscape", "px", "a4");
      const sectionSummaryTableIMG = await getImgData(printId);
      doc.addImage(
        sectionSummaryTableIMG,
        "JPEG",
        PDF_DEFAULT_LEFT_MARGIN,
        pdfTopMargin,
        PDF_DEFAULT_WIDTH,
        PDF_DEFAULT_HEIGHT,
        printId,
        "SLOW"
      );
      doc.save(`${fileName}.pdf`);
      setIsLoadingPDF(false);
    } catch (e) {
      console.error(e);
      setIsLoadingPDF(false);
    }
  };

  return (
    <Button onClick={() => printSpecificId()} disabled={isLoadingPDF}>
      DOWNLOAD PDF
    </Button>
  );
};

export const getSectionSummaryId = section => {
  if (section.id && section.name) {
    return `${section.id}-${section.name.replace(" ", "_")}-summary`;
  }
  throw new Error("Coudln't get the section summary id.");
};

const Button = styled(StyledButton)`
  align-items: center;
  background: ${({ theme }) => theme.tenantAccent};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 40px;
  justify-content: center;
  width: 212px;
`;
export default PrintSectionPDF;
