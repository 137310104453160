import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const LoadingIndicator = ({ variant }) => {
  return (
    <LDSRing variant={variant}>
      <LDSRingDiv variant={variant}></LDSRingDiv>
      <LDSRingDiv variant={variant}></LDSRingDiv>
      <LDSRingDiv variant={variant}></LDSRingDiv>
    </LDSRing>
  );
};

LoadingIndicator.propTypes = {
  variant: PropTypes.oneOf(["md", "sm"])
};
LoadingIndicator.defaultProps = {
  variant: "md"
};

export default LoadingIndicator;

const LDSRing = styled.div`
  box-sizing: border-box;
  display: block;
  margin: auto;
  ${({ variant }) =>
    variant === "sm" &&
    css`
      height: 20px;
      width: 20px;
    `}
  ${({ variant }) =>
    variant === "md" &&
    css`
      height: 80px;
      width: 80px;
    `};
`;

const LDSRingDiv = styled.div`
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 8px solid currentColor;
  border-color: currentColor transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${({ variant }) =>
    variant === "md" &&
    css`
      height: 64px;
      margin: 8px;
      width: 64px;
    `}
  ${({ variant }) =>
    variant === "sm" &&
    css`
      height: 16px;
      margin: 2px;
      width: 16px;
    `}
`;
