import React from "react";

const checkIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-108 -46)">
        <g transform="translate(108 46)">
          <path d="M0 0H12V12H0z"></path>
          <g stroke="#939393" strokeLinejoin="round" transform="translate(1 1)">
            <path d="M5 10a4.984 4.984 0 003.536-1.464A4.984 4.984 0 0010 5a4.984 4.984 0 00-1.464-3.535A4.984 4.984 0 005 0a4.985 4.985 0 00-3.535 1.465A4.985 4.985 0 000 5a4.984 4.984 0 001.465 3.536A4.984 4.984 0 005 10h0z"></path>
            <path
              strokeLinecap="round"
              d="M3.00000419 5.00000419L4.50000419 6.50000419 7.50000419 3.50000419"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default checkIcon;
