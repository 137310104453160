import React, { useState, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";
import styled from "styled-components";
import { useThemeContext } from "state/themeContext/useThemeContext";
import { FlexContainer } from "components/Atoms/Containers";
import { Body1, Body3 } from "components/Atoms/Text";

const TakerSummaryLine = ({ ticks }) => {
  const theme = useThemeContext();
  const [hightlightValue, setHightlightValue] = useState(
    ticks[ticks.length - 1]
  );
  const data = [
    {
      id: 1,
      color: theme.colors.mantisGreen,
      data: ticks.map((tick, index) => {
        return { x: index + 1, y: tick };
      })
    }
  ];

  const MyResponsiveLine = () => (
    <ResponsiveLine
      axisBottom={null}
      axisLeft={null}
      axisRight={{
        tickSize: 0,
        tickPadding: 16,
        tickValues: 7,
        format: formatValue
      }}
      colors={{ datum: "color" }}
      enableGridX={false}
      enableSlices="x"
      data={data}
      sliceTooltip={() => <></>}
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        "crosshair",
        "lines",
        "points",
        "slices",
        "mesh",
        "legends",
        ({ currentSlice }) => (
          <CustomInterceptionLayer
            setHightlightValue={setHightlightValue}
            currentSlice={currentSlice}
          />
        )
      ]}
      margin={{ top: 14, right: 100, bottom: 17, left: 30 }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto"
      }}
      crosshairType="x"
      pointSize={0}
    />
  );

  const formatValue = tickValue => {
    return tickValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  };

  const renderAllTimeChange = () => {
    const allTimeChange = ticks[ticks.length - 1] - 1000;

    return (
      <Body1 color="#6a7f87">
        <AllTimeChange>
          {allTimeChange >= 0 && "+"}
          {formatValue(allTimeChange)}
        </AllTimeChange>{" "}
        All Time
      </Body1>
    );
  };

  const CustomInterceptionLayer = ({ setHightlightValue, currentSlice }) => {
    useEffect(() => {
      if (currentSlice) {
        setHightlightValue(currentSlice.points[0].data.yFormatted);
      } else {
        setHightlightValue(ticks[ticks.length - 1]);
      }
    }, [currentSlice, setHightlightValue]);

    return null;
  };

  return (
    <>
      <FlexContainer margin="14px 0 0 30px" direction="column">
        <Balance padding="0 0 6px 0">{formatValue(hightlightValue)}</Balance>
        {renderAllTimeChange()}
      </FlexContainer>
      <FlexContainer height="171px" width="100%" direction="column">
        {MyResponsiveLine()}
      </FlexContainer>
    </>
  );
};

export default TakerSummaryLine;

const Balance = styled(Body3)`
  font-size: 16px;
`;

const AllTimeChange = styled.span`
  color: black;
  margin-top: 2px;
`;
