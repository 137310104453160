import React from "react";
import { Svg } from "components/Blocks/Styled/Media";

const eyeIcon = (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="11"
    viewBox="0 0 16 11"
    dynamic
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="currentColor"
        fillRule="nonzero"
        transform="translate(-1239 -20)"
      >
        <g transform="translate(1208 9)">
          <g transform="translate(0 8)">
            <g transform="translate(31 3)">
              <path d="M8 3.234c-1.22 0-2.213.992-2.213 2.213 0 1.22.992 2.213 2.213 2.213 1.22 0 2.213-.992 2.213-2.213 0-1.22-.992-2.213-2.213-2.213z"></path>
              <path d="M8 0a8.601 8.601 0 00-8 5.447 8.597 8.597 0 0016 0A8.597 8.597 0 008 0zm0 9.078a3.635 3.635 0 01-3.636-3.631A3.635 3.635 0 018 1.816a3.635 3.635 0 013.636 3.63A3.635 3.635 0 018 9.079z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default eyeIcon;
