import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { getUserDataMW } from "middleware";
import { useUserContext } from "state/userContext/useUserContext";
import { permissionTypes } from "pages/App/helpers/permissionTypes";

const TenantInfo = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    userState: { userData },
    userDispatch
  } = useUserContext();
  const {
    userState: { permissions }
  } = useUserContext();

  const fetchUserData = async () => {
    const userData = await getUserDataMW();
    userDispatch.setUserData(userData.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (permissions.includes(permissionTypes.CAN_READ_TENANT_USER)) {
      fetchUserData();
    } else {
      setIsLoading(false);
    }
  }, []);

  const updateTheme = theme => ({
    ...theme,
    tenantAccent:
      userData?.tenant?.configuration?.default_theme_color || theme.tenantAccent
  });

  return (
    !isLoading && <ThemeProvider theme={updateTheme}>{children}</ThemeProvider>
  );
};

export default TenantInfo;
