import React from "react";

const borderlessCheckIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 9 8"
    fill="none"
  >
    <path
      d="M6.96991 0.969986C7.11078 0.835875 7.29824 0.761733 7.49273 0.763205C7.68722 0.764677 7.87354 0.841647 8.01236 0.977874C8.15118 1.1141 8.23165 1.29893 8.23679 1.49336C8.24194 1.68779 8.17134 1.87661 8.03991 2.01999L4.04991 7.00999C3.9813 7.08389 3.8985 7.14319 3.80645 7.18435C3.71439 7.22552 3.61498 7.2477 3.51416 7.24956C3.41334 7.25143 3.31318 7.23295 3.21967 7.19522C3.12616 7.15749 3.04121 7.10129 2.96991 7.02999L0.323913 4.38399C0.250227 4.31532 0.191125 4.23252 0.150133 4.14052C0.109141 4.04853 0.0870986 3.94921 0.0853218 3.84851C0.083545 3.74781 0.10207 3.64778 0.139791 3.55439C0.177512 3.461 0.233657 3.37617 0.304875 3.30495C0.376094 3.23373 0.460928 3.17758 0.554316 3.13986C0.647704 3.10214 0.747733 3.08362 0.848436 3.08539C0.949139 3.08717 1.04845 3.10921 1.14045 3.15021C1.23245 3.1912 1.31525 3.2503 1.38391 3.32399L3.47791 5.41699L6.95091 0.991987C6.95717 0.984288 6.96384 0.976943 6.97091 0.969986H6.96991Z"
      fill="white"
    />
  </svg>
);

export default borderlessCheckIcon;
