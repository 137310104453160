import React, { useState } from "react";
import jwt from "jsonwebtoken";
import { useForm, FormContext } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { useUserContext } from "state/userContext/useUserContext";
import { paths } from "pages/App/helpers/paths";
import { loginMW } from "pages/AuthPage/components/Login/middleware";
import {
  AuthFormTitle,
  SubmitBtnContainer
} from "pages/AuthPage/styled/StyledComponents";
import { validateEmail } from "pages/PanelPage/helpers/validateEmail";
import { FormContainer } from "components/Atoms/Containers";
import Button from "components/Blocks/Forms/Button";
import Input from "components/Blocks/Forms/Input";
import { ErrorMessage } from "components/Blocks/Styled/Forms";

export const validationSchema = yup.object().shape({
  email: yup
    .string()
    .strict(false)
    .trim()
    .required("Email is required")
    .test(
      "Valid Email",
      "Please enter a valid email",
      value => value && validateEmail(value)
    ),
  password: yup
    .string()
    .strict(false)
    .trim()
    .required("Password is required")
});

const Login = () => {
  const methods = useForm({
    validationSchema
  });
  const history = useHistory();
  const {
    userDispatch: { setAuthenticated, setRolesAndPermissions }
  } = useUserContext();
  const [error, setError] = useState(null);
  const loginFailure = "We were unable to log you in";

  // On successful login
  const onSuccess = response => {
    const { access_token, refresh_token } = response.data;
    try {
      const { permissions } = jwt.decode(access_token);
      setError(null);
      localStorage.setItem("access", access_token);
      localStorage.setItem("refresh", refresh_token);
      const rolesAndPermissions = {
        permissions: permissions?.map(p => p.name) || []
      };
      setRolesAndPermissions(rolesAndPermissions);
      setAuthenticated(true);
      history.push(paths.ADMIN);
    } catch {
      setError(loginFailure);
    }
  };

  // On form submit
  const onSubmit = async data => {
    const loginData = { ...data, email: data.email.toLowerCase() };
    try {
      const loginRequest = await loginMW(loginData);
      onSuccess(loginRequest);
    } catch ({ response }) {
      setError(response?.data?.message.split(".")[0] || loginFailure);
    }
  };

  return (
    <FormContext {...methods}>
      <FormContainer
        onSubmit={methods.handleSubmit(onSubmit)}
        onFocus={() => setError(null)}
        id="login-form"
      >
        <AuthFormTitle>Log into your account</AuthFormTitle>
        <Input
          type="text"
          label="Email"
          name="email"
          id="login-email"
          isDarkTheme
        />
        <PasswordLink to={paths.REQUEST_RESET_PASSWORD} tabIndex="-1">
          Forgot password
        </PasswordLink>
        <Input
          type="password"
          label="Password"
          name="password"
          id="login-password"
          isDarkTheme
        />
        <ErrorMessage isHidden={!error}>{error}</ErrorMessage>
        <SubmitBtnContainer>
          <Button isFullWidth text="SIGN IN" type="submit" />
        </SubmitBtnContainer>
      </FormContainer>
    </FormContext>
  );
};

export default Login;

const PasswordLink = styled(Link)`
  color: rgb(255, 255, 255, 0.5);
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  margin: 24px 0 0 auto;
  padding-top: 2px;
  text-decoration: none;
  width: 97px;
  z-index: 100;
`;
