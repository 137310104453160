import React from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";
import { Body1 } from "components/Atoms/Text";
import OptionTooltip from "components/Blocks/OptionTooltip";
import { Row, Cell } from "components/Blocks/Styled/Tables";
import rightArrowIcon from "assets/rightArrowIcon";

const AssessmentTaker = ({ takerData, uuid }) => {
  const history = useHistory();

  const renderCellWithTooltip = (value, width, maxWidth, tooltip, index) => {
    return (
      <StyledCell maxWidth={maxWidth} key={index}>
        <StyledTooltip content={tooltip} align="center" width="auto">
          <CellText maxWidth={width}>{value}</CellText>
        </StyledTooltip>
      </StyledCell>
    );
  };

  const renderTakerCells = () => {
    return Object.values(takerData).map((datum, index) =>
      datum.tooltipValue ? (
        renderCellWithTooltip(
          datum.value,
          datum.width,
          datum.maxWidth,
          datum.tooltipValue,
          index
        )
      ) : (
        <StyledCell maxWidth={datum.maxWidth} key={index}>
          <CellText maxWidth={datum.width}>{datum.value}</CellText>
        </StyledCell>
      )
    );
  };

  return (
    <TakerRow>
      {renderTakerCells()}
      <Cell textAlign="center">
        <Body1 className="pointer">
          <Link
            style={{ textDecoration: "none" }}
            to={`${history.location.pathname}/${uuid}`}
          >
            {rightArrowIcon}
          </Link>
        </Body1>
      </Cell>
    </TakerRow>
  );
};

AssessmentTaker.propTypes = {
  takerData: PropTypes.array.isRequired,
  uuid: PropTypes.string.isRequired
};

export default AssessmentTaker;

const StyledCell = styled(Cell)`
  max-width: ${props => props.maxWidth};
`;

const StyledTooltip = styled(OptionTooltip)`
  > div {
    padding: 12px 16px 8px 16px;
    width: auto;
  }
`;

const TakerRow = styled(Row)`
  border-bottom: 1px solid #e0e0e0;
  height: 48px;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const CellText = styled(Body1)`
  color: ${({ theme }) => theme.colors.darkBlue};
  max-width: ${props => props.maxWidth};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
