import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useAssessmentContext } from "state/assessmentContext/useAssessmentContext";
import { useUserContext } from "state/userContext/useUserContext";
import { paths } from "pages/App/helpers/paths";
import { ATTEMPT_STATES } from "pages/ManagementPage/Constants";
import { takerFieldTypes, fullNameField } from "pages/PanelPage/Constants";
import DistributionTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/DistributionTable";
import CandidateDetails from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/CandidateDetails";
import OverallPortfolioTimeline from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/OverallPortfolioTimeline";
import OverallSectionSummaryTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/OverallSectionSummaryTable";
import OverallSkillsBreakdownTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/OverallSkillsBreakdownTable";
import PrintSectionAsPDF, {
  getSectionSummaryId
} from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/PrintSectionAsPDF";
import SectionSummaryTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/SectionSummaryTable";
import SkillsBreakdownTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/SkillsBreakdownTable";
import TakerAtAGlance from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/TakerAtAGlance";
import QuestionsTable from "pages/PanelPage/components/selectedAssessment/assessmentResults/takerResults/questionsTable/QuestionsTable";
import {
  fetchHistogramDataMW,
  getAssessmentAttemptMW
} from "pages/PanelPage/middleware";
import { Separator } from "pages/PanelPage/styles/StyledComponents/StyledComponents";
import { FlexContainer } from "components/Atoms/Containers";
import { Body1 } from "components/Atoms/Text";
import Loader from "components/Systems/Loader";
import leftArrowIcon from "assets/leftArrowIcon";

const TakerResults = ({ chartData, setChartData }) => {
  const history = useHistory();
  const currentUuid = history.location.pathname.split("/").pop();
  const { isAnonymous } = useUserContext();
  const {
    assessmentState: {
      selectedAssessment: { sections, taker_fields }
    }
  } = useAssessmentContext();
  let [takerData, setTakerData] = useState();
  let [isLoading, setIsLoading] = useState(true);
  let { assessment } = useParams();

  useEffect(() => {
    const fetchChartData = async () => {
      const {
        data: { chart_data: chartData }
      } = await fetchHistogramDataMW({
        queryParams: {
          slug: assessment,
          attempt_state: ATTEMPT_STATES.COMPLETE
        }
      });
      setChartData(chartData);
    };
    fetchChartData();
  }, []);

  useEffect(() => {
    const fetchAssessmentAttempt = async () => {
      const takerData = await getAssessmentAttemptMW({
        urlParams: [currentUuid]
      });
      setTakerData(takerData.data);
      setIsLoading(false);
    };
    fetchAssessmentAttempt();
  }, [currentUuid]);

  const renderOverallResults = () => {
    return (
      sections.length > 1 && (
        <FlexContainer
          margin="20px 0 0"
          justify="space-between"
          id="OverallResultsIMG"
        >
          {takerData?.section_attempts && (
            <FlexContainer direction="column">
              <FlexContainer direction="row-reverse" margin="0 0 20px">
                <PrintSectionAsPDF
                  fileName={`${takerData.taker.email}-${takerData.uuid}`}
                  printId="summaryId"
                  pdfTopMargin={40}
                />
              </FlexContainer>
              <FlexContainer direction="row" id="summaryId">
                <OverallSectionSummaryTable
                  sectionAttempts={takerData.section_attempts}
                />
                <OverallSkillsBreakdownTable
                  sectionAttempts={takerData.section_attempts}
                />
              </FlexContainer>
            </FlexContainer>
          )}
        </FlexContainer>
      )
    );
  };

  const renderSectionResults = () =>
    takerData?.section_attempts?.map(renderSection);

  const renderSection = section => {
    const printId = getSectionSummaryId(section);
    return (
      <FlexContainer direction="column" key={section.id}>
        {sections.length === 1 && (
          <FlexContainer direction="row-reverse" margin="0 0 -90px">
            <PrintSectionAsPDF
              fileName={`${takerData.taker.email}-${takerData.uuid}`}
              printId={printId}
              pdfTopMargin={-10}
            />
          </FlexContainer>
        )}
        <div id={printId}>
          <SectionSummaryTable section={section} />
          <SkillsBreakdownTable categoryScores={section.category_scores} />
        </div>
        {section.section_type !== "section_stock" && (
          <QuestionsTable slotAttempts={section.slot_attempts} />
        )}
      </FlexContainer>
    );
  };

  // Redirect back to all attempts if the path does not include an existing taker uuid
  if (takerData === "invalid") {
    return <Redirect to={`${paths.ADMIN}/${assessment}`} />;
  }

  const handlePortfolioTimeline = () => {
    if (
      takerData.section_attempts.some(x => x.section_type === "section_stock")
    ) {
      return <OverallPortfolioTimeline currentUuid={currentUuid} />;
    }
  };

  const combinedData = new Map();

  const getCandidateDetailsArray = () => {
    taker_fields.forEach(function(takerField) {
      combinedData.set(takerField.id, takerField);
    });

    return takerData?.taker_field_responses
      .filter(candidateDetails => {
        // this handles cases where we have a response, but no associated field
        // for example, when a field existed and is later deleted or edited
        const takerField = combinedData.get(candidateDetails.taker_field_id);

        return takerField;
      })
      .map(function(response) {
        const takerField = combinedData.get(response.taker_field_id);

        if (takerField?.field_type === takerFieldTypes.SELECT) {
          response.options.forEach(option => {
            takerField.options.forEach(takerFieldOption => {
              if (option.id === takerFieldOption.id) {
                takerField.result = takerFieldOption.content[0].text_display;
              }
            });
          });
        } else if (takerField?.field_type === takerFieldTypes.TEXT) {
          takerField.result = response.result;
        }

        return takerField;
      });
  };

  const candidateDetailsArray = getCandidateDetailsArray();

  const filterForName = () =>
    candidateDetailsArray?.filter(
      candidateDetails =>
        candidateDetails.content[0].title === fullNameField &&
        candidateDetails.result
    );

  const nameData = filterForName();

  const filterCandidateDetailsArray = () =>
    candidateDetailsArray?.filter(
      candidateDetails =>
        !(
          candidateDetails.content[0].title === fullNameField ||
          !candidateDetails.result
        )
    );

  const filteredData = filterCandidateDetailsArray();

  const renderTakerFields = () => {
    return (
      <>
        <FlexContainer>
          <CandidateDetails
            candidateDetailsData={filteredData}
          ></CandidateDetails>
        </FlexContainer>
        <Separator />
      </>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {takerData && (
        <FlexContainer direction="column">
          {!isAnonymous && (
            <FlexContainer
              margin="0 0 32px"
              className="pointer"
              onClick={() => history.goBack()}
              width="fit-content"
              align="center"
            >
              {leftArrowIcon}
              <Body1 margin="3px 0 0" textTransform="capitalize">
                back
              </Body1>
            </FlexContainer>
          )}
          <TakerAtAGlance takerData={takerData} candidateName={nameData} />
          <Separator />
          {renderTakerFields()}
          <DistributionTable
            attemptScore={takerData.weighted_score}
            chartData={chartData}
          />
          <Separator />
          {handlePortfolioTimeline()}
          {renderOverallResults()}
          {renderSectionResults()}
        </FlexContainer>
      )}
    </>
  );
};

TakerResults.propTypes = {
  chartData: PropTypes.object.isRequired,
  setChartData: PropTypes.func.isRequired
};

export default TakerResults;
