import endpointNames from "utils/constants/endpointNames";
import makeRequest from "utils/requests/makeRequest";

export const getAssessmentsCountMW = async ({ queryParams }) => {
  try {
    return await makeRequest({
      endpoint: endpointNames.getAssessmentsCount,
      queryParams
    });
  } catch (err) {
    console.error(err);
  }
};

export const getTenantsMW = async () => {
  try {
    const response = await makeRequest({
      endpoint: endpointNames.getTenants
    });
    return response.data.map(tenant => ({
      id: tenant.id,
      value: tenant.name
    }));
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentsMW = async ({ queryParams }) => {
  try {
    return await makeRequest({
      endpoint: endpointNames.getAssessments,
      queryParams
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentBySlugMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentBySlug,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentCategoryStatisticsMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentCategoryStatistics,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentPreviewUuidMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentPreviewUuid,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const getInviteTemplateMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentTemplate,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const saveDefaultTemplateMW = async ({ urlParams, data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.createAssessmentTemplate,
      urlParams,
      data
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const sendInvitationsMW = async ({ urlParams, data }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.createInvitations,
      urlParams,
      data
    });
    if (result?.status === 200) {
      return result;
    }
  } catch (err) {
    console.error(err);
  }
};

export const fetchAssessmentAttemptsMW = async ({ queryParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getAssessmentAttempts,
      queryParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const fetchHistogramDataMW = async ({ queryParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getHistogramData,
      queryParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentAttemptCsvMW = async ({ queryParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getAssessmentAttemptsCsv,
      queryParams,
      isFileDownload: true
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};
export const getDetailedAssessmentAttemptCsvMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getDetailedAssessmentAttemptsCsv,
      urlParams,
      isFileDownload: true
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const getDetailedWarningMessageTrackingCsvMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getDetailedAssessmentWarningMessageTrackingtCsv,
      urlParams,
      isFileDownload: true
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};
export const getTenantSummaryCsvMW = async ({ urlParams }) => {
  try {
    const response = await makeRequest({
      endpoint: endpointNames.getTenantSummaryCsv,
      urlParams,
      isFileDownload: true
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const createAssessmentAttemptLinkMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.createAssessmentAttemptLink,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentAttemptStockStatisticsMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getAssessmentAttemptStockStatistics,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentAttemptMW = async ({ urlParams }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getAssessmentAttempt,
      urlParams
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const updateAssessmentStateMW = async ({ urlParams, data }) => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.updateAssessmentState,
      urlParams,
      data
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};

export const getCategoriesMW = async () => {
  try {
    const request = await makeRequest({
      endpoint: endpointNames.getCategories
    });
    return request;
  } catch (err) {
    console.error(err);
  }
};
