import React from "react";
import styled from "styled-components";
import uploadIcon from "assets/uploadIcon";


const FileUpload = ({
  label,
}) => {
  return (
    <FileUploadContainer>
      <InputLabel>{label}</InputLabel>
      <DragDropText>
        Drag and drop your files here, or click to select files
      </DragDropText>
      {uploadIcon}
      <UploadInput
        type="file"
        title=""
        value=""
      />
    </FileUploadContainer>
  );
};

export default FileUpload;

const FileUploadContainer = styled.section`
  align-items: center;
  background-color: white;
  border: 2px dotted lightgray;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin: 25px 0 15px;
  padding: 35px 20px;
  position: relative;
`;

const UploadInput = styled.input`
  border: none;
  bottom: 0;
  display: block;
  font-size: 18px;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-transform: none;
  top: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const InputLabel = styled.label`
  color: black;
  font-size: 13px;
  left: 0;
  position: absolute;
  top: -21px;
`;

const DragDropText = styled.p`
  font-family: AvenirLTPro-Roman;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
`;
