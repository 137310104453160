import { getQuestions } from "pages/ManagementPage/AssessmentCreation/utils/questionUtils";
import { EACH_QUESTION, WHOLE_SECTION } from "pages/ManagementPage/Constants";

export const getSections = (questions, sections) => {
  return sections.map(
    (
      {
        id,
        title,
        is_timed,
        time_allowed_seconds,
        description,
        is_linear,
        slots,
        section_type
      },
      idx
    ) => {
      return {
        sectionModelId: id,
        sectionId: idx,
        sectionTitle: title,
        sectionDuration: is_timed ? WHOLE_SECTION : EACH_QUESTION,
        sectionSeconds: time_allowed_seconds,
        sectionDescription: description,
        sectionIsLinear: is_linear,
        sectionType: section_type,
        questions: getQuestions(slots, questions)
      };
    }
  );
};
