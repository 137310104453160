const endpoints = Object.freeze({
  login: {
    endpoint: "authentication/login",
    method: "post"
  },
  publicAccess: {
    endpoint: "authentication/public_access",
    method: "post"
  },
  getAssessments: {
    endpoint: "assessment",
    method: "get"
  },
  getAssessmentBySlug: slug => ({
    endpoint: `assessment/slug/${slug}`,
    method: "get"
  }),
  getAssessmentPreviewData: previewUuid => ({
    endpoint: `assessment/preview/${previewUuid}`,
    method: "get"
  }),
  getPoolsByAssessment: slug => ({
    endpoint: `assessment/slug/${slug}/pools`,
    method: "get"
  }),
  getTenantSummaryCsv: tenantId => ({
    endpoint: `assessment/tenant-summary?assessment_states=active&tenant_id=${tenantId}`,
    method: "get"
  }),
  getQuestionList: {
    endpoint: "question",
    method: "get"
  },
  createAssessment: {
    endpoint: "assessment",
    method: "post"
  },
  uploadLogo: {
    endpoint: "configuration/logo",
    method: "post"
  },
  updateAssessmentConfig: {
    endpoint: "configuration/update_assessment_config",
    method: "put"
  },
  updateAssessment: assessmentId => ({
    endpoint: `assessment/${assessmentId}`,
    method: "put"
  }),
  updateAssessmentMetadata: assessmentId => ({
    endpoint: `assessment/${assessmentId}/update-metadata`,
    method: "post"
  }),
  checkAssessmentBySlug: slug => ({
    endpoint: `proctoring/${slug}`,
    method: "get"
  }),
  getAssessmentsCount: {
    endpoint: "assessment/count",
    method: "get"
  },
  getTenants: {
    endpoint: "identity/tenant",
    method: "get"
  },
  getUserData: {
    endpoint: "identity/tenant-user",
    method: "get"
  },
  requestResetPassword: {
    endpoint: "identity/tenant-user/password/request-reset",
    method: "post"
  },
  resetPassword: {
    endpoint: "identity/tenant-user/password/reset",
    method: "post"
  },
  getAssessmentCategoryStatistics: assessmentId => ({
    endpoint: `assessment/${assessmentId}/statistics/category`,
    method: "get"
  }),
  getAssessmentPreviewUuid: assessmentId => ({
    endpoint: `assessment/${assessmentId}/get-preview`,
    method: "get"
  }),
  getAssessmentTemplate: slug => ({
    endpoint: `template/${slug}`,
    method: "get"
  }),
  createAssessmentTemplate: slug => ({
    endpoint: `template/${slug}`,
    method: "post"
  }),
  createInvitations: slug => ({
    endpoint: `proctoring/invite/${slug}`,
    method: "post"
  }),
  getQuestionGroupList: params => ({
    endpoint: "question/group",
    method: "get",
    params
  }),
  getAssessmentsByQuestion: params => ({
    endpoint: "question-additional-information/used-questions",
    method: "get",
    params
  }),
  updateQuestion: questionId => ({
    endpoint: `question/${questionId}`,
    method: "put"
  }),
  getSingleQuestionData: questionId => ({
    endpoint: `question/${questionId}`,
    method: "get"
  }),
  getSingleQuestionGroupData: groupId => ({
    endpoint: `question/group/${groupId}`,
    method: "get"
  }),
  getAssessmentAttempts: params => ({
    endpoint: "reporting/attempt",
    method: "get",
    params
  }),
  getHistogramData: params => ({
    endpoint: "reporting/attempt/histogram",
    method: "get",
    params
  }),
  getAssessmentAttemptsCsv: params => ({
    endpoint: "reporting/attempt/csv",
    method: "get",
    params
  }),
  getDetailedAssessmentAttemptsCsv: uuid => ({
    endpoint: `reporting/assessment/${uuid}/all-questions-and-answers-csv`,
    method: "get"
  }),

  getDetailedAssessmentWarningMessageTrackingtCsv: uuid => ({
    endpoint: `reporting/assessment/${uuid}/warning-message-tracking-csv`,
    method: "get"
  }),
  createAssessmentAttemptLink: uuid => ({
    endpoint: `reporting/attempt/${uuid}/link`,
    method: "post"
  }),
  getAssessmentAttemptStockStatistics: uuid => ({
    endpoint: `reporting/attempt/${uuid}/statistics/stock`,
    method: "get"
  }),
  getAssessmentAttempt: uuid => ({
    endpoint: `reporting/attempt/${uuid}`,
    method: "get"
  }),
  getQuestionInformation: questionId => ({
    endpoint: `question-additional-information/${questionId}`,
    method: "get"
  }),
  getTenantWebhooks: tenantId => ({
    endpoint: `webhook?tenant_id=${tenantId}`,
    method: "get"
  }),
  createQuestion: {
    endpoint: "question",
    method: "post"
  },
  deleteFile: (questionId, questionFileId) => ({
    endpoint: `question/${questionId}/file/${questionFileId}`,
    method: "delete"
  }),
  saveFile: questionId => ({
    endpoint: `question/${questionId}/file`,
    method: "put"
  }),
  updateAssessmentState: assessmentId => ({
    endpoint: `assessment/${assessmentId}/update-state`,
    method: "patch"
  }),
  getCategories: {
    endpoint: "category",
    method: "get"
  }
});

export default endpoints;
