import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useThemeContext } from "state/themeContext/useThemeContext";
import { FlexContainer } from "components/Atoms/Containers";
import { Body1 } from "components/Atoms/Text";

const DistributionBar = ({ attemptScore, chartData }) => {
  const [initialScores, setInitialScores] = useState([]);
  const [scores, setScores] = useState([]);

  const theme = useThemeContext();

  useEffect(() => {
    const interpretedChartData = chartData
      ? Object.entries(chartData).map(datum => {
          return {
            score: datum[0],
            numberOfTakers: datum[1],
            global: 0
          };
        })
      : [];

    setScores(interpretedChartData);
    setInitialScores(interpretedChartData);
  }, [chartData]);

  useEffect(() => {
    if (initialScores.length) {
      const filteredChartData = Object.entries(chartData).map(datum => {
        return {
          score: datum[0],
          withinFilterParams: datum[1]
        };
      });

      for (let i = 0; i < filteredChartData.length; i++) {
        if (
          filteredChartData[i].withinFilterParams <
          initialScores[i].numberOfTakers
        ) {
          filteredChartData[i].numberOfTakers =
            initialScores[i].numberOfTakers -
            filteredChartData[i].withinFilterParams;
        }
      }

      setScores(filteredChartData);
    }
  }, [chartData, initialScores]);

  const handleBarColor = data => {
    /*
    NOTE: The below is assuming that the range is always 5 here.
    */
    const barDoesNotContainAttempt =
      parseInt(data.indexValue) > attemptScore ||
      parseInt(data.indexValue) + 4.9999 < attemptScore;
    const dataFallsOtsideFilterParams = data.id === "numberOfTakers";

    if (
      (attemptScore && barDoesNotContainAttempt) ||
      (!attemptScore && dataFallsOtsideFilterParams)
    ) {
      return "#cbcbcb";
    }
    return theme.colors.darkBlue;
  };

  const tooltip = ({ data: { score, numberOfTakers, withinFilterParams } }) => {
    return (
      <>
        <Score className="bold" margin="0 0 5px">
          {score}
        </Score>
        <FlexContainer align="center">
          <ColorBlock background={handleBarColor(score)} />
          <Body1 color="#6a7f87">
            {attemptScore
              ? numberOfTakers
              : withinFilterParams + numberOfTakers || withinFilterParams}{" "}
            Candidates
          </Body1>
        </FlexContainer>
      </>
    );
  };

  return (
    <ResponsiveBar
      animate={false}
      axisLeft={{
        tickValues: 4
      }}
      borderRadius={2}
      colors={handleBarColor}
      data={scores}
      enableLabel={false}
      indexBy="score"
      keys={["withinFilterParams", "numberOfTakers"]}
      layers={["grid", "axes", "bars", "markers", "legends"]}
      margin={{
        top: 10,
        right: 10,
        bottom: 36,
        left: 36
      }}
      maxValue={"auto"}
      padding={0.6}
      theme={{
        tooltip: {
          container: {
            border: `1px solid ${theme.colors.iron}`,
            borderRadius: "4px",
            padding: "8px 12px"
          }
        }
      }}
      tooltip={tooltip}
    />
  );
};

DistributionBar.propTypes = {
  attemptScore: PropTypes.number
};

DistributionBar.defaultProps = {
  attemptScore: null
};

export default DistributionBar;

const Score = styled(Body1)`
  font-size: 16px;
`;

const ColorBlock = styled.div`
  background: ${({ background }) => background};
  border-radius: 2px;
  height: 12px;
  margin: 0 6px 5px 0;
  width: 12px;
`;
