import endpointNames from "utils/constants/endpointNames";
import makeRequest from "utils/requests/makeRequest";

export const getUserDataMW = async () => {
  try {
    return await makeRequest({
      endpoint: endpointNames.getUserData
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAssessmentBySlugMW = async ({ urlParams }) => {
  try {
    const result = await makeRequest({
      endpoint: endpointNames.getAssessmentBySlug,
      urlParams
    });
    return result;
  } catch (err) {
    console.error(err);
  }
};
