import { useCallback, useEffect } from "react";
import { getCategoriesMW } from "pages/PanelPage/middleware";

const cache = {};

const useFetchCategories = setCategories => {
  const fetchCategories = useCallback(async () => {
    if (cache?.data) {
      setCategories(cache.data);
      return;
    }

    const categories = await getCategoriesMW();
    setCategories(categories.data);
    cache.data = categories.data;
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
};

export default useFetchCategories;
