import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Checkbox from "pages/ManagementPage/candidateInfoForm/Checkbox";
import { FlexContainer } from "components/Atoms/Containers";
import cancelIcon from "assets/cancelIcon";

const QuestionFieldItem = ({ item, handleChange, handleRemove, onClick }) => {
  const { isChecked, isDisabled, itemName } = item;
  return (
    <Item align="center" isDisabled={isDisabled}>
      <Checkbox
        isChecked={isChecked}
        isDisabled={isDisabled}
        onClick={handleChange}
      />
      <RightContainer align="left" onClick={onClick}>
        <ItemName>{itemName}</ItemName>
        {!item?.isDisableEdit && (
          <RemoveIcon
            data-testid="field-remove"
            onClick={ev => {
              ev.stopPropagation();
              handleRemove();
            }}
            className="remove-field"
          >
            {cancelIcon}
          </RemoveIcon>
        )}
      </RightContainer>
    </Item>
  );
};

export default QuestionFieldItem;

QuestionFieldItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

const Item = styled(FlexContainer)`
  background: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 16px;
  width: 100%;
  ${({ isDisabled }) =>
    !isDisabled
      ? `&:hover {
    .remove-field {
      display: flex;
    }
  }`
      : `&:hover {
    cursor: default;
  }`}
`;

const RightContainer = styled(FlexContainer)`
  width: 100%;
`;

const ItemName = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirNextMedium};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 20px;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const RemoveIcon = styled.span`
  cursor: pointer;
  display: none;
  margin-left: auto;
  position: relative;
  z-index: 10;
`;
