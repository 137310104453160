import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Body1 } from "components/Atoms/Text";
import { Table, Row, Cell } from "components/Blocks/Styled/Tables";

const AtAGlance = ({ assessmentData }) => {
  const getGlanceRenderData = () => {
    const fallBackCharacter = "-";

    const {
      average_time,
      average_score,
      weighted_max_score,
      variance
    } = assessmentData;

    const getVariance = () => {
      const _variance = parseFloat(variance?.toFixed(2));
      const weightedMaxScore = parseFloat(weighted_max_score?.toFixed(2));
      const total = ((_variance / 100) * weightedMaxScore)?.toFixed(2);
      return `${!isNaN(total) ? total : fallBackCharacter} /
	${!isNaN(weightedMaxScore) ? weightedMaxScore : fallBackCharacter}`;
    };

    const getAvgAttemptScore = () => {
      const avgScore = parseFloat(average_score?.toFixed(2));
      const weightedMaxScore = parseFloat(weighted_max_score?.toFixed(2));
      return `${!isNaN(avgScore) ? avgScore : fallBackCharacter} / ${
        !isNaN(weightedMaxScore) ? weightedMaxScore : fallBackCharacter
      }`;
    };

    const getAvgTimeTaken = () => {
      const total = average_time
        ? new Date(average_time * 1000).toISOString().substr(11, 8)
        : null;
      return `${total || fallBackCharacter}`;
    };

    const cleanRenderData = renderData => {
      const cleanedData = { ...renderData };
      Object.keys(cleanedData).forEach(key => {
        if (!cleanedData[key] && cleanedData[key] !== 0) {
          cleanedData[key] = fallBackCharacter;
        }
      });
      return cleanedData;
    };

    const renderData = {
      avgTime: getAvgTimeTaken(),
      numOfTakers: assessmentData?.attempt_stats?.COMPLETE,
      avgScore: getAvgAttemptScore(),
      popAvgScore: getVariance()
    };

    return cleanRenderData(renderData);
  };

  const renderCells = () => {
    const {
      numOfTakers,
      avgTime,
      avgScore,
      popAvgScore
    } = getGlanceRenderData();

    const cells = [
      { id: 0, cellHeader: "assessments taken", cellInfo: numOfTakers },
      { id: 1, cellHeader: "avg. time", cellInfo: avgTime },
      { id: 2, cellHeader: "avg. score", cellInfo: avgScore },
      { id: 3, cellHeader: "pop. avg. score", cellInfo: popAvgScore }
    ];

    return cells.map(({ id, cellHeader, cellInfo }) => (
      <Cell
        key={id}
        padding="12px 30px 8px"
        border="1px solid rgb(218, 220, 223)"
        textAlign="center"
      >
        <CellInfo>{cellInfo}</CellInfo>
        <CellName color="#6a7f87" textTransform="capitalize">
          {cellHeader}
        </CellName>
      </Cell>
    ));
  };

  return (
    <GlanceTable>
      <tbody>
        <Row>{renderCells()}</Row>
      </tbody>
    </GlanceTable>
  );
};

AtAGlance.propTypes = {
  assessmentData: PropTypes.object
};

AtAGlance.defaultProps = {
  assessmentData: {}
};

export default AtAGlance;

const GlanceTable = styled(Table)`
  border: 1px solid rgb(218, 220, 223);
  border-collapse: collapse;
  width: auto;
`;

const CellInfo = styled.div`
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-weight: 900;
  letter-spacing: 0.15px;
  padding: 0 0 3px;
`;

const CellName = styled(Body1)`
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
`;
