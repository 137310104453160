import React from "react";

const withinOptimalRangeDashIcon = (
  <svg
    width="16px"
    height="10px"
    viewBox="0 0 16 3"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-324.000000, -2488.000000)" fill="#7EBF5A">
        <g transform="translate(208.000000, 36.000000)">
          <g transform="translate(27.000000, 2038.000000)">
            <g transform="translate(0.018109, 0.247173)">
              <g transform="translate(55.325956, 395.568659)">
                <g transform="translate(34.265594, 4.019386)">
                  <rect x="0" y="15" width="15" height="2" rx="1"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default withinOptimalRangeDashIcon;
