import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AddButton from "pages/ManagementPage/AddButton";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import {
  FREE_RESPONSE,
  MULTIPLE_CHOICE,
  MAP_BACKEND_QUESTION_TYPES,
  questionLibraryPath,
  FILE_UPLOAD,
  VIDEO_RECORD
} from "pages/QuestionPage/Constants";
import { fetchSingleQuestionDataMW } from "pages/QuestionPage/middleware";
import {
  AnswerDescription,
  FreeResponseInput,
  QuestionBody,
  RadioInput,
  RadioLabel,
  RadioRowWrapper,
  FileUpload
} from "pages/QuestionPage/questionPreview/ProctorComponents";
import QuestionVariantsWarning from "pages/QuestionPage/questionPreview/QuestionVariantsWarning";
import { FlexContainer } from "components/Atoms/Containers";
import EmptyContentPlaceHolder from "components/Blocks/EmptyContentPlaceholder";
import QuestionInformationItem from "components/Blocks/QuestionInformationItem";
import { QuestionInformationItemTypes } from "utils/constants/constants";
import eyeIcon from "assets/eyeIcon";
import videoCamera from "assets/videocamera";

const QuestionPreview = ({
  answersOptionsData,
  body,
  type,
  match,
  questionId,
  isAssessmentCreation,
  handleAddButtonClick,
  showActionBtn,
  isQuestionLibrary,
  usedVariants
}) => {
  const [fetchedData, setFetchedData] = useState(null);
  const questionPreviewId = questionId || match?.params?.questionId;
  const { accept_types } = fetchedData || {};
  const fetchData = useCallback(async () => {
    const questionPreviewData = await fetchSingleQuestionDataMW({
      urlParams: [questionPreviewId]
    });
    const questionData = questionPreviewData.data;
    const questionDataContent = questionData.content[0];
    const type = MAP_BACKEND_QUESTION_TYPES[questionData.question_type];
    const fetchAnswerOptions = () => {
      switch (type) {
        case FREE_RESPONSE:
          return questionData.bands.map(({ min_value, max_value, score }) => ({
            min: min_value,
            max: max_value,
            score
          }));
        case MULTIPLE_CHOICE:
          return questionData.options.map(({ content, value }) => ({
            body: content[0].text_display,
            score: value
          }));
        default:
          return [];
      }
    };
    setFetchedData({
      answersOptionsData: {
        answerOptions: fetchAnswerOptions(),
        helperText: questionDataContent.placeholder
      },
      body: questionDataContent.text_display,
      type,
      accept_types: questionData.accept_types,
      category: questionData?.default_category?.name
    });
  }, [questionPreviewId]);

  useEffect(() => {
    if (questionPreviewId) {
      fetchData();
    }
  }, [questionPreviewId, fetchData]);

  const { answerOptions, helperText } = fetchedData
    ? fetchedData.answersOptionsData
    : answersOptionsData;
  const renderAnswerOptions = () => {
    let shouldShowAnswers = !!fetchedData;
    // When we get the type from the backend,
    // video record and file upload types are the same
    // so we can fake it here
    if (
      fetchedData &&
      fetchedData.type === FILE_UPLOAD &&
      accept_types?.includes("video")
    ) {
      fetchedData.type = VIDEO_RECORD;
    }
    switch (fetchedData ? fetchedData.type : type) {
      case MULTIPLE_CHOICE:
        shouldShowAnswers =
          shouldShowAnswers || answerOptions.some(({ body }) => body);
        return (
          shouldShowAnswers && (
            <>
              <AnswerDescription>
                Please select a single option below
              </AnswerDescription>
              {answerOptions.map(
                ({ body }, index) =>
                  body && (
                    <RadioRowWrapper
                      key={`preview-answer-option-${index}`}
                      data-testid="preview-multiple-answer-option"
                    >
                      <RadioInput readOnly type="radio" checked={false} />
                      <RadioLabel className="quill" htmlFor={body}>
                        {parseHtml(body, true)}
                      </RadioLabel>
                    </RadioRowWrapper>
                  )
              )}
            </>
          )
        );
      case FREE_RESPONSE:
        return (
          <>
            <AnswerDescription>
              Please enter your answer below
            </AnswerDescription>
            <FreeResponseInput data-testid="preview-free-answer-option">
              {helperText}
            </FreeResponseInput>
          </>
        );
      case FILE_UPLOAD:
        return (
          <>
            <AnswerDescription>Please Upload your File</AnswerDescription>
            <FileUpload data-testid="preview-file-upload-option"></FileUpload>
          </>
        );
      case VIDEO_RECORD:
        return (
          <>
            <AnswerDescription>Please Record your Video</AnswerDescription>
            <PreviewBox>{videoCamera}</PreviewBox>
          </>
        );
      default:
        return;
    }
  };

  const getExtraInformation = () => {
    const { category, type, accept_types } = fetchedData || {};
    return (
      <>
        <ExtraInformation
          align="center"
          justify="space-between"
          data-testid="assessment-creation-extra-info"
        >
          <FlexContainer align="center">
            {category && (
              <QuestionAdditionalInfoItem
                content={category}
                type={QuestionInformationItemTypes.QUESTION_TYPE}
              />
            )}
            {type && (
              <QuestionAdditionalInfoItem
                content={accept_types?.includes("video") ? VIDEO_RECORD : type}
                type={QuestionInformationItemTypes.CATEGORY}
              />
            )}
          </FlexContainer>
          <Actions align="center">
            <div>
              <PreviewIcon
                to={`${questionLibraryPath}/question-preview/${questionId}`}
                target="_blank"
              >
                {eyeIcon}
              </PreviewIcon>
            </div>
            {showActionBtn && (
              <div>
                <StyledAddBtn onClick={handleAddButtonClick} />
              </div>
            )}
          </Actions>
        </ExtraInformation>
        {usedVariants?.length > 0 && (
          <QuestionVariantsWarningContainer>
            <QuestionVariantsWarning questionIds={usedVariants} />
          </QuestionVariantsWarningContainer>
        )}
      </>
    );
  };

  const renderPreviewContent = () => {
    const bodyToRender = fetchedData ? fetchedData.body : body;
    const shouldShowPreview = fetchedData ? Boolean(questionPreviewId) : true;
    return (
      shouldShowPreview && (
        <>
          {bodyToRender && (
            <>
              {isAssessmentCreation && getExtraInformation()}
              <QuestionBody className="quill">
                {parseHtml(bodyToRender, true)}
              </QuestionBody>
            </>
          )}
          {renderAnswerOptions()}
        </>
      )
    );
  };

  return (
    <>
      <PreviewContainer
        className={!!fetchedData && "fetched"}
        isQuestionLibrary={isQuestionLibrary}
      >
        {renderPreviewContent()}
      </PreviewContainer>
      <EmptyContentPlaceHolder
        className="placeholder"
        content="No content yet"
      />
    </>
  );
};

QuestionPreview.propTypes = {
  answersOptionsData: PropTypes.object,
  body: PropTypes.string,
  type: PropTypes.oneOf([
    MULTIPLE_CHOICE,
    FREE_RESPONSE,
    FILE_UPLOAD,
    VIDEO_RECORD
  ]),
  questionId: PropTypes.number,
  isAssessmentCreation: PropTypes.bool,
  handleAddButtonClick: PropTypes.func,
  showActionBtn: PropTypes.bool,
  isQuestionLibrary: PropTypes.bool,
  usedVariants: PropTypes.array
};

QuestionPreview.defaultProps = {
  answersOptionsData: {
    answerOptions: [],
    helperText: ""
  },
  body: "",
  type: MULTIPLE_CHOICE,
  questionId: null,
  isAssessmentCreation: false,
  handleAddButtonClick: () => null,
  showActionBtn: true,
  isQuestionLibrary: false,
  usedVariants: []
};

export default QuestionPreview;

const PreviewContainer = styled.div`
  position: relative;
  top: ${({ isQuestionLibrary }) => (isQuestionLibrary ? "50px" : "134px")};
  word-break: break-word;
  .quill {
    font-size: 16px;
  }
  img {
    max-width: 100%;
  }
  * {
    white-space: pre-wrap;
  }
  &.fetched {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    max-width: 1016px;
  }
  .katex * {
    white-space: nowrap;
  }
  + .placeholder {
    display: none;
  }
  &:empty {
    + .placeholder {
      display: flex;
    }
  }
  pre {
    background-color: #23241f;
    color: #f8f8f2;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
    white-space: pre-wrap;
  }
`;

const ExtraInformation = styled(FlexContainer)`
  margin-bottom: 22px;
`;

const Actions = styled(FlexContainer)`
  > div {
    cursor: pointer;
    &:first-of-type {
      margin-right: 16px;
    }
  }
`;

const StyledAddBtn = styled(AddButton)`
  height: 32px;
  width: 32px;
`;

const QuestionAdditionalInfoItem = styled(QuestionInformationItem)`
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.avenirProMedium};
  font-size: 14px;
  & span {
    position: relative;
    top: 2px;
  }
`;

const PreviewIcon = styled(Link)`
  position: relative;
  z-index: 10;
`;

const QuestionVariantsWarningContainer = styled.div`
  margin-bottom: 24px;
`;

const PreviewBox = styled.div`
  width: 100%;
  height: 240px;
  border: 2px dotted #999; // Medium grey, adjust as needed
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666; // Darker grey for text, if needed
  font-family: Arial, sans-serif;
`;
