import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import { FlexContainer } from "components/Atoms/Containers";

const MessagePreview = ({ messageParagraph }) =>
  messageParagraph && (
    <PreviewContainer align="center" direction="column" justify="center">
      <div>{parseHtml(messageParagraph)}</div>
    </PreviewContainer>
  );

export default MessagePreview;

MessagePreview.propTypes = {
  messageParagraph: PropTypes.string.isRequired
};

const PreviewContainer = styled(FlexContainer)`
  padding: 80px 0 0 0;
  text-align: left;
  word-break: break-word;
  span {
    background: transparent !important;
  }
`;
