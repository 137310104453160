import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FORM_ID } from "pages/QuestionPage/Constants";
import { Label } from "components/Atoms/Text";
import { handleButtonHover } from "utils/helpers/handleButtonHover";
import leftArrowCompleteIcon from "assets/leftArrowCompleteIcon";

const QuestionCreationNav = ({ handleGoBack, existingQuestion }) => {
  return (
    <Nav>
      <GoBack data-testid="goBack-btn" onClick={handleGoBack}>
        <GoBackIcon>{leftArrowCompleteIcon}</GoBackIcon>
        <GoBackText>
          {existingQuestion ? "Edit question" : "Add a new question"}
        </GoBackText>
      </GoBack>

      <NavActions>
        <ActionButton
          data-testid="cancel-btn"
          onClick={handleGoBack}
          className="cancel"
        >
          Cancel
        </ActionButton>
        <ActionButton form={FORM_ID} data-testid="save-btn" className="save">
          Save
        </ActionButton>
      </NavActions>
    </Nav>
  );
};

export default QuestionCreationNav;

QuestionCreationNav.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  existingQuestion: PropTypes.bool.isRequired
};

const Nav = styled.nav`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 21px 48px;
`;
const GoBack = styled.nav`
  align-items: center;
  display: flex;
`;
const GoBackIcon = styled.span`
  margin-right: 15px;
`;
const GoBackText = styled(Label)`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-size: 14px;
  font-weight: 400;
  height: 18px;
  letter-spacing: 0.1px;
`;

const NavActions = styled.div`
  display: flex;
`;
const ActionButton = styled.button`
  border: 1px solid rgb(41, 41, 69);
  border-radius: 2px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  letter-spacing: 1.25px;
  min-width: 94px;
  outline: 0;
  padding: 7px 14px;
  text-align: center;
  text-transform: uppercase;
  transition: 200ms all ease-out;
  &.save {
    background: ${({ theme }) => theme.tenantAccent};
    border: 0;
    color: ${({ theme }) => theme.colors.white};
    margin-left: 16px;
    &:hover {
      background: ${({ theme }) => handleButtonHover(theme.tenantAccent)};
    }
    &:disabled {
      background: #9c9c9c;
      cursor: auto;
    }
  }
  &.cancel {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.darkBlue};
    &:hover {
      background: rgb(41, 41, 69);
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
