import { fullNameField } from "pages/PanelPage/Constants";

//Receives the all of the taker fields and returns the full name if exists
export const getFullName = takerFieldResponses => {
  const filteredFields = takerFieldResponses?.filter(
    ({ taker_field }) => taker_field.content[0].title === fullNameField
  );
  return filteredFields[0]?.result;
};

export const getFullNameShort = fullName => {
  if (!fullName) return "";

  const fullNameTrimed = fullName.trim();
  const words = fullNameTrimed.split(" ");

  if (words.length === 0) {
    return "";
  }

  if (words.length < 2) {
    return words[0];
  }
  return `${words[0]} ${words[words.length - 1][0]}.`;
};

export default getFullName;
