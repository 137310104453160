import React from "react";

const multipleChoiceQuestionIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-576 -311)">
        <g transform="translate(430 208)">
          <g transform="translate(42 74)">
            <g transform="translate(104 29)">
              <circle
                cx="18"
                cy="18"
                r="17"
                stroke="#6A7F87"
                strokeWidth="2"
              ></circle>
              <circle
                cx="18"
                cy="18"
                r="14"
                fill="#6A7F87"
                fillOpacity="0.6"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default multipleChoiceQuestionIcon;
