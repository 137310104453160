export const permissionTypes = Object.freeze({
  CAN_ACCESS_ADMIN_PANEL: "can_access_admin_panel",
  CAN_ACCESS_DEV_PANEL: "can_access_dev_panel",
  CAN_CREATE_ALL_TENANT_USERS: "can_create_all_tenant_users",
  CAN_CREATE_ALL_ASSESSMENTS: "can_create_all_assessments",
  CAN_CREATE_ASSESSMENTS: "can_create_assessments",
  CAN_CREATE_QUESTIONS: "can_create_questions",
  CAN_CREATE_SHARED_QUESTIONS: "can_create_shared_questions",
  CAN_CREATE_TAKER_INVITE: "can_create_taker_invite",
  CAN_CREATE_TENANT: "can_create_tenant",
  CAN_CREATE_TENANT_USER: "can_create_tenant_user",
  CAN_READ_ALL_ASSESSMENTS: "can_read_all_assessments",
  CAN_READ_ALL_ASSESSMENT_ATTEMPTS: "can_read_all_assessment_attempts",
  CAN_READ_ALL_QUESTIONS: "can_read_all_questions",
  CAN_READ_ALL_TENANT_USERS: "can_read_all_tenant_users",
  CAN_READ_ALL_TENANTS: "can_read_all_tenants",
  CAN_READ_ASSESSMENTS: "can_read_assessments",
  CAN_READ_ASSESSMENT_ATTEMPTS: "can_read_assessment_attempts",
  CAN_READ_QUESTIONS: "can_read_questions",
  CAN_READ_SINGLE_ASSESSMENT: "can_read_single_assessment",
  CAN_READ_SINGLE_ASSESSMENT_ATTEMPT: "can_read_single_assessment_attempt",
  CAN_READ_TENANT: "can_read_tenant",
  CAN_READ_TENANT_USER: "can_read_tenant_user",
  CAN_UPDATE_TENANT: "can_update_tenant",
  CAN_ACCESS_GREENHOUSE: "can_access_greenhouse",
  CAN_VIEW_QUESTION_LIBRARY: [
    "can_read_questions",
    "can_read_all_questions",
    "can_create_questions",
    "can_create_shared_questions"
  ],
  CAN_MANAGE_QUESTION_LIBRARY: [
    "can_create_questions",
    "can_create_shared_questions"
  ],
  CAN_VIEW_ASSESSMENTS: ["can_read_assessments", "can_read_all_assessments"]
});

export const c1AdminPermissions = Object.freeze(
  Object.keys(permissionTypes)
    .filter(permission => !Array.isArray(permissionTypes[permission]))
    .map(permission => permissionTypes[permission])
);
