import React from "react";
import { Svg } from "components/Blocks/Styled/Media";

const activeFocusIcon = (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-24 -86)">
        <g transform="translate(8 68)">
          <g transform="translate(8 8)">
            <g transform="translate(8 10)">
              <circle cx="13" cy="13" r="13" fill="currentColor"></circle>
              <path
                fill="#FFFFFF"
                fillRule="nonzero"
                stroke="#FFFFFF"
                strokeWidth="0.2"
                d="M10.9 18.964a.352.352 0 00.43-.096l5.625-7.055a.352.352 0 00-.275-.57h-2.245l1.856-3.735A.352.352 0 0015.977 7h-4.22a.352.352 0 00-.34.267l-1.407 5.648a.352.352 0 00.342.437h1.66l-1.298 5.212c-.04.16.037.326.185.4zm1.902-5.88a.352.352 0 00-.341-.436H10.8l1.232-4.945h3.376l-1.857 3.734a.352.352 0 00.315.508h2.083l-4.164 5.223 1.016-4.083z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Svg>
);

export default activeFocusIcon;
