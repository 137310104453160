import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CategoriesDropdown from "pages/ManagementPage/CategoriesDropdown";
import { FlexContainer } from "components/Atoms/Containers";
import { Body2 } from "components/Atoms/Text";
import { ErrorMessage } from "components/Blocks/Styled/Forms";

const QuestionItemPoolBody = ({
  handleOpenQuestionPoolEditor,
  handleUpdateQuestions,
  questionPools,
  selectedPool
}) => {
  const onOpenPoolEditor = ev => {
    ev.preventDefault();
    handleOpenQuestionPoolEditor();
  };

  const handleSuffixQuestionCount = category => {
    const questionCount =
      questionPools.find(({ title }) => category === title)?.questions
        ?.length || 0;

    return category && `${category} (${questionCount})`;
  };

  const renderNoListBody = () => (
    <CreatePoolButton onClick={onOpenPoolEditor}>
      create new bank
    </CreatePoolButton>
  );

  const filterEmptyLists = categories => {
    const usedPools = questionPools.map(({ title }) => title);
    return categories.filter(({ value }) => {
      const questionCount =
        questionPools.find(({ title }) => value === title)?.questions?.length ||
        0;
      return usedPools.includes(value) && questionCount;
    });
  };

  const renderChooseListBody = () => {
    const validPools = questionPools.filter(
      ({ questions, title }) =>
        questions.length && !title?.startsWith("Untitled Bank")
    );

    const mainElement = validPools?.length ? (
      <DropdownContainer>
        <StyledCategoriesDropdown
          handleCustomOptionsFilter={filterEmptyLists}
          onModifyOption={handleSuffixQuestionCount}
          onModifySelectedOption={handleSuffixQuestionCount}
          defaultCategory={selectedPool}
          updateCategory={({ value }) =>
            handleUpdateQuestions("selectedPool", value)
          }
          placeholder="Select question bank"
        />
      </DropdownContainer>
    ) : (
      <CustomErrorMessage>
        There are empty pools or not attached to a category
      </CustomErrorMessage>
    );

    return (
      <>
        {mainElement}
        <QuestionPoolActionButton onClick={onOpenPoolEditor}>
          go to editor
        </QuestionPoolActionButton>
      </>
    );
  };

  return (
    <BodyContainer>
      <CopyText>
        This question will be randomly chosen from a predefined bank.
      </CopyText>
      <InformationContainer align="center" justify="space-between">
        {questionPools.length ? renderChooseListBody() : renderNoListBody()}
      </InformationContainer>
    </BodyContainer>
  );
};

export default QuestionItemPoolBody;

QuestionItemPoolBody.propTypes = {
  handleOpenQuestionPoolEditor: PropTypes.func.isRequired,
  handleUpdateQuestions: PropTypes.func.isRequired,
  questionPools: PropTypes.array,
  selectedPool: PropTypes.string
};

QuestionItemPoolBody.defaultProps = {
  questionPools: [],
  selectedPool: ""
};

const BodyContainer = styled.section`
  margin-top: 40px;
`;

const CopyText = styled(Body2)`
  line-height: 20px;
`;

const InformationContainer = styled(FlexContainer)`
  margin-top: 16px;
`;

const QuestionPoolActionButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightSilver}`};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.slateGrey};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.25px;
  margin: ${({ isCentered }) => isCentered && "0 auto"};
  padding: 10px 16px 8px;
  text-align: center;
  text-transform: uppercase;
`;

const DropdownContainer = styled.div`
  flex: 1;
  margin-right: 8px;
  .dropdown-options {
    max-height: 145px;
    overflow-y: scroll;
  }
`;

const StyledCategoriesDropdown = styled(CategoriesDropdown)`
  border: ${({ theme }) => `1px solid ${theme.colors.lightSilver}`};
  height: 32px;
  > div {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const CreatePoolButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightSilver}`};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.slateGrey};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.25px;
  margin: 0 auto;
  padding: 10px 16px 8px;
  text-align: center;
  text-transform: uppercase;
`;

const CustomErrorMessage = styled(ErrorMessage)`
  width: auto;
`;
