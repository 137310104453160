import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { questionTypes } from "pages/App/helpers/questionTypes";
import OptionIcon from "pages/ManagementPage/OptionIcon";
import questionItemIcon from "assets/questionItemIcon";
import questionPoolItemIcon from "assets/questionPoolItemIcon";
import questionSectionIcon from "assets/questionSectionIcon";

const PreviewNavSection = ({
  title,
  slots,
  sectionIndex,
  handleScrollToItem
}) => {
  const sectionTarget = `S${sectionIndex}`;

  const determineType = type => {
    const types = Object.freeze({
      question_banded: "Banded Question",
      question_select: "Multiple Choice",
      question_stock: "Stock Trading"
    });
    return types[type];
  };

  const renderQuestionItem = (
    questionType,
    slotTarget,
    itemId,
    index,
    icon
  ) => (
    <PreviewSlotName
      onClick={e => handleScrollToItem(e, slotTarget)}
      key={itemId}
    >
      <AddQuestionSvg>{icon}</AddQuestionSvg>
      <span>
        {index + 1} - {questionType}
      </span>
    </PreviewSlotName>
  );

  const renderSlots = slots =>
    slots.map(({ question, slot_type, id }, index) => {
      const isSlotRandom = slot_type === questionTypes.SLOT_RANDOM;
      const type = isSlotRandom
        ? "Question Bank"
        : determineType(question.question_type);
      const icon = isSlotRandom ? questionPoolItemIcon : questionItemIcon;
      const slotTarget = `S${sectionIndex}-Q${index}`;
      return renderQuestionItem(type, slotTarget, id, index, icon);
    });

  return (
    <div
      onClick={e => handleScrollToItem(e, sectionTarget)}
      data-testid="preview-nav-section"
    >
      <PreviewNavTitle>
        <OptionIcon
          background={"#5a5a5a"}
          svg={questionSectionIcon}
          text={title}
        />
      </PreviewNavTitle>
      <PreviewSlot>{renderSlots(slots)}</PreviewSlot>
    </div>
  );
};

export default PreviewNavSection;

PreviewNavSection.propTypes = {
  title: PropTypes.string.isRequired,
  slots: PropTypes.array.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  handleScrollToItem: PropTypes.func.isRequired
};

const PreviewNavTitle = styled.div`
  padding: 16px;
  user-select: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.whiteSmoke};
    cursor: pointer;
  }
`;

const AddQuestionSvg = styled.span`
  display: inline-block;
  height: 30px;
  margin-right: 4px;
  width: 30px;
  > svg {
    height: 30px;
    width: 30px;
  }
`;

const PreviewSlot = styled.ul`
  font-family: ${({ theme }) => theme.fonts.avenirNextMedium};
  margin-top: 0;
  padding-left: 0;
`;

const PreviewSlotName = styled.li`
  align-items: center;
  display: flex;
  list-style-type: none;
  margin: 2px;
  padding: 8px 16px 8px 42px;
  user-select: none;
  &:hover {
    background-color: ${({ theme }) => theme.colors.whiteSmoke};
    cursor: pointer;
  }
`;
