import {
  EXPIRATION_TYPES,
  ASSESSMENT_TYPES
} from "pages/ManagementPage/Constants";

export const processSettings = (settings, previousSettings) => {
  const {
    "assessment-settings-name": assessmentName = "",
    "assessment-settings-slug": assessmentSlug,
    "assessment-settings-email-sender": emailSender,
    "assessment-settings-email-sender-name": emailSenderName,
    "assessment-settings-type": selectedType,
    "assessment-settings-complete-notification-sender": notificationSender,
    "assessment-settings-color-theme": assessmentColorTheme,
    "assessment-settings-question-pool-toggle": isQuestionPoolEnabled,
    "assessment-settings-expiration-date": expirationDate,
    "assessment-settings-availability-start-date": openDate,
    "assessment-settings-availability-start-hour": openHour,
    "assessment-settings-availability-close-date": closeDate,
    "assessment-settings-availability-close-hour": closeHour,
    "assessment-settings-expiration-hour": expirationHour,
    "assessment-settings-attempt-limit": attemptLimit,
    "assessment-settings-expiration-type": expirationType,
    "assessment-settings-selected-webhook": selectedWebhook
  } = settings;

  const expirationData = {};
  if (expirationType === EXPIRATION_TYPES.DATE) {
    expirationData.expirationType = EXPIRATION_TYPES.DATE;
    expirationData.expirationDate = new Date(expirationDate);
    expirationData.expirationHour = expirationHour;
  } else if (expirationType === EXPIRATION_TYPES.AVAILABILITY) {
    expirationData.expirationType = EXPIRATION_TYPES.AVAILABILITY;
    expirationData.openDate = new Date(openDate);
    expirationData.openHour = openHour;
    expirationData.closeDate = new Date(closeDate);
    expirationData.closeHour = closeHour;
  } else {
    // ExpirationType equals no expiration date
    expirationData.expirationType = EXPIRATION_TYPES.NO_EXPIRATION;
    expirationData.expirationNumber = null;
    expirationData.expirationUnit = null;
    expirationData.openDate = null;
    expirationData.closeDate = null;
  }

  const weights = Object.keys(settings).filter(value =>
    /^weight__/i.test(value)
  );

  const categoriesWeight = {};
  for (const weightObjId of weights) {
    const [, catWeightId, catId, name] = weightObjId.split("__");
    categoriesWeight[catId] = {
      catWeightId,
      id: weightObjId,
      name,
      weight: settings[weightObjId]
    };
  }

  const mapSettings = {
    ...previousSettings,
    assessmentName,
    assessmentSlug,
    selectedType,
    emailSender,
    emailSenderName,
    categoriesWeight,
    notificationSender,
    assessmentColorTheme,
    isQuestionPoolEnabled,
    webhooks:
      selectedWebhook !== "NONE" ? [{ id: Number(selectedWebhook) }] : [],
    attemptLimit: selectedType === ASSESSMENT_TYPES.PUBLIC && attemptLimit,
    ...expirationData
  };

  const modifiedAssessmentData = {
    title: assessmentName,
    formTitle: assessmentName,
    headerTitle: assessmentName
  };

  return { mapSettings, modifiedAssessmentData };
};
