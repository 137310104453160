import endpointNames from "utils/constants/endpointNames";
import makeRequest from "utils/requests/makeRequest";

export const requestResetPasswordMW = async ({ data }) => {
  try {
    const requestPassword = await makeRequest({
      endpoint: endpointNames.requestResetPassword,
      data
    });

    if (requestPassword?.status === 200) {
      return requestPassword;
    } else {
      return {
        error: {
          message:
            requestPassword?.data?.message.split(".")[0] ||
            "We were unable to confirm your email"
        }
      };
    }
  } catch (err) {
    console.error("error");
  }
};

export const resetPasswordMW = async ({ data }) => {
  try {
    const resetPassword = await makeRequest({
      endpoint: endpointNames.resetPassword,
      data
    });

    if (resetPassword?.status === 200) {
      return resetPassword;
    } else if (resetPassword?.status === 401) {
      return {
        error: {
          message:
            "We were unable to reset your password. Please request a new password reset email and try again."
        }
      };
    } else {
      return {
        error: {
          message: "We were unable to reset your password. "
        }
      };
    }
  } catch (err) {
    console.error("error");
  }
};
