import React, { useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import PropTypes from "prop-types";
import styled from "styled-components";
import ChartSkeletonLoader from "pages/PanelPage/components/charts/ChartSkeletonLoader";
import { FlexContainer } from "components/Atoms/Containers";
import ToggleWrapper from "components/Blocks/ToggleWrapper";

const renderCategoriesBars = (chartData, activeCategory) => (
  <BarsContainer barCount={chartData.length}>
    {chartData.map(({ id, value }) =>
      activeCategory === id ? (
        <CategoryBar key={id} active>
          <CategoryValue>{id}</CategoryValue>
          <CategoryValue>{value}</CategoryValue>
        </CategoryBar>
      ) : (
        <CategoryBar key={id}>
          <CategoryValue>{id}</CategoryValue>
          <CategoryValue>{value}</CategoryValue>
        </CategoryBar>
      )
    )}
  </BarsContainer>
);

const getBarSizing = barCount => {
  if (barCount <= 4) {
    return "80px";
  } else if (barCount <= 6) {
    return "120px";
  } else if (barCount <= 8) {
    return "160px";
  } else if (barCount <= 10) {
    return "200px";
  } else if (barCount <= 12) {
    return "240px";
  } else if (barCount <= 14) {
    return "280px";
  }
  return "320px";
};

const AssessmentCategoriesPie = ({
  chartData,
  toggleCategoriesDetail,
  isChartLoading
}) => {
  const [activeCategory, setActiveCategory] = useState(null);

  const handleMouseEnter = node => {
    const { id } = node;
    setActiveCategory(id);
  };

  const handleMouseLeave = () => {
    setActiveCategory(null);
  };

  const renderCategoriesCharts = () => {
    return (
      <>
        <ChartContainer>
          <PieContainer>
            <ResponsivePie
              borderWidth={0}
              animate={true}
              cornerRadius={1}
              data={chartData}
              colors={"#d8d8d8"}
              width={200}
              height={200}
              enableRadialLabels={false}
              isInteractive={true}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              innerRadius={0.6}
              padAngle={1}
            />
          </PieContainer>
          {renderCategoriesBars(chartData, activeCategory)}
        </ChartContainer>
      </>
    );
  };

  return (
    <AssessmentCategoriesPieWrapper>
      <ToggleWrapper
        showCallback={() => toggleCategoriesDetail(true)}
        showLabel="Show categories"
      >
        {isChartLoading ? <ChartSkeletonLoader /> : renderCategoriesCharts()}
      </ToggleWrapper>
    </AssessmentCategoriesPieWrapper>
  );
};

export default AssessmentCategoriesPie;

AssessmentCategoriesPie.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.number
    })
  ).isRequired,
  toggleCategoriesDetail: PropTypes.func.isRequired,
  isChartLoading: PropTypes.bool.isRequired
};

AssessmentCategoriesPie.defaultProps = {
  chartData: [{}]
};

const AssessmentCategoriesPieWrapper = styled.div`
  padding: 12px;
  width: 100%;
`;

const BarsContainer = styled.section`
  display: flex;
  flex: 2.5;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: ${({ barCount }) => getBarSizing(barCount)};
  @media (max-width: 992px) {
    max-height: 1100px;
  }
`;

const ChartContainer = styled(FlexContainer)`
  min-height: 200px;
  @media (max-width: 667px) {
    flex-direction: column;
  }
`;

const CategoryBar = styled.div`
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.lightGrey.tenantAccent : theme.colors.lightGrey};
  display: flex;
  justify-content: space-between;
  margin: -1px 4px 8px 4px;
  max-height: 50px;
  max-width: 500px;
  min-width: 200px;
  padding: 8px;
`;

const CategoryValue = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
  font-size: 14px;
  margin: 0 4px;
  user-select: none;
`;

const PieContainer = styled.div`
  flex: 1;
  @media (max-width: 667px) {
    height: 210px;
  }
`;
