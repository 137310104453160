import React from "react";
import { encode, decode } from "html-entities";
import { renderToString } from "react-dom/server";
import Latex from "react-latex";
import LatexWrapper from "./SpecialLatexCases";

const processText = string => {
  const escapeDollarSign = () => {
    const dollarSignIndexes = [];
    //Array of characters from the string
    const escapedStringArray = string.split("");
    let result = [];
    //Get indexes of array when there is a dollar sign
    while ((result = dollarSignRegex.exec(string))) {
      dollarSignIndexes.push(result.index);
    }
    //If the array length is odd,the last dollar sign shouldn't be treated
    dollarSignIndexes.length % 2 !== 0 && dollarSignIndexes.pop();

    for (let i = 0; i < dollarSignIndexes.length; ++i) {
      //If the previous character is a backslash don't replace the dollar sign
      //Used to render dollar signs inside mathematical notations
      if (escapedStringArray?.[dollarSignIndexes[i] - 1] !== "\\") {
        //Even dollar signs will be replaced with starting notation
        //Odd dollar signs will be replaced with ending notation
        if (i % 2 === 0) escapedStringArray[dollarSignIndexes[i]] = "\\[$";
        else escapedStringArray[dollarSignIndexes[i]] = "$\\]";
      }
    }
    return escapedStringArray.join("");
  };
  const dollarSignRegex = /\$/gi;

  if (!string) return "";
  if ((string.match(dollarSignRegex) || []).length > 1)
    return escapeDollarSign();

  return string;
};

// Codeblocks needs the HTML tag entities within it's content to be encoded,
// this function encodes all the HTML tag entities enclosed by <pre> tags
const processCodeBlock = string => {
  // Takes all the content within <pre> tag
  const processedString = string.replace(
    /<pre(.*?)>(.|\n)*?<\/pre>/g,
    match => {
      const element = document.createElement("span");
      const htmlTagRegex = /<[/\n\r]*[^>]*>/g;
      const lineBreaksRegex = /<br\s*\/?>/gm;

      element.innerHTML = match;

      // Replace all HTML tags with the encoded entitiy
      const encodedTags = element.firstChild.innerHTML
        .replaceAll(lineBreaksRegex, "\n")
        .replaceAll(htmlTagRegex, tag => encode(tag));

      const newCodeBlock = `<pre>${encodedTags}</pre>`;
      element.remove();
      return newCodeBlock;
    }
  );
  return processedString;
};

export const getLatexHtml = text => {
  let processedText = processText(decode(text));
  processedText = processCodeBlock(processedText);
  return decode(
    renderToString(
      <LatexWrapper>
        <Latex strict throwOnError={false} errorColor="inherit">
          {processedText}
        </Latex>
      </LatexWrapper>
    )
  );
};
