import {
  SET_AUTHENTICATED,
  SET_USER_DATA,
  SET_TENANT_USER,
  SET_ROLES_AND_PERMISSIONS
} from "state/userContext/userContext";

export const dispatchActions = dispatch => {
  return {
    setAuthenticated: payload =>
      dispatch({
        type: SET_AUTHENTICATED,
        payload
      }),
    setUserData: payload =>
      dispatch({
        type: SET_USER_DATA,
        payload
      }),
    setRolesAndPermissions: payload =>
      dispatch({
        type: SET_ROLES_AND_PERMISSIONS,
        payload
      }),
    setTenantUser: payload =>
      dispatch({
        type: SET_TENANT_USER,
        payload
      })
  };
};
