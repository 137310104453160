import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import EmptyContentPlaceHolder from "components/Blocks/EmptyContentPlaceholder";
import celebration from "assets/celebration.gif";

const CompletionPagePreview = ({
  completionHeading,
  completionBody,
  completionButtonText
}) => {
  return (
    <Container direction={"column"} padding={"3rem"} height={"100vh"}>
      {completionHeading || completionBody || completionButtonText ? (
        <>
          <HeadingContainer>
            <CelebrationImage src={celebration} alt="Celebretion Gif" />
            <HeadingText>{completionHeading}</HeadingText>
          </HeadingContainer>
          <CompletionBodyContainer
            dangerouslySetInnerHTML={{ __html: completionBody }}
          ></CompletionBodyContainer>
          <FooterContainer>
            {completionButtonText && <Button>{completionButtonText}</Button>}
          </FooterContainer>
        </>
      ) : (
        <EmptyContentPlaceHolder
          className="placeholder"
          content="No page or content yet"
        />
      )}
    </Container>
  );
};

const Container = styled(FlexContainer)`
  margin: 0 auto;
  max-width: 1100px;
`;

const CelebrationImage = styled.img`
  max-width: 200px;
  width: 100%;
`;

const HeadingContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  margin-top: 50px;
`;

const Button = styled.a`
  background: #28b2eb;
  border-radius: 2px;
  color: white;
  font-weight: bold;
  padding: 4px;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const HeadingText = styled.h5`
  color: #5db5e5;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
`;

const CompletionBodyContainer = styled.div`
  text-align: start;
`;

export default CompletionPagePreview;

CompletionPagePreview.propTypes = {
  assessmentColorTheme: PropTypes.string.isRequired,
  completionHeading: PropTypes.string.isRequired,
  completionBody: PropTypes.string.isRequired,
  completionButtonText: PropTypes.string.isRequired,
  completionButtonUrl: PropTypes.string.isRequired
};
