import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import PreviewNavSection from "pages/PreviewPage/PreviewNavSection";
import { FlexContainer } from "components/Atoms/Containers";
import { H4 } from "components/Atoms/Text";
import rightArrowIcon from "assets/rightArrowIcon";

const PreviewNav = ({
  sections,
  name,
  handleScrollToItem,
  hasQuestionPools,
  handleGoToPoolsView
}) => (
  <PreviewNavWrapper>
    <AssessmentHeading>{name}</AssessmentHeading>
    {hasQuestionPools && (
      <AssessmentQuestionBanks
        align="center"
        justify="space-between"
        onClick={handleGoToPoolsView}
      >
        <span>Question Banks</span>
        {rightArrowIcon}
      </AssessmentQuestionBanks>
    )}
    <PreviewNavContent>
      {sections.map(({ slots, title, id }, i) => (
        <PreviewNavSection
          slots={slots}
          title={title}
          key={id}
          sectionIndex={i}
          handleScrollToItem={handleScrollToItem}
        />
      ))}
    </PreviewNavContent>
  </PreviewNavWrapper>
);

export default PreviewNav;

PreviewNav.propTypes = {
  name: PropTypes.string.isRequired,
  sections: PropTypes.array.isRequired,
  handleScrollToItem: PropTypes.func.isRequired,
  hasQuestionPools: PropTypes.bool.isRequired,
  handleGoToPoolsView: PropTypes.func.isRequired
};

const PreviewNavWrapper = styled.section`
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  width: 350px;
`;

const AssessmentHeading = styled(H4)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 16px;
  padding: 18px 4px;
  text-align: center;
`;

const AssessmentQuestionBanks = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  cursor: pointer;
  margin-bottom: 18px;
  padding: 6px 24px 0;
  span {
    color: #34b7ea;
    font-family: ${({ theme }) => theme.fonts.avenirProHeavy};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.8px;
    text-align: left;
    text-transform: uppercase;
  }
  svg {
    bottom: 2px;
    position: relative;
    path {
      fill: #34b7ea;
    }
  }
`;

const PreviewNavContent = styled.div`
  padding: 12px 8px;
`;
