import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StyledButton } from "components/Atoms/Buttons";
import { H6 } from "components/Atoms/Text";
import Modal from "components/Systems/Modal";

const SelectAssessment = ({ onClose, onCreateScratch }) => {
  return (
    <Modal title="Start a new assessment" onClose={onClose}>
      <StartFromScratch
        data-testid="select-assessment-start-scratch"
        onClick={onCreateScratch}
      >
        Start from scratch
      </StartFromScratch>
      <ModalSubtitle>Templates</ModalSubtitle>
      <ModalText>
        Sorry, there are currently no templates to choose from.
      </ModalText>
    </Modal>
  );
};

export default SelectAssessment;

SelectAssessment.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreateScratch: PropTypes.func.isRequired
};

const StartFromScratch = styled(StyledButton)`
  margin: 40px 0 20px auto;
  text-transform: uppercase;
`;

const ModalSubtitle = styled(H6)`
  border-bottom: 1px solid #e5e5e5;
  color: #212629;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin-bottom: 20px;
  padding-bottom: 2px;
`;

const ModalText = styled.span`
  color: #5c5c5c;
  font-size: 14px;
  letter-spacing: 0.47px;
`;
