import { paths } from "pages/App/helpers/paths";
import freeResponseQuestionSquareIcon from "assets/freeResponseQuestionSquareIcon";
import multipleChoiceQuestionIcon from "assets/multipleChoiceQuestionIcon";
import uploadIcon from "assets/uploadIcon";
import videoRecordIcon from "assets/videoRecordIcon";

export const MULTIPLE_CHOICE = "Multiple Choice";
export const FREE_RESPONSE = "Free Response";
export const FILE_UPLOAD = "File upload";
export const VIDEO_RECORD = "Video record";
export const STOCK_TRADING = "Stock Trading (non-editable)";
export const QUESTION_ICONS = Object.freeze({
  [MULTIPLE_CHOICE]: multipleChoiceQuestionIcon,
  [FREE_RESPONSE]: freeResponseQuestionSquareIcon,
  [FILE_UPLOAD]: uploadIcon,
  [VIDEO_RECORD]: videoRecordIcon
});

export const MAP_BACKEND_QUESTION_TYPES = Object.freeze({
  question_select: MULTIPLE_CHOICE,
  question_banded: FREE_RESPONSE,
  question_stock: STOCK_TRADING,
  question_upload: FILE_UPLOAD,
  video_record: VIDEO_RECORD
});
export const QUESTION_BACKEND_TYPES = Object.freeze({
  [MULTIPLE_CHOICE]: "question_select",
  [FREE_RESPONSE]: "question_banded",
  [FILE_UPLOAD]: "question_upload",
  [VIDEO_RECORD]: "question_upload"
});
export const INPUT_TYPES = Object.freeze({
  [MULTIPLE_CHOICE]: "multiple",
  [FREE_RESPONSE]: "free-response",
  [FILE_UPLOAD]: "file-upload",
  [VIDEO_RECORD]: "file-upload"
});
export const FORM_ID = "CREATE_QUESTION_FORM";
export const MIN_ANSWERS_OPTIONS = 1;
export const DEFAULT_ANSWER_OPTIONS = 2;
export const MAX_ANSWERS_OPTIONS = 12;
export const questionLibraryPath = `${paths.ADMIN}/question-library`;
export const INVALID_VARIANT_ID = "INVALID_VARIANT_ID";
