import styled from "styled-components";

const Container = styled.div`
  background: ${({ background }) => background};
  border: ${({ border }) => border};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  position: relative;
  width: ${({ width }) => width};
  &.pointer {
    cursor: pointer;
  }
`;

const FlexContainer = styled(Container)`
  align-items: ${({ align }) => align};
  display: flex;
  flex: ${({ flex }) => flex};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${props => props.wrap};
  gap: ${({ gap }) => `${gap}px`};
  justify-content: ${({ justify }) => justify};
`;

const PositionContainer = styled(Container)`
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  position: ${({ position }) => position || "absolute"};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
`;

const Option = styled(Container)`
  border-bottom: ${({ selected, theme }) =>
    selected && `solid 4px ${theme.tenantAccent}`};
  &:hover {
    border-bottom: ${({ selected, theme }) =>
      !selected && `solid 4px ${theme.tenantAccent}30`};
  }
`;

const MaxWidthContainer = styled.div`
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 20px;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const PageContentContainer = styled.div`
  background-color: ${({ isInvitePage, theme }) =>
    isInvitePage && theme.colors.grey};
  height: ${({ isInvitePage }) => isInvitePage && "100vh"};
  padding: 32px 0;
  width: 100%;
`;

const QuestionListContainer = styled.div`
  flex: 1;
  max-width: 40%;
  width: 100%;
`;

const QuestionPreviewContainer = styled.div`
  margin: -50px 0 auto;
  max-width: 56%;
  position: sticky;
  right: 16px;
  text-align: start;
  width: 100%;
  > div:first-child:not(:empty) {
    background: #f1f3f4;
    height: 100vh;
    max-height: 820px;
    overflow-y: auto;
    padding: 31px 28px;
  }
`;

// Export Container
export {
  Container,
  FlexContainer,
  PositionContainer,
  Option,
  MaxWidthContainer,
  FormContainer,
  PageContentContainer,
  QuestionListContainer,
  QuestionPreviewContainer
};
