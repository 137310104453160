import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useFormContext, useFieldArray } from "react-hook-form";
import styled, { css } from "styled-components";
import ImportModal from "pages/PanelPage/components/selectedAssessment/assessmentInvite/ImportModal";
import Recipients from "pages/PanelPage/components/selectedAssessment/assessmentInvite/Recipients";
import {
  FlexContainer,
  Container,
  PositionContainer
} from "components/Atoms/Containers";
import { Body3, Subtitle } from "components/Atoms/Text";
import { useClickedOutsideEvent } from "hooks/useClickedOutsideEvent";
import importIcon from "assets/importIcon";

const InviteHeader = ({
  template,
  showErrors,
  sendSuccess,
  showSubjectChanges,
  setSubjectHasChanged,
  setSubject
}) => {
  const [importToggleState, setImportToggleState] = useState(false);
  const { control, register, errors } = useFormContext();
  const subjectError = errors.subject && errors.subject.message;
  const emailError =
    errors.recipients &&
    "One or more of the emails you have entered is invalid";

  // Set field array for recipients
  const fieldProps = useFieldArray({
    control,
    name: "recipients"
  });

  // Handle clicks outside of the modal
  const modalRef = useRef(null);
  useClickedOutsideEvent(modalRef, () => setImportToggleState(false));

  // Toggle CSV modal
  const handleSetImportToggle = () => {
    setImportToggleState(!importToggleState);
  };

  const handleInputChange = event => {
    const newHeader = event.target.value;
    const header = template?.formatted_subject;
    setSubject(newHeader);
    setSubjectHasChanged(newHeader !== header);
  };

  return (
    <>
      <Header justify="flex-start">
        <Subtitle textAlign="left" color="#ffffff">
          Send invitation by email
        </Subtitle>
      </Header>
      <Container width="100%" padding="4px 16px">
        <EmailListContainer invalidEmails={showErrors && Boolean(emailError)}>
          <ImportContainer ref={modalRef}>
            <FlexContainer className="pointer" onClick={handleSetImportToggle}>
              {importIcon}
              <ImportText margin="0 0 0 8px">Import .CSV</ImportText>
            </FlexContainer>
            {importToggleState && (
              <ImportModal
                setImportToggleState={setImportToggleState}
                fieldProps={fieldProps}
              />
            )}
          </ImportContainer>
          <Recipients fieldProps={fieldProps} sendSuccess={sendSuccess} />
        </EmailListContainer>
        <ErrorMessage className={!showErrors && "hidden"}>
          {showErrors && emailError}
        </ErrorMessage>
        <EmailSubject
          showChanges={showSubjectChanges}
          subjectError={showErrors && Boolean(subjectError)}
        >
          <InputStyled
            defaultValue={template?.formatted_subject}
            name="subject"
            ref={register}
            onChange={handleInputChange}
          />
        </EmailSubject>
        <ErrorMessage className={!showErrors && "hidden"}>
          {showErrors && subjectError}
        </ErrorMessage>
      </Container>
    </>
  );
};

export default InviteHeader;

InviteHeader.propTypes = {
  template: PropTypes.object,
  showErrors: PropTypes.bool.isRequired,
  sendSuccess: PropTypes.bool,
  showSubjectChanges: PropTypes.bool.isRequired,
  setSubjectHasChanged: PropTypes.func.isRequired
};

InviteHeader.defaultProps = {
  template: {},
  sendSuccess: null
};

const ImportContainer = styled(PositionContainer)`
  border-radius: 4px;
  bottom: 4px;
  padding: 4px;
  right: 0px;
  z-index: 99;
  &:hover {
    background: #f7f7f7;
  }
`;

const ImportText = styled(Body3)`
  color: ${({ theme }) => theme.tenantAccent};
  font-size: 12px;
`;

const EmailListContainer = styled(FlexContainer)`
  border-bottom: ${({ invalidEmails }) =>
    invalidEmails ? "1px solid #d93025" : "1px solid rgb(0, 0, 0, 0.1)"};
`;

const handleBackground = () => css`
  background: ${({ theme }) => theme.colors.skyBlue};
  > input {
    background: transparent;
  }
`;

const EmailSubject = styled.div`
  border: none;
  border-bottom: ${({ subjectError }) =>
    subjectError ? "1px solid #d93025" : "1px solid rgb(0, 0, 0, 0.1)"};
  color: #242437;
  font-family: ${({ theme }) => theme.fonts.avenirProBook};
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.4px;
  padding: 18px 0 10px 0;
  text-align: left;
  width: 100%;
  ${({ showChanges }) => showChanges && handleBackground};
`;

const InputStyled = styled.input`
  appearance: none;
  border: none;
  outline: none;
  width: 100%;
`;

const ErrorMessage = styled.div`
  color: #d93025;
  font-family: ${({ theme }) => theme.fonts.avenirProRoman};
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  &.hidden {
    visibility: hidden;
  }
`;

const Header = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.smoky};
  border-radius: 4px 4px 0 0;
  margin: 0 0 12px;
  padding: 8px 16px 4px;
  width: 100%;
`;
