import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import QuestionList from "pages/QuestionPage/QuestionList";
import QuestionPreview from "pages/QuestionPage/questionPreview/QuestionPreview";
import {
  FlexContainer,
  QuestionListContainer,
  QuestionPreviewContainer
} from "components/Atoms/Containers";

const QuestionLibraryList = ({
  setSelectedQuestion,
  selectedQuestions,
  setQuestions,
  selectedQuestion,
  isQuestionPool,
  usedVariants
}) => (
  <QuestionContainer align="flex-start" justify="space-between">
    <QuestionListContainer data-testid="assessment-question-list">
      <QuestionList
        activeCard={selectedQuestion?.id}
        isAssessmentCreation
        onCardClick={setSelectedQuestion}
        excludedQuestionIds={selectedQuestions}
        preserveHistory={false}
      />
    </QuestionListContainer>
    <QuestionPreviewContainer
      data-testid="assessment-question-preview"
      isQuestionPool={isQuestionPool}
    >
      <QuestionPreview
        usedVariants={usedVariants}
        questionId={selectedQuestion?.id}
        isAssessmentCreation
        handleAddButtonClick={ev => {
          ev.preventDefault();
          setQuestions(selectedQuestion);
        }}
      />
    </QuestionPreviewContainer>
  </QuestionContainer>
);

export default QuestionLibraryList;

QuestionLibraryList.propTypes = {
  setSelectedQuestion: PropTypes.func.isRequired,
  selectedQuestions: PropTypes.array,
  setQuestions: PropTypes.func.isRequired,
  selectedQuestion: PropTypes.object,
  isQuestionPool: PropTypes.bool.isRequired,
  usedVariants: PropTypes.array.isRequired
};

const QuestionContainer = styled(FlexContainer)`
  margin-top: 34px;
  width: 100%;
`;
