import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "components/Atoms/Containers";
import { Body1 } from "components/Atoms/Text";
import leftArrowIcon from "assets/leftArrowIcon";
import rightArrowIcon from "assets/rightArrowIcon";

const Paginator = ({
  itemsPerPage,
  setItemsPerPage,
  log,
  setLog,
  pageNum,
  setPageNum,
  totalCount,
  totalPages
}) => {
  const firstItem = 1 + (pageNum - 1) * itemsPerPage;
  const lastItem =
    itemsPerPage * pageNum > totalCount ? totalCount : itemsPerPage * pageNum;
  const showBackArrow = pageNum !== 1;
  const showForwardArrow = lastItem !== totalCount;

  const changePage = nextPage => {
    if (nextPage) {
      if (pageNum + 1 <= totalPages) {
        setPageNum(pageNum + 1);
        setLog({
          rangeStart: pageNum * itemsPerPage + 1,
          rangeEnd: pageNum * itemsPerPage + itemsPerPage
        });
      }
      if (pageNum + 1 === totalPages) {
        setLog({
          rangeStart: pageNum * itemsPerPage + 1,
          rangeEnd: log.rangeEnd + (totalCount % itemsPerPage)
        });
      }
    } else if (pageNum > 1) {
      setPageNum(pageNum - 1);
      // if we are on the first page, the rangeStart can just be set to one
      setLog({
        rangeStart: pageNum - 1 === 1 ? 1 : log.rangeStart - itemsPerPage,
        rangeEnd:
          log.rangeEnd -
          (pageNum === totalPages ? totalCount % itemsPerPage : itemsPerPage)
      });
    }
  };

  const handleItemsPerPage = ({ target: { value } }) => {
    // If we are not on the first page
    if (pageNum > 1) {
      // If we decrease the limit
      if (itemsPerPage > value) {
        setPageNum(pageNum + 1);
        setItemsPerPage(parseInt(value));
      } else {
        const newTotalPages = Math.ceil(totalCount / parseInt(value));
        setPageNum(Math.min(pageNum - 1, newTotalPages));
        setItemsPerPage(parseInt(value));
      }
      setLog({
        ...log,
        rangeStart:
          pageNum === totalPages
            ? log.rangeStart
            : log.rangeStart - itemsPerPage
      });
    } else {
      // If we are on the first page, remain on this page, but increase the limit and adjust the range
      setItemsPerPage(parseInt(value));
      setLog({
        rangeStart: 1,
        rangeEnd: pageNum * parseInt(value)
      });
    }
  };

  return (
    <FlexContainerMarginWrapper justify="space-between">
      <FlexContainer align="center">
        <Body1ColorWrapper>Items per page:</Body1ColorWrapper>
        <ItemsPerPageDropdown
          onChange={event => handleItemsPerPage(event)}
          data-testid="page-limit-dropdown"
        >
          <option data-testid="page-limit-option">10</option>
          <option data-testid="page-limit-option">20</option>
        </ItemsPerPageDropdown>
      </FlexContainer>
      {Boolean(totalCount) && (
        <FlexContainer align="center">
          <PaginateInformation>{`${firstItem}-${lastItem} of ${totalCount} items`}</PaginateInformation>
          <ClickableIcon
            showIcon={showBackArrow}
            onClick={() => changePage(false)}
            data-testid="left-arrow"
          >
            {leftArrowIcon}
          </ClickableIcon>
          <ClickableIcon
            showIcon={showForwardArrow}
            onClick={() => changePage(true)}
            data-testid="right-arrow"
          >
            {rightArrowIcon}
          </ClickableIcon>
        </FlexContainer>
      )}
    </FlexContainerMarginWrapper>
  );
};

Paginator.propTypes = {
  itemsPerPage: PropTypes.number.isRequired,
  setItemsPerPage: PropTypes.func.isRequired,
  log: PropTypes.object.isRequired,
  setLog: PropTypes.func.isRequired,
  pageNum: PropTypes.number.isRequired,
  setPageNum: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired
};

export default Paginator;

const ClickableIcon = styled.span`
  cursor: pointer;
  visibility: ${({ showIcon }) => (showIcon ? "visible" : "hidden")};
`;
const PaginateInformation = styled.div`
  color: ${({ theme }) => theme.colors.smoky};
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
`;

const ItemsPerPageDropdown = styled.select`
  border: none;
  color: ${({ theme }) => theme.tenantAccent};
  margin-top: -6px;
  outline: none;
`;

const FlexContainerMarginWrapper = styled(FlexContainer)`
  margin: 21px 0 0;
`;

const Body1ColorWrapper = styled(Body1)`
  color: ${({ theme }) => theme.colors.smoky};
`;
