import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { QUESTION_ICONS } from "pages/QuestionPage/Constants";
import { Body2 } from "components/Atoms/Text";

const QuestionTypeRadioGroup = ({
  questionType,
  questionTypeOptions,
  setQuestionType
}) => {
  const { register } = useFormContext();

  const getRadios = () =>
    questionTypeOptions.map(type => (
      <RadioContainer
        data-testid="question-radio"
        className={questionType === type && "checked"}
        key={type}
      >
        <RadioIcon className={type.toLowerCase().replace(" ", "-")}>
          {QUESTION_ICONS[type]}
        </RadioIcon>
        <RadioCaption>{type}</RadioCaption>
        <Radio
          type="radio"
          name="question-type"
          id={type}
          value={type}
          ref={register({ required: true })}
          checked={questionType === type}
          onChange={() => setQuestionType(type)}
        />
      </RadioContainer>
    ));

  return <RadioGroup>{getRadios()}</RadioGroup>;
};

export default QuestionTypeRadioGroup;

QuestionTypeRadioGroup.propTypes = {
  questionType: PropTypes.string.isRequired,
  questionTypeOptions: PropTypes.array.isRequired,
  setQuestionType: PropTypes.func.isRequired
};

const RadioGroup = styled.div`
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
`;

const RadioIcon = styled.span`
  display: contents;
  & > svg {
    height: 18px;
    width: 18px;
  }
  &.free-response {
    & > svg {
      height: 15px;
      width: 26px;
    }
  }
`;
const RadioCaption = styled(Body2)`
  height: 18px;
  line-height: 22px;
  margin-left: 10px;
  @media (max-width: 992px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const RadioContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #e5e1e1;
  border-radius: 4px;
  display: flex;
  padding: 14px;
  position: relative;
  white-space: nowrap;
  &.checked {
    border-color: ${({ theme }) => theme.tenantAccent};
  }
`;

const Radio = styled.input`
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;
