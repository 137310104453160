import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer, Container } from "components/Atoms/Containers";
import emptyContentIcon from "assets/emptyContentIcon";

const EmptyContentPlaceHolder = ({ className, content }) => (
  <PlaceholderContainer align="center" direction="column" className={className}>
    <Container>{emptyContentIcon}</Container>
    <PlaceholderText>{content}</PlaceholderText>
  </PlaceholderContainer>
);

export default EmptyContentPlaceHolder;

EmptyContentPlaceHolder.propTypes = {
  className: PropTypes.string,
  emptyContent: PropTypes.string
};

EmptyContentPlaceHolder.defaultProps = {
  className: "",
  content: ""
};

const PlaceholderContainer = styled(FlexContainer)`
  position: relative;
  top: 180px;
`;

const PlaceholderText = styled.div`
  color: #d3d4d4;
  font-family: ${({ theme }) => theme.fonts.avenirProBlack};
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: 8px;
  text-align: center;
`;
