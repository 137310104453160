import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { MAJOR_EDITING_MODE, FORM_ID } from "pages/ManagementPage/Constants";
import { StyledButton } from "components/Atoms/Buttons";
import { FlexContainer } from "components/Atoms/Containers";
import Input from "components/Blocks/Forms/Input";
import cogIcon from "assets/cogIcon";
import eyeIcon from "assets/eyeIcon";
import leftArrowCompleteIcon from "assets/leftArrowCompleteIcon";

const Header = ({
  onEditSettings,
  onGoBack,
  setData,
  title,
  isMajorChange,
  setMajorEditingMode,
  setShowMajorEditingModal,
  isDisabled
}) => {
  const getSaveButtons = () => {
    const majorEditingProps = mode => ({
      onClick: ev => {
        ev.preventDefault();
        setShowMajorEditingModal(true);
        setMajorEditingMode(mode);
      }
    });

    return isMajorChange ? (
      <>
        {Object.keys(MAJOR_EDITING_MODE).map(mode => (
          <MajorEditingButton
            key={mode}
            {...majorEditingProps(mode)}
            disabled={isDisabled}
          >
            {MAJOR_EDITING_MODE[mode].text}
          </MajorEditingButton>
        ))}
      </>
    ) : (
      <EditingButton
        data-testid="save-button"
        form={FORM_ID}
        disabled={isDisabled}
      >
        Save
      </EditingButton>
    );
  };

  return (
    <HeaderContainer align="center" justify="space-between">
      <MainInfo align="center">
        <GoBackIcon data-testid="go-back-button" onClick={onGoBack}>
          {leftArrowCompleteIcon}
        </GoBackIcon>
        <Input
          testId="assessment-title-input"
          onChange={ev =>
            setData({ title: ev.target.value, formTitle: ev.target.value })
          }
          name="assessment-title"
          value={title || "Untitled"}
        />
      </MainInfo>
      <FlexContainer align="center">
        <ActionIcon data-testid="settings-button" onClick={onEditSettings}>
          {cogIcon}
        </ActionIcon>
        <ActionIcon data-testid="preview-button">{eyeIcon}</ActionIcon>
        <FlexContainer>{getSaveButtons()}</FlexContainer>
      </FlexContainer>
    </HeaderContainer>
  );
};

export default Header;

Header.propTypes = {
  onEditSettings: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isMajorChange: PropTypes.bool.isRequired,
  setMajorEditingMode: PropTypes.func.isRequired,
  setShowMajorEditingModal: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

const HeaderContainer = styled(FlexContainer)`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px 33px 16px 38px;
`;

const GoBackIcon = styled.div`
  cursor: pointer;
`;

const MainInfo = styled(FlexContainer)`
  input {
    border: 0;
    border-color: #d7d7d7;
    font-family: ${({ theme }) => theme.fonts.avenirProRoman};
    &:focus {
      background: #f8f9fa;
      border: solid 1px #d7d7d7;
    }
    ~ div {
      display: none;
    }
  }
`;

const ActionIcon = styled.div`
  cursor: pointer;
  height: 16px;
  margin-right: 15px;
`;

const handleDisabledButton = () => css`
  &:disabled {
    background: ${({ theme }) => theme.colors.disableGrey};
  }
`;

const MajorEditingButton = styled(StyledButton)`
  &:first-child {
    margin-right: 16px;
  }
  ${handleDisabledButton}
`;

const EditingButton = styled(StyledButton)`
  ${handleDisabledButton}
`;
