import React from "react";

const videocamera = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100px"
    viewBox="0 0 24 24"
    width="100px"
    fill="#5f6368"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M15 8v8H5V8h10m1-2H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4V7c0-.55-.45-1-1-1z" />
  </svg>
);
export default videocamera;
