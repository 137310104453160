import { useContext } from "react";
import { dispatchActions } from "state/userContext/dispatchActions";
import { UserContext } from "state/userContext/userContext";

export const useUserContext = () => {
  const { userState, userDispatch } = useContext(UserContext);

  return {
    userState,
    userDispatch: dispatchActions(userDispatch),
    isAnonymous: !userState?.userData
  };
};
