import React from "react";
import { encode } from "html-entities";
import { parseHtml } from "pages/PanelPage/helpers/parseHtml";
import { TruncateTextByLines } from "components/Atoms/Text";

const createPreview = (string, trimLines) => {
  const trimLineBreaks = string?.replace(/<br\s*\/?>/gi, " ");
  const stripTags = trimLineBreaks?.replace(/(<([^>]+)>)/gi, " ");
  const trimmed = stripTags?.trim();
  const decoded = encode(trimmed);

  return (
    trimmed && (
      <TruncateTextByLines lines={trimLines} isSingleWord={!/\s/.test(trimmed)}>
        {parseHtml(decoded, true, false)}
      </TruncateTextByLines>
    )
  );
};

export default createPreview;
