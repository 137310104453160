import { useEffect } from "react";

export const useClickedOutsideEvent = (
  ref,
  setState,
  containerExceptionSelector
) => {
  useEffect(() => {
    const handleClickOutside = event => {
      const isExceptionElement = !!(
        containerExceptionSelector &&
        event.target.closest(containerExceptionSelector)
      );
      if (!isExceptionElement && !ref?.current?.contains(event.target)) {
        setState();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, setState, containerExceptionSelector]);
};
