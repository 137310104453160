import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { validateEmail } from "pages/PanelPage/helpers/validateEmail";
import { FlexContainer, Container } from "components/Atoms/Containers";
import closeIcon from "assets/closeIcon";

const RecipientInput = ({
  index,
  fields,
  field,
  append,
  remove,
  focus,
  hide
}) => {
  const { register, watch } = useFormContext();
  const [inputFocus, setInputFocus] = useState(false);
  const isLast = fields.length - 1 === index;
  const value = `recipients[${index}].value`;
  const recipient = watch(value);
  const emailValid =
    recipient === undefined
      ? validateEmail(field.value)
      : validateEmail(recipient);

  // Handles key down logic
  // 'Enter', ',', 'Tab', ' ' - add an input to array
  // 'Backspace' - remove one
  const handleKeyDown = e => {
    switch (e.key) {
      case "Enter":
      case ",":
      case "Tab":
      case " ":
        e.preventDefault();
        if (!recipient) {
          return;
        }
        append("");
        break;
      case "Backspace":
        if (fields.length === 1) {
          !e.target.value.length && remove(fields.length - 1);
        } else {
          !e.target.value.length && remove(fields.length - 2);
        }
        break;
      default:
        break;
    }
  };

  // Removes input if close button clicked
  // If index is 0 resets form to re-render placeholder text
  const handleRemoveRecipient = index => {
    if (!index && fields.length === 1) {
      remove();
    } else {
      remove(index);
    }
  };

  return (
    <Recipient
      align="center"
      emailValid={emailValid}
      hide={hide}
      inputFocus={inputFocus}
      isLast={isLast}
    >
      <Email
        onClick={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        inputFocus={inputFocus}
        isLast={isLast}
        key={field.id}
        autoFocus={focus && isLast}
        emailValid={emailValid}
        onKeyDown={e => handleKeyDown(e)}
        name={value}
        ref={register()}
        defaultValue={recipient || field.value}
      />
      {!isLast && !inputFocus && (
        <Container
          className="pointer"
          onClick={() => handleRemoveRecipient(index)}
        >
          {closeIcon}
        </Container>
      )}
    </Recipient>
  );
};

export default RecipientInput;

const Email = styled.input`
  background: transparent;
  border: none;
  color: ${({ emailValid, isLast, inputFocus }) =>
    emailValid || isLast || inputFocus ? "#242437" : "#ffffff"};
  outline: none;
  padding: 0;
  width: 25ch;
`;

const Recipient = styled(FlexContainer)`
  background: ${({ isLast, emailValid, inputFocus }) =>
    !isLast && inputFocus
      ? "rgba(106, 163, 213, 0.51)"
      : emailValid || isLast
      ? "transparent"
      : "#d93025"};
  border: ${({ emailValid, isLast, inputFocus }) =>
    isLast || inputFocus
      ? "none"
      : emailValid
      ? "1px solid #e1e1e1"
      : "1px solid #d93025"};
  border-radius: ${({ inputFocus }) => (inputFocus ? "0" : "10.5px")};
  color: ${({ emailValid, theme }) =>
    emailValid ? "#242437" : theme.colors.white};
  display: ${({ hide }) => (hide ? "none" : "flex")};
  margin: 0 4px 4px 0;
  padding: ${({ isLast }) => (isLast ? "4px" : "5px 8px 2px 12px")};
  svg {
    fill: ${({ emailValid, theme }) =>
      emailValid ? "#979797" : theme.colors.white};
    margin-left: 4px;
  }
`;
